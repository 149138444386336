import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { SourceType } from 'utils/enums';

const getCardStyles = (theme: GrafanaTheme2) => ({
  statusTooltipContent: css`
    padding: ${theme.spacing(1)};

    small {
      color: ${theme.colors.text.secondary};
    }
  `,
  leftMargin: css`
    margin-left: ${theme.spacing(1)};
  `,
});

export const StatusTooltipContent = ({
  type,
  connectionType,
}: {
  type: 'installed' | 'new-updates';
  connectionType?: SourceType;
}) => {
  const styles = useStyles2((theme) => getCardStyles(theme));
  let status: string | React.JSX.Element = '';

  if (connectionType === SourceType.DataSource) {
    status =
      type === 'installed' ? (
        <p>
          This plugin is installed. View <a href="/connections/datasources">available data sources</a> or{' '}
          <a href="/connections/datasources/new">create new instances</a>
        </p>
      ) : (
        'There are updates available. Click the tile to view details and install the new updates.'
      );
  } else {
    status =
      type === 'installed'
        ? 'This integration has been installed. Proceed to the integration details page to follow the configuration instructions to configure this integration on a new machine.'
        : 'There are updates available for this integration. Proceed to the integration details page to install the new updates.';
  }
  return (
    <div className={styles.statusTooltipContent}>
      <div>
        <Icon name={type === 'installed' ? 'check' : 'sync'} color="inherit" size="xs" />
        <b className={styles.leftMargin}>{type === 'installed' ? 'Installed' : 'Update available'}</b>
      </div>
      <br />
      <small>{status}</small>
    </div>
  );
};
