import React from 'react';

import { DEFAULT_PROM_URL, GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';
import { PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

export const DEFAULT_COLLECTOR_URL = 'localhost';
export const DEFAULT_COLLECTOR_PORT = '12345';

const getPrometheusRemoteWriteConfig = (
  hmInstancePromUrl: string,
  agentUrl: string,
  agentPort: string,
  instanceId?: string,
  token?: string,
  remoteWrite?: PrometheusRemoteWrite
) => {
  let url = hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom/push` : DEFAULT_PROM_URL;
  let withAuth = true;
  if (remoteWrite === PrometheusRemoteWrite.ViaAlloy) {
    if (!agentUrl) {
      agentUrl = DEFAULT_COLLECTOR_URL;
    }
    if (!agentPort) {
      agentPort = DEFAULT_COLLECTOR_PORT;
    }
    url = `https://${agentUrl}:${agentPort}/agent/api/v1/metrics/instance/hosted-prometheus/write`;
    withAuth = false;
  }

  if (withAuth) {
    return `remote_write:
  - url: ${url}
    basic_auth:
      username: ${instanceId ? instanceId : GRAFANA_EXAMPLE_USER}
      password: ${token ? token : GRAFANA_EXAMPLE_API}`;
  } else {
    return `remote_write:
  - url: ${url}`;
  }
};

export const getPrometheusCompleteConfig = (
  hmInstancePromUrl: string,
  agentUrl: string,
  agentPort: string,
  value: PrometheusOptions,
  instanceId?: string,
  token?: string,
  remoteWrite?: PrometheusRemoteWrite
) => {
  const snippet = getPrometheusRemoteWriteConfig(
    hmInstancePromUrl,
    agentUrl,
    agentPort,
    instanceId,
    token,
    remoteWrite
  );

  const config = {
    [PrometheusOptions.SingleInstance]: `global:
  scrape_interval: 60s
${snippet}`,
    [PrometheusOptions.MultipleInstances]: `global:
  scrape_interval: 60s
  external_labels:
    origin_prometheus: prometheus01
${snippet}`,
    [PrometheusOptions.MultipleInstancesHa]: `global:
  scrape_interval: 60s
  external_labels:
    cluster: prom-team1
    __replica__: replica1
${snippet}`,
  };

  return `${config[value]}
scrape_configs:
  - job_name: node
    static_configs:
      - targets: ["localhost:9090"]`;
};

export const getAlloySnippet = (hmInstancePromUrl: string, instanceId: string, token?: string) => {
  const url = hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom/push` : DEFAULT_PROM_URL;

  return `prometheus.remote_write "metrics_hosted_prometheus" {
   endpoint {
      name = "hosted-prometheus"
      url  = "${url}"
  
      basic_auth {
        username = "${instanceId ? instanceId : GRAFANA_EXAMPLE_USER}"
        password = "${token ? token : GRAFANA_EXAMPLE_API}"
      }
   }
}`;
};

export const getAlloyCompleteConfig = (
  hmInstancePromUrl: string,
  configFileName: string,
  instanceId: string,
  token?: string
) => {
  const url = hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom/push` : DEFAULT_PROM_URL;

  return `${configFileName ? `cat << EOF > ${configFileName}\n` : ''}prometheus.scrape "metrics_hosted_prometheus_node" {
  targets = [{
    __address__ = "localhost:9100",
  }]
  forward_to = [prometheus.remote_write.metrics_hosted_prometheus.receiver]
  job_name   = "node"
}
prometheus.remote_write "metrics_hosted_prometheus" {
   endpoint {
      name = "hosted-prometheus"
      url  = "${url}"
    
      basic_auth {
        username = "${instanceId ? instanceId : GRAFANA_EXAMPLE_USER}"
        password = "${token ? token : GRAFANA_EXAMPLE_API}"
      }
   }
}${configFileName ? '\nEOF' : ''}`;
};

export const AdditionalOptions = ({ value }: { value: string }) => {
  const title = <h2>Suggested additional action</h2>;

  switch (value) {
    case PrometheusOptions.MultipleInstances:
      return (
        <>
          {title}
          <p>
            Optionally, edit the values for the <code>origin_prometheus</code> label for each of your Prometheus
            instances, which will help you query specific data from various instances.
          </p>
        </>
      );

    case PrometheusOptions.MultipleInstancesHa:
      return (
        <>
          {title}
          <p>
            Optionally, edit the values for the <code>cluster</code> and <code>__replica__</code> labels, which will
            help you query your Prometheus data from one cluster when another cluster is down. Learn more about
            high-availability instances.
          </p>
        </>
      );

    default:
      return null;
  }
};

const getCompleteConfigSuccessMessage = (remoteWrite?: PrometheusRemoteWrite) => {
  if (remoteWrite === PrometheusRemoteWrite.Direct) {
    return 'Copy and paste this snippet in your prometheus.yml file to forwards metrics to Grafana Cloud.';
  } else if (remoteWrite === PrometheusRemoteWrite.ViaAlloy) {
    return 'Append this snippet in your Grafana Alloy config file to forwards metrics to Grafana Cloud.';
  } else {
    return 'Copy and run this code to create a new Alloy configuration file and forward metrics to Grafana Cloud.';
  }
};

const getSnippetSuccessMessage = (remoteWrite?: PrometheusRemoteWrite) => {
  if (!remoteWrite) {
    return 'Append this code into your Alloy configuration file to push data into Grafana Cloud Prometheus metrics instance.';
  }
  return 'Locate your Alloy configuration file and append this code to push data into Grafana Cloud Prometheus metrics instance.';
};

export const getSuccessMessage = (isSnippet: boolean, remoteWrite?: PrometheusRemoteWrite) =>
  isSnippet ? getSnippetSuccessMessage(remoteWrite) : getCompleteConfigSuccessMessage(remoteWrite);
