import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { SelectGroup } from 'components/SelectGroup';
import { useCollectorToken } from 'hooks/useCollectorToken';
import { useQueryParam } from 'hooks/useQueryParam';
import { PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

import { RemoteWriteDirect } from './RemoteWriteDirect';
import { RemoteWriteViaAlloy } from './RemoteWriteViaAlloy';
import { getPrometheusCompleteConfig } from './helper';

type RemoteWriteContentProps = {
  value: PrometheusOptions;
  remoteWrite: PrometheusRemoteWrite;
};

const remoteWriteOptions = [
  {
    value: PrometheusRemoteWrite.Direct,
    label: 'Directly',
    description: 'Remote write metrics directly from Prometheus to Grafana Cloud',
  },
  {
    value: PrometheusRemoteWrite.ViaAlloy,
    label: 'Via Grafana Alloy',
    description: 'Remote write metrics to Grafana Cloud using a component-based telemetry collector',
  },
];

const getStyles = (theme: GrafanaTheme2) => ({
  list: css({
    p: {
      fontSize: theme.typography.body.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
  selectGroup: css({
    marginBottom: theme.spacing(2),
  }),
  text: css({
    color: theme.colors.text.disabled,
  }),
});

export const RemoteWriteContent = ({ value, remoteWrite }: RemoteWriteContentProps) => {
  const styles = useStyles2(getStyles);
  const token = useCollectorToken();
  const [_, setRemoteWrite] = useQueryParam('remoteWrite');
  const { hmInstancePromId, hmInstancePromUrl } = useHostedDataDetailsWithFallback();

  function onRemoteWriteOptionChange(option: PrometheusRemoteWrite) {
    setRemoteWrite(option, true);
  }

  return (
    <ol className={styles.list}>
      <li>
        <h2>Select how to send the metrics from Prometheus to Grafana Cloud</h2>
        <SelectGroup
          options={remoteWriteOptions}
          value={remoteWrite}
          onChange={onRemoteWriteOptionChange}
          className={styles.selectGroup}
        />
      </li>
      {remoteWrite === PrometheusRemoteWrite.Direct && (
        <RemoteWriteDirect
          configCode={getPrometheusCompleteConfig(
            hmInstancePromUrl,
            '',
            '',
            value,
            hmInstancePromId.toString(),
            token,
            remoteWrite
          )}
          value={value}
        />
      )}
      {remoteWrite === PrometheusRemoteWrite.ViaAlloy && <RemoteWriteViaAlloy value={value} />}
    </ol>
  );
};
