import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDashboards } from 'api/baseApi';
import { Dashboard } from 'types/dashboard';

import { setBillingUrl, DEFAULT_BILLING_URL } from './slice';

export const fetchBillingUrl = createAsyncThunk('dashboards/fetchBillingUrl', async (params, thunkAPI) => {
  try {
    const dashboards = await getDashboards<Dashboard[]>();
    const billingDashboard = dashboards.data.filter(
      (dashboard: Dashboard) => dashboard.uri === 'db/grafana-cloud-billing-usage'
    )[0];
    thunkAPI.dispatch(setBillingUrl(billingDashboard ? billingDashboard.url : DEFAULT_BILLING_URL));
  } catch (error) {
    thunkAPI.dispatch(setBillingUrl(DEFAULT_BILLING_URL));
  }
});
