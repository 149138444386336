import { SyntheticIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const SYNTHETIC_MONITORING_ID = 'synthetic-monitoring-app';

export const syntheticMonitoring: Source = {
  id: SYNTHETIC_MONITORING_ID,
  name: 'Synthetic Monitoring',
  logo_url: SyntheticIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: '/a/grafana-synthetic-monitoring-app',
  categories: ['Networking', 'Security'],
};
