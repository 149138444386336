import React, { FC } from 'react';

import { useStyles2, LinkButton } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

import { getInstructionsStyles } from './InstructionsComponent.styles';

export const InstructionsComponent: FC = () => {
  const styles = useStyles2(getInstructionsStyles);
  return (
    <ol className={styles.instructions}>
      <li className={styles.instructionItem}>
        <h2>Create a new API key in Confluent Cloud</h2>
        <p>
          To configure your Confluent Cloud integration,&nbsp;
          <TextLink
            href={'https://docs.confluent.io/cloud/current/monitoring/metrics-api.html#metrics-quick-start'}
            external
            inline
          >
            you need to create a Cloud API key with the MetricsViewer role.
          </TextLink>
        </p>
        <LinkButton target="_blank" href="https://confluent.cloud/" variant="primary">
          Open Confluent Cloud
        </LinkButton>
      </li>
      <li className={styles.instructionItem}>
        <h2>Create a new scrape job</h2>
        <p>
          A scrape job consists of an API key and one or more resources. You can find the API key and resource
          information in Confluent Cloud. We recommend you to create a scrape job for each group of resources that are
          connected together. This will give you the ability to filter and analyse them as a group on your dashboards,
          separated from other unrelated resources (i.e. a Connector linked to a different Kafka Cluster), using your
          scrape job name.&nbsp;
          <TextLink
            href={'https://grafana.com/docs/grafana-cloud/integrations/integrations/integration-confluent-cloud/'}
            external
            inline
          >
            Learn more about scrape jobs for the Confluent Cloud integration.
          </TextLink>
        </p>
      </li>
    </ol>
  );
};
