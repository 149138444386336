import useLocalStorageState from 'use-local-storage-state';

import { Pages } from './Pages';

type AgentModeSelection = Pages.Alloy | Pages.Static | undefined;

export const agentModeSelectionKey = 'grafana.connections.AgentModeSelection';

export function useAgentModeSelection(): {
  agentModeSelection: AgentModeSelection;
  setAgentModeSelection: (selection: AgentModeSelection) => void;
} {
  const [selection, setSelection] = useLocalStorageState<AgentModeSelection>(agentModeSelectionKey);

  return {
    agentModeSelection: selection,
    setAgentModeSelection: (selection) => setSelection(selection),
  };
}
