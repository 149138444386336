import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getFilterStyles = (theme: GrafanaTheme2) => ({
  wrapper: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    rowGap: '10px',
  }),
  leftContent: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '10px',
  }),
  rightContent: css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '40px',
  }),
  statusFilter: css({
    width: '315px',
  }),
  narrowStatusFilter: css({
    width: '150px',
  }),
  selectSort: css({
    marginLeft: theme.spacing(1),
  }),
  hiddenContainer: css({
    position: 'absolute',
    visibility: 'hidden',
    pointerEvents: 'none',
  }),
});
