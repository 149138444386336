import React, { FC, useEffect } from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import {
  AlloyStatus,
  ConnectionTestButton,
  ConnectionTestStatus,
  setAgentCheckInstalled,
  setAgentStatus,
  useCollectorDispatch,
  useCollectorSelector,
} from '@grafana-cloud/collector';

import { getModalFooterStyles } from '../ModalFooterStyles';

type Props = {
  isDisabled: boolean;
  onClose: () => void;
};

export const AlloyModalFooter: FC<Props> = ({ isDisabled, onClose }) => {
  const styles = useStyles2(getModalFooterStyles);
  const collectorDispatch = useCollectorDispatch();

  const alloyStatus = useCollectorSelector((state) => state.collector.agentStatus);
  const isConnectionSuccessful = alloyStatus === AlloyStatus.Success;

  useEffect(() => {
    collectorDispatch(setAgentStatus(AlloyStatus.None));
    collectorDispatch(setAgentCheckInstalled(false));
  }, [collectorDispatch]);

  return (
    <div className={styles.footer}>
      <ConnectionTestStatus />

      <div className={styles.buttonRow}>
        <ConnectionTestButton isDisabled={isDisabled} />
        <Button variant={isConnectionSuccessful ? 'primary' : 'secondary'} onClick={onClose}>
          Proceed to install integration
        </Button>
      </div>
    </div>
  );
};
