import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { handleKeyDown } from 'utils/aria';

import { getStyles as getCardElementStyles } from './CardElement';
import { OptionValue } from './SelectGroup';

interface ThreeLevelCardProps<T = any> {
  option: OptionValue;

  isSelected?: boolean;
  onClick?: (value?: T) => void;
  size?: 'md' | 'base';
}

const getStyles = (theme: GrafanaTheme2) => ({
  title: css({
    color: theme.colors.text.maxContrast,
  }),
  icon: css({
    width: theme.spacing(4),
    height: 'auto',
    marginRight: theme.spacing(1.5),
  }),
});

export const ThreeLevelCard = ({ option, isSelected, onClick }: ThreeLevelCardProps) => {
  const cardElementStyles = useStyles2(getCardElementStyles);
  const styles = useStyles2(getStyles);

  return (
    <div
      onClick={onClick}
      onKeyDown={handleKeyDown(onClick)}
      tabIndex={0}
      role="button"
      className={cx(cardElementStyles.selectElement, isSelected && cardElementStyles.selected)}
    >
      <div className={cardElementStyles.titleWrapper}>
        {option.icon && <img src={option.icon} className={styles.icon} alt={`icon of ${option.value}`} />}
        <h4 className={styles.title}>{option.label}</h4>
      </div>
      {option.subtitle && <p>{option.subtitle}</p>}
      {option.description && <p className={cardElementStyles.description}>{option.description}</p>}
      <span className={cardElementStyles.checkbox}>
        {isSelected && <Icon className={cardElementStyles.checked} name="check" size="xl" />}
      </span>
    </div>
  );
};
