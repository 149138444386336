import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Collapse, useStyles2 } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { useAgentDetailsQuery } from 'api/integrations/integrationsRtqApi';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';

import { resetConfiguredParameters } from '../state/slice';
import { useCollectorMode } from '../useCollectorMode';
import { areFilteredBlocksPresent } from '../utils';

import { AlertsToggle } from './AlertsToggle';
import { FilteredMetricsToggle, LogsToggle } from './ConfigurableParameters';
import { getStyles } from './MakeConfigurationSelectionsSection.styles';
import { SaveConfigurationSelections } from './SaveConfigurationSelections';
import { SetupModeSelector } from './SetupModeSelector';

export const MakeConfigurationSelectionsSection = () => {
  const styles = useStyles2(getStyles);

  const source = useSelectedAgentOrSaasIntegration();
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const agentMode = useCollectorMode();
  const integrationId = useSelectedIntegrationId();
  const { data: agentDetails } = useAgentDetailsQuery({ integrationId, mode: agentMode });

  const setupMode = useSelector((state: RootState) => state.agent.configuredParameters.setupMode);

  const shouldShowFilteredMetricsToggle =
    agentDetails === undefined
      ? false
      : agentDetails.contentType === 'static'
        ? agentDetails.static.enableHostnameRelabelAndFilter
        : areFilteredBlocksPresent(agentDetails, setupMode);

  const shouldShowLogsToggle = Boolean(sourceDetails.configuration_defaults?.enable_logs_toggle);

  const shouldShowAlertsToggle = Boolean(sourceDetails.configuration_defaults?.enable_alerts_toggle);

  const shouldShowSetupModeSelector = agentDetails?.contentType === 'alloy-both-modes';

  const shouldShowMakeOptionalSelectionsBox =
    shouldShowFilteredMetricsToggle || shouldShowLogsToggle || shouldShowAlertsToggle || shouldShowSetupModeSelector;

  // shouldShowSaveConfigurationButton reflects parameters that we save to the backend
  const shouldShowSaveConfigurationButton =
    Boolean(source.installation) && (shouldShowLogsToggle || shouldShowAlertsToggle);

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetConfiguredParameters());
    };
  }, [dispatch]);

  if (!shouldShowMakeOptionalSelectionsBox) {
    return null;
  }

  return (
    <li>
      <h2>Make configuration selections</h2>
      <p className={styles.p}>
        To help us generate configuration snippets that are customized for you, make integration selections below.
      </p>

      {shouldShowMakeOptionalSelectionsBox && (
        <Collapse
          label={<h3 className={styles.noMargin}>Make optional selections</h3>}
          className={styles.step}
          isOpen={isCollapseOpen}
          onToggle={() => setIsCollapseOpen((v) => !v)}
          collapsible
        >
          <div className={styles.container}>
            {shouldShowFilteredMetricsToggle && <FilteredMetricsToggle />}
            {shouldShowLogsToggle && <LogsToggle />}
            {shouldShowAlertsToggle && <AlertsToggle />}
            {shouldShowSetupModeSelector && <SetupModeSelector />}
            {shouldShowSaveConfigurationButton && <SaveConfigurationSelections />}
          </div>
        </Collapse>
      )}
    </li>
  );
};
