import { CloudIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const CSP_APP_ID = 'grafana-csp-app';

export const cspApp: Source = {
  id: CSP_APP_ID,
  name: 'Cloud provider',
  logo_url: CloudIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: `/a/${CSP_APP_ID}`,
  search_keywords: [
    'amazon',
    'aws',
    'monitoring',
    'metrics',
    's3',
    'ec2',
    'cloudfront',
    'lambda',
    'ebs',
    'rds',
    'azure',
    'gcp',
    'blob',
  ],
  categories: ['Cloud Provider', 'Logs', 'Servers and VMs'],
};
