import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { AlloyInstallationInstructions, useCollectorSelector } from '@grafana-cloud/collector';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

import { AlloyModalFooter } from '../AgentConnection/AlloyModalFooter';
import { clickOpenAgentConfigModal } from '../state/slice';

export const AlloyConfigModal: FC<{ showOSSelection: boolean }> = ({ showOSSelection }) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const existingToken = useCollectorSelector((state) => state.collector.existingToken);
  const token = useCollectorSelector((state) => state.collector.token);
  const selectedIntegrationId = useSelectedIntegrationId();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        size="md"
        onClick={() => {
          setShowModal(true);
          dispatch(clickOpenAgentConfigModal(selectedIntegrationId));
        }}
        data-testid={Pages.Source.AgentConfig.agentConfigButton}
      >
        Run Grafana Alloy
      </Button>
      <Modal
        title="Alloy configuration"
        isOpen={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
        contentClassName={styles.modalContent}
      >
        <AlloyInstallationInstructions showTestConnection={false} showOSSelection={showOSSelection} />
        <AlloyModalFooter isDisabled={!(token || existingToken)} onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modalContent: css({
    display: 'flex',
    flexDirection: 'column',
    ol: {
      marginLeft: '10px',
      li: {
        marginBottom: '40px',
        '> p:first-child': {
          fontSize: theme.typography.h5.fontSize,
        },

        '> h2': {
          fontSize: theme.typography.h5.fontSize,
        },
      },
      overflow: 'scroll',
      padding: '0 4px 0 4px',
      flexGrow: 1,
    },
  }),
});
