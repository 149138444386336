import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventSource } from 'utils/enums';

import { SortIntegration } from '../Filters/filterTypes';
import { Category } from '../types/Category';
import { FilterIntegration } from '../types/FilterIntegration';

type catalogState = {};

const initialState: catalogState = {};

type PayloadActionWithEventSource<T> = PayloadAction<{ value: T; source: EventSource }>;

// Kept around for userTracking
const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadActionWithEventSource<string>) => {},
    setSearchCat: (state, action: PayloadActionWithEventSource<string>) => {},
    setSortFilter: (state, action: PayloadActionWithEventSource<SortIntegration>) => {},
    resetSort: (state) => {},
    setFilterIntegrations: (state, action: PayloadActionWithEventSource<FilterIntegration>) => {},
    resetFilterIntegrations: (state) => {},
    setCategoryFilter: (state, action: PayloadAction<{ category: Category }>) => {},
    initCategoryFilter: (state, action: PayloadAction<Category | null>) => {},
    removeCategoryFilter: (state) => {},
    resetFilters: (state) => {},
    seeUpdates: (state) => {},
  },
});

// eslint-disable-next-line import/no-unused-modules
export const {
  setSearch,
  setSearchCat,
  setFilterIntegrations,
  setSortFilter,
  setCategoryFilter,
  initCategoryFilter,
  removeCategoryFilter,
  resetFilterIntegrations,
  resetSort,
  resetFilters,
  seeUpdates,
} = catalogSlice.actions;
export default catalogSlice.reducer;
