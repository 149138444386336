import { RootState } from 'state';
import { Source } from 'types/Source';
import { filterBySearchTerm } from 'utils/filterBySearchTerm';

export const filterInstalledIntegrations = (state: RootState, installedIntegrations: Source[]) => {
  const { searchTerm, isSortAscending } = state.integrations;

  const filteredIntegrations = filterBySearchTerm(installedIntegrations, searchTerm);

  const result = filteredIntegrations.sort(
    isSortAscending ? (a, b) => a.name.localeCompare(b.name) : (a, b) => b.name.localeCompare(a.name)
  );

  return result;
};
