import React from 'react';

import { StatusMessage } from 'components/StatusMessage';
import { TextLink } from 'components/TextLink';
import { AGENT_FAQ_LINK } from 'utils/consts';

export const TestStatusError = () => {
  return (
    <StatusMessage status="error">
      Oops! Something went wrong. Make sure the install instructions were copied correctly and check for any optional
      configurations. If you&apos;re still running into issues, read the{' '}
      <TextLink href={AGENT_FAQ_LINK} external inline>
        troubleshooting instructions.
      </TextLink>
    </StatusMessage>
  );
};
