import { css } from '@emotion/css';
import React, { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, Select, useStyles2, Icon, LinkButton } from '@grafana/ui';

import { Resource } from 'models/api-models';
import { FormErrors } from 'utils/consts';

const getJobFormStyles = (theme: GrafanaTheme2) => ({
  spacer: css`
    padding: 20px 0;
    border-top: 1px solid ${theme.components.input.borderColor};
  `,
  resource: css`
    position: relative;
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
  `,
  singleResource: css`
    display: flex;
    align-items: center;

    > div:not(:last-child) {
      width: 40%;
      margin: 0 ${theme.spacing(2)} ${theme.spacing(2)} 0;
    }
  `,
  dropdown: css`
    font-size: ${theme.typography.body.fontSize};
  `,
  link: css`
    margin-left: 0.2rem;
    color: ${theme.colors.text.link};
    font-size: ${theme.typography.body.fontSize};
  `,
});

type ResourceField = Resource & {
  id: string;
};

interface FormValues {
  resources: Resource[];
  apiKey: string;
  apiSecret: string;
  name: string;
}

const resourceTypes: string[] = ['Kafka Cluster', 'Kafka Connector', 'ksqlDB', 'Schema Registry'];

export const AddResources: FC<{ isAppendButtonEnabled?: boolean }> = ({ isAppendButtonEnabled = true }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<FormValues>();
  const styles = useStyles2(getJobFormStyles);

  const { fields, append, remove } = useFieldArray({
    name: 'resources',
    control,
  });

  const singleResource = (field: ResourceField, fieldIndex: number, allFields: ResourceField[]) => (
    <div className={styles.singleResource} key={field.id}>
      <Field
        label="Resource Type"
        invalid={errors.resources && errors.resources[fieldIndex]?.resourceType ? true : undefined}
        error={
          errors.resources && errors.resources[fieldIndex]?.resourceType ? 'At least one resource is required' : ''
        }
        htmlFor={`resources.${fieldIndex}.resourceType`}
      >
        <Controller
          control={control}
          name={`resources.${fieldIndex}.resourceType`}
          rules={{ required: true }}
          defaultValue={field.resourceType}
          render={({ field: { value, onChange } }) => (
            <Select<string>
              options={resourceTypes.map((resourceType) => ({
                label: resourceType,
                value: resourceType,
              }))}
              placeholder="Choose"
              inputId={`resources.${fieldIndex}.resourceType`}
              noOptionsMessage="Unable to load resource types."
              className={styles.dropdown}
              value={value}
              onChange={(option) => onChange(option.value)}
            />
          )}
        />
      </Field>
      <Field
        label="Resource ID"
        invalid={errors.resources && errors.resources[fieldIndex]?.resourceId ? true : undefined}
        error={errors.resources && errors.resources[fieldIndex]?.resourceId ? FormErrors.REQUIRED_FIELD : ''}
      >
        <Input
          width={31}
          {...register(`resources.${fieldIndex}.resourceId`, { required: true })}
          id={`resource-id-${fieldIndex}`}
          defaultValue={field.resourceId}
        />
      </Field>
      {fields.length > 1 && <Icon name="trash-alt" onClick={() => remove(fieldIndex)} />}
    </div>
  );

  return (
    <div className={styles.spacer}>
      <h4>Add Resources</h4>
      <p>
        Add one or more resources that will be scraped. These can be found on your Confluent Dashboard, for more
        information visit
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          href="https://docs.confluent.io/cloud/current/access-management/authenticate/api-keys/api-keys.html#cloud-api-key-resource"
        >
          Confluent Resources
        </a>
        .
      </p>
      <h6>Resources</h6>
      <div className={styles.resource}>
        {fields.map((field, index) => singleResource(field, index, fields))}
        <LinkButton
          variant="secondary"
          aria-label="Add resource"
          onClick={() => append({ resourceType: '', resourceId: '' })}
          disabled={!isAppendButtonEnabled}
        >
          Add Resource
        </LinkButton>
      </div>
    </div>
  );
};
