import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { getArchOptions, showOSSelector, useCollectorSelector, useOsOptions } from '@grafana-cloud/collector';
import { OSSelectionWrapper } from 'components/OSSelectionWrapper';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

const getStyles = (theme: GrafanaTheme2) => ({
  p: css`
    margin-top: 10px;
  `,
  topMargin: css`
    margin-top: ${theme.spacing(3)};
  `,
});

export const PlatformSelectionSection = () => {
  const styles = useStyles2(getStyles);

  const integrationId = useSelectedIntegrationId();
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);

  const { data: osOptions } = useOsOptions(integrationId);
  const archOptions = useMemo(
    () => selectedOs.osValue && getArchOptions(integrationId, selectedOs.osValue),
    [integrationId, selectedOs.osValue]
  );

  const shouldShowOsSelector = osOptions && showOSSelector(osOptions, archOptions);

  if (!shouldShowOsSelector) {
    return null;
  }

  return (
    <li>
      <h2>Select platform</h2>
      <p className={styles.p}>Select a platform environment to generate a configuration component.</p>
      <div className={styles.topMargin}>
        <OSSelectionWrapper />
      </div>
    </li>
  );
};
