/* istanbul ignore file */
import { ComponentClass } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';

import { initializeFaro } from 'faro';
import { App } from 'features/app';

export const faro = initializeFaro();
export const plugin = new AppPlugin().setRootPage(App as unknown as ComponentClass<AppRootProps>);
