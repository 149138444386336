import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

import { ALLOY_LINK, ALLOY_SYNTAX_LINK } from '../consts';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.body.fontSize,
  }),
});

export const AlloyDescription = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.container}>
      <p>
        Grafana Alloy is a component-based telemetry collector. It focuses on ease of use, adaptability to your needs,
        and easy debugging. You configure Grafana Alloy with{' '}
        <TextLink href={ALLOY_SYNTAX_LINK} external inline>
          Alloy Configuration Syntax
        </TextLink>
        , an HCL-inspired expression language that uses small reusable blocks that work together to form programmable
        pipelines.
      </p>

      <LinkButton icon="question-circle" href={ALLOY_LINK} target="_blank" rel="noreferrer" fill="text">
        Read more about Grafana Alloy
      </LinkButton>
    </div>
  );
};
