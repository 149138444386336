import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Field, Input, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { ConnectionCheckButton } from 'components/SaasIntegration/ConnectionCheckButton';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { MetricsEndpointFlavor } from 'models/api-models';
import { checkMetricsEndpointConnection } from 'state/metricsEndpoint/actions';

const getStyles = () => ({
  radio: css`
    margin-bottom: 10px;
  `,
});

const authOptions = [
  {
    label: 'Basic',
    value: 'basic',
  },
  {
    label: 'Bearer',
    value: 'bearer',
  },
];

const AuthField = ({ onSuccess }: { onSuccess: () => void }) => {
  const styles = useStyles2(getStyles);
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const state = watch();
  const dispatch = useDispatch();

  const onCheckConnection = useCallback(() => {
    const {
      basicPassword: basic_password,
      basicUsername: basic_username,
      url,
      bearerToken: bearer_token,
      authenticationMethod: authentication_method,
    } = state;
    dispatch(
      checkMetricsEndpointConnection({
        payload: {
          basic_password,
          basic_username,
          url,
          bearer_token,
          authentication_method,
          flavor: MetricsEndpointFlavor.Default,
        },
        mainErrorField: authentication_method,
      })
    );
  }, [dispatch, state]);

  return (
    <>
      <Field label="Type of Authentication Credentials">
        <Controller
          name="authenticationMethod"
          control={control}
          render={({ field }) => (
            <RadioButtonGroup
              className={styles.radio}
              options={authOptions}
              value={field.value}
              onChange={(value) => field.onChange(value)}
              aria-label="Authentication Method"
            />
          )}
        />
      </Field>
      {state.authenticationMethod === 'basic' && (
        <>
          <Field
            label="Basic Username"
            invalid={errors.basicUsername ? true : undefined}
            error="Valid basic username is required"
          >
            <Input
              type="text"
              aria-label="username"
              id="username"
              placeholder="Username"
              data-testid={Pages.MetricsEndpointIntegration.authParam('basic-username')}
              {...register('basicUsername', { required: true })}
            />
          </Field>
          <Field
            label="Basic Password"
            invalid={errors.basicPassword ? true : undefined}
            error="Valid basic password is required"
          >
            <Input
              type="password"
              aria-label="password"
              id="password"
              placeholder="Password"
              data-testid={Pages.MetricsEndpointIntegration.authParam('basic-password')}
              {...register('basicPassword', { required: true })}
            />
          </Field>
        </>
      )}
      {state.authenticationMethod === 'bearer' && (
        <Field
          label="Bearer Token"
          invalid={errors.bearerToken ? true : undefined}
          error="Valid bearer token is required"
        >
          <Input
            type="password"
            aria-label="bearer token"
            id="bearer_token"
            placeholder="Bearer Token"
            data-testid={Pages.MetricsEndpointIntegration.authParam('bearer-token')}
            {...register('bearerToken', { required: true })}
          />
        </Field>
      )}
      <ConnectionCheckButton
        onSuccess={onSuccess}
        disabled={
          errors.url !== undefined ||
          (state.authenticationMethod === 'basic' && (!state.basicUsername || !state.basicPassword)) ||
          (state.authenticationMethod === 'bearer' && !state.bearerToken)
        }
        onCheckConnection={() => onCheckConnection}
        successMessage="All good! Successfully scraped metrics from URL"
      />
    </>
  );
};

export default AuthField;
