import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';

import { ConnectionCheckButton } from 'components/SaasIntegration/ConnectionCheckButton';
import { useDispatch } from 'hooks/useDispatch';
import { MetricsEndpointFlavor } from 'models/api-models';
import { checkMetricsEndpointConnection } from 'state/metricsEndpoint/actions';

const getAuthFieldStyles = () => ({
  descriptionWithCodeBlock: css({
    // the height of a <code> element is 23px
    lineHeight: '23px',
  }),
});

const AuthField = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useDispatch();
  const styles = getAuthFieldStyles();
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const state = watch();

  const onCheckConnection = useCallback(() => {
    const { apiKey: api_key, projectId: project_id } = state;
    dispatch(
      checkMetricsEndpointConnection({
        payload: { supabase: { api_key, project_id }, flavor: MetricsEndpointFlavor.Supabase },
        mainErrorField: project_id,
        urlField: 'Project Reference ID',
      })
    );
  }, [dispatch, state]);

  return (
    <>
      <Field
        label="Project Reference ID"
        invalid={errors.projectId ? true : undefined}
        error="Valid project reference ID is required"
        description={
          <div className={styles.descriptionWithCodeBlock}>
            Your project reference ID can also be found in your project URL, such as{' '}
            <code>https://&lt;project-reference-id&gt;.supabase.co</code>
          </div>
        }
      >
        <Input
          type="text"
          aria-label="project reference ID"
          id="project-reference-id"
          placeholder="Project Reference ID"
          {...register('projectId', {
            required: 'Valid project reference ID is required',
          })}
        />
      </Field>
      <Field
        label="Service role API Key"
        invalid={errors.apiKey ? true : undefined}
        error="Valid API key is required"
        description={
          <div className={styles.descriptionWithCodeBlock}>
            The <code>service_role</code> key is needed to scrape metrics from Supabase
          </div>
        }
      >
        <Input
          type="password"
          aria-label="service role api key"
          id="service-role-api-key"
          placeholder="Service role API Key"
          {...register('apiKey', {
            required: 'Valid API key is required',
          })}
        />
      </Field>
      <ConnectionCheckButton
        onSuccess={onSuccess}
        disabled={!state.projectId || !state.apiKey}
        onCheckConnection={() => onCheckConnection}
        successMessage="All good! Successfully scraped metrics from URL"
      />
    </>
  );
};

export default AuthField;
