import { useEffect, useMemo, useRef } from 'react';

import { useQueryParams } from './useQueryParams';

/**
 * Hook for reading and writing a single query parameter.
 * Accepts a parameter name and an optional callback for when the parameter changes,
 * for syncing with external state or triggering side effects.
 * Returns an array [value, function to update the value].
 */
export const useQueryParam = (
  param: string,
  sync?: (value: string | undefined, fromURL: boolean) => void
): [string | undefined, (value: string | undefined, replace?: boolean) => void] => {
  const syncRef = useRef({
    sync,
    initialized: false,
  });

  // Keep a reference to the sync callback
  syncRef.current.sync = sync;

  const [params, update] = useQueryParams();
  const value = useMemo(
    () => (typeof params[param] === 'string' ? (params[param] as string) : undefined),
    [param, params]
  );

  useEffect(() => {
    const { sync, initialized } = syncRef.current;
    if (sync) {
      sync(value, !initialized);
      syncRef.current.initialized = true;
    }
  }, [value]);

  // avoid unnecessary re-renders
  const setValue = useMemo(() => {
    return (newValue: string | undefined, replace?: boolean) => {
      update({ [param]: newValue }, replace);
    };
  }, [param, update]);

  return [value, setValue];
};
