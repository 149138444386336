import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { TextLink } from 'components/TextLink';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    marginLeft: theme.spacing(3),
    li: {
      '::marker': {
        fontSize: theme.typography.h3.fontSize,
      },
      li: {
        marginLeft: '2em',
        '::marker': {
          fontSize: 'unset',
        },
      },
      a: {
        color: theme.colors.text.link,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  }),
  topMargin: css({
    marginTop: theme.spacing(3),
  }),
});

const PreInstructions: FC = () => {
  return (
    <li>
      <h3>Check prerequisites specific to the Velero integration</h3>
      <br />
      <h4>Metrics</h4>
      <p>
        Velero exposes a Prometheus metrics endpoint, <code>/metrics</code>, on Velero containers by default{' '}
      </p>
      <p>You can verify that this endpoint is enabled by running the following commands:</p>
      <Clipboard
        code={`kubectl port-forward -n <namespace> <name-of-velero-pod> 8085:8085 &
curl http://localhost:8085/metrics
        `}
        multipleLines={true}
        clipboardButtonType="below"
      />
      <h4>Logs</h4>
      <p>By default, Velero sends logs to stdout. You can verify this by running the following commands: </p>
      <Clipboard
        code={`kubectl logs -n <namespace> <name-of-velero-pod>`}
        multipleLines={true}
        clipboardButtonType="below"
      />
    </li>
  );
};

const ConfigurationInstallation: FC = () => {
  return (
    <li>
      <h3>Configuration & Installation</h3>
      <br />
      <h4>General Agent Configuration & Installation</h4>
      <p>
        The only way to install the Velero integration is to follow the{' '}
        <TextLink inline href="/a/grafana-k8s-app/configuration">
          Kubernetes Monitoring Configuration
        </TextLink>{' '}
        instructions.
      </p>
      <br />
      <h4>Velero Integration Configuration & Installation</h4>
      <p>
        You can find the Velero integration for Kubernetes{' '}
        <TextLink inline href="/a/grafana-k8s-app/configuration/integrations">
          here
        </TextLink>
        . Follow the instructions for both configuration and installation.
      </p>
      <p>
        To show logs and metrics signals correlated in your dashboards, as a single pane of glass, ensure the following:
        <ul>
          <li>job label must be set to integrations/velero (already configured in the snippets).</li>
        </ul>
      </p>
    </li>
  );
};

export const VeleroInstructions: FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <ol className={styles.instructions}>
      <PreInstructions />
      <ConfigurationInstallation />
    </ol>
  );
};
