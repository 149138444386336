import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { AgentDetailsStatic } from 'api/integrations/types';
import { IntegrationSnippetMarkdown, Markdown } from 'components/Markdown';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { LINUX_NODE_ID, RASPBERRY_PI_NODE_ID } from 'utils/consts';

import { HostnameField } from '../MakeConfigurationSelections/HostnameField';
import { copyToClipboard } from '../state/slice';

import { ConfigFileHelp } from './ConfigFileHelp';
import { useStaticSnippets } from './useSnippets';

interface InstructionsStaticProps {
  agentDetails: AgentDetailsStatic;
}

export const InstructionsStatic = ({ agentDetails }: InstructionsStaticProps) => {
  const source = useSelectedAgentOrSaasIntegration();
  const integrationId = useSelectedIntegrationId();
  const { showLoadingBar, configuredParameters } = useSelector((state: RootState) => state.agent);
  const [shouldHighlightLines, setShouldHighlightLines] = useState(false);

  const isLogsEnabled = configuredParameters.isLogsEnabled === undefined ? true : configuredParameters.isLogsEnabled;

  const {
    integrationSnippet,
    logsSnippet,
    metricsSnippet,
    integrationSnippetHighlightLines,
    logsSnippetHighlightLines,
    metricsSnippetHighlightLines,
    isHostnameRelabelKeyPresentInSnippets,
  } = useStaticSnippets(configuredParameters, agentDetails);
  const isHostnameRelabelEnabled =
    isHostnameRelabelKeyPresentInSnippets &&
    (integrationId === LINUX_NODE_ID ||
      integrationId === RASPBERRY_PI_NODE_ID ||
      agentDetails.static.enableHostnameRelabelAndFilter);

  const dispatch = useDispatch();

  const trackOnCopy = agentDetails.static.enableHostnameRelabelAndFilter
    ? () => dispatch(copyToClipboard(integrationId))
    : undefined;

  return (
    <>
      <h3>Follow instructions specific to the {source.name} integration</h3>
      <ConfigFileHelp />
      {isHostnameRelabelEnabled && (
        <HostnameField onFocus={() => setShouldHighlightLines(true)} onBlur={() => setShouldHighlightLines(false)} />
      )}

      {integrationSnippet && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.integrationInstructionsHeader}>Integration</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={integrationSnippet}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? integrationSnippetHighlightLines : undefined}
          />
        </>
      )}

      {isLogsEnabled && logsSnippet && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.logsInstructionsHeader}>Logs</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={logsSnippet}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? logsSnippetHighlightLines : undefined}
          />
        </>
      )}

      {metricsSnippet && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.metricsInstructionsHeader}>Metrics</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={metricsSnippet}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? metricsSnippetHighlightLines : undefined}
          />
        </>
      )}

      <div data-testid={Pages.Source.AgentConfig.postInstructions}>
        <Markdown markdownSnippet={agentDetails.static.postInstructions} />
      </div>
    </>
  );
};
