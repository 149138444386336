import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Badge, Button, Icon, Modal, useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';
import { handleKeyDown } from 'utils/aria';

import { CONVERT_LINK, ALLOY_LINK, STATIC_CONFIG_LINK, STATIC_LINK, ALLOY_SYNTAX_LINK } from '../consts';

const getStyles = (theme: GrafanaTheme2) => ({
  compareText: css({
    color: theme.colors.info.text,
    cursor: 'pointer',
  }),
  icon: css({
    marginBottom: '4px',
  }),
  table: css({
    td: {
      verticalAlign: 'text-top',
      whiteSpace: 'pre-line',
    },
    tableLayout: 'fixed',
    width: '700px',
    marginBottom: theme.spacing(3),
  }),
  paddingAndBorderBottom: css({
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.colors.border.medium}`,
  }),
  paddingTop: css({
    paddingTop: theme.spacing(2),
  }),
  middleColumn: css({
    width: theme.spacing(6),
  }),
  buttonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
});

export function AgentModeCompare(): React.JSX.Element {
  const styles = useStyles2(getStyles);
  const [showModal, setShowModal] = React.useState(false);
  const title = 'Compare Grafana Alloy and Grafana Agent Static';

  return (
    <>
      <span
        className={styles.compareText}
        role="button"
        tabIndex={0}
        aria-label={title}
        onKeyDown={handleKeyDown(() => setShowModal(true))}
        onClick={() => setShowModal(true)}
      >
        <Icon name="question-circle" className={styles.icon} /> {title}
      </span>
      <Modal isOpen={showModal} onDismiss={() => setShowModal(false)} title={title}>
        <Alert
          title={'The scheduled end-of-life date for Grafana Agent is November 1, 2025.'}
          severity="warning"
          bottomSpacing={3}
        >
          Until November 1, 2025, Grafana Agent will only receive fixes for security issues and critical bugs and will
          no longer receive feature updates. If you do not yet have a Grafana Agent Static configuration file, we
          recommend installing Grafana Alloy instead. If you already have an existing Grafana Agent Static configuration
          file, we recommend using the{' '}
          <TextLink href={CONVERT_LINK} external inline>
            convert command
          </TextLink>{' '}
          to convert your configuration file to Grafana Alloy.
        </Alert>
        <table className={styles.table}>
          <tr>
            <th className={styles.paddingAndBorderBottom}>
              Grafana Alloy <Badge text="New!" color="green" />
            </th>
            <th className={cx(styles.middleColumn, styles.paddingAndBorderBottom)} />
            <th className={styles.paddingAndBorderBottom}>Grafana Agent Static</th>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              100% native compatibility with OpenTelemetry across metrics, logs and traces.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>Only OTel compatible with traces.</td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Includes a UI for debugging the state of a pipeline.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Includes troubleshooting docs for common problems.
            </td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Built for scale: Grafana Alloy has native clustering, so fleets of collectors work together for workload
              distribution and high availability against pull-based workloads.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Can run at scale using manual manipulation of your configuration file and management using 3rd party
              tools.
            </td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              HCP Vault support which helps to avoid managing authentication credentials and tokens locally.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>No built-in security support.</td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              GitOps friendly: Alloy introduces a framework which can pull configs from anywhere, like Git, S3 and HTTP
              endpoints.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>Not GitOps friendly.</td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Based on <b>“components”</b> — small, reusable blocks that work together to form programmable pipelines.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Based on multiple independent <b>“subsystems”</b> for metrics, logs and traces.
            </td>
          </tr>
          <tr>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Uses an expression language called{' '}
              <TextLink href={ALLOY_SYNTAX_LINK} external inline>
                Alloy Configuration Syntax
              </TextLink>
              , developed by Grafana Labs and inspired by Terraform HCL.{'\n\n'}Alloy Configuration Syntax uses
              declarative components which are not reliant on indentation or order.
            </td>
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)} />
            <td className={cx(styles.paddingAndBorderBottom, styles.paddingTop)}>
              Configuration files use{' '}
              <TextLink href={STATIC_CONFIG_LINK} external inline>
                YAML
              </TextLink>
              , a common data serialization language.{'\n\n'}YAML requires specific indentation and order of snippets.
            </td>
          </tr>
        </table>
        <p>
          Read more about{' '}
          <TextLink href={ALLOY_LINK} external inline>
            Grafana Alloy
          </TextLink>{' '}
          and{' '}
          <TextLink href={STATIC_LINK} external inline>
            Grafana Agent Static
          </TextLink>
          .
        </p>
        <div className={styles.buttonContainer}>
          <Button onClick={() => setShowModal(false)} variant="secondary">
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}
