import { css } from '@emotion/css';
import React, { useEffect } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Icon, useStyles2 } from '@grafana/ui';

import { useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { AlertFromRtqError } from 'components/AlertFromRtqError';
import useRudderStack from 'hooks/useRudderstack';
import { OnboardingLogo } from 'img/label';
import { Source } from 'types/Source';
import { JAVA_ID } from 'utils/consts';
import { RudderStackEvents } from 'utils/enums';
import { javaLocalSources } from 'utils/localSources';
import { JAVA_OPEN_TELEMETRY_ID } from 'utils/localSources/openTelemetry';

import { CardOnSecondLevel } from './CardOnSecondLevel';

const getStyles = (theme: GrafanaTheme2) => ({
  navIcon: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `,
  container: css`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
  `,
  header: css`
    display: flex;
    align-items: center;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto ${theme.spacing(2)};
    padding: 0 20px;

    h2 {
      margin-bottom: 0;
    }
  `,
  content: css`
    margin-top: ${theme.spacing(4)};
  `,
  img: css`
    margin-right: 10px;
    width: 40px;
  `,
  groupWrapper: css`
    display: flex;
    margin-top: ${theme.spacing(3)};
    margin-bottom: 60px;
  `,
  link: css`
    padding-left: ${theme.spacing(0.5)};
    padding-right: ${theme.spacing(0.5)};
  `,
});

export const Java: React.FC = () => {
  const styles = useStyles2(getStyles);
  const javaIconUrl = 'https://storage.googleapis.com/grafanalabs-integration-logos/java.png';
  const { data: javaSources, isError, error, refetch } = useJavaIntegrationsQuery({});

  const javaOtelIntegration = javaLocalSources.find((source) => source.id === JAVA_OPEN_TELEMETRY_ID) ?? ({} as Source);
  const { trackRudderStackEvent } = useRudderStack();

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.ViewSource, { integration_slug: JAVA_ID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels = [
    {
      label: 'Type',
      value: (
        <div className={styles.navIcon}>
          <img src={OnboardingLogo} height={15} alt="Integration Icon" />
          Integration
        </div>
      ),
    },
    {
      label: 'Author',
      value: (
        <div className={styles.navIcon}>
          <Icon name="grafana" title="Grafana Icon" />
          Grafana Labs
        </div>
      ),
    },
  ];

  return (
    <PluginPage pageNav={{ text: 'Java', img: javaIconUrl }} info={labels}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p>
            If you are starting a new Java project, we recommend to use OpenTelemetry, which provides an easy setup for
            metrics, logs, and traces.
          </p>
          <div className={styles.groupWrapper}>
            <CardOnSecondLevel
              item={javaOtelIntegration}
              key={JAVA_OPEN_TELEMETRY_ID}
              onClick={() => {
                trackRudderStackEvent(RudderStackEvents.CardClick, {
                  integration_slug: JAVA_OPEN_TELEMETRY_ID,
                  page_section: 'java',
                });
              }}
            />
          </div>
          {isError ? (
            <AlertFromRtqError error={error} refetch={refetch} />
          ) : (
            <>
              <p>If you already have a Prometheus based setup, choose one of the following:</p>
              <div className={styles.groupWrapper}>
                {javaSources &&
                  javaSources.map((integration) => (
                    <CardOnSecondLevel
                      item={integration}
                      key={integration.id}
                      onClick={() => {
                        trackRudderStackEvent(RudderStackEvents.CardClick, {
                          integration_slug: integration.id,
                          page_section: 'java',
                        });
                      }}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </PluginPage>
  );
};
