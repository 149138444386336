import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { SelectGroup } from 'components/SelectGroup';
import { useDispatch } from 'hooks/useDispatch';
import { RootState } from 'state';

import { selectSetupMode, SetupMode } from '../state/slice';

const getStyles = (theme: GrafanaTheme2) => ({
  selectGroup: css({
    justifyContent: 'flex-start',
  }),
  paragraph: css({
    marginBottom: theme.spacing(1),
  }),
});

const setupModeOptions = [
  {
    value: SetupMode.Simple,
    label: 'Simple set-up',
    description: 'Choose this option if you are running this integration on a single host or machine.',
  },
  {
    value: SetupMode.Advanced,
    label: 'Advanced set-up',
    description: 'Choose this option if you are running Alloy on a remote host or scraping multiple nodes.',
  },
];

export const SetupModeSelector = () => {
  const styles = useStyles2(getStyles);
  const selectedSource = useSelectedAgentOrSaasIntegration();
  const dispatch = useDispatch();
  const setupMode = useSelector((state: RootState) => state.agent.configuredParameters.setupMode);

  useEffect(() => {
    dispatch(selectSetupMode(SetupMode.Simple));
  }, [dispatch]);

  return (
    <div>
      <p className={styles.paragraph}>
        Choose one of the following two options to configure your {selectedSource.name} integration:
      </p>
      <SelectGroup
        options={setupModeOptions}
        onChange={(value: SetupMode) => dispatch(selectSetupMode(value))}
        value={setupMode ?? SetupMode.Simple}
        className={styles.selectGroup}
        size="base-fixed"
      />
    </div>
  );
};
