import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Select, useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { TextLink } from 'components/TextLink';
import { useApiToken } from 'hooks/useApiToken';
import { DEFAULT_PYROSCOPE_URL } from 'utils/consts';
import { LanguageOptions } from 'utils/enums';

import { configs, docLinks } from './Pyroscope/configs';
import { languageOptions } from './utils';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css({
    margin: `${theme.spacing(3)} 0`,
  }),
  option: css({
    minWidth: '209px',
    fontSize: theme.typography.body.fontSize,
    marginBottom: '10px',
    marginTop: '5px',
  }),
  tabsWrapper: css({
    marginBottom: '40px',

    label: {
      fontSize: theme.typography.bodySmall.fontSize,
      marginRight: theme.spacing(2),
    },
  }),
});

const generateConfig = (lang: LanguageOptions, url: string) => {
  return configs[lang].replace(DEFAULT_PYROSCOPE_URL, url).replace(/^\n/m, '');
};

export const HostedProfiles = () => {
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const [language, setLanguage] = useState<LanguageOptions>(languageOptions[0].value);
  const apiToken = useApiToken();
  const styles = useStyles2(getStyles);

  const ebpfOrAgent =
    language === LanguageOptions.GrafanaAlloy || LanguageOptions.GrafanaAgent || language === LanguageOptions.EBPF;
  const successMessage = ebpfOrAgent
    ? 'Your configuration, complete with your API key, has been generated below. Copy and paste this code into the configuration file of your Grafana Alloy or Grafana Agent:'
    : 'Your configuration, complete with your API key, has been generated below. Copy and paste this code into your application:';

  const docsLink = docLinks[language];
  return (
    <ol>
      <li className={styles.tabsWrapper}>
        <h2>Choose your SDK / collector</h2>
        <label htmlFor="pyroscope-language-select">
          Select an SDK or a collector
          <Select
            className={styles.option}
            options={[...languageOptions]}
            value={language}
            inputId="pyroscope-language-select"
            allowCustomValue
            onChange={(selected) => {
              if (selected.value) {
                setLanguage(selected.value);
              }
            }}
          />
        </label>
      </li>
      <li>
        <h2>Configure your application</h2>
        <GenerateAPI
          config={generateConfig(language, hostedDataDetails.hpInstanceUrl)}
          instanceId={hostedDataDetails.hpInstanceId}
          successMessage={successMessage}
        />
      </li>
      {apiToken.token && (
        <li>
          <h2>Visit our documentation to learn more</h2>
          <p className={styles.content}>
            To learn more about configuring the application SDKs and sending data to Grafana Profiles, visit{' '}
            <TextLink href={docsLink} external inline>
              our documentation
            </TextLink>
            .
          </p>
        </li>
      )}
    </ol>
  );
};
