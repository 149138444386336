import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useAgentDetailsQuery } from 'api/integrations/integrationsRtqApi';
import { Pages } from 'e2eSelectors/pages';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';

import { useCollectorMode } from '../useCollectorMode';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modalButton: css`
      padding: ${theme.spacing(0.25)};
    `,
    buttons: css`
      display: flex;
      justify-content: flex-end;
    `,
    modal: css`
      pre {
        max-height: 45vh;
      }
    `,
  };
};

export const ConfigurationExampleModal = () => {
  const styles = useStyles2(getStyles);
  const [showModal, setShowModal] = useState(false);
  const integrationId = useSelectedIntegrationId();
  const agentMode = useCollectorMode();
  const { data: agentDetails } = useAgentDetailsQuery({ integrationId, mode: agentMode });
  const {
    configuredParameters: { isFullMetricsSelected, isLogsEnabled: isLogsEnabledCanBeUndefined },
  } = useSelector((state: RootState) => state.agent);

  const isLogsEnabled = isLogsEnabledCanBeUndefined === undefined ? true : isLogsEnabledCanBeUndefined;

  if (agentDetails && agentDetails.contentType !== 'static') {
    // TODO: implement for Alloy
    return <></>;
  }

  const exampleConfiguration = isFullMetricsSelected
    ? isLogsEnabled
      ? agentDetails?.static.agentExampleConfiguration
      : agentDetails?.static.agentExampleConfigurationLogsDisabled
    : isLogsEnabled
      ? agentDetails?.static.agentExampleConfigurationFiltered
      : agentDetails?.static.agentExampleConfigurationFilteredLogsDisabled;

  return exampleConfiguration ? (
    <>
      {' '}
      This
      <Button
        fill="text"
        variant="primary"
        className={styles.modalButton}
        onClick={() => setShowModal(true)}
        data-testid={Pages.Source.AgentConfig.exampleConfigButton}
      >
        example configuration
      </Button>{' '}
      provides a good model for what your configuration should look like for the first integration that you install.{' '}
      <Modal
        title="Configuration example"
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
        className={styles.modal}
      >
        <div>
          <p>
            The following is an example of a configuration file with one integration installed. If you&apos;ve installed
            more than one integration, your configuration file will have an entry for each of them in the{' '}
            <code>integrations</code> stanza. Additionally, metrics configurations should appear in the{' '}
            <code>metrics.configs.scrape_configs</code> stanza and logs configurations should appear in the{' '}
            <code>logs.configs.scrape_configs</code> stanza.
          </p>

          <Clipboard multipleLines expandHeight code={exampleConfiguration} clipboardButtonType="below" />

          <div className={styles.buttons}>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};
