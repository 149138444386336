import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { AgentDetailsAlloyAdvanced, AgentDetailsAlloyBothModes, AgentDetailsAlloySimple } from 'api/integrations/types';
import { IntegrationSnippetMarkdown, Markdown } from 'components/Markdown';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';

import { HostnameField } from '../MakeConfigurationSelections/HostnameField';
import { copyToClipboard, SetupMode } from '../state/slice';

import { ConfigFileHelp } from './ConfigFileHelp';
import { useAdvancedAlloyBlocks, useAlloyPlatformsInstructions, useSimpleAlloyBlock } from './useSnippets';

interface InstructionsAlloySimpleProps {
  agentDetails: AgentDetailsAlloySimple | AgentDetailsAlloyBothModes;
}

const InstructionsAlloySimple = ({ agentDetails }: InstructionsAlloySimpleProps) => {
  const source = useSelectedAgentOrSaasIntegration();
  const { showLoadingBar, configuredParameters } = useSelector((state: RootState) => state.agent);

  const block = useSimpleAlloyBlock(configuredParameters, agentDetails);

  const dispatch = useDispatch();
  const integrationId = useSelectedIntegrationId();
  const trackOnCopy = () => dispatch(copyToClipboard(integrationId));

  return (
    <>
      <h3>Set up Grafana Alloy to use the {source.name} integration</h3>
      <ConfigFileHelp />
      <Markdown
        markdownSnippet={agentDetails.simple.generalGuidance}
        data-testid={Pages.Source.AgentConfig.alloyGeneralGuidance}
      />

      <IntegrationSnippetMarkdown
        markdownSnippet={block}
        showLoadingBar={showLoadingBar}
        onCopy={trackOnCopy}
        data-testid={Pages.Source.AgentConfig.alloySimpleBlock}
      />
    </>
  );
};

interface InstructionsAlloyAdvancedProps {
  agentDetails: AgentDetailsAlloyAdvanced | AgentDetailsAlloyBothModes;
}

const InstructionsAlloyAdvanced = ({ agentDetails }: InstructionsAlloyAdvancedProps) => {
  const source = useSelectedAgentOrSaasIntegration();
  const { showLoadingBar, configuredParameters } = useSelector((state: RootState) => state.agent);
  const [shouldHighlightLines, setShouldHighlightLines] = useState(false);

  const isLogsEnabled = configuredParameters.isLogsEnabled === undefined ? true : configuredParameters.isLogsEnabled;

  const {
    integrationBlock,
    logsBlock,
    metricsBlock,
    integrationBlockHighlightLines,
    logsBlockHighlightLines,
    metricsBlockHighlightLines,
    isHostnameRelabelKeyPresentInBlocks,
  } = useAdvancedAlloyBlocks(configuredParameters, agentDetails);
  const { integrationInstructions, logsInstructions, metricsInstructions } =
    useAlloyPlatformsInstructions(agentDetails);

  const isHostnameRelabelEnabled = isHostnameRelabelKeyPresentInBlocks;

  const dispatch = useDispatch();
  const integrationId = useSelectedIntegrationId();
  const trackOnCopy = () => dispatch(copyToClipboard(integrationId));

  return (
    <>
      <h3>Set up Grafana Alloy to use the {source.name} integration</h3>
      <ConfigFileHelp />
      <Markdown
        markdownSnippet={agentDetails.advanced.generalGuidance}
        data-testid={Pages.Source.AgentConfig.alloyGeneralGuidance}
      />
      {isHostnameRelabelEnabled && (
        <HostnameField onFocus={() => setShouldHighlightLines(true)} onBlur={() => setShouldHighlightLines(false)} />
      )}

      {integrationBlock && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.integrationInstructionsHeader}>Integration</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={integrationBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? integrationBlockHighlightLines : undefined}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedIntegrationsBlock}
          />
          <Markdown
            markdownSnippet={integrationInstructions}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedIntegrationsInstructions}
          />
        </>
      )}

      {isLogsEnabled && logsBlock && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.logsInstructionsHeader}>Logs</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={logsBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? logsBlockHighlightLines : undefined}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedLogsBlock}
          />
          <Markdown
            markdownSnippet={logsInstructions}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedLogsInstructions}
          />
        </>
      )}

      {metricsBlock && (
        <>
          <h5 data-testid={Pages.Source.AgentConfig.metricsInstructionsHeader}>Metrics</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={metricsBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={shouldHighlightLines ? metricsBlockHighlightLines : undefined}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedMetricsBlock}
          />
          <Markdown
            markdownSnippet={metricsInstructions}
            data-testid={Pages.Source.AgentConfig.alloyAdvancedMetricsInstructions}
          />
        </>
      )}
    </>
  );
};

interface InstructionsAlloyProps {
  agentDetails: AgentDetailsAlloySimple | AgentDetailsAlloyAdvanced | AgentDetailsAlloyBothModes;
}

export const InstructionsAlloy = ({ agentDetails }: InstructionsAlloyProps) => {
  const setupMode = useSelector((state: RootState) => state.agent.configuredParameters.setupMode);
  if (
    agentDetails.contentType === 'alloy-simple' ||
    (agentDetails.contentType === 'alloy-both-modes' && setupMode !== SetupMode.Advanced)
  ) {
    return <InstructionsAlloySimple agentDetails={agentDetails} />;
  } else {
    return <InstructionsAlloyAdvanced agentDetails={agentDetails} />;
  }
};
