import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  mainContent: css`
    ol {
      font-size: 20px;
      font-weight: ${theme.typography.fontWeightMedium};
      margin-bottom: 40px;
      margin-left: ${theme.spacing(3)};

      li {
        margin-bottom: ${theme.spacing(6)};
        font-size: 19px;

        ::marker {
          font-weight: ${theme.typography.h2.fontWeight};
          font-size: ${theme.typography.h2.fontSize};
          line-height: 26px;
        }

        h2 {
          margin: ${theme.spacing(3)} 0;
          color: ${theme.colors.text.primary};
        }

        p {
          font-size: ${theme.typography.body.fontSize};
          font-weight: ${theme.typography.fontWeightRegular};
        }
      }
    }
  `,
});

export const LocalInstructionsContainer = ({ children }: { children: React.ReactNode }) => {
  const styles = useStyles2(getStyles);

  return <div className={styles.mainContent}>{children}</div>;
};
