import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert, LinkButton, useStyles2 } from '@grafana/ui';

import { RootState } from 'state';
import { InstallationStatus } from 'state/source/slice';
import { Source } from 'types/Source';

import { getUpdateStyles } from './Update.styles';
import { UpdateModal } from './UpdateModal';

export const Update = ({ selectedIntegration }: { selectedIntegration: Source }) => {
  const styles = useStyles2((theme) => getUpdateStyles(theme));
  const integrationUpdateStatus = useSelector((state: RootState) => state.source.integrationUpdateStatus);

  const [showModal, setShowModal] = useState(false);
  const [isRecentlyUpdated, setIsRecentlyUpdated] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setShowModal(false);
  }, [selectedIntegration.installation]);

  useEffect(() => {
    if (isEmpty(selectedIntegration.installation) && isRecentlyUpdated) {
      setIsRecentlyUpdated(false);
    }
  }, [selectedIntegration.installation, isRecentlyUpdated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isRecentlyUpdated && integrationUpdateStatus === InstallationStatus.Success) {
        setShowAlert(false);
      }
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isRecentlyUpdated, integrationUpdateStatus]);

  return (
    <>
      {selectedIntegration.has_update && selectedIntegration.installation && (
        <div className={styles.alertWrapper}>
          <Alert title="" severity="info" className={styles.alert}>
            <div className={styles.alertContent}>
              There are new updates available for this integration.
              <LinkButton className={styles.updateButton} fill="solid" onClick={() => setShowModal(true)}>
                Update integration
              </LinkButton>
            </div>
          </Alert>
        </div>
      )}

      {showAlert && isRecentlyUpdated && integrationUpdateStatus === InstallationStatus.Success && (
        <div className={styles.alertWrapper}>
          <Alert title={``} className={styles.alert} severity="success">
            {selectedIntegration.name} - Successfully updated!
          </Alert>
        </div>
      )}

      {showModal && (
        <UpdateModal
          selectedIntegration={selectedIntegration}
          setIsRecentlyUpdated={setIsRecentlyUpdated}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};
