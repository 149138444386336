import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Collapse, useStyles2 } from '@grafana/ui';

import { CollectorState, useCollectorSelector, UseToken } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';
import { jsonStringRepl } from 'utils/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  generateToken: css`
    font-size: ${theme.typography.body.fontSize};

    h3 {
      font-size: ${theme.typography.h4.fontSize};
    }
  `,
});

type Props = {
  config: string;
  configTitle?: string;
  collapseTitle?: string;
  instanceId: number;
  shouldShowClipboard?: boolean;
  shouldShowInstruction?: boolean;
};

export const GenerateCollectorAPI: FC<Props> = ({
  config,
  configTitle,
  collapseTitle = 'Use another token',
  instanceId,
  shouldShowClipboard = true,
  shouldShowInstruction = true,
}) => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const newToken = useCollectorSelector((state: CollectorState) => state.collector.token);
  const existingToken = useCollectorSelector((state: CollectorState) => state.collector.existingToken);
  const token = newToken || existingToken;

  let updatedConfig = jsonStringRepl(config, new RegExp(GRAFANA_EXAMPLE_USER, 'g'), instanceId.toString());
  if (token) {
    updatedConfig = jsonStringRepl(updatedConfig, new RegExp(GRAFANA_EXAMPLE_API, 'g'), token);
  }

  return (
    <>
      {!token && shouldShowInstruction && (
        <p className={styles.title}>
          Click below to generate a Grafana.com Access Policy token. This Access Policy token will appear as the
          password in the configuration below.
        </p>
      )}

      <div className={styles.generateToken}>
        {!token && <UseToken />}
        {token && (
          <Collapse label={collapseTitle} isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} collapsible={true}>
            <UseToken />
          </Collapse>
        )}
        {token && shouldShowClipboard && (
          <Clipboard
            title={configTitle}
            code={updatedConfig}
            multipleLines={true}
            expandHeight={true}
            clipboardButtonType="below"
          />
        )}
      </div>
    </>
  );
};
