import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { Pages } from 'e2eSelectors/pages';
import { MetricsEndpointFlavor } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { CreateJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';
import { getInstructionsStyles, getJobFormStyles } from '../common/MetricsEndpointInstructions.styles';
import { ScrapeIntervalField } from '../common/ScrapeIntervalField';
import { ScrapeJobNameField } from '../common/ScrapeJobNameField';

import AuthField from './AuthField';
import { MetricsURLField } from './MetricsURLField';

type ReactHookFormProps = {
  url: string;
  authenticationMethod: string;
  scrapeIntervalSeconds: number;
  basicPassword: string;
  basicUsername: string;
  bearerToken: string;
  name: string;
};

const defaultValues: ReactHookFormProps = {
  url: '',
  authenticationMethod: 'basic',
  scrapeIntervalSeconds: 60,
  basicPassword: '',
  basicUsername: '',
  bearerToken: '',
  name: '',
};

export const CreateJob: FC<CreateJobFormProps> = ({ onSaveJob }) => {
  const jobStyles = useStyles2(getJobFormStyles);
  const styles = useStyles2(getInstructionsStyles);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });

  const methods = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleCreate: SubmitHandler<ReactHookFormProps> = (currentJob) => {
    onSaveJob({
      name: currentJob.name,
      authentication_method: currentJob.authenticationMethod,
      enabled: true,
      scrape_interval_seconds: currentJob.scrapeIntervalSeconds,
      url: currentJob.url,
      basic_password: currentJob.basicPassword,
      basic_username: currentJob.basicUsername,
      bearer_token: currentJob.bearerToken,
      flavor: MetricsEndpointFlavor.Default,
    });
  };

  const isButtonDisabled =
    !connectionSuccess || createJobStatus === QueryStatus.pending || createJobStatus === QueryStatus.fulfilled;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCreate)}>
        <ol className={styles.instructions}>
          <li className={styles.instructionItem}>
            <h2>Give your Scrape Job a name</h2>
            <ScrapeJobNameField />
          </li>
          <li className={styles.instructionItem}>
            <h2>Enter the URL to scrape, and the necessary Auth Credentials</h2>
            <div className={jobStyles.job}>
              <p>In order to create a Scrape Job, a valid HTTPs URL is required</p>
              <MetricsURLField />
              <ScrapeIntervalField />
              <AuthField onSuccess={() => setConnectionSuccess(true)} />
            </div>
          </li>
          <JobStatusWidget />
          <Button
            variant="primary"
            type="submit"
            aria-label="Save Scrape Job"
            disabled={isButtonDisabled}
            data-testid={Pages.MetricsEndpointIntegration.saveScrapeJobButton}
          >
            {createJobStatus === QueryStatus.pending && <Spinner className={jobStyles.spinner} />}
            Save Scrape Job
          </Button>
        </ol>
      </form>
    </FormProvider>
  );
};
