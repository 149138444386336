import { MacOsInstallOptions, OsBasedOptions, SelectedOSDetails } from '@grafana-cloud/collector';
import { CollectorMode } from 'utils/enums';

function getAlloyConfigFilePath(osInstallDetails: SelectedOSDetails) {
  switch (osInstallDetails.osValue) {
    case OsBasedOptions.Debian:
    case OsBasedOptions.RedHat:
      return '/etc/alloy/config.alloy';
    case OsBasedOptions.MacOs:
      if (osInstallDetails.macOsInstallOption === MacOsInstallOptions.Homebrew) {
        return '$(brew --prefix)/etc/alloy/config.alloy';
      } else {
        return '/etc/alloy/config.alloy';
      }
    case OsBasedOptions.Windows:
      return '%PROGRAMFILES%\\GrafanaLabs\\Alloy\\config.alloy';
    case OsBasedOptions.OtherLinuxDistros:
      return './config.alloy';
    case OsBasedOptions.K8s:
      return '';
  }
}

function getAgentConfigFilePath(osInstallDetails: SelectedOSDetails) {
  switch (osInstallDetails.osValue) {
    case OsBasedOptions.Debian:
    case OsBasedOptions.RedHat:
      return '/etc/grafana-agent.yaml';
    case OsBasedOptions.MacOs:
      if (osInstallDetails.macOsInstallOption === MacOsInstallOptions.Homebrew) {
        return '$(brew --prefix)/etc/grafana-agent/config.yml';
      } else {
        return '/etc/grafana-agent/config.yml';
      }
    case OsBasedOptions.Windows:
      return '%PROGRAMFILES%\\Grafana Agent\\agent-config.yaml';
    case OsBasedOptions.OtherLinuxDistros:
      return './grafana-agent.yaml';
    case OsBasedOptions.K8s:
      return '';
  }
}

export function getCollectorConfigFilePath(osInstallDetails: SelectedOSDetails, mode: CollectorMode) {
  if (mode === CollectorMode.Alloy) {
    return getAlloyConfigFilePath(osInstallDetails);
  } else {
    return getAgentConfigFilePath(osInstallDetails);
  }
}

export const generateOsInstallConfig = (osInstallDetails: SelectedOSDetails, mode: CollectorMode) => {
  let restartTitle = undefined;
  let restartCode = undefined;
  const configPath = getCollectorConfigFilePath(osInstallDetails, mode);
  const isStatic = mode === CollectorMode.AgentStatic;

  switch (osInstallDetails.osValue) {
    case OsBasedOptions.MacOs:
      if (osInstallDetails.macOsInstallOption === MacOsInstallOptions.Binary) {
        restartCode = isStatic
          ? `./grafana-agent-darwin-${osInstallDetails.archValue} --config.file=${configPath}`
          : `./alloy-darwin-${osInstallDetails.archValue} run ${configPath}`;
      } else if (osInstallDetails.macOsInstallOption === MacOsInstallOptions.Homebrew) {
        restartCode = `brew services restart ${isStatic ? 'grafana-agent' : 'alloy'}`;
      }
      break;
    case OsBasedOptions.Windows:
      const agentService = isStatic ? 'Grafana Agent' : 'Alloy';
      restartCode = `sc stop "${agentService}" \nsc start "${agentService}" \nsc query "${agentService}" | find "STATE"`;
      restartTitle = `After installation, the config is stored in ${configPath}. Restart ${isStatic ? 'Grafana Agent' : 'Alloy'} for any changes to take effect:`;
      break;
    case OsBasedOptions.Debian:
    case OsBasedOptions.RedHat:
      restartCode = `sudo systemctl restart ${isStatic ? 'grafana-agent.service' : 'alloy.service'}`;
      restartTitle = `After installation, the config is stored in ${configPath}. Restart ${isStatic ? 'Grafana Agent' : 'Alloy'} for any changes to take effect:`;
      break;
    case OsBasedOptions.OtherLinuxDistros:
      restartCode = isStatic
        ? `./grafana-agent-linux-${osInstallDetails.archValue} --config.file=${configPath}`
        : `./alloy-linux-${osInstallDetails.archValue} run ${configPath}`;
      break;
    default:
      break;
  }

  return { restartTitle, restartCode };
};
