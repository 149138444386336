import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { TextLink } from 'components/TextLink';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    marginLeft: theme.spacing(3),
    li: {
      '::marker': {
        fontSize: theme.typography.h3.fontSize,
      },
      li: {
        marginLeft: '2em',
        '::marker': {
          fontSize: 'unset',
        },
      },
      a: {
        color: theme.colors.text.link,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  }),
  topMargin: css({
    marginTop: theme.spacing(3),
  }),
});

const PreInstructions: FC = () => {
  return (
    <li>
      <h3>Check prerequisites specific to the Istio integration</h3>
      <br />
      <h4>Metrics</h4>
      <p>
        Istio exposes a Prometheus metrics endpoint, <code>/stats/prometheus</code>, on all <code>istio-proxy</code>{' '}
        containers by default.
      </p>
      <p>
        You can verify that this endpoint is enabled by running the following command on an Istio pod&apos;s{' '}
        <code>istio-proxy</code> container:
      </p>
      <Clipboard
        code={`kubectl exec -it <pod-name> -n <namespace> -c istio-proxy -- sh -c 'curl http://localhost:15020/stats/prometheus'`}
        multipleLines={true}
        clipboardButtonType="below"
      />
      <p>
        Istio also exposes a Prometheus metrics endpoint, <code>/metrics</code>, on the <code>discovery</code> container
        of the <code>istiod</code> pod.
      </p>
      <p>You can verify that this endpoint is enabled by running the following command on this container:</p>
      <Clipboard
        code={`kubectl exec -it istiod -n <namespace> -c discovery -- sh -c 'curl http://localhost:15014/metrics'`}
        multipleLines={true}
        clipboardButtonType="below"
      />
      <h4>Logs</h4>
      <p>By default, Istio proxies send system logs to stdout.</p>
      <p>
        In order to enable envoy proxy access logs as well, you can use an IstioOperator CR by adding the following
        field to your configuration:
      </p>
      <Clipboard
        code={`spec:
  meshConfig:
    accessLogFile: /dev/stdout`}
        multipleLines={true}
        clipboardButtonType="below"
      />
      <p>Alternatively you can use the Telemetry API to enable access logs:</p>
      <Clipboard
        code={`apiVersion: telemetry.istio.io/v1alpha1
kind: Telemetry
metadata:
  name: mesh-default
  namespace: istio-system
spec:
  accessLogging:
    - providers:
      - name: envoy`}
        multipleLines={true}
        clipboardButtonType="below"
      />
      <p>
        For up-to-date information on envoy proxy logging for Istio, refer to{' '}
        <TextLink
          href="https://istio.io/latest/docs/tasks/observability/logs/access-log/#enable-envoys-access-logging"
          external
          inline
        >
          this documentation
        </TextLink>
        .
      </p>
    </li>
  );
};

const ConfigurationInstallation: FC = () => {
  return (
    <li>
      <h3>Configuration & Installation</h3>
      <br />
      <h4>General Agent Configuration & Installation</h4>
      <p>
        The only way to install the Istio integration is to follow the{' '}
        <TextLink href="/a/grafana-k8s-app/configuration" inline>
          Kubernetes Monitoring Configuration
        </TextLink>{' '}
        instructions.
      </p>
      <br />
      <h4>Istio Integration Configuration & Installation</h4>
      <p>
        You can find the Istio integration for Kubernetes{' '}
        <TextLink href="/a/grafana-k8s-app/configuration/integrations" inline>
          here
        </TextLink>
        . Follow the instructions for both configuration and installation.
      </p>
      <p>
        To show logs and metrics signals correlated in your dashboards, as a single pane of glass, ensure the following:
        <ul>
          <li>job label must be set to integrations/istio (already configured in the snippets).</li>
        </ul>
      </p>
    </li>
  );
};

export const IstioInstructions: FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <ol className={styles.instructions}>
      <PreInstructions />
      <ConfigurationInstallation />
    </ol>
  );
};
