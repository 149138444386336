import { createAsyncThunk } from '@reduxjs/toolkit';

import { checkConnection } from 'api/hostedExporters';
import { MetricsEndpointCredentials, SupabaseCredentials } from 'models/api-models';
import { OnboardingPluginError } from 'types';
import { ConnectionStatus } from 'utils/enums';
import { text } from 'utils/errors';

import { handleError } from '../saasIntegration/actions';
import { setConnectionStatus } from '../saasIntegration/slice';

export const checkMetricsEndpointConnection = createAsyncThunk(
  'metricsEndpoint/checkMetricsEndpointConnection',
  async (
    {
      payload,
      mainErrorField,
      urlField = 'URL',
    }: {
      payload: MetricsEndpointCredentials | SupabaseCredentials;
      mainErrorField: string;
      urlField?: string;
    },
    thunkAPI
  ) => {
    const jobType = 'metrics-endpoint';
    try {
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Pending));
      await checkConnection(payload, jobType, mainErrorField);
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Success));
    } catch (error: any) {
      const metricsEndpointError = text.errorFunctions.checkMetricsEndpointConnection(
        error?.data?.error ? error.data.error.code : undefined,
        urlField
      );
      const obError: OnboardingPluginError = {
        ...metricsEndpointError,
        code: '',
        errorObject: error,
      };

      thunkAPI.dispatch(handleError({ obError }));
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Error));
    }
  }
);
