import { css, cx } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { Markdown } from 'components/Markdown';
import { RootState } from 'state';

const getStyles = (theme: GrafanaTheme2) => ({
  previousVersion: css({
    marginTop: '30px',
  }),
  currentVersion: css({
    background: theme.colors.background.secondary,
  }),
  table: css({
    width: '100%',
    marginTop: '12px',
    marginLeft: '-10px',
    'li > ul': {
      paddingLeft: '15px',
    },
    td: {
      padding: '10px',
    },
    'td:first-child': {
      width: '220px',
      verticalAlign: 'top',
    },
    'td:last-child': {
      color: theme.colors.warning.text,
      width: '150px',
    },
  }),
});

export const VersionHistory = () => {
  const styles = useStyles2(getStyles);
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const selectedIntegration = useSelectedAgentOrSaasIntegration();

  const installedVersion = selectedIntegration.installation?.version;
  const versionHistory = sourceDetails.version_history;

  return (
    <>
      {versionHistory && (
        <>
          <h3>Latest version</h3>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>
                  {versionHistory[0].Version} - {versionHistory[0].Date}
                </td>
                <td>
                  <Markdown markdownSnippet={versionHistory[0].Text} />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          {versionHistory.length > 1 && (
            <>
              <h4 className={styles.previousVersion}>Previous versions</h4>
              <table className={styles.table}>
                <tbody>
                  {versionHistory.map(
                    (el, i) =>
                      i !== 0 && (
                        <tr key={i} className={cx({ [styles.currentVersion]: el.Version === installedVersion })}>
                          <td>
                            {el.Version} - {el.Date}
                          </td>
                          <td>
                            <Markdown markdownSnippet={el.Text} />
                          </td>
                          <td>{el.Version === installedVersion ? 'Currently running' : ''}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </>
  );
};
