import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Spinner as UiSpinner } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  delayed: css({
    '@keyframes delayVisibility': {
      '0%': {
        opacity: 0,
        animationTimingFunction: 'linear',
      },
      '75%': {
        opacity: 0,
        animationTimingFunction: 'ease',
      },
      '100%': {
        opacity: 1,
      },
    },
    animationName: 'delayVisibility',
    animationDuration: '1s',
    animationFillMode: 'both',
  }),
  loading: css({
    display: 'flex',
    margin: `0 ${theme.spacing(3)}`,
  }),
  spinner: css({
    marginRight: '10px',
  }),
});

interface Props {
  message?: string;
  doDelay?: boolean;
  ['data-testId']?: string;
}

export const Spinner: FC<Props> = ({ message, doDelay = true, 'data-testId': dataTestId }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={cx(styles.loading, { [styles.delayed]: doDelay })} data-testid={dataTestId}>
      <UiSpinner className={styles.spinner} /> {message}
    </div>
  );
};
