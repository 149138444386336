import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';

import { faro } from '@grafana/faro-web-sdk';

export const rtqErrorReporter: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.data.error) {
      faro.api.pushError(action.payload.data.error);
    }
  }
  return next(action);
};
