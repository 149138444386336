import { css } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Select, useStyles2 } from '@grafana/ui';

import { useDispatch } from 'hooks/useDispatch';
import { jobStatusFilterSelector } from 'state/saasIntegration/selectors';
import { resetSelectedJobs, setJobStatusFilter } from 'state/saasIntegration/slice';
import { FilterJobStatus } from 'utils/enums';

const JOB_STATUS_FILTER_OPTIONS = [
  {
    value: FilterJobStatus.All,
    label: 'All',
  },
  {
    value: FilterJobStatus.Enabled,
    label: 'Enabled jobs only',
  },
  {
    value: FilterJobStatus.Disabled,
    label: 'Disabled jobs only',
  },
];

const getStyles = (theme: GrafanaTheme2) => ({
  selectWrapper: css`
    max-width: 180px;
  `,
});

export const JobStatusFilter = () => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const filterValue = useSelector(jobStatusFilterSelector());

  return (
    <Select
      className={styles.selectWrapper}
      value={filterValue}
      options={JOB_STATUS_FILTER_OPTIONS}
      onChange={(selected: SelectableValue<FilterJobStatus>) => {
        dispatch(setJobStatusFilter(selected.value as FilterJobStatus));
        dispatch(resetSelectedJobs());
      }}
    />
  );
};
