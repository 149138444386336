import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';
import { K8sClusterSetupModal } from 'features/agent-integrations/K8s/K8sClusterSetupModal';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css({
    margin: `${theme.spacing(3)} 0`,
  }),
});

export const KubernetesContent = () => {
  const styles = useStyles2(getStyles);

  return (
    <li>
      <h2>Install the Kubernetes Integration</h2>
      <p className={styles.content}>
        The Kubernetes Integration provides you with preconfigured Kubernetes manifests and quickstart instructions for
        rolling out the Grafana Alloy into a Kubernetes cluster. It also provisions a set of prebuilt Grafana dashboards
        to gain insight into your cluster and Pod resource usage, as well as Kubelet metrics and Persistent Volume
        usage.
      </p>
      <K8sClusterSetupModal />
      <p className={styles.content}>
        For{' '}
        <TextLink href="https://grafana.com/docs/alloy/latest/get-started/install/kubernetes/" external inline>
          custom deployments
        </TextLink>{' '}
        and to learn how to operate and configure Grafana Agent to scrape additional targets, please see the{' '}
        <TextLink href="https://grafana.com/docs/alloy/latest/tasks/configure/configure-kubernetes/" external inline>
          Grafana Alloy documentation
        </TextLink>
        .
      </p>
    </li>
  );
};
