import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { queryDatasource } from 'api/integrations/integrationsApi';
import { OrgInfoResult } from 'models/api-models';
import { QueryError } from 'types';
import { ORG_INFO } from 'utils/consts';

const GRAFANA_USAGE_DATASOURCE = 'grafanacloud-usage';

export const orgInfoApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  reducerPath: 'orgInfoApi',
  endpoints: (build) => ({
    getOrgInfo: build.query<OrgInfoResult[], void>({
      async queryFn() {
        try {
          const result = await queryDatasource<OrgInfoResult>(GRAFANA_USAGE_DATASOURCE, ORG_INFO);
          return { data: result };
        } catch (e) {
          const err = e as any;
          return { error: { data: { message: err?.data?.error || err?.message } } as QueryError };
        }
      },
    }),
  }),
});

export const { useGetOrgInfoQuery } = orgInfoApi;
