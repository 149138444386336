import React from 'react';

import { Icon, IconName, Tooltip, useStyles2 } from '@grafana/ui';

import { getStyles } from 'features/catalog/Filters/CatalogFilterPill.styles';
import { handleKeyDown } from 'utils/aria';

interface CatalogFilterPillProps {
  selected: boolean;
  label?: string;
  onClick: () => void;
  icon?: IconName;
  imgUrl?: string;
  ariaLabel: string;
}

export const CatalogFilterPill: React.FC<CatalogFilterPillProps> = ({
  label,
  selected,
  onClick,
  icon,
  imgUrl,
  ariaLabel,
}) => {
  const styles = useStyles2((theme) => getStyles(theme));

  const content = (
    <>
      {icon && <Icon name={icon} className={label && styles.icon} />}
      {!icon && imgUrl && <img src={imgUrl} height={16} className={label && styles.icon} alt={label} />}
      {label && <span>{label}</span>}
    </>
  );

  if (selected) {
    return (
      <Tooltip content={ariaLabel} theme="info" show={label ? false : undefined}>
        <div className={styles.coloredBackground}>
          <div className={styles.greyBackground}>
            <div
              className={styles.selected}
              onClick={onClick}
              onKeyDown={handleKeyDown(onClick)}
              tabIndex={0}
              role="button"
              aria-label={ariaLabel}
            >
              {content}
              <Icon name="check" className={styles.checkMark} />
            </div>
          </div>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={ariaLabel} theme="info" show={label ? false : undefined}>
        <div
          className={styles.unselected}
          onClick={onClick}
          onKeyDown={handleKeyDown(onClick)}
          tabIndex={0}
          role="button"
          aria-label={ariaLabel}
        >
          {content}
        </div>
      </Tooltip>
    );
  }
};
