import { useEffect } from 'react';

import { useSourceLogoUrl } from 'api/integrations/hooks';
import { unsetLocalError } from 'features/app/state/slice';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { openSourcePageThunk } from 'state/source/actions';
import { unsetSourceDetails } from 'state/source/slice';
import { SCROLL_CONTAINER_SELECTOR } from 'utils/consts';
import { isAdmin } from 'utils/user';

export const useSourceInitialization = () => {
  const dispatch = useDispatch();
  const logo_url = useSourceLogoUrl();
  const integrationId = useSelectedIntegrationId();

  useEffect(() => {
    document.querySelector(SCROLL_CONTAINER_SELECTOR)?.scrollTo(0, 0);

    if (isAdmin()) {
      dispatch(openSourcePageThunk({ integrationId }));
    }

    return () => {
      dispatch(unsetSourceDetails());
      dispatch(unsetLocalError());
    };
  }, [dispatch, integrationId]);

  return { integrationId, logo_url };
};
