import { css } from '@emotion/css';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, Field, Select, Toggletip, useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';
import { isGrafanaAtLeast10 } from 'utils/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  smallFont: css({
    fontSize: theme.typography.bodySmall.fontSize,
  }),
  width50: css({
    width: '50%',
  }),
});

const SCRAPE_INTERVAL_SECONDS_OPTIONS = [
  {
    value: 30,
    label: 'Every 30 seconds',
  },
  {
    value: 60,
    label: 'Every minute',
  },
  {
    value: 120,
    label: 'Every 2 minutes',
  },
];

export const ScrapeIntervalField = () => {
  const styles = useStyles2(getStyles);
  const { control, setValue } = useFormContext();
  const scrapeInterval = useWatch({ name: 'scrapeIntervalSeconds', control });

  const tooltip = (
    <Toggletip
      content={
        <div>
          How frequently to scrape the metrics endpoint. Increasing the frequency will result in more frequent data
          collection and increase the DPM of the series collected - this may have billing implications. For more
          information{' '}
          <TextLink
            href={
              'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/understand-your-invoice/#what-is-dpm'
            }
            external
            inline
          >
            refer to cost management and billing documentation.
          </TextLink>
        </div>
      }
      closeButton={true}
    >
      <Button fill={'text'} icon={'info-circle'} aria-label={'Learn more about scrape intervals'}>
        Learn more about scrape intervals
      </Button>
    </Toggletip>
  );

  const alert = (
    <Alert className={styles.smallFont} title="" severity="info">
      How frequently to scrape the metrics endpoint. Increasing the frequency will result in more frequent data
      collection and increase the DPM of the series collected - this may have billing implications. For more information{' '}
      <TextLink
        href={'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/understand-your-invoice/#what-is-dpm'}
        external
        inline
      >
        refer to cost management and billing documentation.
      </TextLink>
    </Alert>
  );

  return (
    <>
      <div className={styles.container}>
        <Field
          label="Scrape Interval"
          data-testid="scrape-interval-field"
          htmlFor={'scrape-interval-selector'}
          className={styles.width50}
        >
          <Select<number>
            options={SCRAPE_INTERVAL_SECONDS_OPTIONS}
            inputId={'scrape-interval-seconds-selector'}
            id={'scrapeIntervalSeconds'}
            onChange={(option) => setValue('scrapeIntervalSeconds', option.value ?? 0)}
            value={scrapeInterval}
          />
        </Field>
        {isGrafanaAtLeast10() && tooltip}
      </div>
      {!isGrafanaAtLeast10() && alert}
    </>
  );
};
