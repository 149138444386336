import { css, cx } from '@emotion/css';
import React, { ElementType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactMarkdown from 'react-markdown';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { TextLink } from 'components/TextLink';

const getStyles = (theme: GrafanaTheme2) => ({
  markdown: css({
    a: {
      color: theme.colors.text.link,
      code: {
        color: theme.colors.text.link,
      },
    },
    'ul,ol': {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      'ul,ol': {
        marginBottom: 0,
      },
      li: {
        p: {
          display: 'inline',
          marginBottom: 0,
        },
      },
    },
  }),
});

// see https://github.com/facebook/react/issues/11538#issuecomment-2053727254
const ReRenderingErrorBoundary = ({ children }: { children: React.ReactElement }) => {
  return <ErrorBoundary fallback={children}>{children}</ErrorBoundary>;
};

interface MarkdownProps {
  markdownSnippet: string;
  className?: string;
  renderers?: { [nodeType: string]: ElementType };
  'data-testid'?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({
  markdownSnippet,
  className,
  renderers,
  'data-testid': dataTestId,
}) => {
  const styles = useStyles2(getStyles);
  return (
    <div data-testid={dataTestId}>
      <ReRenderingErrorBoundary>
        <ReactMarkdown
          components={{
            pre({ children }) {
              return <div>{children}</div>;
            },
            code: ({ children }) => {
              if (!String(children).includes('\n')) {
                return <code>{children}</code>;
              }
              return (
                <div translate="no">
                  <Clipboard multipleLines code={String(children)} expandHeight clipboardButtonType="below" />
                </div>
              );
            },
            a: ({ href, children, ...props }) => (
              <TextLink href={href ?? ''} external inline>
                {children as string}
              </TextLink>
            ),
            ...renderers,
          }}
          className={cx(styles.markdown, className)}
        >
          {markdownSnippet}
        </ReactMarkdown>
      </ReRenderingErrorBoundary>
    </div>
  );
};

export const IntegrationSnippetMarkdown: React.FC<{
  markdownSnippet: string;
  showLoadingBar: boolean;
  onCopy?: () => void;
  highlightLines?: number[];
  'data-testid'?: string;
}> = ({ markdownSnippet, showLoadingBar, onCopy, highlightLines, 'data-testid': dataTestId }) => {
  const styles = useStyles2(getStyles);
  const IntegrationCodeSnippetRenderer = ({ value }: { value: string }) => (
    <Clipboard
      code={value}
      showLoadingBar={showLoadingBar}
      onClipboardCopy={onCopy}
      highlightLines={highlightLines}
      clipboardButtonType="below"
      multipleLines
      expandHeight
      data-testid={dataTestId}
    />
  );
  return (
    <ReRenderingErrorBoundary>
      <ReactMarkdown
        components={{
          pre({ children }) {
            return <div>{children}</div>;
          },
          code: ({ children }) => {
            if (!String(children).includes('\n')) {
              return <code>{children}</code>;
            }
            return (
              <div translate="no">
                <IntegrationCodeSnippetRenderer value={String(children)} />
              </div>
            );
          },
          a: ({ href, children, ...props }) => (
            <TextLink href={href ?? ''} external inline>
              {children as string}
            </TextLink>
          ),
        }}
        className={styles.markdown}
      >
        {markdownSnippet}
      </ReactMarkdown>
    </ReRenderingErrorBoundary>
  );
};
