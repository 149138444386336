export const Components = {
  Alert: {
    alert: 'alert',
  },
  Search: {
    searchInput: 'search-input',
  },
  Actions: {
    viewDashboards: 'view-dashboards',
  },
};
