import { Source } from 'types/Source';

export function filterBySearchTerm(sources: Source[], searchTerm: string): Source[] {
  return sources.filter((integration) => {
    const keywords = integration.search_keywords ?? [];
    const integrationHasKeyword = keywords.filter((keyword) => keyword.includes(searchTerm));
    return (
      integration.name?.toLowerCase().includes(searchTerm) ||
      integrationHasKeyword?.length > 0 ||
      (integration.type === 'data-source' && integration.orgName?.toLowerCase().includes(searchTerm))
    );
  });
}
