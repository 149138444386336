import React, { useMemo } from 'react';

import { useCollectorSelector } from '@grafana-cloud/collector';
import { CollectorMode } from 'utils/enums';

import { getCollectorConfigFilePath } from '../OSInstallConfig';
import { useCollectorMode } from '../useCollectorMode';

export const ConfigFileHelp = ({ customCollectorMode }: { customCollectorMode?: CollectorMode }) => {
  const collectorModeFromUrl = useCollectorMode();
  const collectorMode = customCollectorMode ?? collectorModeFromUrl;
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);
  const configFilePath = useMemo(
    () => getCollectorConfigFilePath(selectedOs, collectorMode),
    [selectedOs, collectorMode]
  );

  return (
    <p>
      You can find your configuration file for your{' '}
      {collectorMode === CollectorMode.AgentStatic ? 'Grafana Agent' : 'Alloy'} instance at{' '}
      <code>{configFilePath}</code>.
    </p>
  );
};
