import { createAsyncThunk } from '@reduxjs/toolkit';

import { faro } from '@grafana/faro-web-sdk';

import { OnboardingPluginError } from 'types';

import { setError } from './slice';

export const handleError = createAsyncThunk(
  'saasIntegrations/handleError',
  async (
    {
      obError,
    }: {
      obError: OnboardingPluginError;
    },
    thunkAPI
  ) => {
    faro.api.pushError(new Error(JSON.stringify(obError.errorObject)));
    thunkAPI.dispatch(setError(obError));
  }
);
