import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getAddConfluentAPIKeyStyles = (theme: GrafanaTheme2) => ({
  spacer: css`
    padding: 20px 0;
    border-top: 1px solid ${theme.components.input.borderColor};
  `,
  link: css`
    margin-left: 0.2rem;
    color: ${theme.colors.text.link};
    font-size: ${theme.typography.body.fontSize};
  `,
});
