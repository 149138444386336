import { ContextServType } from 'types/ContextServType';

const getContextSrv: () => ContextServType | null | undefined = () => require('grafana/app/core/core').contextSrv;

export const isAdmin = (): boolean => {
  return getContextSrv()?.hasRole?.('Admin') === true;
};

export const isEditor = (): boolean => {
  return getContextSrv()?.hasRole?.('Editor') === true;
};
