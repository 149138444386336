import { isEmpty } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Modal, useStyles2, Alert } from '@grafana/ui';

import { useListScrapeJobsQuery } from 'api/hostedExporters/hostedExportersApi';
import { useGetLibraryPanelsQuery } from 'api/hostedGrafana/hostedGrafanaApi';
import { TextLink } from 'components/TextLink';
import { Pages } from 'e2eSelectors/pages';
import { useCollectorMode } from 'features/agent-integrations/useCollectorMode';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { useJobTypeWithFlavor } from 'pages/Source/ConfigurationDetails/Local/SaasIntegrationWithScrapeJobs/hooks/useJobTypeWithFlavor';
import { RootState } from 'state';
import { isSaasIntegrationWithJob } from 'state/saasIntegration/slice';
import { fetchFolderUIDs, removeAllScrapeJobs, uninstallIntegration } from 'state/source/actions';
import { Source } from 'types/Source';
import { CollectorMode, RudderStackEvents, SourceType } from 'utils/enums';
import { constructDashboardUrl } from 'utils/misc';

import { getUninstallStyles } from './UninstallModal.styles';

export const UninstallModal = ({
  selectedIntegration,
  onIntegrationSuccessfulDeletion,
  onIntegrationFailedDeletion,
  onClose,
}: {
  selectedIntegration: Source;
  onIntegrationSuccessfulDeletion: () => void;
  onIntegrationFailedDeletion: () => void;
  onClose?: () => void;
}) => {
  const styles = useStyles2(getUninstallStyles);
  const dispatch = useDispatch();

  const integrationId = useSelectedIntegrationId();
  const { dashboard_folder } = useSelector((state: RootState) => state.source.sourceDetails);
  const folderUIDs = useSelector((state: RootState) => state.source.folderUIDs || []);
  const generalError = useSelector((state: RootState) => state.app.generalError);
  const { trackRudderStackEvent } = useRudderStack();
  const agentMode = useCollectorMode();
  const jobTypeWithFlavor = useJobTypeWithFlavor();

  const { data: jobs } = useListScrapeJobsQuery(jobTypeWithFlavor, {
    // fetch scrape jobs only for integrations that has them
    skip: !isSaasIntegrationWithJob(integrationId),
  });
  const jobNames = (jobs ?? []).map((j) => j.name);

  const [isUninstalling, setIsUninstalling] = useState(false);

  useEffect(() => {
    if (integrationId) {
      dispatch(fetchFolderUIDs(integrationId));
    }
  }, [integrationId, dispatch]);

  const { data: libraryPanelsData } = useGetLibraryPanelsQuery(
    { folderFilterUIDs: folderUIDs },
    {
      skip: folderUIDs.length === 0,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  const hasLibraryPanels = !!libraryPanelsData?.result?.elements?.length;

  const integrationDashboardsUrl = useMemo(
    () => (integrationId && dashboard_folder ? constructDashboardUrl(dashboard_folder) : '/dashboards'),
    [integrationId, dashboard_folder]
  );

  const isButtonDisabled = isUninstalling || hasLibraryPanels;

  useEffect(() => {
    if (isEmpty(selectedIntegration.installation)) {
      setIsUninstalling(false);
    }
  }, [selectedIntegration.installation]);

  useEffect(() => {
    if (generalError?.message.length) {
      onClose?.();
    }
  }, [generalError, onClose]);

  const onDisableModal = () => {
    trackRudderStackEvent(RudderStackEvents.AbandonUninstall, {
      integration_slug: integrationId,
    });
    onClose?.();
  };

  const handleRemoveIntegration = async () => {
    trackRudderStackEvent(RudderStackEvents.RemoveIntegration, {
      integration_slug: integrationId,
    });

    try {
      if (isSaasIntegrationWithJob(integrationId)) {
        await dispatch(removeAllScrapeJobs({ jobNames }));
      }

      await dispatch(uninstallIntegration({ integrationId }));
      setIsUninstalling(true);
      onIntegrationSuccessfulDeletion();
    } catch (error) {
      onIntegrationFailedDeletion();
    } finally {
      onClose?.();
    }
  };

  return (
    <Modal
      className={styles.overrideModal}
      title={`You are about to uninstall this integration.`}
      isOpen={true}
      onDismiss={onDisableModal}
    >
      <div className={styles.modalContent}>
        <Alert severity="error" title="">
          {hasLibraryPanels && (
            <p className={styles.paragraph}>
              <b>
                Move or remove any existing{' '}
                <TextLink href={integrationDashboardsUrl} inline>
                  library panels from the integration&apos;s folder
                </TextLink>{' '}
                before uninstalling.
              </b>
            </p>
          )}
          Uninstalling an integration deletes its dashboard folder and alert rule namespace, as well as associated
          custom dashboards and alerts.
          {selectedIntegration.type === SourceType.AgentIntegration && (
            <p className={styles.paragraph}>
              Uninstalling doesn&apos;t affect{' '}
              <b>{agentMode === CollectorMode.AgentStatic ? 'Grafana Agent' : 'Grafana Alloy'}</b> — you must still stop{' '}
              {agentMode === CollectorMode.AgentStatic ? 'the agent' : 'Alloy'} manually. See{' '}
              <TextLink
                href="https://grafana.com/docs/grafana-cloud/data-configuration/integrations/install-and-manage-integrations/#remove-an-installed-integration"
                external
                inline
              >
                Remove an installed integration
              </TextLink>{' '}
              for details.
            </p>
          )}
        </Alert>
        <div className={styles.buttons}>
          <Button variant="secondary" disabled={isUninstalling} onClick={onDisableModal}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            disabled={isButtonDisabled}
            onClick={handleRemoveIntegration}
            data-testid={Pages.Source.removeIntegration}
            title={hasLibraryPanels ? 'Please remove the library panels first' : ''}
          >
            {isUninstalling && <Icon className={styles.icon} name="fa fa-spinner" />}
            Remove integration
          </Button>
        </div>
      </div>
    </Modal>
  );
};
