import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { CollectorInstallationInstructions, useCollectorSelector } from '@grafana-cloud/collector';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

import { AgentModalFooter } from '../AgentConnection/AgentModalFooter';
import { clickOpenAgentConfigModal } from '../state/slice';

const getStyles = (theme: GrafanaTheme2) => ({
  modalContent: css({
    display: 'flex',
    flexDirection: 'column',
    ol: {
      marginLeft: '10px',
      li: {
        marginBottom: '40px',
        '> p:first-child': {
          fontSize: theme.typography.h5.fontSize,
        },

        '> h2': {
          fontSize: theme.typography.h5.fontSize,
        },
      },
      overflow: 'scroll',
      padding: '0 4px 0 4px',
      flexGrow: 1,
    },
  }),
});

export const AgentConfigModal: FC = () => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { token, existingToken } = useCollectorSelector((state) => state.collector);
  const integrationId = useSelectedIntegrationId();

  return (
    <>
      <Button
        size="md"
        onClick={() => {
          setShowModal(true);
          dispatch(clickOpenAgentConfigModal(integrationId));
        }}
        data-testid={Pages.Source.AgentConfig.agentConfigButton}
      >
        Run the Grafana agent
      </Button>
      <Modal
        title="Agent configuration"
        isOpen={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
        contentClassName={styles.modalContent}
      >
        <CollectorInstallationInstructions showTestConnection={false} showOSSelection={false} />
        <AgentModalFooter isDisabled={!(token || existingToken)} onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};
