import React, { useState, useMemo } from 'react';

import { RadioButtonGroup, TextArea, useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { useApiToken } from 'hooks/useApiToken';
import { GRAFANA_EXAMPLE_API, DEFAULT_GRAPHITE_URL, DEFAULT_PROM_URL } from 'utils/consts';
import { getInfluxUrl } from 'utils/misc';

import { getStyles } from './styles';
import { snippetTypeOptions, dataTypeOptions } from './utilities/dataTypes';
import {
  promBody,
  graphiteBody,
  curlCodeGraphite,
  curlCodePrometheus,
  goCodeGraphite,
  goCodePrometheus,
  javaCodeGraphite,
  javaCodePrometheus,
  nodejsCodeGraphite,
  nodejsCodePrometheus,
  pythonCodeGraphite,
  pythonCodePrometheus,
  rubyCodeGraphite,
  rubyCodePrometheus,
} from './utilities/snippets/codeSnippets';

export const HttpMetrics = () => {
  const styles = useStyles2(getStyles);
  const [dataType, setDataType] = useState<string>('prometheus');
  const [snippetGraf, setSnippetTypeGraf] = useState<string>('curl');
  const [snippetProm, setSnippetTypeProm] = useState<string>('curl');
  const [testMetric, setTestMetric] = useState<string>(promBody);

  const { hmInstancePromId, hmInstanceGraphiteUrl, hmInstanceGraphiteId, hmInstancePromUrl } =
    useHostedDataDetailsWithFallback();
  const { token } = useApiToken();

  const influxUrl = useMemo(
    () => getInfluxUrl(hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom/push` : DEFAULT_PROM_URL),
    [hmInstancePromUrl]
  );
  const graphiteUrl = hmInstanceGraphiteUrl ? `${hmInstanceGraphiteUrl}/graphite/metrics` : DEFAULT_GRAPHITE_URL;
  const isMetricsSelected = dataType === 'prometheus';

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestMetric(e.target.value);
  };

  useMemo(() => {
    if (dataType === 'prometheus') {
      setTestMetric(promBody);
    } else if (dataType === 'graphite') {
      setTestMetric(graphiteBody);
    } else {
      return;
    }
  }, [dataType]);

  const displaySnippet = () => {
    return isMetricsSelected ? getSnippetTypeProm(snippetProm) : getSnippetTypeGraf(snippetGraf);
  };

  const getSnippetTypeGraf = (snippet: string) => {
    if (snippet === 'curl') {
      return curlCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'python') {
      return pythonCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'node') {
      return nodejsCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'ruby') {
      return rubyCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'go') {
      return goCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'java') {
      return javaCodeGraphite(hmInstanceGraphiteId, token, graphiteUrl);
    } else {
      return;
    }
  };

  const getSnippetTypeProm = (snippet: string) => {
    if (snippet === 'curl') {
      return curlCodePrometheus(hmInstancePromId, token, influxUrl);
    } else if (snippet === 'python') {
      return pythonCodePrometheus(hmInstancePromId, token, influxUrl);
    } else if (snippet === 'node') {
      return nodejsCodePrometheus(hmInstancePromId, token, influxUrl);
    } else if (snippet === 'ruby') {
      return rubyCodePrometheus(hmInstancePromId, token, influxUrl);
    } else if (snippet === 'go') {
      return goCodePrometheus(hmInstancePromId, token, influxUrl);
    } else if (snippet === 'java') {
      return javaCodePrometheus(hmInstancePromId, token, influxUrl);
    } else {
      return;
    }
  };

  const successMessage = 'Your API key has been generated below!';

  return (
    <>
      <h1>Configure sending metrics via HTTP</h1>
      <ol className={styles.instructions}>
        <li>
          <h2>Metrics Format: </h2>
          <p>
            <b>Select your metrics format (prometheus or graphite) to be used in your application.</b>
          </p>
          <RadioButtonGroup
            options={dataTypeOptions}
            value={dataType}
            onChange={(val) => setDataType(val)}
            className={styles.radioButtonGroup}
          />
        </li>
        <li>
          <h2>Generate API Key</h2>
          <p>
            <b>
              Generate an API Key to be used to authenticate your metrics (api key will be added to below code snippets
              once generated.)
            </b>
          </p>
          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={isMetricsSelected ? hmInstancePromId : hmInstanceGraphiteId}
            successMessage={successMessage}
          />
          <li>
            <h2>Anatomy of a metric</h2>
            <p>
              <b>Learn more about what a metric holds and how you can use them in your application.</b>
            </p>
            <div>
              {isMetricsSelected ? (
                <>
                  <p>Name: Prometheus style name(required)</p>
                  <p>Label: Identifies a specific visualization of a metric</p>
                  <p>Source: The source of the metric, the instance job that is generating the metric</p>
                  <p>Metric: Metric to be pushed up, the specific value</p>
                </>
              ) : (
                <>
                  <p>Name: Graphite style name(required) E.g. accounts.authentication.password.succeeded </p>
                  <p>Interval: the resolution of the metric in seconds (required) </p>
                  <p>Value: float64 value (required)</p>
                  <p>Time: unix timestamp in seconds (required)</p>
                  <p>
                    Tags: a list of key value pairs of tags(optional) [&lsquo;key1=value1&lsquo;,
                    &lsquo;key2=value2&lsquo;] E.g. [&lsquo;location=usa&lsquo;, &lsquo;environment=production&lsquo;]
                  </p>
                </>
              )}
              <TextArea type="textarea" value={testMetric} onChange={handleChange} rows={10}></TextArea>
            </div>
          </li>
          <li>
            <h2>Send a {isMetricsSelected ? 'Prometheus' : 'Graphite'} metric from your application code.</h2>
            <p>
              <b>Add the code snippet of your choice to your application and start pushing metrics to Grafana Cloud!</b>
            </p>
          </li>
          {isMetricsSelected ? (
            <RadioButtonGroup
              options={snippetTypeOptions}
              value={snippetProm}
              onChange={(v) => setSnippetTypeProm(v)}
              className={styles.radioButtonGroup}
            />
          ) : (
            <RadioButtonGroup
              options={snippetTypeOptions}
              value={snippetGraf}
              onChange={(v) => setSnippetTypeGraf(v)}
              className={styles.radioButtonGroup}
            />
          )}
          <pre>{displaySnippet()}</pre>
        </li>
      </ol>
    </>
  );
};
