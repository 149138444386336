import {
  OpenTelemetryLogo,
  OpenTelemetryArch,
  OpenTelemetryJavaArch,
  OpenTelemetryCollectorArch,
} from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

const otelArchitecture = `
![architecture diagram](${OpenTelemetryArch})
`;

const javaOtelArchitecture = `
![architecture diagram](${OpenTelemetryJavaArch})
`;

const collectorOtelArchitecture = `
![architecture diagram](${OpenTelemetryCollectorArch})
`;

const otelDescription = `
OpenTelemetry instrumentation is the recommended standard to observe applications with Grafana Cloud.
This integration helps you set up the Grafana Agent to send all OpenTelemetry data: traces, metrics, and logs, to Grafana Cloud.

Note that it is also possible to send OpenTelemetry signals to Grafana Cloud using the OpenTelemetry Collector as documented
[here](https://grafana.com/docs/opentelemetry/collector/send-otlp-to-grafana-cloud-databases).
Moreover, Grafana Cloud will soon expose a native OTLP intake supporting all signal types: traces, metrics, and logs, that will simplify the data collection architecture.`;

const collectorOtelDescription = `
OpenTelemetry instrumentation is the recommended standard to observe applications with Grafana Cloud.
This integration helps you set up the OpenTelemetry Collector to send all telemetry data: traces, metrics, and logs, to Grafana Cloud.

This is also documented [here](https://grafana.com/docs/opentelemetry/collector/send-otlp-to-grafana-cloud-databases).
Moreover, Grafana Cloud will soon expose a native OTLP intake supporting all signal types: traces, metrics, and logs, that will simplify the data collection architecture.`;

export const OPEN_TELEMETRY_ID = 'open-telemetry';
export const JAVA_OPEN_TELEMETRY_ID = 'java-open-telemetry';
export const COLLECTOR_OPEN_TELEMETRY_ID = 'collector-open-telemetry';

export const javaOtel: Source = {
  id: JAVA_OPEN_TELEMETRY_ID,
  name: 'Java OpenTelemetry',
  logo_url: OpenTelemetryLogo,
  overview: javaOtelArchitecture + otelDescription,
  type: SourceType.AgentIntegration,
  search_keywords: ['jvm', 'otel'],
  categories: ['Traces', 'APM', 'Data Format'],
};

export const openTelemetry: Source = {
  id: OPEN_TELEMETRY_ID,
  name: 'OpenTelemetry (OTLP)',
  logo_url: OpenTelemetryLogo,
  overview: otelArchitecture + otelDescription,
  type: SourceType.AgentIntegration,
  search_keywords: ['otel'],
  categories: ['Traces', 'APM', 'Data Format'],
};

export const openTelemetryCollector: Source = {
  id: COLLECTOR_OPEN_TELEMETRY_ID,
  name: 'OpenTelemetry Collector',
  logo_url: OpenTelemetryLogo,
  overview: collectorOtelArchitecture + collectorOtelDescription,
  type: SourceType.AgentIntegration,
  search_keywords: ['otel'],
  categories: ['Traces', 'APM', 'Data Format'],
};
