import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { Screenshot } from 'models/api-models';
import { handleKeyDown } from 'utils/aria';

import { getDashboardScreenshotStyles } from './DashboardScreenshot.styles';

interface Props {
  screenshot: Screenshot;
  onClick: () => void;
}
export const DashboardScreenshot = ({ screenshot, onClick }: Props) => {
  const styles = useStyles2(getDashboardScreenshotStyles);
  return (
    <div onClick={onClick} onKeyDown={handleKeyDown(onClick)} tabIndex={0} role="button">
      <img className={styles.img} title={screenshot.description} src={screenshot.url} alt={screenshot.description} />
    </div>
  );
};
