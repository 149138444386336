import { css } from '@emotion/css';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { RootState } from 'state';

import { IntegrationConnectionStatus } from '../../state/slice';

import { IntegrationButtonProps } from './props';

export const TestConnectionButton: FC<IntegrationButtonProps> = ({ testConnection }) => {
  const getStyles = () => ({
    spinnerIcon: css`
      margin-right: 5px;
    `,
    button: css`
      margin-bottom: 16px;
    `,
  });

  const styles = useStyles2(getStyles);
  const { integrationConnectionResult } = useSelector((state: RootState) => state.agent);

  const pendingStatus =
    integrationConnectionResult.status === IntegrationConnectionStatus.Pending ||
    integrationConnectionResult.status === IntegrationConnectionStatus.NoData;

  return (
    <Button
      size="md"
      onClick={testConnection}
      className={styles.button}
      data-testid={Pages.Source.AgentConfig.testConnectionButton}
    >
      {pendingStatus && <Icon className={styles.spinnerIcon} name="fa fa-spinner" />}
      Test connection
    </Button>
  );
};
