import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getUninstallStyles = (theme: GrafanaTheme2) => ({
  overrideModal: css({
    maxWidth: '600px',
    padding: '6px',
  }),
  modalContent: css({
    h3: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(2),
      fontSize: theme.typography.h4.fontSize,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }),
  icon: css({
    marginRight: '10px',
  }),
  buttons: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '100px',
  }),
  paragraph: css({
    marginTop: '15px',
  }),
});
