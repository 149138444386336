import { lastValueFrom } from 'rxjs';

import { DataSourceInstanceSettings } from '@grafana/data';
import { BackendSrvRequest, config, getBackendSrv, FetchResponse } from '@grafana/runtime';

import { Dashboard } from 'types/dashboard';

import { SuccessfulFetchResponse } from '../models/api-models';

import { PLUGIN_PROXY_URL } from './utils';

export function getAllDataSources(): DataSourceInstanceSettings[] {
  return Object.values(config.datasources);
}

export function fetchApi<D>(
  url: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    url: url,
    showErrorAlert: false,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export function get<D>(url: string): Promise<D> {
  return getBackendSrv().get(url);
}

export async function apiGet<D>(url: string, optionsOverride: Partial<BackendSrvRequest> = {}): Promise<D> {
  const response = await fetchApi<D>(url, { ...optionsOverride, method: 'GET' });
  return response?.data;
}

export function post<D>(url: string, data: any, options: Partial<BackendSrvRequest> = {}): Promise<D> {
  return getBackendSrv().post(url, data, options);
}

export async function del<D>(url: string, options: Partial<BackendSrvRequest> = {}): Promise<FetchResponse<D>> {
  return await lastValueFrom(
      getBackendSrv().fetch<D>({
      method: 'DELETE',
      url,
      ...options,
    })
  );
}

export function requestIntegrations<D = any>(
  path: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: `${PLUGIN_PROXY_URL}${path}`,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export function getDashboards<D = Dashboard[]>(): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: `api/search?type=dash-db`,
  };

  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export class NoDataSourceFoundError extends Error {}

export function requestDatasource<D = any>(
  dataSourceName: string,
  path: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const datasource: DataSourceInstanceSettings | undefined = getAllDataSources().find(
    (el) => el?.name === dataSourceName || el?.uid === dataSourceName
  );
  if (!datasource) {
    throw new NoDataSourceFoundError(`No datasource called ${dataSourceName} found.`);
  }

  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: datasource.url + path,
    ...optionsOverride,
  };

  if (datasource.basicAuth || datasource.withCredentials) {
    options.credentials = 'same-origin';
  }

  if (datasource.basicAuth && options.headers) {
    options.headers.Authorization = datasource.basicAuth;
  }

  return lastValueFrom(getBackendSrv().fetch<D>(options));
}
