import { DeployMode } from './BeylaInstructions';
import { METRICS_ENDPOINT_PATH, OTLP_PREFIX, TRACES_ENDPOINT_PATH } from './beylaConstants';

interface ConfigOptions {
  token: string;
  hostedPromId: number;
  hostedTempoId: number;
  otlpHttpUrl: string;
  includeAssertsAppO11y: boolean;
  includeOtelApplication: boolean;
  includeOtelTraces: boolean;
  deploymentMode: DeployMode;
  alloyEndpoint?: string;
}

export function generateStandaloneBeylaConfig({
  token,
  hostedPromId,
  hostedTempoId,
  otlpHttpUrl,
  includeAssertsAppO11y,
  includeOtelApplication,
  includeOtelTraces,
  deploymentMode,
  alloyEndpoint,
}: ConfigOptions) {
  return `helm repo add grafana https://grafana.github.io/helm-charts &&  
helm repo update &&  
helm upgrade --install --atomic --timeout 300s beyla grafana/beyla \\
    --namespace "beyla" --create-namespace --values - <<EOF  
config:  
  data:    
    # Contents of the actual Beyla configuration file.  
    # By default Beyla will instrument all services in this cluster.  
    # If you'd like to change that please visit our documentation for instructions:  
    # https://grafana.com/docs/beyla/next/configure/options/.  
    discovery:  
      services:  
        - k8s_namespace: .  
env:  
  ${generateEnv({ token, hostedPromId, hostedTempoId, otlpHttpUrl, includeAssertsAppO11y, includeOtelApplication, includeOtelTraces, deploymentMode, alloyEndpoint })}
EOF`;
}

function generateEnv({
  token,
  hostedPromId,
  hostedTempoId,
  otlpHttpUrl,
  includeAssertsAppO11y,
  includeOtelApplication,
  includeOtelTraces,
  deploymentMode,
  alloyEndpoint,
}: ConfigOptions) {
  const envEntries = [];
  if (includeOtelApplication || includeAssertsAppO11y) {
    envEntries.push(
      `OTEL_EXPORTER_OTLP_METRICS_ENDPOINT: "${
        deploymentMode !== DeployMode.Standalone ? alloyEndpoint : otlpHttpUrl
      }${deploymentMode === DeployMode.Standalone ? OTLP_PREFIX : ''}${METRICS_ENDPOINT_PATH}"`
    );
    if (deploymentMode === DeployMode.Standalone) {
      envEntries.push(
        `OTEL_EXPORTER_OTLP_METRICS_HEADERS: "Authorization=Basic ${generateAuthHeader(token, hostedPromId)}"`
      );
    }
    envEntries.push(
      `BEYLA_OTEL_METRIC_FEATURES: "${generateMetricFeatures({ includeAssertsAppO11y, includeOtelApplication })}"`
    );
  }

  if (includeAssertsAppO11y) {
    envEntries.push('BEYLA_NETWORK_METRICS: "true"');
  }
  if (includeOtelTraces) {
    envEntries.push(
      `OTEL_EXPORTER_OTLP_TRACES_ENDPOINT: "${deploymentMode !== DeployMode.Standalone ? alloyEndpoint : otlpHttpUrl}${
        deploymentMode === DeployMode.Standalone ? OTLP_PREFIX : ''
      }${TRACES_ENDPOINT_PATH}"`
    );
    envEntries.push(
      `OTEL_EXPORTER_OTLP_TRACES_HEADERS: "Authorization=Basic ${generateAuthHeader(token, hostedPromId)}"`
    );
  }

  return envEntries.join('\n  ');
}

export function generateMetricFeatures({
  includeAssertsAppO11y,
  includeOtelApplication,
}: Pick<ConfigOptions, 'includeAssertsAppO11y' | 'includeOtelApplication'>) {
  const features = [];
  if (includeAssertsAppO11y) {
    features.push('application_span', 'application_service_graph');
  }
  if (includeOtelApplication) {
    features.push('application');
  }
  return features.join(',');
}

function generateAuthHeader(token: string, stackId: number) {
  if (!token) {
    return '<Generate token to continue>';
  }
  return btoa(`${stackId}:${token}`);
}
