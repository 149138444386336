/* eslint-disable-next-line no-restricted-imports */
import { useDispatch as useDispatchUntyped } from 'react-redux';

import { AppDispatch } from 'state';

// Use throughout the app instead of plain `useDispatch` in order
// to have proper typing for thunks.
// https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
type DispatchFunc = () => AppDispatch;
export const useDispatch: DispatchFunc = useDispatchUntyped;
