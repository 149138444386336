import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Badge, useStyles2 } from '@grafana/ui';

import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

const getStyles = (theme: GrafanaTheme2) => ({
  header: css({
    color: theme.colors.text.maxContrast,
    marginBottom: 0,
    lineHeight: '22px',
  }),
  modalTitleLogoWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    marginRight: '13px',
    width: '30px',
    height: '30px',
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      alignSelf: 'center',
    },
  }),
  modalTitle: css({
    marginRight: '10px',
  }),
});

export const ModalTitle: FC<{ item: Source }> = ({ item }) => {
  const styles = useStyles2((theme) => getStyles(theme));
  const logo_url = config.theme2.isLight && item.light_logo_url ? item.light_logo_url : item.logo_url;
  return (
    <>
      {logo_url && (
        <div className={styles.modalTitleLogoWrapper}>
          <img src={logo_url} alt={`logo of ${item.name}`} />
        </div>
      )}
      <div className={styles.modalTitle}>
        <h4 className={styles.header}> {item.name}</h4>
      </div>
      {item.type === SourceType.Alpha && <Badge text="Alpha" color="blue" />}
    </>
  );
};
