import { SourceType } from 'utils/enums';

import { Category } from '../types/Category';

export const sourceTypeToCategoryMap = new Map([
  [SourceType.AgentIntegration, Category.Integration],
  [SourceType.Alpha, Category.Integration],
  [SourceType.CloudApp, Category.CloudApp],
  [SourceType.DataSource, Category.DataSource],
  [SourceType.HostedData, Category.HostedData],
  [SourceType.SaasIntegration, Category.Integration],
  [SourceType.MetricsEndpointIntegration, Category.Integration],
]);

export const categoryToLabelMap = new Map([
  [Category.Integration, 'Integration'],
  [Category.MostPopular, 'Most popular'],
  [Category.HostedData, 'Custom data'],
  [Category.DataSource, 'Data source'],
  [Category.CloudApp, 'Cloud app'],
]);
