import { LanguageOptions } from 'utils/enums';

export const languageOptions = [
  { label: 'Grafana Alloy', value: LanguageOptions.GrafanaAlloy },
  { label: 'Grafana Agent', value: LanguageOptions.GrafanaAgent },
  { label: 'Golang', value: LanguageOptions.Golang },
  { label: 'Java', value: LanguageOptions.Java },
  { label: '.NET', value: LanguageOptions.Dotnet },
  { label: 'eBPF', value: LanguageOptions.EBPF },
  { label: 'Python', value: LanguageOptions.Python },
  { label: 'Ruby', value: LanguageOptions.Ruby },
  { label: 'Node.js', value: LanguageOptions.NodeJs },
  { label: 'Rust', value: LanguageOptions.Rust },
];
