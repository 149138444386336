import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  saveButton: css({
    width: 'fit-content',
  }),
  icon: css({
    path: {
      fill: theme.colors.warning.text,
    },
    marginRight: '10px',
  }),
  alertText: css({
    color: theme.colors.warning.text,
  }),
  text: css({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.colors.text.primary,
    paddingLeft: '30px',
    marginTop: '15px',
  }),
  buttons: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  overrideModal: css({
    maxWidth: '600px',
    padding: '6px',
  }),
  modalContent: css({
    h3: {
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(2),
      fontSize: theme.typography.h4.fontSize,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }),
});
