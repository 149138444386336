import { useQueryParam } from 'hooks/useQueryParam';

import { Pages } from './Pages';
import { useAgentModeSelection } from './useAgentModeSelection';

export function useSaveOptionAndNavigate() {
  const [_, setPageQueryParam] = useQueryParam('page');
  const { setAgentModeSelection } = useAgentModeSelection();

  return function (page: Pages) {
    if (page === Pages.Selection) {
      setAgentModeSelection(undefined);
    } else if (page !== Pages.Convert) {
      setAgentModeSelection(page);
    }

    setPageQueryParam(page);
  };
}
