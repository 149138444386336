import React from 'react';
import { Provider } from 'react-redux';

import { AppRootProps } from '@grafana/data';

import { AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { CollectorProvider } from '@grafana-cloud/collector';
import { GrafanaApiProvider } from '@grafana-cloud/grafana-api';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { IntegrationsApiProvider } from '@grafana-cloud/integrations-api';
import { useGetHostedDataDetailsQuery } from 'api/grafanaCom/grafanaComApi';
import { PLUGIN_PROXY_URL } from 'api/utils';
import { OSSelectionWrapper } from 'components/OSSelectionWrapper';
import { store } from 'state';
import { track } from 'utils/track';

import { JsonData } from './state/slice';

interface ProvidersProps {
  appRootProps: AppRootProps<JsonData>;
  children: React.ReactElement;
}

const InitializedAccessPoliciesProvider: React.FC<{ children: React.ReactElement; stackId: string }> = ({
  children,
  stackId,
}) => {
  // We cannot use this hook in Providers component, because the redux store is not initialized yet
  const { data: hostedData } = useGetHostedDataDetailsQuery();
  return (
    <AccessPoliciesProvider
      config={{
        realm: { realmType: RealmTypes.STACK, realmIdentifier: stackId },
        region: hostedData?.regionSlug!,
        track,
      }}
    >
      {children}
    </AccessPoliciesProvider>
  );
};

export const Providers: React.FC<ProvidersProps> = ({ appRootProps, children }) => {
  const stackId = String(appRootProps.meta.jsonData?.grafana_instance_id);

  return (
    <Provider store={store}>
      <GrafanaComApiProvider config={{ baseApiUrl: `${PLUGIN_PROXY_URL}/grafanacom-api/auth` }}>
        <GrafanaApiProvider
          config={{
            baseApiUrl: 'api',
          }}
        >
          <IntegrationsApiProvider
            config={{
              baseApiUrls: {
                admin: `${PLUGIN_PROXY_URL}/integrations-api-admin`,
                editor: `${PLUGIN_PROXY_URL}/integrations-api-editor`,
              },
            }}
          >
            <CollectorProvider
              config={{
                stackId,
                components: {
                  OSSelection: OSSelectionWrapper,
                },
                track,
              }}
            >
              <InitializedAccessPoliciesProvider stackId={stackId}>{children}</InitializedAccessPoliciesProvider>
            </CollectorProvider>
          </IntegrationsApiProvider>
        </GrafanaApiProvider>
      </GrafanaComApiProvider>
    </Provider>
  );
};
