import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { InstallCard } from 'components/InstallCard';

import { TestConnection } from '../AgentConnection/TestConnection';

import { K8sIntegrationInstructions } from './K8sIntegrationInstructions';
import { SetupK8sCluster } from './SetupK8sCluster';

const getStyles = (theme: GrafanaTheme2) => ({
  step: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
});

export function K8sInstallationInstructions(): React.JSX.Element {
  const styles = useStyles2(getStyles);

  return (
    <>
      <SetupK8sCluster />
      <K8sIntegrationInstructions />
      <InstallCard installCardStyles={styles.step} />

      <div className={styles.step}>
        <h4>Test connection</h4>
        <p>Test that Grafana Alloy is collecting data and sending it to Grafana Cloud.</p>
        <TestConnection />
      </div>
    </>
  );
}
