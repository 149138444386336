import { OsBasedOptions } from '@grafana-cloud/collector';
import {
  AgentDetails,
  AgentDetailsAlloyAdvanced,
  AgentDetailsAlloyBothModes,
  AgentDetailsAlloySimple,
  AlloyAdvancedContent,
  AlloyPlatforms,
  AlloySimpleContent,
  StaticContent,
} from 'api/integrations/types';

import { SetupMode } from './state/slice';

export function selectBlocksForPlatform<Blocks>(
  osValue: OsBasedOptions,
  alloyPlatforms: AlloyPlatforms<Blocks> | undefined
): Blocks | undefined {
  if (alloyPlatforms === undefined) {
    return undefined;
  } else if (alloyPlatforms.type === 'unified') {
    return alloyPlatforms.all;
  } else {
    switch (osValue) {
      case OsBasedOptions.Debian:
      case OsBasedOptions.RedHat:
      case OsBasedOptions.OtherLinuxDistros:
        return alloyPlatforms.linux;
      case OsBasedOptions.Windows:
        return alloyPlatforms.windows;
      case OsBasedOptions.MacOs:
        return alloyPlatforms.darwin;
      case OsBasedOptions.K8s:
        return undefined;
    }
  }
}

export function getAgentDetailsContent(
  agentDetails: AgentDetails,
  setupMode: SetupMode | undefined
): StaticContent | AlloySimpleContent | AlloyAdvancedContent {
  switch (agentDetails.contentType) {
    case 'static':
      return agentDetails.static;
    case 'alloy-simple':
      return agentDetails.simple;
    case 'alloy-advanced':
      return agentDetails.advanced;
    case 'alloy-both-modes':
      if (setupMode === SetupMode.Simple) {
        return agentDetails.simple;
      } else {
        return agentDetails.advanced;
      }
  }
}

export function areFilteredBlocksPresent(
  agentDetails: AgentDetailsAlloyAdvanced | AgentDetailsAlloySimple | AgentDetailsAlloyBothModes,
  setupMode: SetupMode | undefined
): boolean {
  const content = getAgentDetailsContent(agentDetails, setupMode) as AlloySimpleContent | AlloyAdvancedContent;

  const hasIntegrationsFilteredBlocks =
    content.integrations !== undefined &&
    Object.values(content.integrations).some((child) => typeof child === 'object' && 'blocks_filtered' in child);

  const hasMetricsFilteredBlocks =
    content.metrics !== undefined &&
    Object.values(content.metrics).some((child) => typeof child === 'object' && 'blocks_filtered' in child);

  return hasIntegrationsFilteredBlocks || hasMetricsFilteredBlocks;
}
