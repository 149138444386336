export const pipCommand = `pip install grafana-openai-monitoring`;

export const nodeCommand = `npm install grafana-openai-monitoring`;

export function pythonCodeCompletion(
  openAiKey: string,
  promUrl: string,
  lokiUrl: string,
  hmInstancePromId: number,
  hlInstanceId: number,
  token: string | undefined
): string {
  return `
  from openai import OpenAI
  from grafana_openai_monitoring import chat_v1
  
  client = OpenAI(
      api_key="${!openAiKey ? 'YOUR_OPEN_AI_API_KEY' : openAiKey}",
  )
  
  # Apply the custom decorator to the OpenAI API function
  client.completions.create = chat_v1.monitor(
      client.completions.create,
      metrics_url="${promUrl}",  # Example: "https://prometheus.grafana.net/api/prom"
      logs_url="${lokiUrl}",  # Example: "https://logs.example.com/loki/api/v1/push/"
      metrics_username=${hmInstancePromId},  # Example: "123456"
      logs_username=${hlInstanceId},  # Example: "987654"
      access_token="${!token ? 'your-api-key' : token}"  # Example: "glc_eyasdansdjnaxxxxxxxxxxx"
  )
  
  # Now any call to client.completions.create will be automatically tracked
  response = client.completions.create(model="davinci", max_tokens=100, prompt="Isn't Grafana the best?")
  print(response)`;
}

export function pythonChatCompletion(
  openAiKey: string,
  promUrl: string,
  lokiUrl: string,
  hmInstancePromId: number,
  hlInstanceId: number,
  token: string | undefined
): string {
  return `
  from openai import OpenAI
  from grafana_openai_monitoring import chat_v2
  
  client = OpenAI(
      api_key="${!openAiKey ? 'YOUR_OPEN_AI_API_KEY' : openAiKey}",
  )
  
  # Apply the custom decorator to the OpenAI API function
  client.chat.completions.create = chat_v2.monitor(
      client.chat.completions.create,
      metrics_url="${promUrl}",  # Example: "https://prometheus.grafana.net/api/prom"
      logs_url="${lokiUrl}",  # Example: "https://logs.example.com/loki/api/v1/push/"
      metrics_username=${hmInstancePromId},  # Example: "123456"
      logs_username=${hlInstanceId},  # Example: "987654"
      access_token="${!token ? 'your-api-key' : token}"  # Example: "glc_eyasdansdjnaxxxxxxxxxxx"
  )
  
  # Now any call to client.chat.completions.create will be automatically tracked
  response = client.chat.completions.create(model="gpt-4", max_tokens=100, messages=[{"role": "user", "content": "What is Grafana?"}])
  print(response)`;
}

export function nodeChatCompletion(
  openAiKey: string,
  promUrl: string,
  lokiUrl: string,
  hmInstancePromId: string | number,
  hlInstanceId: string | number,
  token: string | undefined
): string {
  return `
import OpenAI from 'openai';
import { chat_v2 } from 'grafana-openai-monitoring';

const openai = new OpenAI({
  apiKey: '${!openAiKey ? 'YOUR_OPEN_AI_API_KEY' : openAiKey}',
});

// Patch method
chat_v2.monitor(openai, {
  metrics_url: '${promUrl}',
  logs_url: '${lokiUrl}',
  metrics_username: ${hmInstancePromId},
  logs_username: ${hlInstanceId},
  access_token: '${!token ? 'your-api-key' : token}'  # Example: "glc_eyasdansdjnaxxxxxxxxxxx",
});

// Now any call to openai.chat.completions.create will be automatically tracked
async function main() {
  const completion = await openai.chat.completions.create({
    model: 'gpt-4',
    max_tokens: 100,
    messages: [{ role: 'user', content: 'What is Grafana?' }],
  });
  console.log(completion);
}

main();`;
}

export function nodeCodeCompletion(
  openAiKey: string,
  promUrl: string,
  lokiUrl: string,
  hmInstancePromId: string | number,
  hlInstanceId: string | number,
  token: string | undefined
): string {
  return `
import OpenAI from 'openai';
import { chat_v1 } from 'grafana-openai-monitoring';

const openai = new OpenAI({
  apiKey: '${!openAiKey ? 'YOUR_OPEN_AI_API_KEY' : openAiKey}',
});

// Patch method
chat_v1.monitor(openai, {
  metrics_url: '${promUrl}',
  logs_url: '${lokiUrl}',
  metrics_username: ${hmInstancePromId},
  logs_username: ${hlInstanceId},
  access_token: '${!token ? 'your-api-key' : token}'  # Example: "glc_eyasdansdjnaxxxxxxxxxxx',
});

// Now any call to openai.completions.create will be automatically tracked
async function main() {
  const completion = await openai.completions.create({
    model: 'davinci',
    max_tokens: 100,
    prompt: 'Isn\'t Grafana the best?',
  });
  console.log(completion);
}

main();`;
}
