import { TempoLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const HOSTED_TRACES_ID = 'htInstanceId';

export const hostedTraces: Source = {
  id: HOSTED_TRACES_ID,
  name: 'Hosted traces',
  logo_url: TempoLogo,
  overview: `Powered by Grafana Tempo, Grafana Cloud provides an easy-to-use, highly scalable, and cost-effective distributed tracing system.`,
  type: SourceType.HostedData,
  search_keywords: ['traces, tempo'],
  categories: ['Traces'],
};
