import React, { useMemo } from 'react';

import { useCollectorSelector } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { Pages } from 'e2eSelectors/pages';
import { CollectorMode } from 'utils/enums';

import { generateOsInstallConfig } from '../OSInstallConfig';

interface RestartCollectorProps {
  collectorMode: CollectorMode;
  isLargeTitle?: boolean;
}

export function RestartCollector(props: RestartCollectorProps) {
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);
  const collector = props.collectorMode === CollectorMode.AgentStatic ? 'the agent' : 'Grafana Alloy';

  const { restartCode, restartTitle } = useMemo(
    () => generateOsInstallConfig(selectedOs, props.collectorMode),
    [selectedOs, props.collectorMode]
  );

  return (
    <>
      {props.isLargeTitle ? <h2>Restart {collector}</h2> : <h4>Restart {collector}</h4>}
      <p>Once you’ve changed your configuration file, run the following command to restart {collector}.</p>
      {restartCode && (
        <Clipboard
          title={restartTitle}
          code={restartCode}
          multipleLines
          data-testid={Pages.Source.AgentConfig.restartCommand}
          clipboardButtonType="below"
        />
      )}
    </>
  );
}
