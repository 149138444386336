import { GraphiteLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const HOSTED_GRAPHITE_METRICS_ID = 'hmInstanceGraphiteId';

export const hostedGraphiteMetrics: Source = {
  id: HOSTED_GRAPHITE_METRICS_ID,
  name: 'Hosted Graphite metrics',
  logo_url: GraphiteLogo,
  overview: `Your Grafana Cloud stack includes a scalable, high performance, and highly available Graphite metrics service.`,
  type: SourceType.HostedData,
  search_keywords: ['graphite', 'metrics'],
  categories: ['Time Series'],
};
