import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, useStyles2 } from '@grafana/ui';

import { Markdown } from 'components/Markdown';
import { TextLink } from 'components/TextLink';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';
import { DATASOURCES_URL, INTEGRATIONS_URL } from 'utils/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    a {
      text-decoration: underline;
    }
  `,
  warningIcon: css`
    color: ${theme.colors.warning.text};
    margin-top: ${theme.spacing(0.25)};
  `,
  row: css`
    display: flex;
    flex-direction: row;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacing(1)};
    width: 100%;
  `,
  button: css`
    align-self: end;
  `,
});

const getGrafanaComUrlForDataSource = (id: string) =>
  `https://grafana.com/grafana/plugins/${id}?utm_source=cloud-connections-plugin`;

export const PermissionMissingModalContent: FC<{ item: Source; closeModal: () => void }> = ({ item, closeModal }) => {
  const styles = useStyles2((theme) => getStyles(theme));

  return (
    <div className={styles.wrapper}>
      {/* We don't have an overview for data sources */}
      {item.type === SourceType.DataSource ? (
        <p>
          Read more about this data source{' '}
          <TextLink href={getGrafanaComUrlForDataSource(item.id.split('datasource-')[1])} external inline>
            here
          </TextLink>
        </p>
      ) : (
        item.overview && <Markdown markdownSnippet={item.overview} />
      )}
      <hr />
      <div className={styles.row}>
        <Icon name="exclamation-triangle" className={styles.warningIcon} />
        <div className={styles.column}>
          <p>
            Editors cannot add new connections.
            {item.type === SourceType.DataSource ? (
              <>
                {' '}
                You may check to see if it is already configured in{' '}
                <TextLink href={DATASOURCES_URL} inline>
                  Data sources
                </TextLink>
                .
              </>
            ) : (
              (item.type === SourceType.AgentIntegration ||
                item.type === SourceType.SaasIntegration ||
                item.type === SourceType.MetricsEndpointIntegration) && (
                <>
                  {' '}
                  You may check to see if it is already configured in{' '}
                  <TextLink href={INTEGRATIONS_URL} inline>
                    Integrations
                  </TextLink>
                  .
                </>
              )
            )}
          </p>
          <p>To add a new connection, contact your Grafana admin.</p>
          <Button className={styles.button} onClick={closeModal}>
            Okay
          </Button>
        </div>
      </div>
    </div>
  );
};
