import React from 'react';

import { Button } from '@grafana/ui';

import { resetFilters } from 'features/catalog/state/slice';
import { useQueryParams } from 'hooks';
import { useDispatch } from 'hooks/useDispatch';

export const ResetFilters = () => {
  const [_, setQueryParams] = useQueryParams();
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(resetFilters());
    setQueryParams({
      cat: undefined,
      type: undefined,
      search: undefined,
      searchCat: undefined,
    });
  };

  return (
    <>
      <p>Not what you were looking for?</p>
      <Button fill="solid" variant="secondary" onClick={handleReset}>
        Clear all filters to see more search results
      </Button>
    </>
  );
};
