// The API in this file is created with RTQ

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { LibraryElementsResult } from 'api/hostedGrafana/libraryPanels.types';
import { GrafanaDatasourceResponse } from 'models/api-models';
import { Source } from 'types/Source';
import { formatDatasourceList, formatGrafanaDatasourceList } from 'utils/configTransformation';
import { DEFAULT_SCRAPE_INTERVAL } from 'utils/consts';

export const hostedGrafanaApi = createApi({
  reducerPath: 'hostedGrafanaApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getScrapeInterval: builder.query<string, void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const result = await baseQuery('/datasources/uid/grafanacloud-prom');
        if (result.data) {
          const response = result.data as { jsonData: { timeInterval?: string } };
          return { data: response.jsonData?.timeInterval ?? DEFAULT_SCRAPE_INTERVAL };
        } else if (result.error?.status === 404) {
          return { data: DEFAULT_SCRAPE_INTERVAL };
        } else if (result.error) {
          return { error: result.error };
        } else {
          return { error: { error: `unknown error in getScrapeInterval`, status: 'CUSTOM_ERROR' } };
        }
      },
    }),
    getDataSources: builder.query<Source[], void>({
      query: () => '/plugins?enabled=1&type=datasource&embedded=0',
      transformResponse: formatDatasourceList,
    }),
    getGrafanaDataSources: builder.query<Source[], void>({
      query: () => '/gnet/plugins?typeCodeIn[]=datasource',
      transformResponse: (response: GrafanaDatasourceResponse) => formatGrafanaDatasourceList(response.items),
    }),
    getLibraryPanels: builder.query<LibraryElementsResult, { folderFilterUIDs: string[] }>({
      query: ({ folderFilterUIDs }) => ({
        url: '/library-elements',
        params: { folderFilterUIDs: folderFilterUIDs.join(',') },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetScrapeIntervalQuery,
  useGetDataSourcesQuery,
  useGetGrafanaDataSourcesQuery,
  useGetLibraryPanelsQuery,
} = hostedGrafanaApi;
