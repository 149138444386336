import { flatten, groupBy, mergeWith } from 'lodash';
import { useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { useGetDataSourcesQuery, useGetGrafanaDataSourcesQuery } from 'api/hostedGrafana/hostedGrafanaApi';
import { useIntegrationsQuery, useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { allSemanticSearchCategories } from 'models/api-models';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';
import { javaLocalSources } from 'utils/localSources';
import { CONNECT_DATA_URL } from 'utils/misc';

import { SourcesByType, constructSourcesByType } from '../hooks';
import { Category } from '../types/Category';
import { isCardWithModal, isPermissionMissing } from '../utils';
import { categoryToLabelMap } from '../utils/mappings';

import { GroupBase, OptionsOrGroups, SemanticSearchOption, SemanticSearchOptionType } from './utils';

function getCategoriesWithCount(integrations: Source[]): SemanticSearchOption[] {
  return allSemanticSearchCategories.map((category) => {
    let count = 0;
    integrations.forEach((integration) => {
      if (integration.categories?.includes(category)) {
        count++;
      }
    });

    return { value: category, label: `${category} (${count})`, type: SemanticSearchOptionType.Category };
  });
}

function getSourcesAsOptions(allSources: SourcesByType): Array<GroupBase<SemanticSearchOption>> {
  const typeToLabelMap: Map<string, string | undefined> = new Map([
    ['integrations', categoryToLabelMap.get(Category.Integration)],
    ['dataSources', categoryToLabelMap.get(Category.DataSource)],
    ['hostedDataIntegrations', categoryToLabelMap.get(Category.HostedData)],
    ['cloudApps', categoryToLabelMap.get(Category.CloudApp)],
  ]);

  return Object.entries(allSources).map(([type, sources]) => {
    const sortedSources = sources.sort((a, b) => a.name.localeCompare(b.name));
    const sourceOptions: SemanticSearchOption[] = sortedSources.map((source) => {
      if (isCardWithModal(source) || isPermissionMissing(source)) {
        return {
          value: source.name,
          label: source.name,
          type: SemanticSearchOptionType.SourceWithModal,
        };
      }

      return {
        value: source.name,
        label: source.name,
        type: SemanticSearchOptionType.SourceWithLink,
        url: source.externalUrl ?? `${CONNECT_DATA_URL}/${source.id}`,
      };
    });
    const group = { label: typeToLabelMap.get(type), options: sourceOptions };
    return group;
  });
}

function mergeSecondLevelSources(firstLevelSources: SourcesByType, secondLevelSources: Source[]): SourcesByType {
  const secondLevelGrouped = groupBy(secondLevelSources, 'type');

  const secondLevelSourcesByType: SourcesByType = {
    // Deliberately omitting Alpha integrations here, because they don't have a page where we could redirect to.
    integrations: [
      ...(secondLevelGrouped[SourceType.AgentIntegration] ?? []),
      ...(secondLevelGrouped[SourceType.SaasIntegration] ?? []),
      ...(secondLevelGrouped[SourceType.MetricsEndpointIntegration] ?? []),
    ],
    dataSources: secondLevelGrouped[SourceType.DataSource] ?? [],
    hostedDataIntegrations: secondLevelGrouped[SourceType.HostedData] ?? [],
    cloudApps: secondLevelGrouped[SourceType.CloudApp] ?? [],
  };

  function concatArraysAndFilterDuplicates(destinationArray: Source[], sourceArray: Source[]) {
    const sourceArrayWithoutDuplicates = sourceArray.filter(
      (sourceSource) => !destinationArray.some((destSource) => destSource.id === sourceSource.id)
    );
    return destinationArray.concat(sourceArrayWithoutDuplicates);
  }

  return mergeWith(firstLevelSources, secondLevelSourcesByType, concatArraysAndFilterDuplicates);
}

export const useOptions = (): { options: OptionsOrGroups<SemanticSearchOption>; isLoading: boolean } => {
  const dataSourcesQuery = useGetDataSourcesQuery();
  const grafanaDataSourcesQuery = useGetGrafanaDataSourcesQuery();
  const integrationsQuery = useIntegrationsQuery({});
  const javaIntegrationsQuery = useJavaIntegrationsQuery({});

  const sourcesByType = useMemo(
    () =>
      constructSourcesByType(
        integrationsQuery.data,
        javaIntegrationsQuery.data,
        dataSourcesQuery.data,
        grafanaDataSourcesQuery.data
      ),
    [integrationsQuery.data, javaIntegrationsQuery.data, dataSourcesQuery.data, grafanaDataSourcesQuery.data]
  );

  const isAllSourcesLoading =
    dataSourcesQuery.isLoading ||
    grafanaDataSourcesQuery.isLoading ||
    integrationsQuery.isLoading ||
    javaIntegrationsQuery.isLoading;

  // Calculate category counts based on the cards we show in the catalog, i.e. sourcesByType.
  // That means that we don't include second-level cards, like Cloudwatch Logs.
  // This way the count will always match the number of cards shown after selecting a category.
  const categories = useMemo(() => getCategoriesWithCount(flatten(Object.values(sourcesByType))), [sourcesByType]);

  const sourcesByTypeIncludingSecondLevel = useMemo(() => {
    const secondLevelSources = [...javaLocalSources, ...(javaIntegrationsQuery.data ?? [])];
    return mergeSecondLevelSources(sourcesByType, secondLevelSources);
  }, [javaIntegrationsQuery.data, sourcesByType]);

  // Show second level cards in exact sources for better discoverability.
  const exactSources = useMemo(
    () => getSourcesAsOptions(sourcesByTypeIncludingSecondLevel),
    [sourcesByTypeIncludingSecondLevel]
  );

  return { options: [{ label: 'Categories', options: categories }, ...exactSources], isLoading: isAllSourcesLoading };
};

function getRecentsGroup(options: SemanticSearchOption[]) {
  return [{ label: 'Recent', options }];
}

export const useRecents = () => {
  const [storedSelections, setStoredSelections] = useLocalStorage<SemanticSearchOption[]>(
    'connections-semantic-search',
    []
  );

  function removeAgentInstallationEntry() {
    const updatedSelections = (storedSelections ?? []).filter(
      (option) => option.value !== 'Agent Installation' || option.type !== SemanticSearchOptionType.SourceWithLink
    );
    setStoredSelections(updatedSelections);
  }

  const recents = useMemo(() => getRecentsGroup(storedSelections ?? []), [storedSelections]);

  function updateStoredSelections(selectedOption: SemanticSearchOption) {
    const filteredSelections = (storedSelections ?? []).filter(
      (recentOption) => recentOption.value !== selectedOption.value
    );
    const newSelectionsToStore = [{ ...selectedOption, recent: true }, ...filteredSelections].slice(0, 7);
    setStoredSelections(newSelectionsToStore);
  }
  return { recents, updateStoredSelections, removeAgentInstallationEntry };
};

export const useSuggestions = (allOptions: OptionsOrGroups<SemanticSearchOption>) => {
  const suggestionOptions = useMemo(() => {
    const suggestions = [
      { category: 'Categories', names: ['Servers and VMs', 'Cloud Provider', 'Database'] },
      { category: categoryToLabelMap.get(Category.Integration), names: ['Linux Server', 'MySQL'] },
      { category: categoryToLabelMap.get(Category.HostedData), names: ['Hosted Prometheus metrics'] },
      { category: categoryToLabelMap.get(Category.CloudApp), names: ['Kubernetes Monitoring'] },
    ];

    return suggestions.map((value) => {
      const { category, names } = value;
      const categoryGroup = allOptions.find((group) => group.label === category) as GroupBase<SemanticSearchOption>;
      const options = names.map((name) => categoryGroup?.options.find((option) => option.value === name));
      const foundOptions = options.filter((option) => option !== undefined);
      return foundOptions as SemanticSearchOption[];
    });
  }, [allOptions]);

  return [{ label: 'Suggestions', options: flatten(suggestionOptions) }];
};
