import { css } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { RootState } from 'state';

import { AgentStatus } from '../state/slice';

import { TestStatusError } from './TestStatusError';
import { TestStatusSuccess } from './TestStatusSuccess';

const TestStatus = () => {
  const styles = useStyles2(getStyles);

  const { agentStatus } = useSelector((state: RootState) => state.agent);

  let statusMessage = null;
  switch (agentStatus) {
    case AgentStatus.None:
      statusMessage = null;
      break;

    case AgentStatus.Pending:
    case AgentStatus.NoData:
      statusMessage = <p className={styles.checkConnection}>Checking connection. It might take up to a minute...</p>;
      break;

    case AgentStatus.Success:
      statusMessage = <TestStatusSuccess />;
      break;

    case AgentStatus.Error:
      statusMessage = <TestStatusError />;
      break;
  }
  return <div>{statusMessage}</div>;
};

export default TestStatus;

const getStyles = (theme: GrafanaTheme2) => ({
  checkConnection: css({
    fontSize: theme.typography.bodySmall.fontSize,
    color: theme.colors.text.secondary,
    marginRight: theme.spacing(2),
    width: '100%',
  }),
});
