import { css } from '@emotion/css';
import React from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { seeUpdates } from 'features/catalog/state/slice';
import { useQueryParams } from 'hooks';
import { useDispatch } from 'hooks/useDispatch';

import { FilterIntegration } from '../types/FilterIntegration';

const getStyles = () => ({
  button: css`
    padding-top: 0;
    padding-bottom: 0;
  `,
});

export const UpdateNotification = () => {
  const styles = useStyles2(getStyles);
  const [_, setQueryParams] = useQueryParams();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(seeUpdates());
    setQueryParams({
      cat: undefined,
      type: FilterIntegration.NewUpdates,
    });
  };

  return (
    <Button fill="text" variant="primary" onClick={handleClick} className={styles.button}>
      View available updates
    </Button>
  );
};
