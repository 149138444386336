import { RootState } from 'state';

export const isLogsToggleDirty = (state: RootState) => {
  const sourceDetails = state.source.sourceDetails;
  const isLogsEnabled = state.agent.configuredParameters.isLogsEnabled;

  const isLogsToggleDisplayed = sourceDetails.configuration_defaults?.enable_logs_toggle ?? false;

  if (!isLogsToggleDisplayed) {
    return false;
  }

  const savedOrDefaultValue = sourceDetails.installation?.configuration?.configurable_logs
    ? !sourceDetails.installation.configuration.configurable_logs.logs_disabled
    : sourceDetails.configuration_defaults?.configurable_logs_defaults
      ? !sourceDetails.configuration_defaults.configurable_logs_defaults.logs_disabled
      : false;

  return Boolean(isLogsEnabled) !== savedOrDefaultValue;
};
