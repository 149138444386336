import { k6Icon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const k6: Source = {
  id: 'k6-app',
  name: 'Grafana Cloud k6',
  logo_url: k6Icon,
  type: SourceType.CloudApp,
  externalUrl: '/a/k6-app',
  categories: ['Developer Tools'],
};
