import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, LinkButton, useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';
import { Pages as e2eSelectors } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { CONVERT_LINK, ALLOY_LINK, STATIC_LINK, ALLOY_INSTALL_LINK } from '../consts';

import { Pages } from './Pages';
import { useSaveOptionAndNavigate } from './useSaveOptionAndNavigate';

export const AgentConfigConvertPage = ({ integrationName }: { integrationName: string }) => {
  const styles = useStyles2(getStyles);
  const saveOptionAndNavigate = useSaveOptionAndNavigate();
  const { trackRudderStackEvent } = useRudderStack();
  return (
    <ol className={styles.list} data-testid={e2eSelectors.Source.AgentModeSelection.agentConfigConvertPage}>
      <li className={styles.gapBelow}>
        <h2>Install the Alloy binary next to Grafana Agent</h2>
        <p>
          Follow the{' '}
          <TextLink href={ALLOY_INSTALL_LINK} external inline>
            documentation
          </TextLink>{' '}
          to install Grafana Alloy.
        </p>
      </li>
      <li>
        <h2>Convert your entire existing Static configuration file to use Grafana Alloy</h2>
        <p>
          Alloy’s built-in convert command can migrate your{' '}
          <TextLink href={STATIC_LINK} external inline>
            Grafana Agent Static
          </TextLink>{' '}
          configuration to a{' '}
          <TextLink href={ALLOY_LINK} external inline>
            Grafana Alloy
          </TextLink>{' '}
          configuration.
        </p>
        <LinkButton href={CONVERT_LINK} target="_blank" rel="noreferrer" className={styles.gapBelow}>
          Use the convert command
        </LinkButton>
      </li>
      <li>
        <h2>View instructions to configure the {integrationName} integration using Grafana Alloy</h2>
        <p>
          After you have converted your Static configuration to a Grafana Alloy configuration, you will follow the
          Grafana Alloy instructions to configure all future integrations.
        </p>
        <Button
          onClick={() => {
            saveOptionAndNavigate(Pages.Alloy);
            trackRudderStackEvent(RudderStackEvents.AgentModeProceedFromConvertPage, {});
          }}
          variant="secondary"
        >
          Configure the {integrationName} integration
          <Icon name="arrow-right" size="md" className={styles.icon} />
        </Button>
      </li>
    </ol>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  icon: css({
    marginLeft: theme.spacing(1),
  }),
  list: css({
    listStylePosition: 'inside', // to place marker inside content area
    'li::marker': {
      fontSize: theme.typography.h2.fontSize,
    },
    h2: {
      display: 'inline', // to place header on the same line as marker
    },
    p: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  }),
  gapBelow: css({
    marginBottom: theme.spacing(10),
  }),
});
