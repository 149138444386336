import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    hostnameInput: css({
      maxWidth: '480px',
    }),
    label: css({
      maxWidth: 'unset',
    }),
  };
};
