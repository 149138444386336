import React from 'react';

import { LinkButton } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSecnfkFLNnIDNv4yjET99xpu-upq8_qlkvp_SSXZvQ_y9Jo8g/viewform?usp=sf_link';

export const CICDObservabilityModalContent = () => {
  return (
    <>
      <p>
        CI/CD Observability is a prospective feature that is currently under evaluation for potential inclusion in
        Grafana Cloud. This feature aims to offer insights into your CI/CD pipelines, empowering you to monitor and
        enhance your software delivery process effectively. It helps you understand the impact of your changes on your
        software delivery performance and provides visibility into the health of your pipelines.
      </p>
      <p>Do you mind answering a few brief questions?</p>
      <LinkButton href={FORM_LINK} target="_blank" data-testid={Pages.Catalog.alphaIntegrationModalButton}>
        Take survey
      </LinkButton>
    </>
  );
};
