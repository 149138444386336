import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button, Field, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { MetricsEndpointFlavor, MetricsEndpointJob, MetricsEndpointJobApiResponse } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { EditJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';
import { getJobFormStyles } from '../common/MetricsEndpointInstructions.styles';
import { ScrapeIntervalField } from '../common/ScrapeIntervalField';

import AuthField from './AuthField';
import { MetricsURLField } from './MetricsURLField';

type ReactHookFormProps = {
  url: string;
  authenticationMethod: string;
  scrapeIntervalSeconds: number;
  basicPassword: string;
  basicUsername: string;
  bearerToken: string;
  name: string;
};

const getDefaultValues = (job: MetricsEndpointJob) => {
  if (!job) {
    return {
      basicPassword: '',
      basicUsername: '',
      scrapeIntervalSeconds: 60,
      authenticationMethod: 'basic',
      bearerToken: '',
      url: '',
      name: '',
      flavor: MetricsEndpointFlavor.Default,
    };
  }

  return job;
};

export const EditJob: FC<EditJobFormProps<MetricsEndpointJobApiResponse>> = ({ onSaveJob, job: jobApi }) => {
  const job = {
    authenticationMethod: jobApi?.authentication_method,
    scrapeIntervalSeconds: jobApi?.scrape_interval_seconds,
    basicPassword: jobApi?.basic_password,
    basicUsername: jobApi?.basic_username,
    bearerToken: jobApi?.bearer_token,
    url: jobApi?.url,
    name: jobApi?.name,
    flavor: jobApi?.flavor,
  } as MetricsEndpointJob;

  const styles = useStyles2(getJobFormStyles);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });

  const methods = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues: getDefaultValues(job),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const isButtonDisabled =
    !connectionSuccess || createJobStatus === QueryStatus.pending || createJobStatus === QueryStatus.fulfilled;

  const handleEdit: SubmitHandler<ReactHookFormProps> = (currentJob) => {
    onSaveJob({
      name: currentJob.name,
      authentication_method: currentJob.authenticationMethod,
      scrape_interval_seconds: currentJob.scrapeIntervalSeconds,
      basic_password: currentJob.basicPassword,
      basic_username: currentJob.basicUsername,
      bearer_token: currentJob.bearerToken,
      url: currentJob.url,
      enabled: job.enabled ?? true,
      flavor: MetricsEndpointFlavor.Default,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleEdit)}>
        <Field label="Scrape Job Name" invalid={errors.name ? true : undefined} error={errors.name?.message}>
          <span>{job.name}</span>
        </Field>
        <div className={styles.job}>
          <MetricsURLField />
          <ScrapeIntervalField />
          <AuthField onSuccess={() => setConnectionSuccess(true)} />
        </div>
        <JobStatusWidget />
        <Button variant="primary" type="submit" aria-label="Save Scrape Job" disabled={isButtonDisabled}>
          {createJobStatus === QueryStatus.pending && <Spinner className={styles.spinner} />}
          Save Scrape Job
        </Button>
      </form>
    </FormProvider>
  );
};
