import { css } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { RootState } from 'state';

export const GrafanaAgentMessage = () => {
  const styles = useStyles2(getGrafanaAgentMessageStyles);

  const { agentCheckInstalled } = useSelector((state: RootState) => state.agent);

  return agentCheckInstalled ? (
    <p className={styles.paragraph}>
      You are now running the agent on your machine. We automatically installed the
      <LinkButton
        className={styles.linkButton}
        fill="text"
        target="_blank"
        href="/d/integration-agent/grafana-agent-overview"
      >
        Grafana Agent Overview dashboard
      </LinkButton>
      so you can check on the health of your agent.
    </p>
  ) : (
    <p className={styles.paragraph}>You are now running the agent on your machine.</p>
  );
};

const getGrafanaAgentMessageStyles = (theme: GrafanaTheme2) => ({
  linkButton: css({
    padding: theme.spacing(0.5),
    '&:hover': {
      textDecoration: 'underline',
      background: 'transparent',
    },
  }),
  paragraph: css({
    marginBottom: 20,
  }),
});
