import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { TextLink } from 'components/TextLink';

const getStyles = (theme: GrafanaTheme2) => ({
  externalLinks: css({
    p: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.colors.text.maxContrast,
      marginBottom: '18px',
    },

    ul: {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
      listStyle: 'none',

      li: {
        marginBottom: '9px',

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
});

const constructExternalLinks = (orgSlug: string) => {
  const orgBaseUrl = 'https://grafana.com/orgs';
  const docBaseUrl = 'https://grafana.com/docs/grafana-cloud';

  return {
    grafanaCloud: {
      label: 'Manage Grafana Cloud',
      links: [
        { label: 'Subscription and billing', url: `${orgBaseUrl}/${orgSlug}/subscription` },
        { label: 'API keys', url: `${orgBaseUrl}/${orgSlug}/api-keys` },
        { label: 'Team members', url: `${orgBaseUrl}/${orgSlug}/members` },
      ],
    },
    extraHelp: {
      label: 'Looking for extra help?',
      links: [
        { label: 'Integrations documentation', url: `${docBaseUrl}/integrations/` },
        { label: 'Learn more about Grafana Agent', url: `${docBaseUrl}/agent/` },
        { label: 'Quickstarts', url: `${docBaseUrl}/quickstart/` },
      ],
    },
  };
};

type Links = {
  label: string;
  url: string;
};

type Props = {
  externalLinks: {
    label: string;
    links: Links[];
  };
};

const RenderLinks: FC<Props> = ({ externalLinks }) => {
  return (
    <>
      <p>{externalLinks.label}</p>
      <ul>
        {externalLinks.links.map((link, index) => {
          return (
            <li key={index}>
              <TextLink href={link.url} external>
                {link.label}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const ExternalLinks: FC = () => {
  const styles = useStyles2(getStyles);
  const { orgSlug } = useHostedDataDetailsWithFallback();
  const externalLinks = constructExternalLinks(orgSlug);

  return (
    <div className={styles.externalLinks}>
      <RenderLinks externalLinks={externalLinks.grafanaCloud} />
      <RenderLinks externalLinks={externalLinks.extraHelp} />
    </div>
  );
};
