import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppError } from 'utils/errors';

type AppState = {
  generalError?: AppError;
  isGeneralErrorShowing: boolean;
  localError: AppError | undefined;
};

export type JsonData = {
  grafana_instance_id: string;
  integrations_endpoint: string;
  hosted_exporters_api_endpoint: string;
  datasources?: any;
};

const initialState: AppState = {
  generalError: undefined,
  isGeneralErrorShowing: false,
  /**
   * localError represents a non-destructive error, in the sense that it
   * does not break the UX.
   */
  localError: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setGeneralError(state, action: PayloadAction<AppError>) {
      state.generalError = action.payload;
    },
    setLocalError(state, action: PayloadAction<AppError | undefined>) {
      state.localError = action.payload;
    },
    unsetLocalError(state) {
      state.localError = initialState.localError;
    },
    unsetGeneralError(state) {
      delete state.generalError;
    },
    setIsGeneralErrorShowing(state, action: PayloadAction<boolean>) {
      state.isGeneralErrorShowing = action.payload;
    },
    pathnameChanged(state) {
      // clear general error when the user navigates away
      if (state.isGeneralErrorShowing) {
        delete state.generalError;
      }
    },
  },
});

// eslint-disable-next-line import/no-unused-modules
export const {
  resetState,
  setLocalError,
  unsetLocalError,
  setGeneralError,
  unsetGeneralError,
  setIsGeneralErrorShowing,
  pathnameChanged,
} = appSlice.actions;

export const apiTokenGenerateSuccess = createAction<string>('apiTokenGenerateSuccess');

export default appSlice.reducer;
