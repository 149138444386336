import { cx } from '@emotion/css';
import React, { useRef, useState } from 'react';

import { Modal, useStyles2, Icon } from '@grafana/ui';

import { Screenshot } from 'models/api-models';
import { handleKeyDown } from 'utils/aria';

import { DashboardScreenshot } from './DashboardScreenshot';
import { getDashboardScreenshotsStyles } from './DashboardScreenshots.styles';

export const DashboardScreenshots = ({ screenshots }: { screenshots: Screenshot[] }) => {
  const [expandedScreenshotIndex, setExpandedScreenshotIndex] = useState<number>(-1);
  const styles = useStyles2(getDashboardScreenshotsStyles);

  const leftArrowRef = useRef<HTMLDivElement>(null);
  const rightArrowRef = useRef<HTMLDivElement>(null);

  const expandedScreenshot = screenshots[expandedScreenshotIndex];
  const imageCount = screenshots.length;
  const currentPosition = expandedScreenshotIndex + 1;
  const isModalOpen = expandedScreenshotIndex !== -1;
  const isLeftArrowDisabled = expandedScreenshotIndex === 0;
  const isRightArrowDisabled = expandedScreenshotIndex === screenshots.length - 1;

  function closeModal(): void {
    setExpandedScreenshotIndex(-1);
  }

  function navigateTo(index: number): void {
    if (index >= 0 && index < screenshots.length) {
      setExpandedScreenshotIndex(index);
    }
  }

  function navigateBack(): void {
    if (isLeftArrowDisabled) {
      return;
    }
    navigateTo(expandedScreenshotIndex - 1);
    if (leftArrowRef.current) {
      leftArrowRef.current.focus();
    }
  }

  function navigateForward(): void {
    if (isRightArrowDisabled) {
      return;
    }
    navigateTo(expandedScreenshotIndex + 1);
    if (rightArrowRef.current) {
      rightArrowRef.current.focus();
    }
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'ArrowLeft') {
      navigateBack();
    } else if (event.key === 'ArrowRight') {
      navigateForward();
    }
  }

  return (
    <>
      <div className={styles.screenshotsWrapper}>
        {screenshots.map((dashboard, index) => {
          return (
            <DashboardScreenshot
              key={dashboard.url}
              onClick={() => setExpandedScreenshotIndex(index)}
              screenshot={dashboard}
            />
          );
        })}
      </div>
      <Modal
        className={styles.modal}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        title={expandedScreenshot?.description}
      >
        <h2 className={styles.overlayText}>
          {currentPosition}/{imageCount}
        </h2>
        <div
          ref={leftArrowRef}
          onClick={navigateBack}
          onKeyUp={handleKeyUp}
          tabIndex={0}
          role="button"
          data-testid="left-arrow"
          className={cx(styles.arrowLeft, { [styles.disabled]: isLeftArrowDisabled })}
          aria-disabled={isLeftArrowDisabled}
        >
          <Icon size="xxl" name="arrow-left" className={styles.arrowIcon} />
        </div>

        <div onClick={closeModal} onKeyDown={handleKeyDown(closeModal)} tabIndex={0} role="button">
          <img
            className={styles.img}
            title={expandedScreenshot?.description}
            src={expandedScreenshot?.url}
            alt={expandedScreenshot?.description}
          />
        </div>

        <div
          ref={rightArrowRef}
          className={cx(styles.arrowRight, { [styles.disabled]: isRightArrowDisabled })}
          onClick={navigateForward}
          onKeyUp={handleKeyUp}
          tabIndex={0}
          role="button"
          aria-disabled={isRightArrowDisabled}
          data-testid="right-arrow"
        >
          <Icon size="xxl" name="arrow-right" className={styles.arrowIcon} />
        </div>
      </Modal>
    </>
  );
};
