import { css } from '@emotion/css';
import React, { useState, FC } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, RadioButtonGroup } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { useGetScrapeIntervalQuery } from 'api/hostedGrafana/hostedGrafanaApi';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { GenerateAPI } from 'components/GenerateAPI';
import { Install } from 'components/Install';
import { TextLink } from 'components/TextLink';
import { TestConnection } from 'features/agent-integrations/AgentConnection/TestConnection';
import { ViewYourDashboardsSection } from 'features/agent-integrations/ViewYourDashboards/ViewYourDashboardsSection';
import { useApiToken } from 'hooks/useApiToken';
import { RootState } from 'state';
import { GRAFANA_EXAMPLE_API } from 'utils/consts';
import { ApiKeyType, Status } from 'utils/enums';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css`
    margin-left: 24px;

    li::marker {
      font-size: ${theme.typography.h3.fontSize};
    }

    li {
      a {
        color: ${theme.colors.text.link};

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
  topMargin: css`
    margin-top: ${theme.spacing(3)};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(5)};
  `,
  marginBottomClipboard: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  generateConnectionVariables: css`
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
  `,
});

const instructionOptions = [
  { label: 'Create a new API token', value: ApiKeyType.NewKey },
  { label: 'Use an existing API token', value: ApiKeyType.ExistingKey },
];

const PreInstructions: FC = () => {
  return (
    <li>
      <h3>Install the Grafana Cloud Extension in Docker Desktop</h3>
      <p>
        To start sending data, install the{' '}
        <TextLink href="https://www.docker.com/products/extensions/" external inline>
          Grafana Cloud Extension
        </TextLink>{' '}
        for Docker Desktop.
      </p>
      <p>
        Go to{' '}
        <TextLink href="https://hub.docker.com/extensions/grafana/docker-desktop-extension" external inline>
          Docker Desktop extensions marketplace
        </TextLink>{' '}
        and install the Grafana extension. Once it is installed, open the extension from the side bar to connect your
        Grafana cloud account.
      </p>
      <p>
        Install this integration to get the values of <strong>connection variables</strong> required to connect your
        account.
      </p>
    </li>
  );
};

interface ConnectionVariablesProps {
  apiToken?: string;
}

const ConnectionVariables: FC<ConnectionVariablesProps> = ({ apiToken }) => {
  const styles = useStyles2(getStyles);
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { data: agentScrapeInterval } = useGetScrapeIntervalQuery();
  const code = `CLOUD_URL="${hostedDataDetails.url}" CLOUD_HOSTED_METRICS_URL="${hostedDataDetails.hmInstancePromUrl}/api/prom/push" CLOUD_HOSTED_METRICS_ID="${hostedDataDetails.hmInstancePromId}" CLOUD_SCRAPE_INTERVAL="${agentScrapeInterval}" CLOUD_HOSTED_LOGS_URL="${hostedDataDetails.hlInstanceUrl}/loki/api/v1/push" CLOUD_HOSTED_LOGS_ID="${hostedDataDetails.hlInstanceId}" CLOUD_RW_API_KEY="${apiToken}"`;
  const extensionUrl = `docker-desktop://dashboard/extension-tab?extensionId=grafana/docker-desktop-extension&connectionVariables=${code}`;

  return (
    <>
      <p>
        <TextLink href={extensionUrl} external inline>
          Click here to open and connect Docker Desktop extension
        </TextLink>{' '}
        (make sure the extension is already installed) or copy the line below and paste it in the Docker Desktop
        extension for Grafana.
        <div className={styles.marginBottomClipboard}></div>
        <Clipboard multipleLines code={code} clipboardButtonType="below" />
      </p>
    </>
  );
};

const PostInstructions: FC = () => {
  const styles = useStyles2(getStyles);

  const { sourceDetails } = useSelector((state: RootState) => state.source);
  const areDashboardsAvailable = sourceDetails.dashboards?.status === Status.Available;
  const [instruction, setInstruction] = useState<ApiKeyType>(ApiKeyType.NewKey);
  const apiKeySuccessMessage = 'Your API token has been generated below.';
  const { token, success: isApiTokenGenerated } = useApiToken();
  const tokenValue = instruction === ApiKeyType.NewKey ? token : 'CLOUD_RW_API_KEY';

  return (
    <>
      <li className={styles.topMargin}>
        <h3>Connect your Docker Desktop Instance to Grafana Cloud</h3>
        <p>Use the following details to connect:</p>

        <h6>Grafana.com API token</h6>
        <div>
          <RadioButtonGroup
            options={instructionOptions}
            value={instruction}
            className={styles.marginBottom}
            onChange={(val) => setInstruction(val)}
          />
          {instruction === ApiKeyType.NewKey && (
            <GenerateAPI
              shouldShowClipboard={true}
              shouldShowInstruction={false}
              config={GRAFANA_EXAMPLE_API}
              instanceId={0}
              successMessage={apiKeySuccessMessage}
            />
          )}
          {instruction === ApiKeyType.ExistingKey && (
            <p>
              Use your own Grafana.com API token, the api token must have the <strong>MetricsPublisher</strong> role.{' '}
              Replace <code>CLOUD_RW_API_KEY</code> with your own API key.
            </p>
          )}
        </div>

        {(isApiTokenGenerated || instruction === ApiKeyType.ExistingKey) && (
          <ConnectionVariables apiToken={tokenValue} />
        )}
      </li>
      {(isApiTokenGenerated || instruction === ApiKeyType.ExistingKey) && (
        <>
          <li>
            <h3>Check if Grafana Cloud is receiving data from Docker Desktop</h3>
            <div>
              <TestConnection />
            </div>
          </li>
          {areDashboardsAvailable && <ViewYourDashboardsSection />}
        </>
      )}
    </>
  );
};

export const DockerDesktopInstructions: FC = () => {
  const styles = useStyles2(getStyles);
  const source = useSelectedAgentOrSaasIntegration();
  return (
    <ol className={styles.instructions}>
      <PreInstructions />
      <Install />
      {source.installation && <PostInstructions />}
    </ol>
  );
};
