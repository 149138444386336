import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  error: css({
    color: theme.colors.error.text,
  }),
  alert: css({
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: theme.typography.bodySmall.fontSize,
    alignItems: 'center',
  }),
  success: css({
    color: theme.colors.success.text,
  }),
  warning: css({
    color: theme.colors.warning.text,
  }),
  icon: css({
    marginRight: '6px',
  }),
  alertContent: css({
    marginBottom: 0,
    p: {
      marginBottom: 0,
    },
  }),
  base: css({
    fontSize: theme.typography.body.fontSize,
  }),
});
