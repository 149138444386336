import React, { useEffect, useState } from 'react';

import { locationService } from '@grafana/runtime';
import { Alert, Button, Icon } from '@grafana/ui';

import { useInstallDashboardMutation } from 'api/dashboards/dashboardsApi';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import prometheusStarterDashboard from 'dashboards/prometheus-starter.json';
import { useQueryParam } from 'hooks/useQueryParam';
import { QueryError } from 'types';

export const ViewStarterDashboard = () => {
  const [from] = useQueryParam('from');
  const hostedData = useHostedDataDetailsWithFallback();
  const [installDashboard, { data, isLoading, error }] = useInstallDashboardMutation();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (!!data) {
      locationService.push(data.url);
    }
  }, [data]);

  useEffect(() => {
    if (!!error) {
      setShowAlert(true);
    }
  }, [error]);

  function onOpenDashboard() {
    installDashboard({
      slugUrl: hostedData?.url ?? '',
      dashboard: prometheusStarterDashboard,
      folderTitle: 'Prometheus Onboarding',
      overwrite: true,
    });
  }

  if (from === 'onboarding') {
    return (
      <li>
        <h2>View your Starter Dashboard</h2>
        <p>
          Check out the Starter Dashboard to learn how to query, alert and create customizable dashboard views for your
          Prometheus data.
        </p>
        <Button fill={'outline'} disabled={isLoading} onClick={onOpenDashboard}>
          Go to Starter Dashboard {isLoading && <Icon name={'fa fa-spinner'} />}
        </Button>
        {!!showAlert && (
          <Alert severity="error" title="">
            {(error as QueryError)?.data?.message ||
              'There was an error while trying to access the Prometheus Starter dashboard.'}
          </Alert>
        )}
      </li>
    );
  }
  return null;
};
