import { useCallback } from 'react';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';

const useRudderStack = () => {
  const { slug, orgId } = useHostedDataDetailsWithFallback();

  const trackRudderStackEvent = useCallback(
    (event: string, params: object) => {
      (window as any).rudderanalytics?.track(event, {
        ...params,
        org_id: orgId,
        instance_slug: slug,
      });
    },
    [orgId, slug]
  );

  return {
    trackRudderStackEvent,
  };
};

export default useRudderStack;
