import { css } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, Field, Input, Switch, useStyles2 } from '@grafana/ui';

import {
  CollectorState,
  InstallMode,
  UseToken,
  setInstallationModeValue,
  useCollectorDispatch,
  useCollectorSelector,
} from '@grafana-cloud/collector';
import { Clipboard, SelectGroup } from '@grafana-cloud/ui';
import { useGetInstanceConnectionsQuery, useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { Install } from 'components/Install';
import { TextLink } from 'components/TextLink';
import { TestConnection } from 'features/agent-integrations/AgentConnection/TestConnection';
import { AlloyLogo, BeylaLogo } from 'img/agent';

import { DEFAULT_ALLOY_ENDPOINT } from './beylaConstants';
import { generateAlloyBeylaConfig } from './generateAlloyBeylaConfig';
import { generateStandaloneBeylaConfig } from './generateStandaloneBeylaConfig';

export enum DeployMode {
  Standalone = 'standalone',
  Alloy = 'alloy',
  AlloyPreInstalled = 'alloy-pre-installed',
}

export function BeylaInstructions() {
  const styles = useStyles2(getStyles);
  const [setupMode, setSetupMode] = useState<DeployMode>(DeployMode.Standalone);
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { data: instanceConnections } = useGetInstanceConnectionsQuery();
  const [includeAssertsAppO11y, setIncludeAssertsAppO11y] = useState(true);
  const [includeOtelApplication, setIncludeOtelApplication] = useState(true);
  const [includeOtelTraces, setIncludeOtelTraces] = useState(true);
  const [alloyEndpoint, setAlloyEndpoint] = useState(DEFAULT_ALLOY_ENDPOINT);
  const [isAlloyInstalled, setIsAlloyInstalled] = useState(false);
  const collectorDispatch = useCollectorDispatch();
  const newToken = useCollectorSelector((state: CollectorState) => state.collector.token);
  const existingToken = useCollectorSelector((state: CollectorState) => state.collector.existingToken);
  const token = newToken || existingToken;
  const isTracesOny = !includeAssertsAppO11y && !includeOtelApplication;

  useEffect(() => {
    collectorDispatch(
      setInstallationModeValue({
        installationModeValue: setupMode === DeployMode.Alloy ? InstallMode.ALLOY : InstallMode.STATIC,
      })
    );
  }, [collectorDispatch, setupMode]);

  const config = useMemo(() => {
    if (isAlloyInstalled || setupMode === DeployMode.Standalone) {
      return generateStandaloneBeylaConfig({
        token,
        hostedPromId: hostedDataDetails.hpInstanceId,
        hostedTempoId: hostedDataDetails.htInstanceId,
        otlpHttpUrl: instanceConnections?.otlpHttpUrl ?? '',
        includeAssertsAppO11y,
        includeOtelApplication,
        includeOtelTraces,
        alloyEndpoint,
        deploymentMode: setupMode,
      });
    } else {
      return generateAlloyBeylaConfig({
        token,
        hostedPromId: hostedDataDetails.hpInstanceId,
        hostedTempoId: hostedDataDetails.htInstanceId,
        htInstanceUrl: hostedDataDetails.htInstanceUrl,
        hpInstanceUrl: hostedDataDetails.hpInstanceUrl,
        includeAssertsAppO11y,
        includeOtelApplication,
        includeOtelTraces,
      });
    }
  }, [
    instanceConnections?.otlpHttpUrl,
    isAlloyInstalled,
    setupMode,
    token,
    hostedDataDetails.hpInstanceId,
    hostedDataDetails.hpInstanceUrl,
    hostedDataDetails.htInstanceId,
    hostedDataDetails.htInstanceUrl,
    includeAssertsAppO11y,
    includeOtelApplication,
    includeOtelTraces,
    alloyEndpoint,
  ]);

  return (
    <div>
      <p>
        <b>NOTE</b>: These instructions show how to deploy Beyla to your Kubernetes cluster as a standalone Kubernetes
        Daemonset by using Helm. For alternative configuration options, refer to the{' '}
        <TextLink href="https://grafana.com/docs/beyla/next/setup/" external>
          documentation.
        </TextLink>
      </p>

      <ol>
        <li>
          <h3>Prerequisites</h3>
          <p>Before you begin, ensure you have the following available on your local machine:</p>
          <ul className={styles.prereqs}>
            <li className={styles.li}>
              The kubectl command-line tool. To learn how to install kubectl, refer to{' '}
              <TextLink href="https://kubernetes.io/docs/tasks/tools/#kubectl" external>
                kubectl
              </TextLink>{' '}
              from the Kubernetes docs.
            </li>
            <li className={styles.li}>
              The helm command-line tool for managing Helm charts. To learn how to install helm, refer to{' '}
              <TextLink href="https://helm.sh/docs/intro/install/" external>
                Installing Helm
              </TextLink>{' '}
              from the Helm docs.
            </li>
          </ul>
        </li>
        <li>
          <h3>Select features</h3>
          <div className={styles.featuresBox}>
            <h4>Metrics</h4>
            <div className={styles.leftPaddedBox}>
              <Field
                label="Asserts and Application Observability"
                description="Everything you need to do root cause analysis"
              >
                <Switch
                  value={includeAssertsAppO11y}
                  onChange={() => setIncludeAssertsAppO11y(!includeAssertsAppO11y)}
                />
              </Field>
              <Field label="OpenTelemetry HTTP/gRPC/Database/Messaging metrics" description="Application information">
                <Switch
                  value={includeOtelApplication}
                  onChange={() => setIncludeOtelApplication(!includeOtelApplication)}
                />
              </Field>
            </div>
          </div>
          <div className={styles.featuresBox}>
            <h4>Traces</h4>
            <div className={styles.leftPaddedBox}>
              <Field
                label="OpenTelemetry Application traces"
                description="Note: Full Application Traces support is available only for Go applications. For details refer to the tracing support documentation."
              >
                <Switch value={includeOtelTraces} onChange={() => setIncludeOtelTraces(!includeOtelTraces)} />
              </Field>
            </div>
          </div>
        </li>
        <li>
          <h3>Select setup mode</h3>
          <div className={styles.setupModeBox}>
            <div>
              <SelectGroup
                value={setupMode}
                onChange={(value: DeployMode) => {
                  setSetupMode(value);
                }}
                options={[
                  {
                    value: DeployMode.Standalone,
                    label: 'Standalone',
                    description: 'Choose this option if you do not want to use Grafana Alloy.',
                    icon: BeylaLogo,
                  },
                  {
                    value: DeployMode.Alloy,
                    label: 'Alloy',
                    description: 'Choose this option if you want to use Grafana Alloy',
                    icon: AlloyLogo,
                  },
                ]}
              />
            </div>
          </div>
        </li>

        {setupMode === DeployMode.Alloy && (
          <>
            <li>
              <h3>Alloy installed</h3>
              <Field
                label="Is Alloy already installed and running on your cluster?"
                description="Beyla will send metrics to Alloy if it is already installed. If it is not installed, the config provided will install Alloy and enable Beyla as a component"
              >
                <Checkbox value={isAlloyInstalled} onChange={() => setIsAlloyInstalled(!isAlloyInstalled)} />
              </Field>
            </li>
            {isAlloyInstalled && (
              <li>
                <h3>Enter your Alloy endpoint</h3>
                <Input
                  placeholder={DEFAULT_ALLOY_ENDPOINT}
                  value={alloyEndpoint}
                  onChange={(e) => setAlloyEndpoint(e.currentTarget.value)}
                  className={styles.endpointInput}
                />
              </li>
            )}
          </>
        )}

        <li>
          <UseToken />
        </li>

        <li>
          <h3>Deploy Beyla on the cluster</h3>

          <Clipboard code={config} expandHeight multipleLines />
        </li>

        {!isTracesOny && (
          <li>
            <h3>Test your connection</h3>

            <TestConnection />
          </li>
        )}
        <li>
          <h3>Install dashboards</h3>
          <Install />
        </li>
      </ol>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    prereqs: css({
      paddingLeft: theme.spacing(2),
    }),
    li: css({
      marginBottom: '0!important',
      fontSize: `${theme.typography.body.fontSize}!important`,
    }),
    endpointInput: css({
      maxWidth: '700px',
    }),
    featuresBox: css({
      paddingTop: theme.spacing(1),
    }),
    leftPaddedBox: css({
      paddingLeft: theme.spacing(2),
    }),
    setupModeBox: css({
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-start',
    }),
  };
}
