import { css } from '@emotion/css';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useReducer, useEffect } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button, ConfirmModal, Spinner, Icon } from '@grafana/ui';

import {
  useCreateOrUpdateScrapeJobMutation,
  useDeleteScrapeJobsMutation,
  useListScrapeJobsQuery,
} from 'api/hostedExporters/hostedExportersApi';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { InstallCard } from 'components/InstallCard';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { ScrapeJob } from 'models/api-models';
import { InstallSaasIntegration } from 'pages/Source/ConfigurationDetails/Local/SaasIntegrations/InstallSaasIntegration';
import { SaasIntegrationWithJobType, deselectJob, resetState } from 'state/saasIntegration/slice';
import { useFilteredJobs } from 'state/saasIntegration/useFilteredJobs';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { useJobTypeWithFlavor } from '../hooks/useJobTypeWithFlavor';

import { JobCardProps, JobList } from './JobList';
import { JobTableProps, JobTableView } from './JobTableView';

const getStyles = (theme: GrafanaTheme2) => ({
  backToJobsButton: css`
    &:last-child {
      margin-top: ${theme.spacing(2)};
    }

    margin-bottom: ${theme.spacing(2)};
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  step: css`
    position: relative;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.components.input.borderColor};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    max-width: 780px;
  `,
});

enum JobManagementPage {
  JobList,
  JobForm,
  DeleteJob,
  EditJob,
}

type JobManagementState = {
  page: JobManagementPage;
  selectedJob: ScrapeJob | undefined;
  shouldShowInstallButton: boolean;
};

enum ActionType {
  CreateJob,
  EditJob,
  DeleteJob,
  ListJobs,
  ShowInstallButton,
}

type Action = {
  type: ActionType;
  job?: ScrapeJob;
};

function reducer(state: JobManagementState, action: Action): JobManagementState {
  switch (action.type) {
    case ActionType.CreateJob: {
      return {
        ...state,
        page: JobManagementPage.JobForm,
        selectedJob: undefined,
      };
    }
    case ActionType.EditJob: {
      return {
        ...state,
        page: JobManagementPage.EditJob,
        selectedJob: action?.job,
      };
    }
    case ActionType.DeleteJob: {
      return {
        ...state,
        page: JobManagementPage.DeleteJob,
        selectedJob: action?.job,
      };
    }
    case ActionType.ListJobs: {
      return {
        page: JobManagementPage.JobList,
        selectedJob: undefined,
        shouldShowInstallButton: false,
      };
    }
    case ActionType.ShowInstallButton:
      return {
        ...state,
        shouldShowInstallButton: true,
      };
    default: {
      return state;
    }
  }
}

// enforce our generic types here rather than reimplementing for each component
// these can then be imported and used as required
// e.g. ConfluentInstructions/EditJob.tsx
// export const EditJob: FC<EditJobFormProps<ConfluentJobApiResponse>> = ({ onSaveJob, job: jobApi }) => {
export type CreateJobFormProps = {
  onSaveJob: (job: ScrapeJob) => void;
};

export type EditJobFormProps<T> = CreateJobFormProps & {
  job?: T;
  onCancel: () => void;
};

type JobManagerProps<T> = {
  saasIntegrationId: SaasIntegrationWithJobType;
  EditJobForm: FC<EditJobFormProps<T>>;
  CreateJobForm: FC<CreateJobFormProps>;
  JobCard?: FC<JobCardProps>;
  JobTable?: FC<JobTableProps>;
};

export const JobManager = <T extends ScrapeJob>({
  saasIntegrationId,
  EditJobForm,
  CreateJobForm,
  JobCard,
  JobTable,
}: JobManagerProps<T>) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const { jobType, flavor } = useJobTypeWithFlavor();
  const { data: jobs, isLoading: isJobsLoading } = useListScrapeJobsQuery({ jobType, flavor });
  const filteredJobs = useFilteredJobs();
  const [createOrUpdateJob, { reset: resetCreateOrUpdateJobMutation }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });
  const [deleteScrapeJobs, { status: deleteJobStatus, isError: deleteJobIsError }] = useDeleteScrapeJobsMutation();

  const selectedSource = useSelectedAgentOrSaasIntegration();
  const areJobsPresent = !!jobs?.length;

  const handleBackToJobList = useCallback(() => {
    localDispatch({ type: ActionType.ListJobs });
    resetCreateOrUpdateJobMutation();
  }, [resetCreateOrUpdateJobMutation]);

  useEffect(() => {
    return () => {
      resetCreateOrUpdateJobMutation();
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(selectedSource.installation)) {
      localDispatch({ type: ActionType.ShowInstallButton });
    }
    /* When the integration becomes installed, we still need to show
     * the InstallSaasIntegration component, because it then shows a
     * success message. Therefore we hide it only when we navigate away
     * from the JobForm. */
  }, [selectedSource.installation, saasIntegrationId]);

  useEffect(() => {
    if (!areJobsPresent && !isJobsLoading) {
      localDispatch({
        type: ActionType.CreateJob,
      });
    }
  }, [areJobsPresent, isJobsLoading, saasIntegrationId]);

  const [state, localDispatch] = useReducer(reducer, {
    page: JobManagementPage.JobList,
    selectedJob: undefined,
    shouldShowInstallButton: isEmpty(selectedSource.installation),
  });

  const onCreateJob = useCallback(
    (job: ScrapeJob) => {
      createOrUpdateJob({ job, jobType });
    },
    [createOrUpdateJob, jobType]
  );

  const onUpdateJob = useCallback(
    (job: ScrapeJob) => {
      createOrUpdateJob({ job, jobType });

      // update state with updated job
      localDispatch({ type: ActionType.EditJob, job });
    },
    [createOrUpdateJob, jobType]
  );

  const handleAddScrapeJobClick = () => {
    localDispatch({ type: ActionType.CreateJob });
  };

  const handleJobEdit = (job: ScrapeJob) => {
    localDispatch({ type: ActionType.EditJob, job });
  };

  const handleJobDelete = (job: ScrapeJob) => {
    localDispatch({ type: ActionType.DeleteJob, job });
  };

  const confirmDeleteJob = async () => {
    if (!state.selectedJob) {
      return;
    }
    await deleteScrapeJobs([state.selectedJob.name]);

    if (!deleteJobIsError) {
      dispatch(deselectJob(state.selectedJob.name));
    }

    localDispatch({ type: ActionType.ListJobs });
  };

  const renderMainContent = () => {
    if (state.page === JobManagementPage.EditJob) {
      return <EditJobForm onSaveJob={onUpdateJob} job={state.selectedJob as T} onCancel={handleBackToJobList} />;
    } else if (state.page === JobManagementPage.JobForm) {
      return (
        <>
          <CreateJobForm onSaveJob={onCreateJob} />
          {saasIntegrationId === 'confluent-cloud' && state.shouldShowInstallButton && (
            <InstallCard installCardStyles={styles.step} />
          )}
          {saasIntegrationId !== 'confluent-cloud' && state.shouldShowInstallButton && <InstallSaasIntegration />}
        </>
      );
    } else {
      if (isJobsLoading) {
        return <Spinner className={styles.spinner} />;
      } else {
        if (JobCard) {
          return (
            <JobList
              jobs={filteredJobs}
              handleJobDelete={handleJobDelete}
              handleJobEdit={handleJobEdit}
              handleAddScrapeJob={handleAddScrapeJobClick}
              JobCard={JobCard}
              saasIntegrationId={saasIntegrationId}
            />
          );
        } else if (JobTable) {
          return (
            <JobTableView
              jobs={filteredJobs}
              handleJobDelete={handleJobDelete}
              handleJobEdit={handleJobEdit}
              handleAddScrapeJob={handleAddScrapeJobClick}
              JobTable={JobTable}
              saasIntegrationId={saasIntegrationId}
            />
          );
        } else {
          return null;
        }
      }
    }
  };

  const showBackButton = (): boolean => {
    return (
      !!selectedSource.installation &&
      areJobsPresent &&
      (state.page === JobManagementPage.JobForm || state.page === JobManagementPage.EditJob)
    );
  };

  const backToListButton = showBackButton() && (
    <Button
      onClick={handleBackToJobList}
      variant="secondary"
      type="button"
      aria-label="Back to Job List"
      className={styles.backToJobsButton}
      data-testid={Pages.SaaSIntegration.backToJobList}
    >
      <Icon name={'arrow-left'} /> Back to Job List
    </Button>
  );

  return (
    <>
      <ConfirmModal
        isOpen={state.page === JobManagementPage.DeleteJob}
        title={`Delete job '${state.selectedJob?.name}'`}
        body="Are you sure you want to delete this job?"
        confirmText={deleteJobStatus === QueryStatus.pending ? 'Deleting...' : 'Delete'}
        onConfirm={confirmDeleteJob}
        onDismiss={() => localDispatch({ type: ActionType.ListJobs })}
      />
      <div>{renderMainContent()}</div>
      {backToListButton}
    </>
  );
};
