import { css } from '@emotion/css';

export const getStyles = () => {
  return {
    button: css`
      margin-right: 14px;
      margin-bottom: 16px;
    `,
    marginBottom: css`
      margin-bottom: 8px;
    `,
    p: css`
      margin-top: 10px;
    `,
  };
};
