import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { PLUGIN_PROXY_URL } from 'api/utils';
import { ScrapeJob, SuccessfulApiResult, ScrapeJobApiResponse } from 'models/api-models';

export const hostedExportersApi = createApi({
  reducerPath: 'hostedExportersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${PLUGIN_PROXY_URL}/he-api/`,
  }),
  tagTypes: ['jobs'],
  endpoints: (builder) => ({
    listScrapeJobs: builder.query<ScrapeJob[], { jobType: string; flavor?: string }>({
      query: ({ jobType, flavor }: { jobType: string; flavor?: string }) => ({
        url: 'jobs',
        params: {
          job_type: jobType,
          flavor,
        },
      }),
      transformResponse: (response: SuccessfulApiResult<ScrapeJobApiResponse[]>) => {
        const jobs = Object.entries(response.data).map(([name, job]) => ({
          ...job,
          name,
        }));
        return jobs;
      },
      providesTags: ['jobs'],
    }),
    createOrUpdateScrapeJob: builder.mutation({
      query: ({ job, jobType }: { job: ScrapeJob; jobType: string }) => ({
        url: `job/${job.name}`,
        method: 'PUT',
        body: JSON.stringify({
          job_type: jobType,
          job_data: {
            ...job,
          },
        }),
      }),
      invalidatesTags: ['jobs'],
    }),
    deleteScrapeJobs: builder.mutation({
      async queryFn(jobNames: string[], queryApi, extraOptions, baseQuery) {
        const results = await Promise.all(
          jobNames.map((jobName) => {
            return baseQuery({ url: `job/${jobName}`, method: 'DELETE' });
          })
        );
        return handleMultipleResponses(results);
      },
      invalidatesTags: ['jobs'],
    }),
    enableScrapeJobs: builder.mutation({
      async queryFn(jobNames: string[], queryApi, extraOptions, baseQuery) {
        const results = await Promise.all(
          jobNames.map((jobName) => {
            return baseQuery({ url: `job/${jobName}/enable`, method: 'POST' });
          })
        );
        return handleMultipleResponses(results);
      },
      invalidatesTags: ['jobs'],
    }),
    disableScrapeJobs: builder.mutation({
      async queryFn(jobNames: string[], queryApi, extraOptions, baseQuery) {
        const results = await Promise.all(
          jobNames.map((jobName) => {
            return baseQuery({ url: `job/${jobName}/disable`, method: 'POST' });
          })
        );
        return handleMultipleResponses(results);
      },
      invalidatesTags: ['jobs'],
    }),
  }),
});

function handleMultipleResponses(results: Array<QueryReturnValue<unknown, FetchBaseQueryError, {}>>) {
  const failedResult = results.find((result) => result.error);
  if (failedResult?.error) {
    return { error: failedResult.error as FetchBaseQueryError };
  } else {
    return { data: results.map((result) => result.data) };
  }
}

export const {
  useCreateOrUpdateScrapeJobMutation,
  useDeleteScrapeJobsMutation,
  useEnableScrapeJobsMutation,
  useDisableScrapeJobsMutation,
  useListScrapeJobsQuery,
} = hostedExportersApi;
