import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useAgentDetailsQuery } from 'api/integrations/integrationsRtqApi';
import { Markdown } from 'components/Markdown';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';

import { useCollectorMode } from '../useCollectorMode';
import { getAgentDetailsContent } from '../utils';

export const PreInstructions: FC = () => {
  const agentMode = useCollectorMode();
  const integrationId = useSelectedIntegrationId();
  const { data: agentDetails } = useAgentDetailsQuery({ integrationId, mode: agentMode });
  const setupMode = useSelector((state: RootState) => state.agent.configuredParameters.setupMode);

  const content = agentDetails ? getAgentDetailsContent(agentDetails, setupMode) : undefined;

  const preInstructions = content?.preInstructions;

  return <>{preInstructions && <Markdown markdownSnippet={preInstructions} />}</>;
};
