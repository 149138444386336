import { useEffect, useState } from 'react';

export const useModalHandling = () => {
  const [showModal, setShowModal] = useState(false);
  const [isIntegrationDeletionSuccessful, setIsIntegrationDeletionSuccessful] = useState(false);
  const [isIntegrationDeletionFailed, setIsIntegrationDeletionFailed] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (isIntegrationDeletionSuccessful || isIntegrationDeletionFailed) {
      timeout = setTimeout(() => {
        setIsIntegrationDeletionSuccessful(false);
        setIsIntegrationDeletionFailed(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isIntegrationDeletionSuccessful, isIntegrationDeletionFailed]);

  const handleIntegrationSuccess = () => {
    setIsIntegrationDeletionSuccessful(true);
  };

  const handleIntegrationError = () => {
    setIsIntegrationDeletionFailed(true);
  };

  return {
    showModal,
    setShowModal,
    isIntegrationDeletionSuccessful,
    handleIntegrationSuccess,
    isIntegrationDeletionFailed,
    handleIntegrationError,
  };
};
