import { useGenerateTokenMutation } from '@grafana-cloud/access-policies';
import { APIToken } from 'models/access-policies';
import { QueryError } from 'types';

import { useApiTokenCacheKey } from './useApiTokenCacheKey';

export function useApiToken(): APIToken {
  const fixedCacheKey = useApiTokenCacheKey();

  const [, { data: tokenData, error }] = useGenerateTokenMutation({
    fixedCacheKey,
  });

  if (error) {
    const message =
      (error as QueryError)?.data?.message || (error as Error)?.message || 'Error when generating API token';
    return {
      token: '',
      success: false,
      error: {
        data: {
          message,
        },
      },
    };
  } else if (tokenData) {
    return {
      token: tokenData.token,
      success: true,
      error: null,
    };
  }

  return {
    token: '',
    success: false,
    error: null,
  };
}
