import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Spinner, useStyles2 } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { useAgentDetailsQuery } from 'api/integrations/integrationsRtqApi';
import { AlertFromRtqError } from 'components/AlertFromRtqError';
import { InstallCard } from 'components/InstallCard';
import { Pages } from 'e2eSelectors/pages';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { CollectorMode } from 'utils/enums';

import { TestConnection } from '../AgentConnection/TestConnection';
import { useCollectorMode } from '../useCollectorMode';

import { ConfigurationExampleModal } from './ConfigurationExampleModal';
import { InstructionsAlloy } from './InstructionsAlloy';
import { InstructionsStatic } from './InstructionsStatic';
import { PreInstructions } from './PreInstructions';
import { RestartCollector } from './RestartCollector';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    button: css`
      margin-bottom: 8px;
    `,
    p: css`
      margin-top: 10px;
    `,
    step: css`
      background-color: ${theme.colors.background.secondary};
      padding: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
    `,
    topMargin: css`
      margin-top: ${theme.spacing(3)};
    `,
  };
};

export const ConfigureIntegrationSection = () => {
  const styles = useStyles2(getStyles);

  const agentMode = useCollectorMode();
  const source = useSelectedAgentOrSaasIntegration();
  const integrationId = useSelectedIntegrationId();
  const agentDetailsQuery = useAgentDetailsQuery({ integrationId, mode: agentMode });
  const { data: agentDetails, isError, isLoading } = agentDetailsQuery;

  const collector = agentMode === CollectorMode.AgentStatic ? 'the agent' : 'Grafana Alloy';

  return (
    <li>
      <h2>Prepare your configuration file</h2>
      {agentMode === CollectorMode.AgentStatic && (
        <p className={styles.p}>
          Add the following snippets to your Agent configuration file.
          <ConfigurationExampleModal />
        </p>
      )}

      <div
        className={cx(styles.step, { [styles.topMargin]: agentMode === CollectorMode.Alloy })}
        data-testid={Pages.Source.AgentConfig.preInstructions}
      >
        <h3>Check prerequisites specific to the {source.name} integration</h3>
        <PreInstructions />
      </div>

      {(agentDetails || isLoading) && (
        <div className={styles.step}>
          {isLoading ? (
            <>
              Loading...
              <Spinner />
            </>
          ) : (
            agentDetails &&
            (agentDetails.contentType === 'static' ? (
              <InstructionsStatic agentDetails={agentDetails} />
            ) : (
              <InstructionsAlloy agentDetails={agentDetails} />
            ))
          )}
        </div>
      )}
      {isError && <AlertFromRtqError {...agentDetailsQuery} />}

      <div className={styles.step}>
        <h3>Restart {collector} and test configurations</h3>
        <RestartCollector collectorMode={agentMode} />
        <h4>Test connection</h4>
        <p>Test that {collector} is collecting data and sending it to Grafana Cloud.</p>
        <TestConnection />
      </div>
      <InstallCard installCardStyles={styles.step} />
    </li>
  );
};
