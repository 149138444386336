import { useMemo } from 'react';

import { useCollectorSelector } from '@grafana-cloud/collector';
import {
  AgentDetailsAlloyAdvanced,
  AgentDetailsStatic,
  AgentDetailsAlloySimple,
  AgentDetailsAlloyBothModes,
} from 'api/integrations/types';
import { ALLOY_HOSTNAME_RELABEL_KEY, HOSTNAME_RELABEL_KEY } from 'utils/consts';
import { getHighlightLinesForSnippet, replaceAlloyHostnameInConfig, replaceHostnameInConfig } from 'utils/misc';

import { selectBlocksForPlatform } from '../utils';

const useSnippets = (
  configuredParameters: { isFullMetricsSelected?: boolean; hostName?: string },
  snippets: {
    integrationSnippet: string;
    integrationSnippetFiltered: string;
    logsSnippet: string;
    metricsSnippet: string;
    metricsSnippetFiltered: string;
  },
  isAlloy = false
) => {
  const shouldReplaceAlloyKey = isAlloy;
  return useMemo(() => {
    // When the toggle is not rendered, configuredParameters.isFullMetricsSelected is undefined.
    // Select full metrics in this case
    const isFullMetricsSelected =
      configuredParameters.isFullMetricsSelected === undefined ? true : configuredParameters.isFullMetricsSelected;

    let integrationSnippet = isFullMetricsSelected ? snippets.integrationSnippet : snippets.integrationSnippetFiltered;
    const integrationSnippetHighlightLines = getHighlightLinesForSnippet(
      integrationSnippet,
      HOSTNAME_RELABEL_KEY
    ).concat(shouldReplaceAlloyKey ? getHighlightLinesForSnippet(integrationSnippet, ALLOY_HOSTNAME_RELABEL_KEY) : []);
    if (integrationSnippet) {
      integrationSnippet = replaceHostnameInConfig(
        integrationSnippet,
        configuredParameters.hostName || HOSTNAME_RELABEL_KEY
      );
      if (shouldReplaceAlloyKey) {
        integrationSnippet = replaceAlloyHostnameInConfig(integrationSnippet, configuredParameters.hostName);
      }
    }

    let logsSnippet = snippets.logsSnippet;
    const logsSnippetHighlightLines = getHighlightLinesForSnippet(logsSnippet, HOSTNAME_RELABEL_KEY).concat(
      shouldReplaceAlloyKey ? getHighlightLinesForSnippet(logsSnippet, ALLOY_HOSTNAME_RELABEL_KEY) : []
    );
    if (logsSnippet) {
      logsSnippet = replaceHostnameInConfig(logsSnippet, configuredParameters.hostName || HOSTNAME_RELABEL_KEY);
      if (shouldReplaceAlloyKey) {
        logsSnippet = replaceAlloyHostnameInConfig(logsSnippet, configuredParameters.hostName);
      }
    }

    let metricsSnippet = isFullMetricsSelected ? snippets.metricsSnippet : snippets.metricsSnippetFiltered;
    const metricsSnippetHighlightLines = getHighlightLinesForSnippet(metricsSnippet, HOSTNAME_RELABEL_KEY).concat(
      shouldReplaceAlloyKey ? getHighlightLinesForSnippet(metricsSnippet, ALLOY_HOSTNAME_RELABEL_KEY) : []
    );
    if (metricsSnippet) {
      metricsSnippet = replaceHostnameInConfig(metricsSnippet, configuredParameters.hostName || HOSTNAME_RELABEL_KEY);
      if (shouldReplaceAlloyKey) {
        metricsSnippet = replaceAlloyHostnameInConfig(metricsSnippet, configuredParameters.hostName);
      }
    }

    const isHostnameRelabelKeyPresentInSnippets =
      snippets.integrationSnippet.includes(HOSTNAME_RELABEL_KEY) ||
      snippets.logsSnippet.includes(HOSTNAME_RELABEL_KEY) ||
      snippets.metricsSnippet.includes(HOSTNAME_RELABEL_KEY);
    const isAlloyHostnameRelabelKeyPresentInSnippets =
      snippets.integrationSnippet.includes(ALLOY_HOSTNAME_RELABEL_KEY) ||
      snippets.logsSnippet.includes(ALLOY_HOSTNAME_RELABEL_KEY) ||
      snippets.metricsSnippet.includes(ALLOY_HOSTNAME_RELABEL_KEY);
    const isAnyHostnameRelabelKeyPresentInSnippets =
      isHostnameRelabelKeyPresentInSnippets || (shouldReplaceAlloyKey && isAlloyHostnameRelabelKeyPresentInSnippets);

    return {
      integrationSnippet,
      logsSnippet,
      metricsSnippet,
      integrationSnippetHighlightLines,
      logsSnippetHighlightLines,
      metricsSnippetHighlightLines,
      isHostnameRelabelKeyPresentInSnippets: isAnyHostnameRelabelKeyPresentInSnippets,
    };
  }, [configuredParameters, snippets, shouldReplaceAlloyKey]);
};

export const useStaticSnippets = (
  configuredParameters: { isFullMetricsSelected?: boolean; hostName?: string },
  agentDetails: AgentDetailsStatic
) => {
  return useSnippets(configuredParameters, {
    integrationSnippet: agentDetails.static.integrationsSnippets,
    integrationSnippetFiltered: agentDetails.static.integrationsSnippetsFiltered,
    logsSnippet: agentDetails.static.logsSnippets,
    metricsSnippet: agentDetails.static.metricsSnippets,
    metricsSnippetFiltered: agentDetails.static.metricsSnippetsFiltered,
  });
};

function wrapInTripleTicks(snippet: string) {
  return snippet.length > 0 ? `\`\`\`\n${snippet}\`\`\`` : '';
}

export const useAdvancedAlloyBlocks = (
  configuredParameters: { isFullMetricsSelected?: boolean; hostName?: string },
  agentDetails: AgentDetailsAlloyAdvanced | AgentDetailsAlloyBothModes
) => {
  const osValue = useCollectorSelector((state) => state.collector.selectedOs.osValue);

  const result = useSnippets(
    configuredParameters,
    {
      integrationSnippet: selectBlocksForPlatform(osValue, agentDetails.advanced.integrations)?.blocks ?? '',
      integrationSnippetFiltered:
        selectBlocksForPlatform(osValue, agentDetails.advanced.integrations)?.blocks_filtered ?? '',
      logsSnippet: selectBlocksForPlatform(osValue, agentDetails.advanced.logs)?.blocks ?? '',
      metricsSnippet: selectBlocksForPlatform(osValue, agentDetails.advanced.metrics)?.blocks ?? '',
      metricsSnippetFiltered: selectBlocksForPlatform(osValue, agentDetails.advanced.metrics)?.blocks_filtered ?? '',
    },
    true
  );

  return {
    integrationBlock: wrapInTripleTicks(result.integrationSnippet),
    logsBlock: wrapInTripleTicks(result.logsSnippet),
    metricsBlock: wrapInTripleTicks(result.metricsSnippet),
    integrationBlockHighlightLines: result.integrationSnippetHighlightLines,
    logsBlockHighlightLines: result.logsSnippetHighlightLines,
    metricsBlockHighlightLines: result.metricsSnippetHighlightLines,
    isHostnameRelabelKeyPresentInBlocks: result.isHostnameRelabelKeyPresentInSnippets,
  };
};

export const useAlloyPlatformsInstructions = (agentDetails: AgentDetailsAlloyAdvanced | AgentDetailsAlloyBothModes) => {
  const osValue = useCollectorSelector((state) => state.collector.selectedOs.osValue);
  return {
    integrationInstructions: selectBlocksForPlatform(osValue, agentDetails.advanced.integrations)?.instructions ?? '',
    logsInstructions: selectBlocksForPlatform(osValue, agentDetails.advanced.logs)?.instructions ?? '',
    metricsInstructions: selectBlocksForPlatform(osValue, agentDetails.advanced.metrics)?.instructions ?? '',
  };
};

export const useSimpleAlloyBlock = (
  configuredParameters: { isFullMetricsSelected?: boolean; isLogsEnabled?: boolean },
  agentDetails: AgentDetailsAlloySimple | AgentDetailsAlloyBothModes
) => {
  const osValue = useCollectorSelector((state) => state.collector.selectedOs.osValue);
  const isLogsEnabled = configuredParameters.isLogsEnabled === undefined ? true : configuredParameters.isLogsEnabled;
  // When the toggle is not rendered, configuredParameters.isFullMetricsSelected is undefined.
  // Select full metrics in this case
  const isFullMetricsSelected =
    configuredParameters.isFullMetricsSelected === undefined ? true : configuredParameters.isFullMetricsSelected;

  const blocks = {
    integrationBlocks: selectBlocksForPlatform(osValue, agentDetails.simple.integrations)?.blocks ?? '',
    integrationBlocksFiltered:
      selectBlocksForPlatform(osValue, agentDetails.simple.integrations)?.blocks_filtered ?? '',
    logsBlocks: selectBlocksForPlatform(osValue, agentDetails.simple.logs)?.blocks ?? '',
    metricsBlocks: selectBlocksForPlatform(osValue, agentDetails.simple.metrics)?.blocks ?? '',
    metricsBlocksFiltered: selectBlocksForPlatform(osValue, agentDetails.simple.metrics)?.blocks_filtered ?? '',
  };

  function joinBlocks(blocks: string[]) {
    return wrapInTripleTicks(blocks.filter((b) => b !== '').join('\n'));
  }

  if (isFullMetricsSelected) {
    if (isLogsEnabled) {
      return joinBlocks([blocks.integrationBlocks, blocks.logsBlocks, blocks.metricsBlocks]);
    } else {
      return joinBlocks([blocks.integrationBlocks, blocks.metricsBlocks]);
    }
  } else {
    if (isLogsEnabled) {
      return joinBlocks([blocks.integrationBlocksFiltered, blocks.logsBlocks, blocks.metricsBlocksFiltered]);
    } else {
      return joinBlocks([blocks.integrationBlocksFiltered, blocks.metricsBlocksFiltered]);
    }
  }
};
