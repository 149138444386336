import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { InlineField, Input, useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateCollectorAPI } from 'components/GenerateCollectorAPI';
import { RestartCollector } from 'features/agent-integrations/ConfigureIntegration/RestartCollector';
import { InstallAlloySection } from 'features/agent-integrations/InstallCollector/InstallAlloySection';
import { useCollectorToken } from 'hooks/useCollectorToken';
import { CollectorMode, PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

import {
  AdditionalOptions,
  DEFAULT_COLLECTOR_PORT,
  DEFAULT_COLLECTOR_URL,
  getAlloyCompleteConfig,
  getPrometheusCompleteConfig,
  getSuccessMessage,
} from './helper';

const getStyles = (theme: GrafanaTheme2) => ({
  text: css({
    color: theme.colors.text.disabled,
  }),
});

type Props = { value: PrometheusOptions };

export const RemoteWriteViaAlloy = ({ value }: Props) => {
  const styles = useStyles2(getStyles);
  const [collectorUrl, setCollectorUrl] = useState<string>(DEFAULT_COLLECTOR_URL);
  const [collectorPort, setCollectorPort] = useState<string>(DEFAULT_COLLECTOR_PORT);
  const token = useCollectorToken();
  const { hmInstancePromId, hmInstancePromUrl } = useHostedDataDetailsWithFallback();
  const configTitle = getSuccessMessage(false, PrometheusRemoteWrite.ViaAlloy);

  return (
    <>
      <InstallAlloySection showOSSelection={true} />
      <li>
        <h2>Update Prometheus configuration</h2>
        <p>Change default Grafana Alloy url and/or port if required:</p>
        <InlineField label={'Alloy url:'} labelWidth={15}>
          <Input
            type="text"
            aria-label="Alloy url"
            id="hostname"
            name="hostname"
            placeholder="grafana alloy url"
            onChange={(e) => setCollectorUrl(e.currentTarget.value)}
            value={collectorUrl}
          />
        </InlineField>
        <InlineField label={'Alloy port:'} labelWidth={15}>
          <Input
            type="number"
            aria-label="Alloy port"
            id="port"
            name="port"
            placeholder="grafana alloy port"
            onChange={(e) => setCollectorPort(e.currentTarget.value)}
            value={collectorPort}
          />
        </InlineField>
        <p style={{ paddingTop: '8px' }}>
          Copy and paste this snippet in your <span className={styles.text}>prometheus.yml</span> file to forward
          metrics to the collector.
        </p>
        <Clipboard
          code={getPrometheusCompleteConfig(
            hmInstancePromUrl,
            collectorUrl,
            collectorPort,
            value,
            hmInstancePromId.toString(),
            token,
            PrometheusRemoteWrite.ViaAlloy
          )}
          multipleLines={true}
          expandHeight={true}
          clipboardButtonType="below"
        />
        {token && <AdditionalOptions value={value} />}
      </li>
      <li>
        <h2>Update Alloy configuration</h2>
        <GenerateCollectorAPI
          shouldShowClipboard={true}
          config={getAlloyCompleteConfig(hmInstancePromUrl, '', hmInstancePromId.toString(), token)}
          instanceId={hmInstancePromId}
          configTitle={configTitle}
        />
      </li>
      <li>
        <RestartCollector collectorMode={CollectorMode.Alloy} />
      </li>
    </>
  );
};
