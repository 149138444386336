import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { CHECK_INTERVAL_MS, CHECK_TIMEOUT_MS } from 'utils/consts';

import { installAgentCheck, testAgentConnectionRequest } from '../state/actions';
import { AgentStatus, setAgentStatus } from '../state/slice';

type Props = {
  isDisabled?: boolean;
};

const ConnectionTestButton: FC<Props> = ({ isDisabled }) => {
  const styles = useStyles2(getStyles);
  const { agentStatus } = useSelector((state: RootState) => state.agent);
  const { orgSlug } = useHostedDataDetailsWithFallback();
  const dispatch = useDispatch();
  const integrationId = useSelectedIntegrationId();

  const [checkStartedAt, setCheckStarted] = useState(0);

  const pendingStatus = agentStatus === AgentStatus.Pending || agentStatus === AgentStatus.NoData || isDisabled;

  const testAgentConnection = () => {
    dispatch(installAgentCheck({ orgSlug }));
    dispatch(testAgentConnectionRequest(integrationId));
    setCheckStarted(new Date().getTime());
  };

  useEffect(() => {
    if (agentStatus === AgentStatus.NoData && checkStartedAt) {
      if (new Date().getTime() - checkStartedAt > CHECK_TIMEOUT_MS) {
        dispatch(setAgentStatus({ agentStatus: AgentStatus.Error, integrationId }));
      } else {
        const timeout = setTimeout(() => dispatch(testAgentConnectionRequest(integrationId)), CHECK_INTERVAL_MS);
        return () => clearTimeout(timeout);
      }
    }

    return () => {};
  }, [agentStatus, dispatch, checkStartedAt, integrationId]);

  return (
    <Button size="md" onClick={testAgentConnection} disabled={pendingStatus}>
      {pendingStatus && !isDisabled && <Icon className={styles.spinnerIcon} name="fa fa-spinner" />}
      Test agent connection
    </Button>
  );
};

export default ConnectionTestButton;

const getStyles = () => ({
  spinnerIcon: css({
    marginRight: 5,
  }),
});
