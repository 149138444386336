import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const DEFAULT_BILLING_URL = '/dashboards?layout=folders';
type DashboardsState = {
  billingUrl: string | undefined;
};

const initialState: DashboardsState = {
  billingUrl: undefined,
};

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setBillingUrl: (state, action: PayloadAction<any>) => {
      state.billingUrl = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-unused-modules
export const { setBillingUrl } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
