import React from 'react';

import { useSelectedSource } from 'api/integrations/hooks';
import {
  AgentIntegrationInstructions,
  AlloyOnlyContent,
} from 'features/agent-integrations/AgentIntegrationInstructions';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { SourceType } from 'utils/enums';

import { instructions, instructionsToWrap, LocalInstructions } from './Local/LocalInstructions';
import { MetricsEndpointInstructions } from './Local/SaasIntegrationWithScrapeJobs/MetricsEndpointInstructions';
import { ALLOY_ONLY_INTEGRATION_IDS } from './alloyOnlyIntegrations';

function hasLocalInstructions(integrationId: string) {
  return integrationId in instructions || integrationId in instructionsToWrap;
}

function isAlloyOnlyIntegration(integrationId: string) {
  return ALLOY_ONLY_INTEGRATION_IDS.includes(integrationId);
}

export const ConfigurationDetails: React.FC = () => {
  const selectedSource = useSelectedSource();
  const integrationId = useSelectedIntegrationId();

  return hasLocalInstructions(integrationId) ? (
    <LocalInstructions integrationId={integrationId} />
  ) : isAlloyOnlyIntegration(integrationId) ? (
    <AlloyOnlyContent />
  ) : selectedSource.type === SourceType.MetricsEndpointIntegration ? (
    <MetricsEndpointInstructions />
  ) : (
    <AgentIntegrationInstructions />
  );
};
