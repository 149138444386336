import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useCollectorSelector } from '@grafana-cloud/collector';
import { useQueryParam } from 'hooks/useQueryParam';
import { RootState } from 'state';
import { Status } from 'utils/enums';

import { AgentModeSelection, Pages } from './AgentModeSelection';
import { ConfigureIntegrationSection } from './ConfigureIntegration/ConfigureIntegrationSection';
import { InstallAgentSection } from './InstallCollector/InstallAgentSection';
import { K8sInstallationInstructions } from './K8s/K8sInstallationInstructions';
import { MakeConfigurationSelectionsSection } from './MakeConfigurationSelections/MakeConfigurationSelectionsSection';
import { PlatformSelectionSection } from './PlatformSelection/PlatformSelectionSection';
import { ViewYourDashboardsSection } from './ViewYourDashboards/ViewYourDashboardsSection';

const getStyles = (theme: GrafanaTheme2) => ({
  ol: css`
    > li {
      list-style-position: inside;
      margin-bottom: 70px;
      h2 {
        display: inline;
      }

      ::marker {
        font-weight: ${theme.typography.h2.fontWeight};
        font-size: ${theme.typography.h2.fontSize};
        line-height: 26px;
      }
    }
  `,
  step: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
});

export function AgentIntegrationInstructionsContent(): React.JSX.Element {
  const styles = useStyles2(getStyles);
  const { sourceDetails } = useSelector((state: RootState) => state.source);
  const areDashboardsAvailable = sourceDetails.dashboards?.status === Status.Available;
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);

  return (
    <ol className={styles.ol}>
      <PlatformSelectionSection />
      {selectedOs.osValue === 'k8s' ? (
        <K8sInstallationInstructions />
      ) : (
        <>
          <InstallAgentSection showOSSelection={false} />
          <MakeConfigurationSelectionsSection />
          <ConfigureIntegrationSection />
        </>
      )}
      {areDashboardsAvailable && <ViewYourDashboardsSection />}
    </ol>
  );
}

export function AlloyOnlyContent(): React.JSX.Element {
  const [_, setPageQueryParam] = useQueryParam('page');

  useEffect(() => {
    setPageQueryParam(Pages.Alloy, true);
  }, [setPageQueryParam]);

  return <AgentIntegrationInstructionsContent />;
}

export function AgentIntegrationInstructions(): React.JSX.Element {
  return (
    <AgentModeSelection>
      <AgentIntegrationInstructionsContent />
    </AgentModeSelection>
  );
}
