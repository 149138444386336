import { config } from '@grafana/runtime';

enum FARO_ENV {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

function getFaroEnv() {
  const appUrl = new URL(config.appUrl).hostname;
  switch (true) {
    case appUrl.endsWith('grafana-ops.net'):
      return FARO_ENV.STAGING;
    case appUrl.endsWith('grafana.net'):
      return FARO_ENV.PROD;
    case appUrl.endsWith('grafana-dev.net'):
    case appUrl.endsWith('localhost'):
    default:
      return FARO_ENV.DEV;
  }
}

// The three apps were created at https://appo11y.grafana.net/a/grafana-kowalski-app/apps
// and the collector URLs were generated there.
export function getFaroConfig() {
  const environment = getFaroEnv();
  switch (environment) {
    case FARO_ENV.DEV:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/4012b670526f53646c8a0d7a97108b22',
        name: 'easystart-app-dev',
        environment,
      };
    case FARO_ENV.STAGING:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/b724e7effb14cd1c3ecf23136a409933',
        name: 'easystart-app-staging',
        environment,
      };
    case FARO_ENV.PROD:
    default:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/a5240e942aa987680833d037e975ab2c',
        name: 'easystart-app-prod',
        environment,
      };
  }
}
