import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, LinkButton, useStyles2 } from '@grafana/ui';

import { AgentIntegrationInstructionsContent } from 'features/agent-integrations/AgentIntegrationInstructions';
import { AgentModeSelection, Pages } from 'features/agent-integrations/AgentModeSelection';
import { useCollectorMode } from 'features/agent-integrations/useCollectorMode';
import { useQueryParam } from 'hooks/useQueryParam';
import { CollectorMode } from 'utils/enums';
import { isCollectorAppEnabled } from 'utils/featureToggles';

const getStyles = (theme: GrafanaTheme2) => ({
  rightButton: css({
    marginLeft: theme.spacing(1),
  }),
});

function AgentHealthContent() {
  const styles = useStyles2(getStyles);
  const agentMode = useCollectorMode();
  const [_, setPageQueryParam] = useQueryParam('page');
  if (agentMode === CollectorMode.AgentStatic) {
    return <AgentIntegrationInstructionsContent />;
  } else {
    if (isCollectorAppEnabled()) {
      return (
        <>
          <p>
            This integration can only be configured using Grafana Agent Static. In order to monitor the health of your
            Alloy instances, please navigate to the Alloy Monitoring page.
          </p>
          <Button variant="primary" fill="outline" onClick={() => setPageQueryParam(Pages.Static)}>
            Switch to Grafana Agent Static instructions
          </Button>
          <LinkButton variant="primary" href="/a/grafana-collector-app/alloy/monitoring" className={styles.rightButton}>
            Go to Alloy Monitoring page
          </LinkButton>
        </>
      );
    } else {
      return (
        <>
          <p>
            This integration can only be configured using Grafana Agent Static. In order to monitor the health of your
            Alloy instances, please update Grafana to at least version 10.1.0 to enable the Grafana Alloy Monitoring
            page.
          </p>
          <Button variant="primary" fill="outline" onClick={() => setPageQueryParam(Pages.Static)}>
            Switch to Grafana Agent Static instructions
          </Button>
        </>
      );
    }
  }
}

export function AgentHealth() {
  return (
    <AgentModeSelection>
      <AgentHealthContent />
    </AgentModeSelection>
  );
}
