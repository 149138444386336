import React, { FC } from 'react';

import { Badge } from '@grafana/ui';

import { DisabledReasonTooltip } from 'components/SaasIntegration/DisabledReasonTooltip';
import { DisabledReasonType } from 'utils/consts';

type JobStatusProps = {
  enabled: boolean;
  disabledReason?: DisabledReasonType;
};

export const JobStatus: FC<JobStatusProps> = ({ enabled, disabledReason }) => {
  return (
    <DisabledReasonTooltip reason={disabledReason}>
      {enabled ? (
        <Badge text="Enabled" color="green" icon="check" />
      ) : (
        <Badge text="Disabled" color="red" icon="times-circle" />
      )}
    </DisabledReasonTooltip>
  );
};
