import React from 'react';

import { Icon, useStyles2 } from '@grafana/ui';

import { HostedDataIcon, OnboardingLogo } from 'img/label';
import { SourceType } from 'utils/enums';

import { Source } from '../../types/Source';

import { getStyles } from './Source.styles';

interface PageInfoItem {
  label: string;
  value: React.ReactNode;
}

interface SourceLabelsProps {
  selectedSource: Source;
}

const LabelComponent: React.FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.icon}>
      {icon}
      {text}
    </div>
  );
};

export const sourceLabels = ({ selectedSource }: SourceLabelsProps): PageInfoItem[] => {
  return [
    {
      label: 'Type',
      value: (
        <LabelComponent
          icon={
            <img
              src={selectedSource.type === SourceType.HostedData ? HostedDataIcon : OnboardingLogo}
              height={15}
              alt="Icon"
            />
          }
          text={selectedSource.type === SourceType.HostedData ? 'Custom data' : 'Integration'}
        />
      ),
    },
    {
      label: 'Author',
      value: <LabelComponent icon={<Icon name="grafana" title="Grafana Icon" />} text="Grafana Labs" />,
    },
    {
      label: 'Integration version',
      value: (
        <LabelComponent
          icon={selectedSource.installation?.version ? <Icon name="file-alt" /> : '-'}
          text={selectedSource.installation?.version || '-'}
        />
      ),
    },
    {
      label: 'Status',
      value: (
        <LabelComponent
          icon={<Icon name="check" />}
          text={selectedSource.installation ? 'Installed' : 'Not installed'}
        />
      ),
    },
  ];
};
