import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Input, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { InstallCard } from 'components/InstallCard';
import { useApiToken } from 'hooks/useApiToken';
import { DEFAULT_PROM_URL, GRAFANA_EXAMPLE_API } from 'utils/consts';
import { getHighlightLinesForSnippet } from 'utils/misc';

import {
  nodeChatCompletion,
  nodeCodeCompletion,
  pythonChatCompletion,
  pythonCodeCompletion,
  pipCommand,
  nodeCommand,
} from './snippets';

const getStyles = (theme: GrafanaTheme2) => ({
  apiGroup: css`
    width: 700px;
  `,
  container: css`
    ol li {
      margin-bottom: ${theme.spacing(2)};
      margin-left: ${theme.spacing(3)};
    }

    li {
      font-size: ${theme.typography.pxToRem(16)};
    }
  `,
  linkColor: css`
    color: ${theme.colors.text.link};
  `,
  lastItem: css`
    h3 {
      font-size: ${theme.typography.pxToRem(16)};
    }
  `,
  radioButtonGroup: css`
    margin-bottom: ${theme.spacing(1)};
  `,
  completions: css`
    margin-top: ${theme.spacing(2)};
  `,
  completionsParagraph: css`
    margin-top: ${theme.spacing(4)};
  `,
});

enum MonitorType {
  ChatCompletions = 'chatCompletions',
  Completions = 'completions',
}

enum CodeSnippet {
  Python = 'python',
  NodeJS = 'nodejs',
}

export const OpenAIInstructions: FC = () => {
  const styles = useStyles2(getStyles);
  const { hmInstancePromId, hmInstancePromUrl, hlInstanceId, hlInstanceUrl } = useHostedDataDetailsWithFallback();
  const lokiUrl = `${hlInstanceUrl}/loki/api/v1/push`;
  const promUrl = hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom` : DEFAULT_PROM_URL;
  const { token } = useApiToken();
  const [openAiKey, setOpenAiKey] = useState('');
  const [completionType, setCompletionType] = useState('chatCompletions');
  const [selectedLanguage, setSelectedLanguage] = useState<CodeSnippet>(CodeSnippet.Python);
  const [snippetState, setSnippetState] = useState<{ snippet: string; linesToHighlight: number[] } | undefined>(
    undefined
  );
  const [shouldHighlightLines, setShouldHighlightLines] = useState(false);

  const isCompletionTypeSelected = completionType === 'chatCompletions';
  const command = selectedLanguage === CodeSnippet.Python ? pipCommand : nodeCommand;

  const handleLanguageTypeChange = (value: CodeSnippet) => {
    setSelectedLanguage(value);
  };

  const successMessage = 'Your API key has been generated below!';

  useEffect(() => {
    let snippet: string;
    if (isCompletionTypeSelected) {
      if (selectedLanguage === CodeSnippet.Python) {
        snippet = pythonCodeCompletion(openAiKey, promUrl, lokiUrl, hmInstancePromId, hlInstanceId, token);
      } else {
        snippet = nodeCodeCompletion(openAiKey, promUrl, lokiUrl, hmInstancePromId, hlInstanceId, token);
      }
    } else {
      if (selectedLanguage === CodeSnippet.Python) {
        snippet = pythonChatCompletion(openAiKey, promUrl, lokiUrl, hmInstancePromId, hlInstanceId, token);
      } else {
        snippet = nodeChatCompletion(openAiKey, promUrl, lokiUrl, hmInstancePromId, hlInstanceId, token);
      }
    }
    setSnippetState({
      snippet: snippet,
      linesToHighlight:
        selectedLanguage === CodeSnippet.Python
          ? (getHighlightLinesForSnippet(snippet, 'api_key') as number[])
          : (getHighlightLinesForSnippet(snippet, 'apiKey') as number[]),
    });
  }, [isCompletionTypeSelected, selectedLanguage, openAiKey, promUrl, lokiUrl, hmInstancePromId, hlInstanceId, token]);

  return (
    <div>
      <div className={styles.container}>
        <ol>
          <li>
            <p>Select your projects language</p>
            <RadioButtonGroup
              options={Object.values(CodeSnippet).map((value) => ({ label: value, value }))}
              value={selectedLanguage}
              onChange={(value) => handleLanguageTypeChange(value as CodeSnippet)}
              className={styles.radioButtonGroup}
            />
          </li>
          <li>
            <p>
              Install The Dependency: &nbsp;
              {selectedLanguage === CodeSnippet.Python ? (
                <a
                  className={styles.linkColor}
                  href="https://pypi.org/project/grafana-openai-monitoring/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Grafana OpenAI Monitoring on pypi
                </a>
              ) : (
                <a
                  className={styles.linkColor}
                  href="https://www.npmjs.com/package/grafana-openai-monitoring"
                  target="_blank"
                  rel="noreferrer"
                >
                  Grafana OpenAI Monitoring on npm
                </a>
              )}
            </p>
            <Clipboard code={command} clipboardButtonType="below" />
            <p></p>
          </li>
          <li>
            <div className={styles.apiGroup}>
              <p>Generate Grafana Cloud Token</p>
              <GenerateAPI
                shouldShowClipboard={true}
                shouldShowInstruction={false}
                config={GRAFANA_EXAMPLE_API}
                instanceId={hmInstancePromId}
                successMessage={successMessage}
              />
            </div>
          </li>
          <li>
            <p>
              Create an OpenAI API Key&nbsp;
              <a
                className={styles.linkColor}
                href="https://platform.openai.com/account/api-keys"
                target="_blank"
                rel="noreferrer"
              >
                here&nbsp;
              </a>
              and paste the key below
            </p>
            <Input
              onChange={(e) => setOpenAiKey(e.currentTarget.value)}
              onFocus={() => setShouldHighlightLines(true)}
              onBlur={() => setShouldHighlightLines(false)}
              width={88}
              placeholder="your-openai-key"
            />
          </li>
          <li>
            <p className={styles.completionsParagraph}>
              Select Monitor For {isCompletionTypeSelected ? 'Chat Completions' : 'Completions'}
            </p>
            <RadioButtonGroup
              options={Object.values(MonitorType).map((value) => ({ label: value, value }))}
              value={completionType}
              onChange={(v) => setCompletionType(v)}
              className={styles.radioButtonGroup}
            />
            <div className={styles.completions}>
              <Clipboard
                code={snippetState ? snippetState.snippet : ''}
                multipleLines={true}
                expandHeight={true}
                highlightLines={shouldHighlightLines ? snippetState?.linesToHighlight : []}
                clipboardButtonType="below"
              />
            </div>
          </li>
          <li className={styles.lastItem}>
            <InstallCard />
          </li>
        </ol>
      </div>
    </div>
  );
};
