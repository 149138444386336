function encodeToBase64(input: string): string {
  return btoa(input);
}

export const openLitConfiguration = (
  endpoint: string | undefined,
  instanceId: string | undefined,
  token: string | undefined
): string => {
  return `export OTEL_EXPORTER_OTLP_ENDPOINT = "${!endpoint ? 'https://otlp-gateway-[url].grafana.net/otlp' : endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS = "Authorization=Basic%20${!token ? '<base64 encoded grafana cloud api key>' : encodeToBase64(instanceId + ":" + token)}"`;
};
