import { HTTPMetricsLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const LOGS_HTTP_ID = 'logs-http';

export const logsHttp: Source = {
  id: LOGS_HTTP_ID,
  name: 'Logs HTTP',
  logo_url: HTTPMetricsLogo,
  overview:
    'Ship logs directly from your application code over HTTP for storage in your Grafana Cloud account using Graphite and Prometheus. Featuring the ability to generate an api key and a wide selection of predefined code snippets to plug in and push up.',
  type: SourceType.HostedData,
};
