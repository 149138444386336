import { IconName } from '@grafana/ui';

import { CloudAppsLight, CloudAppsDark } from 'img/catalog';

import { Category } from '../types/Category';
import { FilterIntegration } from '../types/FilterIntegration';

export const INTEGRATION_STATUS_FILTER_OPTIONS = [
  { label: 'All', value: FilterIntegration.All },
  { label: 'Installed', value: FilterIntegration.Installed },
  { label: 'New Updates', value: FilterIntegration.NewUpdates },
];

export const INTEGRATION_STATUS_FILTER_OPTIONS_ICONS = [
  { label: 'All', value: FilterIntegration.All },
  { icon: 'check', value: FilterIntegration.Installed, ariaLabel: 'installed' },
  { icon: 'sync', value: FilterIntegration.NewUpdates, ariaLabel: 'new updates' },
];

export const categoryFilters = (isDark: boolean) => {
  return [
    {
      value: Category.Integration,
      label: 'Integration',
      description: 'Prebuilt dashboards and alerts',
      icon: 'bolt' as IconName,
    },
    {
      value: Category.HostedData,
      label: 'Custom data',
      description: 'Forward your custom metrics, logs, traces and profiles.',
      icon: 'cloud-upload' as IconName,
    },
    {
      value: Category.CloudApp,
      label: 'Cloud app',
      description: 'Grafana Cloud observability solutions',
      imgUrl: isDark ? CloudAppsLight : CloudAppsDark,
    },
    {
      value: Category.DataSource,
      label: 'Data source',
      description: 'Create visualizations on external data',
      icon: 'database' as IconName,
    },
  ];
};

export enum SortIntegration {
  MostPopular = 'featured',
  Alphabetical = 'alphabetical',
  ReversedAlphabetical = 'reversed-alphabetical',
  Updates = 'updates',
}

export const INTEGRATION_SORT_FILTER_OPTIONS = [
  {
    value: SortIntegration.Alphabetical,
    label: 'Sort by A-Z',
  },
  {
    value: SortIntegration.ReversedAlphabetical,
    label: 'Sort by Z-A',
  },
  {
    value: SortIntegration.MostPopular,
    label: 'Sort by most popular',
  },
];
