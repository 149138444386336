import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import _ from 'lodash';

import { grafanaComApi } from 'api/grafanaCom/grafanaComApi';
import {
  AgentStatus,
  copyToClipboard,
  setAgentStatus,
  toggleLogs,
  toggleAlerts,
  toggleMetricsSet,
  IntegrationConnectionStatus,
  setIntegrationConnectionStatus,
  clickOpenAgentConfigModal,
  clickOpenExtendedMetricsModal,
  setTracesConnectionStatus,
  clickAlertsAndRecordingButtons,
  clickDashboardsButton,
} from 'features/agent-integrations/state/slice';
import { apiTokenGenerateSuccess } from 'features/app/state/slice';
import {
  initCategoryFilter,
  resetFilters,
  seeUpdates,
  setFilterIntegrations,
  setSearch,
  setSearchCat,
  setSortFilter,
} from 'features/catalog/state/slice';
import {
  clickAddNewInfraIntegration,
  clickIntegrationCard,
  clickIntegrationCardAlerts,
  clickIntegrationCardDashboards,
  initIntegrations,
  selectSort,
  typeIntegrationSearch,
} from 'features/integrations-page/state/slice';
import { RootState } from 'state';
import { HOSTED_DATA_DETAILS_FALLBACK } from 'utils/consts';
import { EventSource, RudderStackEvents } from 'utils/enums';

import {
  InstallationStatus,
  changeTab,
  clickOpenUninstallModal,
  clickViewDashboards,
  openSourcePage,
  setIntegrationInstallationStatus,
  setIntegrationUpdateStatus,
} from './source/slice';

const genericTrackRudderStackEvent = (event: string, params: object, orgId: number, slug: string) => {
  (window as any).rudderanalytics?.track(event, {
    ...params,
    org_id: orgId,
    instance_slug: slug,
  });
};

const debouncedSearchInputEvent = _.debounce(
  (searchTerm, orgId, slug) => genericTrackRudderStackEvent(RudderStackEvents.SearchInput, { searchTerm }, orgId, slug),
  1500
);

const debouncedInfraSearchInputEvent = _.debounce(
  (searchTerm, orgId, slug) =>
    genericTrackRudderStackEvent(RudderStackEvents.InfraSearchInput, { searchTerm }, orgId, slug),
  1500
);

export const userTracking: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const state: RootState = api.getState();
  const hostedDataDetails =
    grafanaComApi.endpoints.getHostedDataDetails.select()(state).data ?? HOSTED_DATA_DETAILS_FALLBACK;
  const { slug, orgId } = hostedDataDetails;

  const trackRudderStackEvent = (event: string, params: object) =>
    genericTrackRudderStackEvent(event, params, orgId, slug);

  switch (action.type) {
    case initCategoryFilter.type:
      trackRudderStackEvent(RudderStackEvents.FilterSetFromUrl, { category: action.payload });
      break;
    case setAgentStatus.type:
      if (action.payload.agentStatus === AgentStatus.Pending) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionClick, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.agentStatus === AgentStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.agentStatus === AgentStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionFailed, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case clickOpenUninstallModal.type:
      trackRudderStackEvent(RudderStackEvents.UninstallButton, {
        integration_slug: action.payload,
      });
      break;
    case clickViewDashboards.type:
      trackRudderStackEvent(RudderStackEvents.ViewDashboards, {
        integration_slug: action.payload,
      });
      break;
    case clickDashboardsButton.type:
      trackRudderStackEvent(RudderStackEvents.DashboardsButtonClick, { integration_slug: action.payload });
      break;
    case openSourcePage.type:
      trackRudderStackEvent(RudderStackEvents.ViewSource, { integration_slug: action.payload });
      break;
    case clickAlertsAndRecordingButtons.type:
      trackRudderStackEvent(RudderStackEvents.AlertsAndRecordingButtonClick, { integration_slug: action.payload });
      break;
    case clickOpenAgentConfigModal.type:
      trackRudderStackEvent(RudderStackEvents.OpenAgentConfigModalButton, { integration_slug: action.payload });
      break;
    case apiTokenGenerateSuccess.type:
      trackRudderStackEvent(RudderStackEvents.CreateApiKeySucceeded, { integration_slug: action.payload });
      break;
    case changeTab.type:
      trackRudderStackEvent(RudderStackEvents.TabChanged, {
        integration_slug: action.payload.integrationId,
        tab: action.payload.currentTab,
      });
      break;
    case clickOpenExtendedMetricsModal.type:
      trackRudderStackEvent(RudderStackEvents.OpenExtendedMetricsModalButton, { integration_slug: action.payload });
      break;
    case setSearch.type:
      if (action.payload) {
        if (action.payload.source === EventSource.URL) {
          if (action.payload.value) {
            trackRudderStackEvent(RudderStackEvents.SearchSetFromUrl, { searchTerm: action.payload.value });
          }
        } else {
          debouncedSearchInputEvent(action.payload.value, orgId, slug);
        }
      }
      break;
    case setSearchCat.type:
      if (action.payload) {
        if (action.payload.source === EventSource.URL) {
          if (action.payload.value) {
            trackRudderStackEvent(RudderStackEvents.SearchCategorySetFromUrl, { category: action.payload.value });
          }
        } else {
          trackRudderStackEvent(RudderStackEvents.SearchCategorySelected, { category: action.payload.value });
        }
      }
      break;
    case setFilterIntegrations.type:
      trackRudderStackEvent(
        action.payload.source === EventSource.URL
          ? RudderStackEvents.StateFilterSetFromUrl
          : RudderStackEvents.StateFilterClicked,
        { filter: action.payload.value }
      );
      break;
    case setSortFilter.type:
      trackRudderStackEvent(
        action.payload.source === EventSource.URL ? RudderStackEvents.SortSetFromUrl : RudderStackEvents.SortClicked,
        { filter: action.payload.value }
      );
      break;
    case setIntegrationUpdateStatus.type:
      if (action.payload.status === InstallationStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.UpdateFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === InstallationStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.UpdateSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case setIntegrationInstallationStatus.type:
      if (action.payload.status === InstallationStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.InstallFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === InstallationStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.InstallSuccess, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case setIntegrationConnectionStatus.type:
      if (action.payload.status === IntegrationConnectionStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === IntegrationConnectionStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === IntegrationConnectionStatus.Pending) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionClick, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case toggleMetricsSet.type:
      trackRudderStackEvent(RudderStackEvents.MetricsSetToggled, {
        integration_slug: action.payload,
        // the state change is not reflected yet
        full_metrics_selected: !state.agent.configuredParameters.isFullMetricsSelected,
      });
      break;
    case copyToClipboard.type:
      trackRudderStackEvent(RudderStackEvents.CopiedSnippetToClipboard, {
        integration_slug: action.payload,
        full_metrics_selected: state.agent.configuredParameters.isFullMetricsSelected,
      });
      break;
    case resetFilters.type:
      trackRudderStackEvent(RudderStackEvents.FilterReset, {});
      break;
    case seeUpdates.type:
      trackRudderStackEvent(RudderStackEvents.SeeUpdates, {});
      break;

    /* Integrations page */
    case initIntegrations.type:
      trackRudderStackEvent(RudderStackEvents.InfraPageView, {});
      break;
    case clickAddNewInfraIntegration.type:
      trackRudderStackEvent(RudderStackEvents.AddNewInfraIntegrationClick, {});
      break;
    case typeIntegrationSearch.type:
      debouncedInfraSearchInputEvent(action.payload, orgId, slug);
      break;
    case selectSort.type:
      trackRudderStackEvent(RudderStackEvents.InfraSortSelected, { ascending: action.payload });
      break;
    case clickIntegrationCard.type:
      trackRudderStackEvent(RudderStackEvents.InfraCardClick, { integration_slug: action.payload });
      break;
    case clickIntegrationCardDashboards.type:
      trackRudderStackEvent(RudderStackEvents.InfraCardDashboardsClick, { integration_slug: action.payload });
      break;
    case clickIntegrationCardAlerts.type:
      trackRudderStackEvent(RudderStackEvents.InfraCardAlertsClick, { integration_slug: action.payload });
      break;
    case toggleLogs.type:
      trackRudderStackEvent(RudderStackEvents.LogsToggled, { integration_slug: action.payload });
      break;
    case toggleAlerts.type:
      trackRudderStackEvent(RudderStackEvents.AlertsToggled, { integration_slug: action.payload });
      break;
    case setTracesConnectionStatus.type:
      const { status, serviceName, serviceNamespace } = action.payload;
      if (status === IntegrationConnectionStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.TestTracesConnectionFailed, { serviceName, serviceNamespace });
      } else if (status === IntegrationConnectionStatus.NoData) {
        trackRudderStackEvent(RudderStackEvents.TestTracesConnectionNoData, { serviceName, serviceNamespace });
      } else if (status === IntegrationConnectionStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.TestTracesConnectionSucceeded, { serviceName, serviceNamespace });
      } else if (status === IntegrationConnectionStatus.Pending) {
        trackRudderStackEvent(RudderStackEvents.TestTracesConnectionClick, { serviceName, serviceNamespace });
      }
      break;
  }

  return next(action);
};

if (process.env.NODE_ENV === 'development') {
  (window as any).rudderanalytics = { track: console.log };
}
