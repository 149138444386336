/* istanbul ignore file */
import React, { useEffect } from 'react';

import { AppRootProps } from '@grafana/data';
import { FaroErrorBoundary } from '@grafana/faro-react';
import { locationService } from '@grafana/runtime';
import { ErrorWithStack } from '@grafana/ui';

import { Providers } from './Providers';
import { Routes } from './Routes';
import { JsonData } from './state/slice';

const Fallback: React.FC<{ error: Error; resetError: () => void }> = ({ error, resetError }) => {
  useEffect(() => {
    const unregister = locationService.getHistory().listen(() => resetError());
    return unregister;
  }, [resetError]);

  return (
    <ErrorWithStack title={error.name} error={error} errorInfo={error.stack ? { componentStack: error.stack } : null} />
  );
};

export const App = (props: AppRootProps<JsonData>) => {
  return (
    <FaroErrorBoundary fallback={(error, resetError) => <Fallback error={error} resetError={resetError} />}>
      <Providers appRootProps={props}>
        <Routes />
      </Providers>
    </FaroErrorBoundary>
  );
};
