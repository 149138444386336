import React, { FC } from 'react';
import { useMeasure } from 'react-use';

import { Select, RadioButtonGroup, Icon, useStyles2, useTheme2 } from '@grafana/ui';

import {
  initCategoryFilter,
  removeCategoryFilter,
  resetFilterIntegrations,
  resetSort,
  setCategoryFilter,
  setFilterIntegrations,
  setSortFilter,
} from 'features/catalog/state/slice';
import { Category } from 'features/catalog/types/Category';
import { useDispatch } from 'hooks/useDispatch';
import { useQueryParam } from 'hooks/useQueryParam';
import useRudderStack from 'hooks/useRudderstack';
import { EventSource, RudderStackEvents } from 'utils/enums';

import { FilterIntegration } from '../types/FilterIntegration';

import { CatalogFilterPill } from './CatalogFilterPill';
import { getFilterStyles } from './Filters.styles';
import {
  INTEGRATION_SORT_FILTER_OPTIONS,
  INTEGRATION_STATUS_FILTER_OPTIONS,
  INTEGRATION_STATUS_FILTER_OPTIONS_ICONS,
  categoryFilters,
  SortIntegration,
} from './filterTypes';

interface ExtensibleFiltersProps {
  isExtended: boolean;
}

const LeftSideFilters: FC<ExtensibleFiltersProps> = ({ isExtended }) => {
  const theme = useTheme2();
  const styles = useStyles2(getFilterStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const filters = categoryFilters(theme.isDark);
  const dispatch = useDispatch();

  const [category, setCategoryQueryParam] = useQueryParam('cat', (category, fromURL) => {
    if (category) {
      if (fromURL) {
        dispatch(initCategoryFilter(category as Category));
      } else {
        dispatch(setCategoryFilter({ category: category as Category }));
      }
    } else if (category === undefined) {
      dispatch(removeCategoryFilter());
    }
  });

  const handleClick = (newCategory?: Category) => {
    setCategoryQueryParam(newCategory === category ? undefined : newCategory);
  };

  return (
    <div className={styles.leftContent}>
      <CatalogFilterPill
        key="view-all"
        label="View all"
        selected={category == null || category === ''}
        onClick={() => {
          handleClick(undefined);
          trackRudderStackEvent(RudderStackEvents.FilterClick, { category: 'view-all' });
        }}
        ariaLabel="View all"
      />
      {filters.map((categoryFilter) => (
        <CatalogFilterPill
          key={categoryFilter.value}
          label={isExtended ? categoryFilter.label : undefined}
          selected={category === categoryFilter.value}
          onClick={() => {
            handleClick(categoryFilter.value);
            trackRudderStackEvent(RudderStackEvents.FilterClick, { category: categoryFilter.value });
          }}
          icon={categoryFilter.icon}
          imgUrl={categoryFilter.imgUrl}
          ariaLabel={categoryFilter.label}
        />
      ))}
    </div>
  );
};

const RightSideFilters: FC<{ isExtended: boolean }> = ({ isExtended }) => {
  const styles = useStyles2(getFilterStyles);
  const dispatch = useDispatch();
  const filterOptions = isExtended ? INTEGRATION_STATUS_FILTER_OPTIONS : INTEGRATION_STATUS_FILTER_OPTIONS_ICONS;

  const [filterIntegrations, setFilterQueryParam] = useQueryParam('type', (value, fromURL) => {
    if (value) {
      dispatch(
        setFilterIntegrations({
          value: value as FilterIntegration,
          source: fromURL ? EventSource.URL : EventSource.Click,
        })
      );
    } else {
      dispatch(resetFilterIntegrations());
    }
  });

  const [sort, setSortQueryParam] = useQueryParam('sort', (value, fromURL) => {
    if (value) {
      dispatch(
        setSortFilter({
          value: value as SortIntegration,
          source: fromURL ? EventSource.URL : EventSource.Click,
        })
      );
    } else {
      dispatch(resetSort());
    }
  });

  return (
    <div className={styles.rightContent}>
      <RadioButtonGroup<FilterIntegration>
        options={filterOptions}
        value={filterIntegrations as FilterIntegration}
        onChange={(v) => setFilterQueryParam(v as FilterIntegration)}
        fullWidth
        className={isExtended ? styles.statusFilter : styles.narrowStatusFilter}
      />
      <Select
        prefix={<Icon name="sort-amount-down" />}
        options={INTEGRATION_SORT_FILTER_OPTIONS}
        value={sort}
        className={styles.selectSort}
        onChange={(selected) => {
          setSortQueryParam(selected.value);
        }}
      />
    </div>
  );
};

export const Filters = () => {
  const styles = useStyles2(getFilterStyles);

  const [wrapperRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>();
  const [leftFiltersRef, { width: leftFiltersWidth }] = useMeasure<HTMLDivElement>();
  const [rightFiltersRef, { width: rightFiltersWidth }] = useMeasure<HTMLDivElement>();

  // Adding +10 to prevent flickering when downsizing the window
  const isExtended = wrapperWidth >= Math.ceil(leftFiltersWidth + rightFiltersWidth) + 10;

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <LeftSideFilters isExtended={isExtended} />
        <RightSideFilters isExtended={isExtended} />
      </div>
      {/* rendering extended filters in hidden containers to measure their widths */}
      <div className={styles.hiddenContainer} ref={leftFiltersRef}>
        <LeftSideFilters isExtended={true} />
      </div>
      <div className={styles.hiddenContainer} ref={rightFiltersRef}>
        <RightSideFilters isExtended={true} />
      </div>
    </>
  );
};
