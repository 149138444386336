import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    unselected: css`
      background: ${theme.colors.background.primary};
      border: 1px solid ${theme.colors.border.strong};
      border-radius: 24px;
      padding: 10px 20px;
      margin-right: 16px;
      height: 40px;

      font-weight: ${theme.typography.fontWeightRegular};
      font-size: ${theme.typography.body.fontSize};
      line-height: 20px;
      color: ${theme.colors.text.primary};
      white-space: nowrap;

      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: ${theme.colors.action.hover};
      }
    `,

    // coloredBackground provides the color of the selected pill's border
    coloredBackground: css`
      background: ${theme.colors.gradients.brandHorizontal};
      border-radius: 24px;
      padding: 1px;
      margin-right: 16px;
      height: 40px;
    `,

    // greyBackground is needed because selected's hover background color
    // is quite transparent, and we don't want to show the coloredBackground
    greyBackground: css`
      background: ${theme.colors.background.secondary};
      border-radius: 24px;
    `,
    selected: css`
      background: ${theme.colors.background.secondary};
      border-radius: 24px;
      padding: 10px 20px;
      height: 38px;

      font-weight: ${theme.typography.fontWeightBold};
      font-size: ${theme.typography.body.fontSize};
      line-height: 20px;
      color: ${theme.colors.text.maxContrast};
      white-space: nowrap;

      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: ${theme.colors.action.focus};
      }
    `,

    icon: css`
      margin-right: 10px;
    `,

    checkMark: css`
      margin-left: ${theme.spacing(0.5)};
    `,
  };
};
