import { HTTPMetricsLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const HTTP_METRICS_ID = 'http-metrics';

export const httpMetrics: Source = {
  id: HTTP_METRICS_ID,
  name: 'HTTP Metrics',
  logo_url: HTTPMetricsLogo,
  overview:
    'Ship metrics directly from your application code over HTTP for storage in your Grafana Cloud account using Graphite and Prometheus. Featuring the ability to generate an api key and a wide selection of predefined code snippets to plug in and push up.',
  type: SourceType.HostedData,
  categories: ['Time Series'],
};
