import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

import { K8S_MANUAL_CONFIG_LINK } from '../consts';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.body.fontSize,
  }),
});

export const K8sDescription = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.container}>
      <p>
        These instructions show how to deploy the Kubernetes Monitoring Helm chart using Grafana Alloy to your
        Kubernetes cluster. For alternative configuration options, refer to the{' '}
        <TextLink href={K8S_MANUAL_CONFIG_LINK} external inline>
          documentation
        </TextLink>
        .
      </p>
    </div>
  );
};
