import { config } from '@grafana/runtime';

import { AwsDarkIcon, AwsLightIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

import { CSP_APP_ID } from './cspApp';

export const AWS_APP_ID = 'aws';

export const awsApp: Source = {
  id: AWS_APP_ID,
  name: 'AWS',
  logo_url: config.theme2.isLight ? AwsLightIcon : AwsDarkIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: `/a/${CSP_APP_ID}/aws`,
  search_keywords: ['amazon', 'aws', 'monitoring', 'metrics', 's3', 'ec2', 'cloudfront', 'lambda', 'ebs', 'rds'],
  categories: ['Cloud Provider', 'Logs', 'Servers and VMs'],
};
