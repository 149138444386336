import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getInstallStyles = (theme: GrafanaTheme2) => ({
  spinner: css({
    marginRight: theme.spacing(1),
  }),
  alert: css({
    marginTop: '16px',
  }),
  marginBottom: css({
    marginBottom: '16px',
  }),
});
