import { RootState } from 'state';

export const isAlertsToggleDirty = (state: RootState) => {
  const sourceDetails = state.source.sourceDetails;
  const isAlertsEnabled = state.agent.configuredParameters.isAlertsEnabled;

  const isAlertsToggleDisplayed = sourceDetails.configuration_defaults?.enable_alerts_toggle ?? false;

  if (!isAlertsToggleDisplayed) {
    return false;
  }

  const savedOrDefaultValue = sourceDetails.installation?.configuration?.configurable_alerts
    ? !sourceDetails.installation.configuration.configurable_alerts.alerts_disabled
    : sourceDetails.configuration_defaults?.configurable_alerts_defaults
      ? !sourceDetails.configuration_defaults.configurable_alerts_defaults.alerts_disabled
      : false;

  return Boolean(isAlertsEnabled) !== savedOrDefaultValue;
};
