import { css, cx } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button, Icon, Alert } from '@grafana/ui';

import { SelectGroup, ThreeLevelCard } from 'components/SelectGroup';
import { TextLink } from 'components/TextLink';
import { Pages as e2eSelectors } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { AgentLogo, AlloyAndAgentLogo, AlloyLogo } from 'img/agent';
import { RudderStackEvents } from 'utils/enums';

import { ALLOY_SYNTAX_LINK } from '../consts';

import { AgentModeCompare } from './AgentModeCompare';
import { Pages } from './Pages';
import { useSaveOptionAndNavigate } from './useSaveOptionAndNavigate';

enum AgentStatus {
  Installed = 'installed',
  NotInstalled = 'notInstalled',
}

const agentStatusOptions = [
  {
    value: AgentStatus.Installed,
    label: 'I am already running the Grafana Agent in the environment I would like to monitor.',
  },
  {
    value: AgentStatus.NotInstalled,
    label: 'I do not yet have Grafana Alloy or the Grafana Agent running in the environment I would like to monitor.',
    description: 'Choose this option if you are new to Grafana Cloud integrations.',
  },
];

const getInstalledOptions = (integrationName: string) => [
  {
    value: Pages.Convert,
    icon: AlloyLogo,
    label: 'Convert to Alloy now',
    subtitle: `Convert your entire existing static configuration file to use Grafana Alloy, before adding the ${integrationName} integration.`,
    description: 'This option will result in you having a single configuration file using Grafana Alloy.',
  },
  {
    value: Pages.Alloy,
    icon: AlloyAndAgentLogo,
    label: 'Deploy Grafana Alloy in parallel to Grafana Agent Static',
    subtitle: `Create a new configuration file to add the ${integrationName} integration using Grafana Alloy.`,
    description:
      'This option will result in you running your current Grafana Agent Static instance alongside a Grafana Alloy instance.',
  },
  {
    value: Pages.Static,
    icon: AgentLogo,
    label: 'Convert to Alloy later',
    subtitle: `Add the ${integrationName} integration to your existing Static agent configuration file.`,
  },
];

export const AgentModeSelectionPage = ({ integrationName }: { integrationName: string }) => {
  const styles = useStyles2(getStyles);
  const [agentStatus, setAgentStatus] = useState<AgentStatus>(AgentStatus.Installed);
  const [mode, setMode] = useState<Exclude<Pages, Pages.Selection>>(Pages.Convert);

  const saveOptionAndNavigate = useSaveOptionAndNavigate();
  const installedOptions = getInstalledOptions(integrationName);
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <div className={styles.ct} data-testid={e2eSelectors.Source.AgentModeSelection.agentModeSelectionPage}>
      <div>
        <Alert
          title="The scheduled end-of-life date for Grafana Agent is November 1, 2025, in favor of Grafana Alloy."
          severity="warning"
        />
        <p>
          Grafana Alloy is a component-based telemetry collector. It focuses on ease of use, adaptability to your needs,
          and easy debugging. You configure Grafana Alloy with{' '}
          <TextLink href={ALLOY_SYNTAX_LINK} external inline>
            Alloy Configuration Syntax
          </TextLink>
          , an HCL-inspired expression language that uses small reusable blocks that work together to form programmable
          pipelines.
        </p>
        <AgentModeCompare />
      </div>

      <h2 className={styles.heading}>Tell us about your use case</h2>
      <SelectGroup
        className={styles.select}
        options={agentStatusOptions}
        value={agentStatus}
        onChange={(value) => setAgentStatus(value)}
        CardElementOverride={ThreeLevelCard}
      />
      {agentStatus === AgentStatus.Installed ? (
        <div className={styles.nextSteps}>
          <p>Choose one of the following three paths to configure your {integrationName} integration:</p>
          <SelectGroup
            options={installedOptions}
            value={mode}
            onChange={(value) => setMode(value)}
            CardElementOverride={ThreeLevelCard}
          />
          <Button
            className={styles.nextButton}
            onClick={() => {
              saveOptionAndNavigate(mode);
              trackRudderStackEvent(RudderStackEvents.AgentModeSelectionProceed, { installed: true, mode });
            }}
          >
            Proceed
            <Icon name="arrow-right" size="md" className={styles.icon} />
          </Button>
        </div>
      ) : (
        <>
          <h3 className={cx(styles.heading, styles.noBottomMargin)}>
            We recommend that you configure your integration using Grafana Alloy.
          </h3>
          <div>
            <Button
              className={styles.nextButton}
              onClick={() => {
                saveOptionAndNavigate(Pages.Alloy);
                trackRudderStackEvent(RudderStackEvents.AgentModeSelectionProceed, {
                  installed: false,
                  mode: Pages.Alloy,
                });
              }}
            >
              Configure using Grafana Alloy
              <Icon name="arrow-right" size="md" className={styles.icon} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  ct: css({
    maxWidth: '1200px',
  }),
  heading: css({
    marginTop: theme.spacing(5),
  }),
  noBottomMargin: css({
    marginBottom: 0,
  }),
  select: css({
    marginTop: theme.spacing(2),
  }),
  nextSteps: css({
    marginTop: theme.spacing(4),
  }),
  nextButton: css({
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
  }),
  icon: css({
    marginLeft: theme.spacing(1),
  }),
});
