import { useQueryParam } from 'hooks/useQueryParam';
import { CollectorMode } from 'utils/enums';

import { Pages as AgentModeSelectionPages } from './AgentModeSelection';

export function useCollectorMode(): CollectorMode {
  const [pageQueryParam, _] = useQueryParam('page');
  const mode = pageQueryParam === AgentModeSelectionPages.Alloy ? CollectorMode.Alloy : CollectorMode.AgentStatic;
  return mode;
}
