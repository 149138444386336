import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { Install } from 'components/Install';
import { TextLink } from 'components/TextLink';

import { ciliumCommand } from './CiliumCommand';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css`
    margin-left: 24px;

    li::marker {
      font-size: ${theme.typography.h3.fontSize};
    }

    li {
      li {
        margin-left: 2em;
      }
      li::marker {
        font-size: unset;
      }

      a {
        color: ${theme.colors.text.link};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
  topMargin: css`
    margin-top: 24px;
  `,
});

const PreInstructions: FC = () => {
  return (
    <li>
      <h3>Configure your cluster</h3>
      <p>
        <strong>
          Please note, this integration is intended exclusively for use with Cilium Enterprise, and does not support the
          Cilium OSS product.
        </strong>
      </p>
      <p>
        This integration monitors a Cilium deployment that has metrics exporters enabled. Please ensure you have
        completed the following setup steps:
        <ul>
          <li>
            Enabled the{' '}
            <TextLink href="https://docs.cilium.io/en/stable/operations/metrics/#installation" external inline>
              embedded Prometheus exporter in your Cilium deployment
            </TextLink>{' '}
            to collect and expose metrics
          </li>
          <li>
            Enabled the{' '}
            <TextLink href="https://docs.cilium.io/en/stable/operations/metrics/#id1" external inline>
              embedded Prometheus exporter in Hubble
            </TextLink>{' '}
            if you want Hubble metrics to be included.
          </li>
        </ul>
      </p>
      <p>
        Once the exporters have been enabled the metrics are automatically exposed and available to be scraped by either
        Prometheus or a Grafana Agent deployed to the cluster.
      </p>
      <Clipboard
        title="The following sample using Helm enables the Prometheus metrics endpoint and configures the relevant metrics for Cilium Agent, Cilium Operator, and Hubble:"
        code={`helm install cilium cilium/cilium --version 1.12.2 \\
      --namespace kube-system \\
      --set prometheus.enabled=true \\
      --set operator.prometheus.enabled=true`}
        multipleLines={true}
        clipboardButtonType="below"
      />

      <Clipboard
        title="Sample Hubble metrics setup using helm, specifying which metrics to enable capture and export for:"
        code={`helm install cilium cilium/cilium --version 1.12.2 \\
      --namespace kube-system \\
      --set hubble.metrics.enabled="{(dns, drop, tcp, flow, icmp, http)}"`}
        multipleLines={true}
        clipboardButtonType="below"
      />
    </li>
  );
};

const PostInstructions: FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <li className={styles.topMargin}>
        <h3>Check prerequisites for configuring Grafana Agent</h3>
        <p>
          Before you begin, you should have the following available:
          <ul>
            <li>
              A Kubernetes cluster with{' '}
              <TextLink href="https://kubernetes.io/docs/reference/access-authn-authz/rbac/" external inline>
                role-based access control
              </TextLink>{' '}
              (RBAC) enabled.
            </li>
            <li>
              The <code>kubectl</code> command-line tool installed on your local machine, configured to connect to your
              cluster. To learn more about <code>kubectl</code>, see the{' '}
              <TextLink href="https://kubernetes.io/docs/tasks/tools/" external inline>
                Kubernetes documentation
              </TextLink>
              .
            </li>
          </ul>
        </p>
      </li>
      <li>
        <h3>Configure Grafana Agent</h3>
        <p>
          The Cilium Enterprise integration uses the Grafana Agent, deployed into your cluster, to scrape metrics from
          your Cilium and Hubble deployment.
        </p>
        <p>
          The agent must be configured to scrape your Cilium and Hubble deployment correctly. To do so, please be sure
          to replace the capitalized variables beginning with <code>YOUR_*</code> with the appropriate cluster name and
          Grafana Cloud credentials.
        </p>
        <p>
          <strong>
            Please note: If configuring multiple clusters to report metrics, ensure unique cluster names are used!
          </strong>
        </p>
        <Clipboard
          title="Paste the following script into your shell and run it to configure the Grafana Agent:"
          code={ciliumCommand}
          multipleLines={true}
          expandHeight={true}
          clipboardButtonType="below"
        />
        <p>
          If you deployed the Agent into a non-default Namespace in the previous step, replace{' '}
          <code>NAMESPACE=default</code> in this command with the new Namespace.
        </p>
        <p>
          This ConfigMap confiures the Agent to scape the <code>Cilium Agent</code>, <code>Cilium Operator</code>,{' '}
          <code>Hubble</code>, and <code>Hubble Timesscape</code> resources in your cluster and ship these scraped
          metrics to Grafana Cloud.
        </p>
        <p>
          This ConfigMap configures the Agent to scrape the <code>cadvisor</code> and <code>kubelet</code> endpoints in
          your cluster and ship these scraped metrics to Grafana Cloud.
        </p>
        <p>
          To learn more about configuring the Agent, please see{' '}
          <TextLink
            href="https://grafana.com/docs/agent/latest/static/configuration/#configure-grafana-agent"
            external
            inline
          >
            Configure Grafana Agent
          </TextLink>{' '}
          from the Agent docs.
        </p>
      </li>
      <li>
        <h3>Deploy Grafana Agent resources</h3>
        <p>In this step you&apos;ll install the Grafana Agent and its required resources into your cluster.</p>
        <p>
          Run the following command from your shell to install the Grafana Agent into the <code>default</code> Namespace
          of your Kubernetes cluster:
        </p>
        <Clipboard
          code={`MANIFEST_URL=https://raw.githubusercontent.com/grafana/agent/v0.24.2/production/kubernetes/agent-bare.yaml NAMESPACE=default /bin/sh -c "$(curl -fsSL https://raw.githubusercontent.com/grafana/agent/v0.24.2/production/kubernetes/install-bare.sh)" | kubectl apply -f -`}
          multipleLines={true}
          clipboardButtonType="below"
        />
        <p>
          This installs a single replica Grafana Agent StatefulSet into your cluster and configures RBAC permissions for
          the Agent. If you would like to deploy the Agent into a different Namespace, change the{' '}
          <code>NAMESPACE=default</code>
          variable, ensuring that this Namespace already exists.
        </p>
      </li>
    </>
  );
};

export const CiliumInstructions: FC = () => {
  const styles = useStyles2(getStyles);

  const source = useSelectedAgentOrSaasIntegration();
  return (
    <>
      <Alert
        title="The Cilium Enterprise integration can only run on a K8s environment. Please follow the instructions below to set up a helm chart to install this integration."
        severity="info"
      />
      <ol className={styles.instructions}>
        <PreInstructions />
        <Install />
        {source.installation && <PostInstructions />}
      </ol>
    </>
  );
};
