import { SuccessfulApiResult } from 'models/api-models';

import {
  APIAgentDetails,
  APIAgentDetailsAlloy,
  APIAgentDetailsAlloyAdvanced,
  APIAgentDetailsAlloySimple,
  AgentDetails,
} from './types';
import { transformAlloyPlatformWithBlocks } from './utils';

function transformSimple(simpleAgentDetails: APIAgentDetailsAlloy & { simple: APIAgentDetailsAlloySimple }) {
  return {
    generalGuidance: simpleAgentDetails.simple.general_guidance,
    integrations: transformAlloyPlatformWithBlocks(simpleAgentDetails.simple.integrations),
    logs: transformAlloyPlatformWithBlocks(simpleAgentDetails.simple.logs),
    metrics: transformAlloyPlatformWithBlocks(simpleAgentDetails.simple.metrics),
    preInstructions: simpleAgentDetails.pre_instructions,
  };
}

function transformAdvanced(advancedAgentDetails: APIAgentDetailsAlloy & { advanced: APIAgentDetailsAlloyAdvanced }) {
  return {
    generalGuidance: advancedAgentDetails.advanced.general_guidance,
    integrations: transformAlloyPlatformWithBlocks(advancedAgentDetails.advanced.integrations),
    logs: transformAlloyPlatformWithBlocks(advancedAgentDetails.advanced.logs),
    metrics: transformAlloyPlatformWithBlocks(advancedAgentDetails.advanced.metrics),
    preInstructions: advancedAgentDetails.pre_instructions,
  };
}

export function transformAgentDetails(response: SuccessfulApiResult<APIAgentDetails>): AgentDetails {
  const agentDetails = response.data;
  if ('static' in agentDetails) {
    return {
      contentType: 'static',
      static: {
        defaultType: agentDetails.static.default_type,
        enableHostnameRelabelAndFilter: agentDetails.static.enable_hostname_relabel_and_filter,
        agentExampleConfiguration: agentDetails.static.agent_example_configuration,
        agentExampleConfigurationFiltered: agentDetails.static.agent_example_configuration_filtered,
        agentExampleConfigurationFilteredLogsDisabled:
          agentDetails.static.agent_example_configuration_filtered_logs_disabled,
        agentExampleConfigurationLogsDisabled: agentDetails.static.agent_example_configuration_logs_disabled,
        integrationsSnippets: agentDetails.static.integrations_snippets,
        integrationsSnippetsFiltered: agentDetails.static.integrations_snippets_filtered,
        logsSnippets: agentDetails.static.logs_snippets,
        metricsSnippets: agentDetails.static.metrics_snippets,
        metricsSnippetsFiltered: agentDetails.static.metrics_snippets_filtered,
        postInstructions: agentDetails.static.post_instructions,
        preInstructions: agentDetails.static.pre_instructions,
      },
    };
  } else if ('simple' in agentDetails.alloy && 'advanced' in agentDetails.alloy) {
    return {
      contentType: 'alloy-both-modes',
      simple: transformSimple(agentDetails.alloy),
      advanced: transformAdvanced(agentDetails.alloy),
    };
  } else if ('simple' in agentDetails.alloy) {
    return {
      contentType: 'alloy-simple',
      simple: transformSimple(agentDetails.alloy),
    };
  } else {
    return {
      contentType: 'alloy-advanced',
      advanced: transformAdvanced(agentDetails.alloy),
    };
  }
}
