import { AzureIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

import { CSP_APP_ID } from './cspApp';

const AZURE_ID = 'azure';

export const azure: Source = {
  id: AZURE_ID,
  name: 'Azure',
  logo_url: AzureIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: `/a/${CSP_APP_ID}/${AZURE_ID}`,
  search_keywords: ['logs', 'event', 'microsoft', 'ad', 'aks', 'cosmos', 'windows'],
  categories: ['Cloud Provider', 'Logs', 'Servers and VMs'],
};
