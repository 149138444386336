import React, { FC } from 'react';

import { JobManager } from '../JobManager/JobManager';

import { CreateJob } from './CreateJob';
import { EditJob } from './EditJob';
import { JobCard } from './JobCard';

export const ConfluentInstructions: FC = () => {
  return (
    <JobManager saasIntegrationId="confluent-cloud" EditJobForm={EditJob} CreateJobForm={CreateJob} JobCard={JobCard} />
  );
};
