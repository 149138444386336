import React, { Fragment, useEffect, useMemo } from 'react';

import { PluginPage } from '@grafana/runtime';
import { Alert, useStyles2 } from '@grafana/ui';

import { useGetOrgInfoQuery } from 'api/orgInfo/orgInfoApi';
import { convertPlan, getContract, getOrgPlan } from 'api/orgInfo/orgInfoUtils';
import { AlertFromRtqError } from 'components/AlertFromRtqError';
import { NoResults } from 'components/NoResults';
import { Spinner } from 'components/Spinner';
import { getStyles } from 'features/catalog/Catalog.styles';
import { useQueryParam } from 'hooks/useQueryParam';
import useRudderStack from 'hooks/useRudderstack';
import { useWrappedChromeHeaderHeight } from 'hooks/useWrappedChromeHeaderHeight';
import { PRO_PLAN_NAME } from 'utils/consts';
import { RudderStackEvents } from 'utils/enums';

import { CardContent } from './CardContent';
import { CategoryHeader } from './CategoryHeader';
import { DatasourceRoadmapLinks } from './DatasourceRoadmap/DatasourceRoadmapLinks';
import { Filters } from './Filters';
import { ResetFilters } from './ResetFilters/ResetFilters';
import { SemanticSearch } from './Search';
import { UpdateNotification } from './UpdateNotification';
import { useCatalog } from './hooks';
import { Category } from './types/Category';
import { FilterIntegration } from './types/FilterIntegration';

export const Catalog: React.FC = () => {
  const chromeHeaderHeight = useWrappedChromeHeaderHeight();
  const styles = useStyles2(getStyles, chromeHeaderHeight);
  const { trackRudderStackEvent } = useRudderStack();

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.PageView, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search] = useQueryParam('search');
  const [searchCategory] = useQueryParam('searchCat');
  const [categoryFilter] = useQueryParam('cat') || [null];

  const { data: orgInfo } = useGetOrgInfoQuery();

  const [filterIntegrations] = useQueryParam('type') || [FilterIntegration.All];
  const { filteredIntegrations, updatableIntegrations, relatedQueries } = useCatalog();

  const isPro = useMemo(() => {
    const plan = convertPlan(getOrgPlan(orgInfo), getContract(orgInfo));
    return plan === PRO_PLAN_NAME;
  }, [orgInfo]);

  const isNoResultsFound = Array.from(filteredIntegrations.values()).flat().length < 1;

  return (
    <PluginPage
      actions={
        updatableIntegrations.length > 0 ? (
          <div className={styles.alertWrapper}>
            <Alert title="" severity="info" className={styles.alert}>
              <UpdateNotification />
            </Alert>
          </div>
        ) : undefined
      }
    >
      <div>
        <div className={styles.sticky}>
          <SemanticSearch />
          <Filters />
        </div>
        {[...filteredIntegrations].map(([category, integrations]) =>
          relatedQueries.get(category)?.every((query) => query.isSuccess) && integrations.length === 0 ? (
            // Results are filtered out
            <Fragment key={category} />
          ) : (
            <Fragment key={category}>
              {category !== Category.MostPopular && <CategoryHeader category={category} />}
              {relatedQueries.get(category)?.some((query) => query.isLoading || query.isUninitialized) && (
                <Spinner message="Loading connections..." data-testId={`spinner-${category}`} />
              )}
              {relatedQueries
                .get(category)
                ?.map((query, index) => query.isError && <AlertFromRtqError key={`${category}${index}`} {...query} />)}
              <ul className={styles.sourcesList}>
                {integrations.map((integration) => {
                  return (
                    <CardContent
                      key={integration.id}
                      item={integration}
                      shouldShowSimplifiedCategory={category === Category.MostPopular}
                      category={category}
                      isPro={isPro}
                    />
                  );
                })}
              </ul>
              {category === Category.DataSource && <DatasourceRoadmapLinks />}
            </Fragment>
          )
        )}
        {isNoResultsFound && <NoResults />}
        {((searchCategory && searchCategory.length > 0) || (search && search.length > 0)) &&
          (categoryFilter !== null || filterIntegrations !== FilterIntegration.All) && <ResetFilters />}
        {isNoResultsFound && <DatasourceRoadmapLinks />}
      </div>
      <p className={styles.versionInfo}>Connections plugin version: {PACKAGE_VERSION}</p>
    </PluginPage>
  );
};
