import { cx, css } from '@emotion/css';
import { Location } from 'history';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Badge, Button, Icon, Modal, Tooltip, useStyles2 } from '@grafana/ui';

import { ModalTitle } from 'components/ModalTitle';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { Source } from 'types/Source';
import { handleKeyDown } from 'utils/aria';
import { JAVA_ID } from 'utils/consts';
import { RudderStackEvents, SourcePlan } from 'utils/enums';

import { Category } from '../types/Category';
import { isCardWithModal, isPermissionMissing } from '../utils';
import { sourceTypeToCategoryMap, categoryToLabelMap } from '../utils/mappings';

import { ModalContentMapping } from './AlphaIntegrations/ModalContentMapping';
import { PermissionMissingModalContent } from './PermissionMissingModalContent';
import { StatusTooltipContent } from './StatusTooltipContent/StatusTooltipContent';

const getCardStyles = (theme: GrafanaTheme2) => ({
  card: css`
    height: 100px;
    transition: all 0.3s ease-out;

    padding: 16px;
    position: relative;
    cursor: pointer;
    color: ${theme.colors.text};

    h4 {
      font-size: 16px;
      line-height: 20px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: ${theme.colors.action.hover};
    }
    background: ${theme.colors.background.secondary};
  `,

  titleWrapper: css`
    display: flex;
    align-items: center;
  `,

  header: css`
    color: ${theme.colors.text.maxContrast};
    margin-bottom: 0;
    line-height: 22px;
  `,

  logoWrapper: css`
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    img {
      max-width: 100%;
      max-height: 100%;
      align-self: center;
    }
  `,

  modal: css`
    width: 531px;
  `,
  badgeStyle: css`
    margin-left: 12px;
  `,
  button: css`
    justify-content: center;
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
    padding: 0;
    background-color: ${theme.colors.background.primary};
  `,
  smallBoldText: css`
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.colors.text.secondary};
  `,
});

const Content = ({
  item,
  shouldShowSimplifiedCategory,
  isPro,
}: {
  item: Source;
  shouldShowSimplifiedCategory: boolean;
  isPro: boolean;
}) => {
  const styles = useStyles2((theme) => getCardStyles(theme));
  const isCatalogCard = item.id === JAVA_ID;

  const isItemUpdatable = Boolean(item.has_update);
  const isItemInstalled = Boolean(item.installation);

  const logo_url = config.theme2.isLight && item.light_logo_url ? item.light_logo_url : item.logo_url;
  return (
    <>
      <div className={styles.titleWrapper}>
        {logo_url && (
          <div className={cx(styles.logoWrapper)}>
            <img src={logo_url} alt={`logo of ${item.name}`} />
          </div>
        )}
        <div>
          <h4 className={styles.header} data-testid={Pages.Catalog.cardTitle(item.name)}>
            {item.name}
            {isPro && item.plan === SourcePlan.Enterprise && (
              <Badge text="Pro add-on" color="green" className={styles.badgeStyle} data-testid="Pro add-on badge" />
            )}
            {isCatalogCard && (
              <Badge
                text="Guide"
                icon="book-open"
                color="purple"
                tooltip="Follow our guided workflow to choose the right integration"
                className={styles.badgeStyle}
                data-testid="Guide badge"
              />
            )}
          </h4>
          {shouldShowSimplifiedCategory && (
            <small className={styles.smallBoldText}>
              {categoryToLabelMap.get(sourceTypeToCategoryMap.get(item.type) as Category)}
            </small>
          )}
        </div>
      </div>
      {isItemInstalled && !isItemUpdatable && (
        <Tooltip content={<StatusTooltipContent type="installed" connectionType={item?.type} />}>
          <Button fill="outline" variant="success" className={styles.button} size="xs">
            <Icon name="check" color="inherit" size="xs" />
          </Button>
        </Tooltip>
      )}
      {isItemUpdatable && (
        <Tooltip content={<StatusTooltipContent type="new-updates" connectionType={item?.type} />}>
          <Button fill="outline" variant="primary" className={styles.button} size="xs">
            <Icon name="sync" color="inherit" size="xs" />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export const CardContent = ({
  item,
  category,
  shouldShowSimplifiedCategory = false,
  isPro = false,
}: {
  item: Source;
  category?: Category;
  shouldShowSimplifiedCategory?: boolean;
  isPro?: boolean;
}) => {
  const styles = useStyles2((theme) => getCardStyles(theme));

  const { trackRudderStackEvent } = useRudderStack();
  const onClick = () => {
    trackRudderStackEvent(RudderStackEvents.CardClick, {
      integration_slug: item.id,
      page_section: category,
    });
  };

  if (isCardWithModal(item)) {
    return (
      <CardWithModal title={<ModalTitle item={item} />} ModalContent={ModalContentMapping[item.id]} onClick={onClick}>
        <Content item={item} shouldShowSimplifiedCategory={shouldShowSimplifiedCategory} isPro={isPro} />
      </CardWithModal>
    );
  } else if (isPermissionMissing(item)) {
    return (
      <CardWithModal
        title={<ModalTitle item={item} />}
        ModalContent={({ closeModal }) => <PermissionMissingModalContent item={item} closeModal={closeModal} />}
        onClick={onClick}
      >
        <Content item={item} shouldShowSimplifiedCategory={shouldShowSimplifiedCategory} isPro={isPro} />
      </CardWithModal>
    );
  } else {
    return (
      <ConditionalLink
        aria-label={`source ${item.name}`}
        key={item.name}
        to={item.id}
        externalUrl={item.externalUrl}
        className={styles.card}
        onClick={onClick}
      >
        <Content item={item} shouldShowSimplifiedCategory={shouldShowSimplifiedCategory} isPro={isPro} />
      </ConditionalLink>
    );
  }
};

const CardWithModal = ({
  children,
  title,
  ModalContent,
  onClick,
}: {
  children: React.ReactNode;
  title: string | React.JSX.Element;
  ModalContent: React.FC<{ closeModal: () => void }>;
  onClick: () => void;
}) => {
  const styles = useStyles2((theme) => getCardStyles(theme));
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
    onClick();
  };

  return (
    <>
      <div
        className={styles.card}
        onClick={handleClick}
        onKeyDown={handleKeyDown(handleClick)}
        tabIndex={0}
        role="button"
      >
        {children}
      </div>
      <Modal className={styles.modal} title={title} isOpen={showModal} onDismiss={() => setShowModal(false)}>
        <ModalContent closeModal={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

const ConditionalLink = ({
  children,
  to,
  onClick,

  className,
  externalUrl,
}: {
  children: React.ReactNode;
  to: string;
  onClick: () => void;

  className?: string;
  externalUrl?: string;
}) => {
  if (externalUrl) {
    return (
      <a href={externalUrl} className={className} onClick={onClick}>
        {children}
      </a>
    );
  }

  // <Link> would delete back to the last slash and then append `to`
  // but we need to just append it.
  const appendTo = (location: Location<unknown>) => {
    const pathname = location.pathname;
    if (pathname.endsWith('/')) {
      return `${pathname}${to}`;
    } else {
      return `${pathname}/${to}`;
    }
  };

  return (
    <Link to={appendTo} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};
