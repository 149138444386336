import React from 'react';

import { CollectorState, useCollectorSelector, UseToken } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { TextLink } from 'components/TextLink';
import { ConfigFileHelp } from 'features/agent-integrations/ConfigureIntegration/ConfigFileHelp';
import { RestartCollector } from 'features/agent-integrations/ConfigureIntegration/RestartCollector';
import { InstallAlloySection } from 'features/agent-integrations/InstallCollector/InstallAlloySection';
import { PlatformSelectionSection } from 'features/agent-integrations/PlatformSelection/PlatformSelectionSection';
import { DEFAULT_TEMPO_URL, GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';
import { CollectorMode } from 'utils/enums';
import { jsonStringRepl } from 'utils/misc';

const generateConfig = (url: string) => {
  const stripedUrl = url.replace(/(^\w+:|^)\/\//, '');
  const constructedUrl = url ? `${stripedUrl}` : DEFAULT_TEMPO_URL;

  return `otelcol.receiver.otlp "otlp_receiver" {
  grpc {
    endpoint = "0.0.0.0:4317"
  }
  http {
    endpoint = "0.0.0.0:4318"
  }

  output {
    traces = [otelcol.exporter.otlp.grafanacloud.input,]
  }
}

otelcol.exporter.otlp "grafanacloud" {
  client {
    endpoint = "${constructedUrl}:443"
    auth = otelcol.auth.basic.grafanacloud.handler
  }
}

otelcol.auth.basic "grafanacloud" {
  username = "${GRAFANA_EXAMPLE_USER}"
  password = "${GRAFANA_EXAMPLE_API}"
}`;
};

export const HostedTraces = () => {
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const token = useCollectorSelector((state: CollectorState) => state.collector.token);

  let updatedConfig = jsonStringRepl(
    generateConfig(hostedDataDetails.htInstanceUrl),
    new RegExp(GRAFANA_EXAMPLE_USER, 'g'),
    hostedDataDetails.htInstanceId.toString()
  );

  if (token) {
    updatedConfig = jsonStringRepl(updatedConfig, new RegExp(GRAFANA_EXAMPLE_API, 'g'), token);
  }

  return (
    <ol>
      <PlatformSelectionSection />
      <InstallAlloySection showOSSelection={false} />
      <li>
        <UseToken />
      </li>
      {token && (
        <>
          <li>
            <h2>Configure Alloy to send traces</h2>
            <p>
              Grafana Alloy supports consuming traces in several common formats, including{' '}
              <TextLink
                href="https://grafana.com/docs/alloy/latest/reference/components/otelcol/otelcol.receiver.otlp/"
                external
                inline
              >
                OpenTelemetry Protocol (OTLP)
              </TextLink>
              ,{' '}
              <TextLink
                href="https://grafana.com/docs/alloy/latest/reference/components/otelcol/otelcol.receiver.zipkin/"
                external
                inline
              >
                Zipkin
              </TextLink>
              , and{' '}
              <TextLink
                href="https://grafana.com/docs/alloy/latest/reference/components/otelcol/otelcol.receiver.jaeger/"
                external
                inline
              >
                Jaeger
              </TextLink>
              . You can use the following configuration snippet to start an OTLP receiver with default ports for both
              gRPC and HTTP. Refer to the{' '}
              <TextLink href="https://grafana.com/docs/alloy/latest/reference/components/otelcol/" external inline>
                complete list of OpenTelemety Collector receivers
              </TextLink>{' '}
              for more supported formats.
            </p>
            <p>
              Your configuration, complete with your API key, has been generated below. Copy and paste this code to your
              Grafana Alloy configuration to push data into your Grafana Cloud Traces instance.
            </p>
            <ConfigFileHelp customCollectorMode={CollectorMode.Alloy} />
            <Clipboard code={updatedConfig} multipleLines={true} expandHeight={true} clipboardButtonType="below" />
          </li>
          <li>
            <RestartCollector collectorMode={CollectorMode.Alloy} isLargeTitle />
          </li>
        </>
      )}
    </ol>
  );
};
