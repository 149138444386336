import React from 'react';

import { AgentIcon, RemoteIcon } from 'img/hostedData';
import { PrometheusMethodForwarding, PrometheusOptions } from 'utils/enums';

import { ConfigureComponent } from './ConfigureComponent';
import { KubernetesContent } from './KubernetesContent';
import { LinkContent } from './LinkContent';
import { UseCase } from './UseCase';

export const K8S_INSTRUCTION = 'kubernetes';
export const STANDARD_INSTRUCTION = 'standard';

export const useCase = [
  {
    component: () => (
      <ConfigureComponent
        value={PrometheusOptions.SingleInstance}
        heading="Set the configuration"
        method={PrometheusMethodForwarding.Alloy}
      />
    ),
    dependsOn: {
      method: PrometheusMethodForwarding.Alloy,
      instruction: STANDARD_INSTRUCTION,
    },
  },
  {
    component: () => <KubernetesContent />,
    dependsOn: {
      method: PrometheusMethodForwarding.Alloy,
      instruction: K8S_INSTRUCTION,
    },
  },
  {
    component: () => <UseCase />,
    dependsOn: {
      method: PrometheusMethodForwarding.RemoteWrite,
      instruction: STANDARD_INSTRUCTION,
    },
  },
  {
    component: () => (
      <LinkContent
        title="Visit our documentation to set up remote_write with Kubernetes"
        links={[
          {
            url: 'https://grafana.com/docs/grafana-cloud/metrics/prometheus/kubernetes/',
            title: 'Introduction to Kubernetes Monitoring',
          },
          {
            url: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/prometheus/remote-write-operator/',
            title: 'Configure remote_write with Prometheus Operator',
          },
          {
            url: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/prometheus/remote-write-helm-prometheus/',
            title: 'Configure remote_write with Helm and Prometheus',
          },
          {
            url: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/prometheus/remote-write-prometheus/',
            title: 'Configure remote_write with a Prometheus ConfigMap',
          },
          {
            url: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/prometheus/remote-write-helm-operator/',
            title: 'Configure remote_write with Helm and kube-prometheus-stack',
          },
        ]}
      />
    ),
    dependsOn: {
      method: PrometheusMethodForwarding.RemoteWrite,
      instruction: K8S_INSTRUCTION,
    },
  },
];

export const methodOptions = [
  {
    label: 'Via Grafana Alloy',
    value: PrometheusMethodForwarding.Alloy,
    icon: AgentIcon,
    description:
      'Use a component-based telemetry collector to send your metrics, and get advantage of Grafana Cloud alerting. You don’t have to store your data locally.',
  },
  {
    label: 'From my local Prometheus server',
    value: PrometheusMethodForwarding.RemoteWrite,
    icon: RemoteIcon,
    description:
      'Ship copies of scraped samples to Grafana Cloud while also maintaining a local copy of your Prometheus data.',
  },
];

export const instructionOptions = [
  { label: 'Standard', value: STANDARD_INSTRUCTION },
  { label: 'Kubernetes', value: K8S_INSTRUCTION },
];
