import React from 'react';

import { HostedGraphiteMetrics } from 'features/hosted-data-integrations/HostedGraphiteMetrics';
import { HostedLogs } from 'features/hosted-data-integrations/HostedLogs';
import { HostedProfiles } from 'features/hosted-data-integrations/HostedProfiles';
import { HostedPrometheusMetrics } from 'features/hosted-data-integrations/HostedPrometheusMetrics';
import { HostedTraces } from 'features/hosted-data-integrations/HostedTraces';
import { HttpMetrics } from 'features/hosted-data-integrations/HttpMetrics';
import { LogsHTTPInstructions } from 'features/hosted-data-integrations/LogsHTTPInstructions/LogsHTTPInstructions';
import { CollectorOpenTelemetryInstructions } from 'features/hosted-data-integrations/OpenTelemetry/OpenTelemetryCollectorInstructions';
import {
  GenericOpenTelemetryInstructions,
  JavaOpenTelemetryInstructions,
} from 'features/hosted-data-integrations/OpenTelemetry/OpenTelemetryInstructions';
import { ReactComponentType } from 'types/reactComponentMap';
import {
  AI_OBSERVABILITY_ID,
  BEYLA_ID,
  CILIUM_ENTERPRISE_ID,
  DOCKER_DESKTOP_ID,
  GRAFANA_AGENT_ID,
  ISTIO_ID,
  SELF_HOSTED_MIMIR_ID,
  VELERO_ID,
} from 'utils/consts';
import { DOTNET_ID } from 'utils/localSources/dotnet';
import { HOSTED_GRAPHITE_METRICS_ID } from 'utils/localSources/hostedGraphiteMetrics';
import { HOSTED_LOGS_ID } from 'utils/localSources/hostedLogs';
import { HOSTED_PROMETHEUS_METRICS_ID } from 'utils/localSources/hostedPrometheusMetrics';
import { HOSTED_TRACES_ID } from 'utils/localSources/hostedTraces';
import { HTTP_METRICS_ID } from 'utils/localSources/httpMetrics';
import { LOGS_HTTP_ID } from 'utils/localSources/logshttp';
import {
  COLLECTOR_OPEN_TELEMETRY_ID,
  JAVA_OPEN_TELEMETRY_ID,
  OPEN_TELEMETRY_ID,
} from 'utils/localSources/openTelemetry';
import { HOSTED_PROFILES_ID } from 'utils/localSources/pyroscope';

import { AgentHealth } from './AgentIntegrations/AgentHealth';
import { BeylaInstructions } from './AgentIntegrations/Beyla/BeylaInstructions';
import { CiliumInstructions } from './AgentIntegrations/CiliumInstructions';
import { DockerDesktopInstructions } from './AgentIntegrations/DockerDesktopInstructions';
import { DotNetInstructions } from './AgentIntegrations/DotNetInstructions';
import { IstioInstructions } from './AgentIntegrations/IstioInstructions';
import { SelfHostedMimir } from './AgentIntegrations/SelfHostedMimir';
import { VeleroInstructions } from './AgentIntegrations/VeleroInstructions';
import { ConfluentInstructions } from './SaasIntegrationWithScrapeJobs/ConfluentInstructions';
import { AIObservabilityInstructions } from './SaasIntegrations/AIObservabilityInstructions/AIObservabilityInstructions';
import { HCPVaultInstructions } from './SaasIntegrations/HCPVaultInstructions';
import { OpenAIInstructions } from './SaasIntegrations/OpenAIInstructions/OpenAIInstructions';
import { LocalInstructionsContainer } from './common/LocalInstructionsContainer';

export const instructions: ReactComponentType = {
  'confluent-cloud': ConfluentInstructions,
  'hcp-vault': HCPVaultInstructions,
  openai: OpenAIInstructions,
  [CILIUM_ENTERPRISE_ID]: CiliumInstructions,
  [DOCKER_DESKTOP_ID]: DockerDesktopInstructions,
  [ISTIO_ID]: IstioInstructions,
  [GRAFANA_AGENT_ID]: AgentHealth, // this should never be wrapped in local instructions container
  [SELF_HOSTED_MIMIR_ID]: SelfHostedMimir,
  [VELERO_ID]: VeleroInstructions,
  [AI_OBSERVABILITY_ID]: AIObservabilityInstructions,
};

export const instructionsToWrap: ReactComponentType = {
  [DOTNET_ID]: DotNetInstructions,
  [OPEN_TELEMETRY_ID]: GenericOpenTelemetryInstructions,
  [JAVA_OPEN_TELEMETRY_ID]: JavaOpenTelemetryInstructions,
  [COLLECTOR_OPEN_TELEMETRY_ID]: CollectorOpenTelemetryInstructions,
  [HOSTED_PROMETHEUS_METRICS_ID]: HostedPrometheusMetrics,
  [HOSTED_LOGS_ID]: HostedLogs,
  [HOSTED_GRAPHITE_METRICS_ID]: HostedGraphiteMetrics,
  [HOSTED_TRACES_ID]: HostedTraces,
  [HOSTED_PROFILES_ID]: HostedProfiles,
  [HTTP_METRICS_ID]: HttpMetrics,
  [LOGS_HTTP_ID]: LogsHTTPInstructions,
  [AI_OBSERVABILITY_ID]: AIObservabilityInstructions,
  [BEYLA_ID]: BeylaInstructions,
};

export const LocalInstructions: React.FC<{ integrationId: string }> = ({ integrationId }) => {
  if (integrationId in instructions) {
    const Component = instructions[integrationId];
    return <Component />;
  } else if (integrationId in instructionsToWrap) {
    const Component = instructionsToWrap[integrationId];
    return (
      <LocalInstructionsContainer>
        <Component />
      </LocalInstructionsContainer>
    );
  }
  return null;
};
