import React, { useEffect } from 'react';

import { Alert, LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { usePluginComponents } from '@grafana-cloud/ui';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { track } from 'utils/track';

import { getInstallSectionStyles } from '../InstallCollector/InstallSection.styles';
import { K8S_INTEGRATION_CONFIG_EXTENSION_ID } from '../consts';

export function K8sIntegrationInstructions(): React.JSX.Element {
  const styles = useStyles2(getInstallSectionStyles);

  const { components: K8sIntegrationConfigExtensionComponents, isLoading: isK8sExtensionLoading } =
    usePluginComponents<{ context: { slug: string } }>({
      extensionPointId: K8S_INTEGRATION_CONFIG_EXTENSION_ID,
    });

  const K8sIntegrationConfigExtension = K8sIntegrationConfigExtensionComponents?.[0];

  useEffect(() => {
    if (!K8sIntegrationConfigExtension) {
      track('installation_instructions_missing_k8s_integration_extension');
    }
  }, [K8sIntegrationConfigExtension]);

  const source = useSelectedAgentOrSaasIntegration();

  return (
    <li className={styles.li}>
      {!K8sIntegrationConfigExtension ? (
        <Alert title="" severity="warning">
          Kubernetes integration instructions are not available. Please check if you run the latest Grafana on this
          instance.
        </Alert>
      ) : (
        <>
          <h2>Kubernetes Monitoring Helm chart configuration</h2>
          {isK8sExtensionLoading && <LoadingPlaceholder text="Loading..." />}
          {K8sIntegrationConfigExtension && <K8sIntegrationConfigExtension context={{ slug: source.slug || '' }} />}
        </>
      )}
    </li>
  );
}
