import React from 'react';
import { useSelector } from 'react-redux';

import { useStyles2, LinkButton } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { RootState } from 'state';
import { constructAlertUrl } from 'utils/misc';

import { getAlertsStyles } from './Alerts.styles';

export const Alerts = () => {
  const styles = useStyles2(getAlertsStyles);

  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const source = useSelectedAgentOrSaasIntegration();

  const alerts = sourceDetails.alerts?.available_alerts || [];

  const isIntegrationInstalled = source.installation !== undefined;

  return (
    <div>
      <h3>Alerts</h3>
      <p>
        The following alerts are included in this integration. You can view, manage, and edit these alerts in Grafana
        Alerting.
      </p>

      {isIntegrationInstalled && (
        <LinkButton variant="primary" fill="outline" icon="bell" href={constructAlertUrl(sourceDetails)}>
          Go to Grafana Alerting
        </LinkButton>
      )}

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Alert name</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {alerts.map((alert, i) => (
            <tr key={i}>
              <td>{alert.name}</td>
              <td>{alert.summary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
