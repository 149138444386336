import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AgentDetails, MetricsSetType } from 'api/integrations/types';
import { InstallationStatus } from 'state/source/slice';

export enum AgentStatus {
  None,
  Pending,
  Success,
  Error,
  NoData,
}

export enum SetupMode {
  Simple = 'simple',
  Advanced = 'advanced',
}

export enum IntegrationConnectionStatus {
  None,
  Pending,
  Success,
  Error,
  NoData,
}

type IntegrationConnectionWithoutErrorStatus =
  | IntegrationConnectionStatus.None
  | IntegrationConnectionStatus.Pending
  | IntegrationConnectionStatus.Success;

type IntegrationConnectionResult =
  | { status: IntegrationConnectionStatus.Error; error: IntegrationConnectionErrorCause }
  | { status: IntegrationConnectionStatus.NoData; error: IntegrationConnectionErrorCause }
  | { status: IntegrationConnectionWithoutErrorStatus };

export enum IntegrationConnectionErrorCause {
  NoMetricsFound,
  AgentCannotScrapeMetrics,
  NoLogsFound,
  Unexpected,
}

type AgentState = {
  agentStatus: AgentStatus;
  configuredParameters: {
    isFullMetricsSelected?: boolean;
    isLogsEnabled?: boolean;
    isAlertsEnabled?: boolean;
    hostName?: string;
    setupMode?: SetupMode;
  };
  showLoadingBar: boolean;
  agentCheckInstalled: boolean;
  saveSelectionsStatus: InstallationStatus;
  integrationConnectionResult: IntegrationConnectionResult;
  tracesConnection: {
    status: IntegrationConnectionStatus;
    link?: string;
    serviceName?: string;
    serviceNamespace?: string;
  };
};

const initialState: AgentState = {
  agentStatus: AgentStatus.None,
  configuredParameters: {},
  showLoadingBar: false,
  agentCheckInstalled: false,
  saveSelectionsStatus: InstallationStatus.Idle,
  integrationConnectionResult: { status: IntegrationConnectionStatus.None },
  tracesConnection: { status: IntegrationConnectionStatus.None },
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAgentStatus(state, action: PayloadAction<{ agentStatus: AgentStatus; integrationId: string }>) {
      state.agentStatus = action.payload.agentStatus;
    },
    initIsFullMetricsSelected(state, action: PayloadAction<AgentDetails>) {
      state.configuredParameters.isFullMetricsSelected =
        action.payload.contentType === 'static' ? action.payload.static.defaultType !== MetricsSetType.Filtered : false;
    },
    toggleMetricsSet(state, action: PayloadAction<string>) {
      state.configuredParameters.isFullMetricsSelected = !state.configuredParameters.isFullMetricsSelected;
      state.showLoadingBar = true;
    },
    initIsLogsEnabled(state, action: PayloadAction<boolean>) {
      state.configuredParameters.isLogsEnabled = action.payload;
    },
    initIsAlertsEnabled(state, action: PayloadAction<boolean>) {
      state.configuredParameters.isAlertsEnabled = action.payload;
    },
    toggleLogs(state, action: PayloadAction<string>) {
      state.configuredParameters.isLogsEnabled = !state.configuredParameters.isLogsEnabled;
    },
    toggleAlerts(state, action: PayloadAction<string>) {
      state.configuredParameters.isAlertsEnabled = !state.configuredParameters.isAlertsEnabled;
    },
    setHostName(state, action: PayloadAction<string>) {
      state.configuredParameters.hostName = action.payload;
      state.showLoadingBar = true;
    },
    resetConfiguredParameters(state) {
      state.configuredParameters = initialState.configuredParameters;
    },
    removeLoadingBar(state) {
      state.showLoadingBar = false;
    },
    copyToClipboard(state, action: PayloadAction<string>) {},
    openAgentModal(state) {
      state.agentCheckInstalled = initialState.agentCheckInstalled;
      state.agentStatus = initialState.agentStatus;
    },
    installedAgentCheck(state) {
      state.agentCheckInstalled = true;
    },
    selectSetupMode(state, action: PayloadAction<SetupMode>) {
      state.configuredParameters.setupMode = action.payload;
    },
    setSaveSelectionsStatus(state, action: PayloadAction<InstallationStatus>) {
      state.saveSelectionsStatus = action.payload;
    },
    setIntegrationConnectionStatus(
      state,
      action: PayloadAction<{ status: IntegrationConnectionWithoutErrorStatus; integrationId: string }>
    ) {
      state.integrationConnectionResult.status = action.payload.status;
    },
    setIntegrationConnectionError(state, action: PayloadAction<IntegrationConnectionErrorCause>) {
      state.integrationConnectionResult = {
        status: IntegrationConnectionStatus.Error,
        error: action.payload,
      };
    },
    setIntegrationConnectionNoData(state, action: PayloadAction<IntegrationConnectionErrorCause>) {
      state.integrationConnectionResult = {
        status: IntegrationConnectionStatus.NoData,
        error: action.payload,
      };
    },
    clickOpenAgentConfigModal: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    clickOpenExtendedMetricsModal: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    clickDashboardsButton: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    clickAlertsAndRecordingButtons: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    setTracesConnectionStatus: (state, action: PayloadAction<AgentState['tracesConnection']>) => {
      state.tracesConnection = action.payload;
    },
  },
});

export const {
  setAgentStatus,
  initIsFullMetricsSelected,
  toggleMetricsSet,
  initIsLogsEnabled,
  initIsAlertsEnabled,
  toggleLogs,
  toggleAlerts,
  setHostName,
  resetConfiguredParameters,
  removeLoadingBar,
  copyToClipboard,
  openAgentModal,
  installedAgentCheck,
  selectSetupMode,
  setSaveSelectionsStatus,
  setIntegrationConnectionStatus,
  setIntegrationConnectionError,
  setIntegrationConnectionNoData,
  clickOpenAgentConfigModal,
  clickOpenExtendedMetricsModal,
  clickDashboardsButton,
  clickAlertsAndRecordingButtons,
  setTracesConnectionStatus,
} = agentSlice.actions;

export default agentSlice.reducer;
