import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getInstallSectionStyles = (theme: GrafanaTheme2) => ({
  box: css({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),

    h5: {
      color: theme.colors.text.primary,
      marginBottom: theme.spacing(2),
    },
  }),
  blueBackground: css({
    backgroundColor: theme.colors.info.transparent,
    border: `1px solid ${theme.colors.info.borderTransparent}`,
  }),
  greyBackground: css({
    backgroundColor: theme.colors.background.secondary,
  }),
  li: css({
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    paddingBottom: '8px',
    button: {
      display: 'flex',
      alignItems: 'center',
      '> div:nth-child(2)': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
    },
    '> div:nth-child(2)': {
      paddingLeft: '34px',
    },
  }),
  marginTop: css({
    marginTop: theme.spacing(2),
  }),
  yellowText: css({
    color: theme.colors.warning.text,
  }),
});
