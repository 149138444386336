// The API in this file is created with RTQ

import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { Source } from 'types/Source';
import { mapIntegrationsToSources } from 'utils/configTransformation';
import { CollectorMode } from 'utils/enums';

import { PLUGIN_PROXY_URL } from '../utils';

import { transformAgentDetails } from './transformResponse';
import { AgentDetails } from './types';
const rawBaseQuery = fetchBaseQuery({
  baseUrl: '',
});

enum ApiPath {
  IntApiAdmin = 'int-api-admin',
  IntApiEditor = 'int-api-editor',
}

interface IntApiFetchArgs extends FetchArgs {
  apiPath: ApiPath;
}

const dynamicBaseQuery: BaseQueryFn<IntApiFetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const urlEnd = args.url;

  let urlMiddle: string;
  switch (args.apiPath) {
    case ApiPath.IntApiAdmin:
      urlMiddle = '/integrations-api-admin';
      break;
    case ApiPath.IntApiEditor:
      urlMiddle = '/integrations-api-editor';
      break;
  }

  const adjustedUrl = `${PLUGIN_PROXY_URL}${urlMiddle}${urlEnd}`;

  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['integrations'],
  endpoints: (builder) => ({
    integrations: builder.query<Source[], { installed?: boolean }>({
      query: ({ installed }) => ({
        apiPath: ApiPath.IntApiEditor,
        url: '/integrations',
        params: { installed },
      }),
      transformResponse: mapIntegrationsToSources,
      providesTags: ['integrations'],
    }),
    javaIntegrations: builder.query<Source[], { installed?: boolean }>({
      query: ({ installed }) => ({
        apiPath: ApiPath.IntApiEditor,
        url: '/integrations',
        params: { catalog: 'java', installed },
      }),
      transformResponse: mapIntegrationsToSources,
      providesTags: ['integrations'],
    }),
    agentDetails: builder.query<AgentDetails, { integrationId: string; mode: CollectorMode }>({
      query: ({ integrationId, mode }) => ({
        apiPath: ApiPath.IntApiAdmin,
        url: `/integrations/${integrationId}/agent`,
        params: { mode },
      }),
      transformResponse: transformAgentDetails,
    }),
  }),
});

export const { useAgentDetailsQuery, useIntegrationsQuery, useJavaIntegrationsQuery } = integrationsApi;
