import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    maxWidth: '700px',
    a: {
      color: theme.colors.text.link,
      fontSize: theme.typography.body.fontSize,
    },
  }),
  table: css({
    borderCollapse: 'collapse',
    border: '1px solid white',

    'td div': {
      width: '100%',
    },

    'td, th': {
      fontSize: theme.typography.body.fontSize,
      border: '1px solid white',
      padding: theme.spacing(1),
    },
  }),
  tabsWrapper: css({
    marginBottom: '40px',

    label: {
      fontSize: theme.typography.bodySmall.fontSize,
      marginRight: theme.spacing(2),
    },
  }),
  nestedList: css({
    'ol li': {
      fontSize: theme.typography.body.fontSize,
    },
  }),
  option: css({
    minWidth: '209px',
    color: theme.colors.text.maxContrast,
    fontSize: theme.typography.body.fontSize,
    marginBottom: '10px',
    marginTop: '5px',
  }),
  // When displaying clipboard with button below, the button adds to much margin (32px), and messes spacing up when in between paragraphs
  clipboardWithButtonBelow: css({
    marginBottom: `-${theme.spacing(1)}`,
  }),
  bold: css({
    fontWeight: theme.typography.fontWeightBold,
  }),
});
