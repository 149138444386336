import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: '780px',
    marginBottom: '40px',
    marginLeft: theme.spacing(3),

    li: {
      marginBottom: '32px',
      fontSize: '19px',

      h2: {
        margin: `${theme.spacing(3)} 0`,
        lineHeight: '26px',
        fontSize: '19px',
      },

      p: {
        fontSize: theme.typography.body.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
  }),
  radioButtonGroup: css({
    marginBottom: '8px',
  }),
  textArea: css({
    marginBottom: '8px',
  }),
  infoIcon: css({
    marginLeft: '4px',
  }),
});
