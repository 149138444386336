import { css } from '@emotion/css';
import React, { FC, useEffect } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Icon, Button } from '@grafana/ui';

import { useDispatch } from 'hooks/useDispatch';
import { AppError } from 'utils/errors';

import { setIsGeneralErrorShowing, unsetGeneralError } from '../state/slice';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    button: css({
      marginTop: theme.spacing(4),
    }),
    text: css({
      marginTop: theme.spacing(2),
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    }),
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '15%',
    }),
  };
};

interface Props {
  appError: AppError;
}

export const GeneralError: FC<Props> = ({ appError }) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const onTryAgainClick = () => dispatch(unsetGeneralError());

  useEffect(() => {
    dispatch(setIsGeneralErrorShowing(true));

    function destruct() {
      dispatch(setIsGeneralErrorShowing(false));
    }
    return destruct;
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Icon size="xxl" name="exclamation-triangle" />
      <p className={styles.text}>
        {appError.message}
        {appError.action}
      </p>
      <Button onClick={onTryAgainClick} className={styles.button}>
        Try again
      </Button>
    </div>
  );
};
