import React, { FC } from 'react';

import { useCollectorSelector } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { useGetInstanceConnectionsQuery, useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { getCollectorConfigFilePath } from 'features/agent-integrations/OSInstallConfig';
import { CollectorMode } from 'utils/enums';

import { getAlloyOtelConfig } from './alloyOtelConfig';

export const AlloyConfig: FC = () => {
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { data: instanceConnectionsDetails } = useGetInstanceConnectionsQuery();
  // use the token from alloy install modal
  const { selectedOs, token } = useCollectorSelector((state) => state.collector);

  const config: { [key in string]: number | string | undefined } = {
    GRAFANA_CLOUD_API_KEY: token,
    GRAFANA_CLOUD_INSTANCE_ID: hostedDataDetails.id,
    GRAFANA_CLOUD_OTLP_ENDPOINT: `${instanceConnectionsDetails?.otlpHttpUrl}/otlp`,
  };

  return (
    <>
      Append the generated configuration to your configuration file at{' '}
      <code>{getCollectorConfigFilePath(selectedOs, CollectorMode.Alloy)}</code>
      <Clipboard
        code={getAlloyOtelConfig(config)}
        multipleLines={true}
        expandHeight={true}
        clipboardButtonType="below"
      />
    </>
  );
};
