import { cx } from '@emotion/css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Tab, TabsBar, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { changeTab, SourceDetails } from 'state/source/slice';
import { Source } from 'types/Source';

import { getStyles } from './Source.styles';
import { getSelectedSourceTabs } from './Tabs';
import { Tabs } from './TabsEnum';

interface SourceTabsProps {
  selectedSource: Source;
  sourceDetails: SourceDetails;
}

export const SourceTabs: React.FC<SourceTabsProps> = ({ selectedSource, sourceDetails }) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();

  const integrationId = useSelectedIntegrationId();
  const selectedSourceTabs = getSelectedSourceTabs(sourceDetails, integrationId, selectedSource.type);
  const firstTab = selectedSourceTabs?.find((tab) => tab.isDefault)?.id ?? Tabs.ConfigurationDetails;
  const currentTab = useSelector((state: RootState) => state.source.currentTab);

  useEffect(() => {
    dispatch(changeTab({ currentTab: firstTab, integrationId }));
  }, [dispatch, firstTab, integrationId]);

  return (
    <TabsBar className={cx(styles.tabsBar, styles.fullWidthPage)}>
      {selectedSourceTabs?.map((tab) => {
        if (tab.isVisible) {
          return (
            <Tab
              key={tab.id}
              label={tab.label}
              onChangeTab={() => {
                dispatch(changeTab({ currentTab: tab.id, integrationId }));
              }}
              active={currentTab === tab.id}
              data-testid={Pages.Source.tabs(tab.id)}
            />
          );
        }
        return;
      })}
    </TabsBar>
  );
};
