import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getDashboardScreenshotStyles = (theme: GrafanaTheme2) => ({
  img: css`
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    border: 1px solid ${theme.colors.border.medium};
    aspect-ratio: 16/9;
    object-fit: cover;
  `,
});
