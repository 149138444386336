import { cx } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { useStyles2 } from '@grafana/ui';

import { RootState } from 'state';
import { CollectorMode } from 'utils/enums';

import { GrafanaAgentMessage } from '../AgentConnection/GrafanaAgentMessage';
import { AgentStatus } from '../state/slice';
import { useCollectorMode } from '../useCollectorMode';

import { AgentConfigModal } from './AgentConfigModal';
import { AgentStaticDescription } from './AgentStaticDescription';
import { InstallAlloySection } from './InstallAlloySection';
import { getInstallSectionStyles } from './InstallSection.styles';

export const InstallAgentSection = ({ showOSSelection }: { showOSSelection: boolean }) => {
  const styles = useStyles2(getInstallSectionStyles);
  const { agentStatus } = useSelector((state: RootState) => state.agent);
  const collectorMode = useCollectorMode();

  if (collectorMode === CollectorMode.Alloy) {
    return <InstallAlloySection showOSSelection={showOSSelection} />;
  }

  return (
    <li className={styles.li}>
      <h2>Install Grafana Agent in Static mode</h2>
      <p className={cx(styles.yellowText, styles.marginTop)}>
        Skip this step if you have already run the agent on the machine which you intend to install this integration.
      </p>
      <div className={cx(styles.box, styles.greyBackground)}>
        <h5>Before you configure this integration, you need to set up the Grafana agent in Static mode.</h5>
        <AgentStaticDescription />
        <AgentConfigModal />
      </div>
      {agentStatus === AgentStatus.Success && <GrafanaAgentMessage />}
    </li>
  );
};
