import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    toggleGroup: css({
      marginBottom: 0,
    }),
    alert: css({
      marginBottom: theme.spacing(1),
    }),
  };
};
