import { css } from '@emotion/css';
import history from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, Redirect } from 'react-router-dom';

import { Button, Modal } from '@grafana/ui';

interface Props {
  confirmRedirect?: boolean;
  onDiscard: () => void;
  /** Extra check to invoke when location changes.
   * Could be useful in multistep forms where each step has a separate URL
   */
  onLocationChange?: (location: history.Location) => void;
  howToSaveDescription?: string;
}

export const FormPrompt = ({ confirmRedirect, onDiscard, onLocationChange, howToSaveDescription }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState<history.Location | null>(null);
  const [changesDiscarded, setChangesDiscarded] = useState(false);

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (confirmRedirect) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [confirmRedirect]);

  // Returning 'false' from this function will prevent navigation to the next URL
  const handleRedirect = (location: history.Location) => {
    const locationChangeCheck = onLocationChange?.(location);

    let blockRedirect = confirmRedirect && !changesDiscarded;
    if (locationChangeCheck !== undefined) {
      blockRedirect = blockRedirect && locationChangeCheck;
    }

    if (blockRedirect) {
      setModalIsOpen(true);
      setBlockedLocation(location);
      return false;
    }

    if (locationChangeCheck) {
      onDiscard();
    }

    return true;
  };

  const onBackToForm = () => {
    setModalIsOpen(false);
    setBlockedLocation(null);
  };

  const onDiscardChanges = () => {
    setModalIsOpen(false);
    setChangesDiscarded(true);
    onDiscard();
  };

  return (
    <>
      <Prompt when={true} message={handleRedirect} />
      {blockedLocation && changesDiscarded && <Redirect to={blockedLocation} />}
      <UnsavedChangesModal
        isOpen={modalIsOpen}
        onDiscard={onDiscardChanges}
        onBackToForm={onBackToForm}
        howToSaveDescription={howToSaveDescription}
      />
    </>
  );
};

interface UnsavedChangesModalProps {
  onDiscard: () => void;
  onBackToForm: () => void;
  isOpen: boolean;
  howToSaveDescription?: string;
}

const UnsavedChangesModal = ({ onDiscard, onBackToForm, isOpen, howToSaveDescription }: UnsavedChangesModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Leave page?"
      onDismiss={onBackToForm}
      className={css`
        width: 500px;
      `}
    >
      <h5>Changes that you made may not be saved.</h5>
      {howToSaveDescription && <p>{howToSaveDescription}</p>}
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={onBackToForm} fill="outline">
          Continue editing
        </Button>
        <Button variant="destructive" onClick={onDiscard}>
          Discard unsaved changes
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
};
