import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useSelectedSource } from 'api/integrations/hooks';
import { useIntegrationsQuery, useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { useDispatch } from 'hooks/useDispatch';
import { RootState } from 'state';
import { fetchIntegrationDetails, fetchVersionHistory } from 'state/source/actions';
import { sourcesWithLocalInstructions } from 'utils/localSources';
import { isAdmin } from 'utils/user';

import { useSourceInitialization } from './sourceUtils';

export const useIntegrationFetching = () => {
  const dispatch = useDispatch();
  const { integrationId } = useSourceInitialization();
  const selectedSource = useSelectedSource();
  const { sourceDetails } = useSelector((state: RootState) => state.source);
  const integrationsQuery = useIntegrationsQuery({});
  const javaIntegrationsQuery = useJavaIntegrationsQuery({});

  const isLocal = sourcesWithLocalInstructions.some((source) => source.id === integrationId);
  const shouldMakeRequests = !isLocal;
  const isSourceDetailsFetchPending = shouldMakeRequests && isEmpty(sourceDetails);
  const isRtqFetchError = integrationsQuery.isError || javaIntegrationsQuery.isError;

  useEffect(() => {
    if (
      integrationId &&
      !sourceDetails.version_history &&
      shouldMakeRequests &&
      !isEmpty(selectedSource) &&
      isAdmin()
    ) {
      dispatch(fetchVersionHistory({ integrationId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSource]);

  useEffect(() => {
    if (shouldMakeRequests && isAdmin()) {
      dispatch(fetchIntegrationDetails({ integrationId }));
    }
  }, [dispatch, integrationId, shouldMakeRequests]);

  return { selectedSource, sourceDetails, isSourceDetailsFetchPending, isRtqFetchError, shouldMakeRequests };
};
