import { useSelector } from 'react-redux';

import { useListScrapeJobsQuery } from 'api/hostedExporters/hostedExportersApi';
import { useJobTypeWithFlavor } from 'pages/Source/ConfigurationDetails/Local/SaasIntegrationWithScrapeJobs/hooks/useJobTypeWithFlavor';
import { FilterJobStatus } from 'utils/enums';

import { jobStatusFilterSelector } from './selectors';

export const useFilteredJobs = () => {
  const jobTypeWithFlavor = useJobTypeWithFlavor();
  const { data: jobs } = useListScrapeJobsQuery(jobTypeWithFlavor);
  const jobStatusFilter = useSelector(jobStatusFilterSelector());

  if (!jobs) {
    return [];
  }

  let filteredJobs = jobs;

  if (jobStatusFilter === FilterJobStatus.Enabled) {
    filteredJobs = filteredJobs.filter((job) => job.enabled);
  } else if (jobStatusFilter === FilterJobStatus.Disabled) {
    filteredJobs = filteredJobs.filter((job) => !job.enabled);
  }

  return filteredJobs;
};
