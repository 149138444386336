import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2, Checkbox } from '@grafana/ui';

import { JobStatus } from 'components/SaasIntegration/JobStatus';
import { useDispatch } from 'hooks/useDispatch';
import { jobSelected } from 'state/saasIntegration/selectors';
import { toggleSelectedJobs } from 'state/saasIntegration/slice';
import { getReadableConfluentJob } from 'utils/saasIntegrations';

import { JobCardProps } from '../JobManager/JobList';

import { getJobCardStyles } from './ConfluentInstructionsStyles';

export const JobCard = ({ job, handleJobDelete, handleJobEdit }: JobCardProps) => {
  const dispatch = useDispatch();
  const isJobSelected = useSelector(jobSelected(job.name));
  const styles = useStyles2((theme: GrafanaTheme2) => getJobCardStyles(theme, { isJobSelected }));
  const readableJob = getReadableConfluentJob(job);
  const resources = Array.isArray(readableJob.resources) ? readableJob.resources : [];

  const onChange = useCallback(
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(toggleSelectedJobs(name));
    },
    [dispatch]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.jobSelector}>
        <div className={styles.jobName}>
          <Checkbox
            className={styles.checkbox}
            label={readableJob.name}
            value={isJobSelected}
            onChange={onChange(job.name)}
          />
        </div>
        <JobStatus enabled={readableJob.enabled} disabledReason={readableJob.disabled_reason} />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Resource type</th>
            <th>Resource ID</th>
          </tr>
        </thead>
        <tbody>
          {resources.map(({ resourceType, resourceId }) => (
            <tr key={resourceId}>
              <td>{resourceType}</td>
              <td>{resourceId}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>
              <div className={styles.icons}>
                <Icon name="pen" onClick={() => handleJobEdit(job)} />
                <Icon name="trash-alt" onClick={() => handleJobDelete(job)} />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
