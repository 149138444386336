// The order of these sources determine the order of cards in the catalog.
// Sources not listed here will show up below these sources.
import { LINUX_NODE_ID } from 'utils/consts';
import { isCSPAppEnabled, isHomeSetupGuideEnabled } from 'utils/featureToggles';
import { AWS_APP_ID } from 'utils/localSources/awsApp';
import { HOSTED_PROMETHEUS_METRICS_ID } from 'utils/localSources/hostedPrometheusMetrics';
import { HTTP_METRICS_ID } from 'utils/localSources/httpMetrics';
import { K8S_MONITORING_ID } from 'utils/localSources/kubernetesMonitoring';
import { OPEN_TELEMETRY_ID } from 'utils/localSources/openTelemetry';
import { SETUP_GUIDE_ID } from 'utils/localSources/setupGuide';
import { SYNTHETIC_MONITORING_ID } from 'utils/localSources/syntheticMonitoring';

export const topPrioritySourceIds = [
  ...isHomeSetupGuideEnabled() ? [SETUP_GUIDE_ID] : [],
  LINUX_NODE_ID,
  HOSTED_PROMETHEUS_METRICS_ID,
  ...(isCSPAppEnabled() ? [AWS_APP_ID] : []),
  'datasource-influxdb',
  HTTP_METRICS_ID,
  K8S_MONITORING_ID,
  'mysql',
  'macos-node',
  SYNTHETIC_MONITORING_ID,
  OPEN_TELEMETRY_ID,
  'datasource-elasticsearch',
];
