import { css, cx } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { HCPVaultMetricsStep1, HCPVaultLogsStep1, HCPVaultStep4 } from 'img/datasources';
import { DEFAULT_LOKI_URL, DEFAULT_PROM_URL, GRAFANA_EXAMPLE_API } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '40px',
    marginLeft: theme.spacing(3),

    li: {
      marginBottom: '32px',
      fontSize: '19px',

      h2: {
        margin: `${theme.spacing(2)} 0`,
        lineHeight: '26px',
        fontSize: '19px',
      },

      p: {
        fontSize: theme.typography.body.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
  }),
  radioButtonGroup: css({
    marginBottom: '32px',
  }),
  imageStep4: css({
    marginBottom: '32px',
  }),
  stepImage: css({
    width: '100%',
  }),
});

const dataTypeOptions = [
  { label: 'Metrics', value: 'metrics' },
  { label: 'Logs', value: 'logs' },
];

const successMessage =
  'Your API key has been generated below. Copy and paste this key to your Hashicorp Vault settings to push data into Grafana Cloud instance.';

export const HCPVaultInstructions: FC = () => {
  const styles = useStyles2(getStyles);
  const [dataType, setDataType] = useState<string>('metrics');

  const { hmInstancePromId, hmInstancePromUrl, hlInstanceId, hlInstanceUrl } = useHostedDataDetailsWithFallback();

  const lokiUrl = hlInstanceUrl ? `${hlInstanceUrl}` : DEFAULT_LOKI_URL.replace('/api/prom/push', '');
  const promUrl = hmInstancePromUrl ? `${hmInstancePromUrl}/api/prom/push` : DEFAULT_PROM_URL;

  const isMetricsSelected = dataType === 'metrics';
  const url = isMetricsSelected ? promUrl : lokiUrl;

  return (
    <>
      <h1>Configure sending data from HCP Vault</h1>
      <RadioButtonGroup
        options={dataTypeOptions}
        value={dataType}
        onChange={(val) => setDataType(val)}
        className={styles.radioButtonGroup}
      />
      <ol className={styles.instructions}>
        <li>
          <h2>
            From the HCP Portal, go to Vault cluster <b>Overview</b>, select the{' '}
            <b>{isMetricsSelected ? 'Metrics' : 'Audit Logs'}</b> view.
          </h2>
          <img
            alt={isMetricsSelected ? 'HCP Vault metric step 1' : 'HCP Vault logs step 1'}
            src={isMetricsSelected ? HCPVaultMetricsStep1 : HCPVaultLogsStep1}
            className={styles.stepImage}
          />
        </li>
        <li>
          <h2>
            If you haven’t yet configured {isMetricsSelected ? 'metrics' : 'logs'} streaming before, click{' '}
            <b>Enable streaming</b>.
          </h2>
        </li>
        <li>
          <h2>
            From the <b>{isMetricsSelected ? 'Stream Vault metrics' : 'Enable audit logs streaming'}</b> view, select{' '}
            <b>Grafana Cloud</b> as the provider.
          </h2>
        </li>
        <li>
          <h2>
            Under <b>Grafana Cloud configuration</b>, enter the following details:
          </h2>
          <img alt="HCP Vault step 4" src={HCPVaultStep4} className={cx(styles.imageStep4, styles.stepImage)} />
          <p>
            For <b>Endpoint</b>, use your Grafana Cloud {isMetricsSelected ? 'metrics' : 'logs'} remote write endpoint:
          </p>
          <Clipboard code={url} clipboardButtonType="below" />
          <p>
            For <b>Grafana Cloud user</b>, use your Grafana Cloud {isMetricsSelected ? 'metrics' : 'logs'} username /
            instance ID:
          </p>
          <Clipboard
            code={isMetricsSelected ? hmInstancePromId.toString() : hlInstanceId.toString()}
            clipboardButtonType="below"
          />
          <p>
            For <b>Grafana Cloud password</b>, generate a Grafana.com API key to be used with HCP Vault:
          </p>
          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={isMetricsSelected ? hmInstancePromId : hlInstanceId}
            successMessage={successMessage}
          />
        </li>
        <li>
          <h2>
            Click <b>Save</b>.
          </h2>
        </li>
      </ol>
    </>
  );
};
