import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useStyles2, LinkButton } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { RootState } from 'state';
import { constructDashboardUrl, sortMetricsByName } from 'utils/misc';

import { getMetricsStyles } from './Metrics.styles';

export const Metrics = () => {
  const styles = useStyles2(getMetricsStyles);

  const { sourceDetails } = useSelector((state: RootState) => state.source);
  const source = useSelectedAgentOrSaasIntegration();

  const metrics = useMemo(() => sortMetricsByName(sourceDetails.metrics?.available_metrics || []), [sourceDetails]);

  const isTypeAvailable = metrics?.some((el) => el.type !== '' && el.type !== undefined);
  const isDescriptionAvailable = metrics?.some((el) => el.description !== '' && el.description !== undefined);
  const isIntegrationInstalled = source.installation !== undefined;

  return (
    <div className={styles.wrapper}>
      <h3>Included metrics</h3>
      <p>
        The following metrics are included in this integration. These metrics are included in the pre-built dashboards
        that are also included in this integration.{' '}
      </p>

      {isIntegrationInstalled && (
        <div className={styles.buttons}>
          {sourceDetails.dashboard_folder && (
            <LinkButton
              variant="primary"
              fill="outline"
              icon="external-link-alt"
              href={constructDashboardUrl(sourceDetails.dashboard_folder)}
            >
              View integration dashboards
            </LinkButton>
          )}
          <LinkButton variant="secondary" fill="outline" icon="apps" href="/dashboards">
            Build a custom dashboard
          </LinkButton>
        </div>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Metric name</th>
            {isTypeAvailable && <th>Type</th>}
            {isDescriptionAvailable && <th>Description</th>}
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric, i) => (
            <tr key={i}>
              <td>{metric.name}</td>
              {metric.type && <td>{metric.type}</td>}
              {metric.description && <td>{metric.description}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
