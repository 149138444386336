import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { Markdown } from 'components/Markdown';
import { TextLink } from 'components/TextLink';
import { Pages } from 'e2eSelectors/pages';
import { Source } from 'types/Source';
import { CONNECT_DATA_URL } from 'utils/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  flex: css({
    display: 'flex',
    maxWidth: '400px',
    minWidth: '230px',
    flexGrow: 1,
    flexBasis: 0,
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    background: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.medium}`,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    transition: 'background 0.2s ease-in-out',
    gap: '10px',

    '&:hover': {
      background: theme.colors.background.secondary,
    },
  }),
  selectElement: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  titleWrapper: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  }),
  icon: css({
    width: '24px',
    height: 'auto',
    marginRight: '12px',
  }),
  title: css({
    fontSize: theme.typography.body.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.colors.text.maxContrast,
    marginBottom: 0,
    lineHeight: '20px',
  }),
  description: css({
    fontSize: theme.typography.bodySmall.fontSize,
    marginBottom: 0,
    lineHeight: '16px',
    color: theme.colors.text.secondary,
    a: {
      color: theme.colors.text.link,
    },
  }),
  button: css({
    justifyContent: 'center',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    padding: '0 3px',
    backgroundColor: theme.colors.background.primary,
  }),
});

interface CardOnSecondLevelProps {
  item: Source;
  onClick: () => void;
  footer?: React.JSX.Element;
}

export const CardOnSecondLevel = ({ item, onClick, footer }: CardOnSecondLevelProps) => {
  const styles = useStyles2(getStyles);

  // For the inner AWS card pick the externalURL if defined, and if not fallback to a crafted url with the integration id
  const useExternalURL = item.externalUrl !== undefined;
  const linkHref = useExternalURL ? item.externalUrl : `${CONNECT_DATA_URL}/${item.id}`;

  return (
    // if useExternalURL, open target in new tab and keep other opened with Grafana to not kill engagement since
    // the use is likely to be directed to another site (Google Form for example)
    <a
      className={styles.flex}
      href={linkHref}
      target={useExternalURL ? '_blank' : ''}
      rel="noreferrer"
      onClick={onClick}
    >
      <div className={styles.selectElement}>
        <div className={styles.titleWrapper}>
          {item.logo_url && <img src={item.logo_url} className={styles.icon} alt={`icon of ${item.name}`} />}
          <p className={styles.title} data-testid={Pages.Catalog.cardTitle(item.name)}>
            {item.name}
          </p>
        </div>
        {item.overview && (
          <Markdown
            className={styles.description}
            renderers={{
              a: ({ href, children }) => (
                <TextLink href={href ?? ''} external inline onClick={(e) => e.stopPropagation()} variant="bodySmall">
                  {children as string}
                </TextLink>
              ),
              img: () => null,
            }}
            markdownSnippet={item.overview}
          />
        )}
        {footer}
      </div>
      {item.installation && !item.has_update && (
        <Tooltip content={'Installed'}>
          <Button fill="outline" variant="success" className={styles.button} size="xs">
            <Icon name="check" color="inherit" size="xs" />
          </Button>
        </Tooltip>
      )}
      {item.has_update && (
        <Tooltip content={'Update available'}>
          <Button fill="outline" variant="primary" className={styles.button} size="xs">
            <Icon name="sync" color="inherit" size="xs" />
          </Button>
        </Tooltip>
      )}
    </a>
  );
};
