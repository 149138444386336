import _ from 'lodash';

import { ConfluentJobApiResponse, Resource, ConfluentJob, ScrapeJob } from 'models/api-models';

export enum ActionType {
  Edit,
  Add,
}

const resourceMapping: { [key: string]: string } = {
  kafka_ids: 'Kafka Cluster',
  connector_ids: 'Kafka Connector',
  ksql_ids: 'ksqlDB',
  schema_registry_ids: 'Schema Registry',
};

export const getReadableConfluentJob = (job: ScrapeJob | undefined): ConfluentJob => {
  if (typeof job === 'undefined') {
    return {
      name: '',
      resources: [],
      enabled: true,
    };
  }

  // We need to keep the signature of this function generic to ScrapeJob,
  // even though we know this job must be a ConfluentJobApiResponse.
  const confluentJob = job as ConfluentJobApiResponse;

  const readableJob: ConfluentJob = {
    name: confluentJob.name,
    resources: [],
    enabled: confluentJob.enabled,
    disabled_reason: confluentJob.disabled_reason,
  };

  Object.keys(confluentJob).forEach((resourceKey) => {
    const resourceType = resourceMapping[resourceKey];
    if (resourceType) {
      const currentResourcesByType = confluentJob[resourceKey as keyof ConfluentJobApiResponse];
      const resource: Resource[] = (currentResourcesByType as [])?.map((resourceId) => ({
        resourceType,
        resourceId,
      }));

      readableJob.resources.push(...resource);
    }
  });

  return readableJob;
};

const confluentResourceNameToIdMapping: { [key: string]: string } = {
  'Kafka Cluster': 'kafka_ids',
  'Kafka Connector': 'connector_ids',
  ksqlDB: 'ksql_ids',
  'Schema Registry': 'schema_registry_ids',
};

export const transformResources = (acc: { [key: string]: string[] }, currentJob: Resource) => {
  const { resourceType, resourceId } = currentJob;
  const id = confluentResourceNameToIdMapping[resourceType];
  if (typeof acc[id] === 'undefined') {
    acc[id] = [];
  }

  acc[id].push(resourceId.trim());
  return acc;
};
