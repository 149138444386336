import { css, cx } from '@emotion/css';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, Icon, Label, Modal, Switch, useStyles2 } from '@grafana/ui';

import { useSelectedSource } from 'api/integrations/hooks';
import { useAgentDetailsQuery } from 'api/integrations/integrationsRtqApi';
import { StatusMessage } from 'components/StatusMessage';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { Tabs } from 'pages/Source/TabsEnum';
import { RootState } from 'state';
import { changeTab } from 'state/source/slice';
import { Status } from 'utils/enums';

import { isLogsToggleDirty } from '../state/isLogsToggleDirty';
import {
  initIsFullMetricsSelected,
  initIsLogsEnabled,
  removeLoadingBar,
  toggleLogs,
  toggleMetricsSet,
  clickOpenExtendedMetricsModal,
} from '../state/slice';
import { useCollectorMode } from '../useCollectorMode';

import { FormPrompt } from './FormPrompt';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    label: css({
      maxWidth: 'unset',
    }),
    toggleGroup: css({
      marginBottom: 0,
    }),
    buttonGroup: css({
      display: 'flex',
      justifyContent: 'space-between',
    }),
    alignRight: css({
      justifyContent: 'flex-end',
    }),
    linkButton: css({
      background: 'none',
      border: 'none',
      color: theme.colors.text.link,
      cursor: 'pointer',
      padding: 0,
      fontSize: theme.typography.bodySmall.fontSize,
      outline: 'inherit',
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    }),
    infoIcon: css({
      marginRight: theme.spacing(0.5),
    }),
    alert: css({
      marginBottom: theme.spacing(1),
    }),
  };
};

export const FilteredMetricsToggle = () => {
  const styles = useStyles2(getStyles);
  const agentMode = useCollectorMode();
  const integrationId = useSelectedIntegrationId();
  const { data: agentDetails } = useAgentDetailsQuery({ integrationId, mode: agentMode });
  const {
    configuredParameters: { isFullMetricsSelected },
  } = useSelector((state: RootState) => state.agent);
  const dispatch = useDispatch();
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const areMetricsAvailable = sourceDetails?.metrics?.status === Status.Available;

  useEffect(() => {
    if (agentDetails) {
      dispatch(initIsFullMetricsSelected(agentDetails));
    }
  }, [dispatch, agentDetails]);

  const debouncedRemoveLoadingBar = debounce(() => dispatch(removeLoadingBar()), 1500);

  const FilteredMetricsLabel = (
    <Label
      className={styles.label}
      description="By default, we only include the metrics needed for the included pre-built dashboards and alerts."
    >
      Extended metrics
    </Label>
  );

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Field label={FilteredMetricsLabel} className={styles.toggleGroup}>
        <Switch
          value={Boolean(isFullMetricsSelected)}
          onChange={() => {
            dispatch(toggleMetricsSet(integrationId));
            debouncedRemoveLoadingBar();
          }}
          disabled={agentDetails === undefined}
        ></Switch>
      </Field>
      <button
        className={styles.linkButton}
        onClick={() => {
          if (!showModal) {
            setShowModal(true);
            dispatch(clickOpenExtendedMetricsModal(integrationId));
          }
        }}
      >
        <Icon className={styles.infoIcon} name="info-circle"></Icon>
        How to choose either default or extended metrics
      </button>
      <Modal
        title="What do extended metrics provide me?"
        isOpen={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
      >
        <p>
          Extended metrics include additional metrics beyond what is needed for the out-of-the-box dashboards and
          alerts. These can be used to create custom dashboards or to run more complex queries.
        </p>
        {areMetricsAvailable && (
          <p>
            You can access the list of default metrics that come with this integration, used for dashboards and alerts
            by clicking on the button below.
          </p>
        )}
        <div className={cx(styles.buttonGroup, { [styles.alignRight]: !areMetricsAvailable })}>
          {areMetricsAvailable && (
            <Button
              onClick={() => {
                setShowModal(false);
                dispatch(changeTab({ currentTab: Tabs.Metrics, integrationId }));
              }}
            >
              List of default metrics
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const LogsToggle = () => {
  const styles = useStyles2(getStyles);

  const selectedSource = useSelectedSource();
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const {
    configuredParameters: { isLogsEnabled },
  } = useSelector((state: RootState) => state.agent);
  const dispatch = useDispatch();
  const integrationId = useSelectedIntegrationId();

  const isDirty = useSelector((state: RootState) => isLogsToggleDirty(state));

  useEffect(() => {
    if (sourceDetails.installation?.configuration?.configurable_logs) {
      dispatch(initIsLogsEnabled(!sourceDetails.installation.configuration.configurable_logs.logs_disabled));
    } else if (sourceDetails.configuration_defaults && sourceDetails.configuration_defaults.enable_logs_toggle) {
      dispatch(initIsLogsEnabled(!sourceDetails.configuration_defaults.configurable_logs_defaults.logs_disabled));
    }
  }, [dispatch, sourceDetails]);

  const isOutdated = Boolean(selectedSource.has_update) && Boolean(selectedSource.installation);

  const howToSaveDescription = 'You can save your changes by clicking the "Save configuration selections" button.';

  return (
    <Field
      label="Include logs"
      className={styles.toggleGroup}
      description="Decide whether you want to send logs as part of this integration."
    >
      <>
        <FormPrompt
          // If the source is not installed, that means that this selection has not been saved yet.
          // In this case we should let the user leave the page without confirmation (i.e. not set this
          // dirty flag), because they didn't install the source - they might have just played around.
          confirmRedirect={isDirty && Boolean(selectedSource.installation)}
          onDiscard={() => {}}
          howToSaveDescription={howToSaveDescription}
        />
        {isOutdated && (
          <StatusMessage status="warning" className={styles.alert} role="status">
            Your installed version of this integration is outdated. Please update the integration first in order to
            change this configuration.
          </StatusMessage>
        )}
        <Switch
          value={Boolean(isLogsEnabled)}
          onChange={() => dispatch(toggleLogs(integrationId))}
          aria-label="Include logs"
          disabled={isLogsEnabled === undefined || isOutdated}
        />
      </>
    </Field>
  );
};
