import { LokiLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const HOSTED_LOGS_ID = 'hlInstanceId';

export const hostedLogs: Source = {
  id: HOSTED_LOGS_ID,
  name: 'Hosted logs',
  logo_url: LokiLogo,
  overview: `Your Grafana Cloud stack includes a logging service powered by Grafana Loki, our Prometheus-inspired log aggregation system. By using the exact same service discovery as Prometheus, Loki can systematically guarantee your logs have consistent labels with your metrics. This allows you to seamlessly switch between metrics and logs, preserving context and saving time.`,
  type: SourceType.HostedData,
  search_keywords: ['loki', 'logs'],
  categories: ['Logs'],
};
