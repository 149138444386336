import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OnboardingPluginError } from 'types';
import { ConnectionStatus, FilterJobStatus } from 'utils/enums';

export type SaasIntegrationsState = {
  connectionStatus: ConnectionStatus;
  error: OnboardingPluginError | undefined;
  selectedJobs: string[];
  jobStatusFilter: FilterJobStatus;
};

const SAAS_INTEGRATIONS_WITH_JOB = ['confluent-cloud', 'metrics-endpoint', 'supabase'] as const;

export type SaasIntegrationWithJobType = (typeof SAAS_INTEGRATIONS_WITH_JOB)[number];

export function isSaasIntegrationWithJob(integrationId: string): integrationId is SaasIntegrationWithJobType {
  return SAAS_INTEGRATIONS_WITH_JOB.includes(integrationId as SaasIntegrationWithJobType);
}

const initialState: SaasIntegrationsState = {
  error: undefined,
  connectionStatus: ConnectionStatus.None,
  selectedJobs: [],
  jobStatusFilter: FilterJobStatus.All,
};

const saasIntegrationSlice = createSlice({
  name: 'saasIntegrations',
  initialState,
  reducers: {
    setConnectionStatus(state: SaasIntegrationsState, action: PayloadAction<ConnectionStatus>) {
      state.connectionStatus = action.payload;
    },
    setError: (state: SaasIntegrationsState, action: PayloadAction<OnboardingPluginError | undefined>) => {
      state.error = action.payload;
    },
    selectJob: (state: SaasIntegrationsState, action: PayloadAction<string>) => {
      if (!state.selectedJobs.includes(action.payload)) {
        state.selectedJobs.push(action.payload);
      }
    },
    deselectJob: (state: SaasIntegrationsState, action: PayloadAction<string>) => {
      const elementIndex = state.selectedJobs.indexOf(action.payload);
      if (elementIndex !== -1) {
        state.selectedJobs.splice(elementIndex, 1);
      }
    },
    toggleSelectedJobs: (state: SaasIntegrationsState, action: PayloadAction<string>) => {
      const elementIndex = state.selectedJobs.indexOf(action.payload);
      if (elementIndex === -1) {
        state.selectedJobs.push(action.payload);
      } else {
        state.selectedJobs.splice(elementIndex, 1);
      }
    },
    resetSelectedJobs: (state: SaasIntegrationsState) => {
      state.selectedJobs = [];
    },
    setJobStatusFilter: (state: SaasIntegrationsState, action: PayloadAction<FilterJobStatus>) => {
      state.jobStatusFilter = action.payload;
    },
    resetState: (state: SaasIntegrationsState) => {
      state.error = undefined;
      state.connectionStatus = ConnectionStatus.None;
      state.selectedJobs = [];
      state.jobStatusFilter = FilterJobStatus.All;
    },
  },
});

export const {
  setConnectionStatus,
  setError,
  setJobStatusFilter,
  selectJob,
  deselectJob,
  toggleSelectedJobs,
  resetSelectedJobs,
  resetState,
} = saasIntegrationSlice.actions;

export default saasIntegrationSlice.reducer;
