import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { useGetDataSourcesQuery, useGetGrafanaDataSourcesQuery } from 'api/hostedGrafana/hostedGrafanaApi';
import { useIntegrationsQuery, useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { useQueryParam } from 'hooks/useQueryParam';
import { SemanticSearchCategoryType } from 'models/api-models';
import { Source } from 'types/Source';
import { JAVA_ID } from 'utils/consts';
import { SourceType } from 'utils/enums';
import { filterBySearchTerm } from 'utils/filterBySearchTerm';
import {
  alphaIntegrations,
  hostedDataIntegrations,
  localAgentIntegrations,
  javaLocalSources,
  cloudApps,
} from 'utils/localSources';

import { SortIntegration } from './Filters/filterTypes';
import { Category } from './types/Category';
import { FilterIntegration } from './types/FilterIntegration';
import { topPrioritySourceIds } from './utils/topPrioritySourceIds';

function getJavaUmbrellaSource(javaSources: Source[]): Source {
  const hasUpdate = javaSources && javaSources.some((integration) => integration.has_update);
  const installation =
    javaSources && javaSources.find((integration) => !isEmpty(integration.installation))?.installation;
  const search_keywords = javaSources.map((el) => (el.search_keywords || []).concat(el.name?.toLowerCase())).flat();
  const categories = javaSources.map((el) => el.categories || []).flat();

  return {
    id: JAVA_ID,
    slug: 'java',
    name: 'Java',
    logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/java.png',
    overview: ``,
    type: SourceType.AgentIntegration,
    has_update: hasUpdate,
    installation,
    search_keywords,
    categories,
  };
}

function filterBySearchCategory(sources: Source[], searchCategory: string | undefined): Source[] {
  if (!searchCategory) {
    return sources;
  }

  return sources.filter((integration) => {
    return integration.categories?.includes(searchCategory as SemanticSearchCategoryType);
  });
}

function filterByInstalled(sources: Source[], filterIntegrations: FilterIntegration): Source[] {
  if (filterIntegrations === FilterIntegration.Installed) {
    return sources.filter((integration) => !isEmpty(integration.installation));
  } else if (filterIntegrations === FilterIntegration.NewUpdates) {
    return sources.filter((integration) => integration.has_update);
  } else {
    return sources;
  }
}

function compareSourcesByPriority(a: Source, b: Source): number {
  const aIndex = topPrioritySourceIds.includes(a.id) ? topPrioritySourceIds.indexOf(a.id) : topPrioritySourceIds.length;
  const bIndex = topPrioritySourceIds.includes(b.id) ? topPrioritySourceIds.indexOf(b.id) : topPrioritySourceIds.length;

  return aIndex - bIndex;
}

export const useFilteredIntegrations = (sourcesByType: SourcesByType) => {
  const [search] = useQueryParam('search');
  const [searchCategory] = useQueryParam('searchCat');
  const [categoryFilter] = useQueryParam('cat');
  const [filterIntegrations] = useQueryParam('type');
  const [sort] = useQueryParam('sort');

  const filterIntegrationsValue = (filterIntegrations as FilterIntegration) ?? FilterIntegration.All;
  const sortValue = (sort as SortIntegration) ?? SortIntegration.MostPopular;

  return useMemo(() => {
    let result: Map<Category, Source[]> = new Map([
      // include Most popular here, so that it becomes the first category
      [Category.MostPopular, []],
      [Category.Integration, sourcesByType.integrations],
      [Category.DataSource, sourcesByType.dataSources],
      [Category.HostedData, sourcesByType.hostedDataIntegrations],
      [Category.CloudApp, sourcesByType.cloudApps],
    ]);

    const searchTerm = search?.toLowerCase() ?? '';

    for (let [category, sources] of result) {
      if (categoryFilter != null && category !== Category.MostPopular && categoryFilter !== category) {
        result.delete(category);
      } else {
        let filteredSources = sources;
        filteredSources = filterByInstalled(filteredSources, filterIntegrationsValue);
        filteredSources = filterBySearchTerm(filteredSources, searchTerm);
        filteredSources = filterBySearchCategory(filteredSources, searchCategory);

        if (sortValue === SortIntegration.Alphabetical) {
          filteredSources.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortValue === SortIntegration.ReversedAlphabetical) {
          filteredSources.sort((a, b) => b.name.localeCompare(a.name));
        } else {
          filteredSources.sort(compareSourcesByPriority);
        }

        result.set(category, filteredSources);
      }
    }

    // create most popular section
    // results are already filtered, so we don't have to filter again
    const includeMostPopularSection =
      searchTerm.length === 0 &&
      !searchCategory &&
      filterIntegrationsValue === FilterIntegration.All &&
      (categoryFilter === null || categoryFilter === undefined);

    if (includeMostPopularSection) {
      const mostPopular: Source[] = [];

      for (let [_, sources] of result) {
        sources.forEach((integration) => {
          if (topPrioritySourceIds.includes(integration.id)) {
            mostPopular.push(integration);
          }
        });
      }
      result.set(Category.MostPopular, mostPopular.sort(compareSourcesByPriority));
    } else {
      result.delete(Category.MostPopular);
    }

    return result;
  }, [categoryFilter, filterIntegrationsValue, search, searchCategory, sortValue, sourcesByType]);
};

const getUpdatableIntegrations = (sourcesByType: SourcesByType) => {
  const sources = [
    ...sourcesByType.integrations,
    ...sourcesByType.dataSources,
    ...sourcesByType.hostedDataIntegrations,
    ...sourcesByType.cloudApps,
  ];
  return sources.filter((source) => source.has_update);
};

export type SourcesByType = {
  integrations: Source[];
  dataSources: Source[];
  hostedDataIntegrations: Source[];
  cloudApps: Source[];
};

export const constructSourcesByType = (
  infraIntegrations: Source[] | undefined,
  javaIntegrations: Source[] | undefined,
  dataSources: Source[] | undefined,
  grafanaDataSources: Source[] | undefined
) => {
  const filteredGrafanaDataSources = grafanaDataSources?.filter(
    (grafanaDatasource) => !dataSources?.some((datasource) => datasource.id === grafanaDatasource.id)
  );

  const allJavaIntegrations = javaLocalSources.concat(javaIntegrations ?? []);

  return {
    integrations: [
      ...(infraIntegrations ?? []),
      ...alphaIntegrations,
      getJavaUmbrellaSource(allJavaIntegrations),
      ...localAgentIntegrations,
    ],
    dataSources: [...(dataSources ?? []), ...(filteredGrafanaDataSources ?? [])],
    hostedDataIntegrations,
    cloudApps,
  };
};

export const useCatalog = () => {
  const dataSourcesQuery = useGetDataSourcesQuery();
  const grafanaDataSourcesQuery = useGetGrafanaDataSourcesQuery();
  const integrationsQuery = useIntegrationsQuery({});
  const javaIntegrationsQuery = useJavaIntegrationsQuery({});

  const sourcesByType = useMemo(
    () =>
      constructSourcesByType(
        integrationsQuery.data,
        javaIntegrationsQuery.data,
        dataSourcesQuery.data,
        grafanaDataSourcesQuery.data
      ),
    [integrationsQuery.data, javaIntegrationsQuery.data, dataSourcesQuery.data, grafanaDataSourcesQuery.data]
  );

  const filteredIntegrations = useFilteredIntegrations(sourcesByType);
  const updatableIntegrations = useMemo(() => getUpdatableIntegrations(sourcesByType), [sourcesByType]);

  const relatedQueries: Map<Category, Array<UseQueryStateResult<any, any>>> = useMemo(
    () =>
      new Map([
        [Category.MostPopular, []],
        [Category.Integration, [integrationsQuery, javaIntegrationsQuery]],
        [Category.DataSource, [dataSourcesQuery, grafanaDataSourcesQuery]],
        [Category.HostedData, []],
        [Category.CloudApp, []],
      ]),
    [integrationsQuery, dataSourcesQuery, grafanaDataSourcesQuery, javaIntegrationsQuery]
  );

  return { filteredIntegrations, updatableIntegrations, relatedQueries };
};
