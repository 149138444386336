export const dataTypeOptions = [
  { label: 'Prometheus', value: 'prometheus' },
  { label: 'Graphite', value: 'graphite' },
];

export const snippetTypeOptions = [
  { label: 'Curl', value: 'curl' },
  { label: 'Python', value: 'python' },
  { label: 'Node', value: 'node' },
  { label: 'Ruby', value: 'ruby' },
  { label: 'Go', value: 'go' },
  { label: 'Java', value: 'java' },
];
