import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

interface Props {
  children: React.ReactNode;
}

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    backgroundColor: theme.colors.info.transparent,
    color: theme.colors.text.primary,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.colors.info.borderTransparent}`,
    borderRadius: theme.shape.radius?.default ?? '2px',
    marginTop: theme.spacing(0.5),
    p: {
      margin: 0,
      fontSize: theme.typography.body.fontSize,
    },
  }),
});

export const KubernetesBadge: React.FC<Props> = (props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.box}>
      <Icon name="info-circle" />
      {props.children}
    </div>
  );
};
