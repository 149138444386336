import React from 'react';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateCollectorAPI } from 'components/GenerateCollectorAPI/GenerateCollectorAPI';
import { RestartCollector } from 'features/agent-integrations/ConfigureIntegration/RestartCollector';
import { InstallAlloySection } from 'features/agent-integrations/InstallCollector/InstallAlloySection';
import { useCollectorToken } from 'hooks/useCollectorToken';
import { CollectorMode, PrometheusMethodForwarding, PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

import { RemoteWriteContent } from './RemoteWriteContent';
import { AdditionalOptions, getAlloySnippet, getSuccessMessage } from './helper';

export const ConfigureComponent = ({
  value,
  heading = 'Configure remote_write',
  method,
  remoteWrite,
}: {
  value: PrometheusOptions;
  heading?: string;
  method: PrometheusMethodForwarding;
  remoteWrite?: PrometheusRemoteWrite;
}) => {
  const { hmInstancePromId, hmInstancePromUrl } = useHostedDataDetailsWithFallback();
  const token = useCollectorToken();
  const configTitle = getSuccessMessage(true, remoteWrite);

  if (method === PrometheusMethodForwarding.Alloy) {
    return (
      <>
        <InstallAlloySection showOSSelection={true} />
        <li>
          <h2>{heading}</h2>
          <GenerateCollectorAPI
            shouldShowClipboard={true}
            config={getAlloySnippet(hmInstancePromUrl, hmInstancePromId.toString(), token)}
            instanceId={hmInstancePromId}
            configTitle={configTitle}
          />
          {token && <AdditionalOptions value={value} />}
        </li>
        <li>
          <RestartCollector collectorMode={CollectorMode.Alloy} />
        </li>
      </>
    );
  } else if (remoteWrite) {
    return (
      <li>
        <h2>{heading}</h2>
        <RemoteWriteContent value={value} remoteWrite={remoteWrite} />
      </li>
    );
  }
  return null;
};
