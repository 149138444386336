import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, useStyles2 } from '@grafana/ui';

import { Pages as e2eSelectors } from 'e2eSelectors/pages';
import { useQueryParam } from 'hooks/useQueryParam';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { AgentModeCompare } from './AgentModeCompare';
import { AgentModeViewAllButton } from './AgentModeViewAllButton';
import { Pages } from './Pages';
import { useSaveOptionAndNavigate } from './useSaveOptionAndNavigate';

export function AgentModeInfo(): React.JSX.Element {
  const styles = useStyles2(getStyles);
  const saveOptionAndNavigate = useSaveOptionAndNavigate();
  const [pageQueryParam] = useQueryParam('page');
  const { trackRudderStackEvent } = useRudderStack();

  return pageQueryParam ? (
    {
      [Pages.Alloy]: (
        <div data-testid={e2eSelectors.Source.AgentModeSelection.agentModeInfo}>
          <h4 className={styles.inlineHeader}>You are currently viewing Grafana Alloy instructions.</h4>
          <span className={styles.inlineCompare}>
            <AgentModeCompare />
          </span>
          <div className={styles.buttons}>
            <Button
              variant="primary"
              fill="outline"
              onClick={() => {
                saveOptionAndNavigate(Pages.Static);
                trackRudderStackEvent(RudderStackEvents.AgentModeSwitchToStatic, {});
              }}
            >
              Switch to Grafana Agent Static instructions
            </Button>
            <AgentModeViewAllButton title="View all options" variant="secondary" />
          </div>
        </div>
      ),
      [Pages.Static]: (
        <div data-testid={e2eSelectors.Source.AgentModeSelection.agentModeInfo}>
          <h4 className={styles.inlineHeader}>You are currently viewing Grafana Agent Static instructions.</h4>
          <Alert
            title="The scheduled end-of-life date for Grafana Agent is November 1, 2025."
            severity="warning"
            topSpacing={2}
          />
          <div className={styles.buttons}>
            <Button
              variant="primary"
              fill="outline"
              onClick={() => {
                saveOptionAndNavigate(Pages.Alloy);
                trackRudderStackEvent(RudderStackEvents.AgentModeSwitchToFlow, {});
              }}
            >
              Switch to Grafana Alloy instructions
            </Button>
            <AgentModeViewAllButton title="View all options" variant="secondary" />
            <span className={styles.inlineCompare}>
              <AgentModeCompare />
            </span>
          </div>
        </div>
      ),
      [Pages.Convert]: (
        <div data-testid={e2eSelectors.Source.AgentModeSelection.agentModeInfo}>
          <h4>You are currently viewing instructions to convert to Alloy.</h4>
          <div className={styles.buttons}>
            <AgentModeViewAllButton title="View other options" icon="arrow-left" variant="primary" />
          </div>
        </div>
      ),
      [Pages.Selection]: <></>,
    }[pageQueryParam as Pages]
  ) : (
    <></>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  inlineHeader: css({
    display: 'inline',
  }),
  inlineCompare: css({
    marginLeft: theme.spacing(2),
  }),
  buttons: css({
    marginTop: theme.spacing(0.8),
    marginBottom: theme.spacing(8),

    button: {
      marginRight: theme.spacing(1),
    },
  }),
});
