import React from 'react';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateCollectorAPI } from 'components/GenerateCollectorAPI';
import { useCollectorToken } from 'hooks/useCollectorToken';
import { PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

import { AdditionalOptions, getSuccessMessage } from './helper';

type Props = {
  configCode: string;
  value: PrometheusOptions;
};
export const RemoteWriteDirect = ({ configCode, value }: Props) => {
  const token = useCollectorToken();
  const { hmInstancePromId } = useHostedDataDetailsWithFallback();
  const configTitle = getSuccessMessage(false, PrometheusRemoteWrite.Direct);

  return (
    <li>
      <h2>Update Prometheus configuration</h2>
      <GenerateCollectorAPI
        shouldShowClipboard={true}
        config={configCode}
        instanceId={hmInstancePromId}
        configTitle={configTitle}
      />
      {token && <AdditionalOptions value={value} />}
    </li>
  );
};
