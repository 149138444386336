import { FaroIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const faro: Source = {
  id: 'frontend-observability-app',
  name: 'Frontend Observability',
  logo_url: FaroIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: '/a/grafana-kowalski-app/apps',
  search_keywords: [
    'faro',
    'frontend',
    'observability',
    'web',
    'app',
    'end user monitoring',
    'real user monitoring',
    'apm',
    'tracing',
    'rum',
    'eum',
    'dem',
    'digital experience',
    'session analysis',
    'javascript',
    'typescript',
    'browser',
    'js',
    'error tracking',
    'opentelemetry',
  ],
  categories: ['APM'],
};
