import React, { FC } from 'react';

import { OsBasedOptions, useCollectorSelector } from '@grafana-cloud/collector';
import { StatusMessage } from 'components/StatusMessage';
import { TextLink } from 'components/TextLink';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { INSTALL_TROUBLESHOOTING_DOCS_LINK } from 'utils/consts';
import { CollectorMode, RudderStackEvents } from 'utils/enums';

import { IntegrationConnectionErrorCause } from '../state/slice';
import { useCollectorMode } from '../useCollectorMode';

export const TestConnectionError: FC<{ error: IntegrationConnectionErrorCause }> = ({ error }) => {
  const { osValue } = useCollectorSelector((state) => state.collector.selectedOs);
  const sourceId = useSelectedIntegrationId();
  const { trackRudderStackEvent } = useRudderStack();

  const agentMode = useCollectorMode();
  const collector = agentMode === CollectorMode.AgentStatic ? 'Agent' : 'Alloy';
  const collectorInUrl = agentMode === CollectorMode.AgentStatic ? 'agent' : 'alloy';

  if (error === IntegrationConnectionErrorCause.Unexpected) {
    return <StatusMessage status="error">An unexpected error occurred. Please try again.</StatusMessage>;
  }

  let errorMessage: string;
  let docsLink = INSTALL_TROUBLESHOOTING_DOCS_LINK;

  switch (osValue) {
    case OsBasedOptions.Windows:
      docsLink += 'ing-windows';
      break;
    case OsBasedOptions.MacOs:
      docsLink += '-mac';
      break;
    default:
      docsLink += '-linux';
      break;
  }

  if (agentMode === CollectorMode.Alloy) {
    docsLink += '-alloy';
  }
  docsLink += '/';

  switch (error) {
    case IntegrationConnectionErrorCause.AgentCannotScrapeMetrics:
      docsLink += `#${collectorInUrl}-was-not-able-to-reach-your-application-prometheus-metrics-endpoint`;
      errorMessage = `${collector} was not able to reach the integration's metrics endpoint.`;
      break;
    case IntegrationConnectionErrorCause.NoMetricsFound:
      docsLink += '#no-metrics-were-found-for-this-integration';
      errorMessage = 'No metrics were found for this integration.';
      break;
    case IntegrationConnectionErrorCause.NoLogsFound:
      docsLink += `#${collectorInUrl}-was-not-able-to-collect-your-application-logs`;
      errorMessage = `${collector} was not able to collect your application logs.`;
      break;
  }

  return (
    <StatusMessage status="error">
      {errorMessage} For more information, see{' '}
      <TextLink
        href={docsLink}
        onClick={() =>
          trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionTroubleshootingDocsLinkClick, {
            integration_slug: sourceId,
            docs_link: docsLink,
          })
        }
        external
        inline
      >
        the documentation
      </TextLink>{' '}
      for troubleshooting.
    </StatusMessage>
  );
};
