import { KubernetesIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const K8S_MONITORING_ID = 'k8s-monitoring-app';

export const kubernetesMonitoring: Source = {
  id: K8S_MONITORING_ID,
  name: 'Kubernetes Monitoring',
  logo_url: KubernetesIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: '/a/grafana-k8s-app/configuration',
  search_keywords: ['k8s', 'container', 'deployment', 'scale'],
  categories: ['Servers and VMs', 'Developer Tools'],
};
