import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  noResults: css`
    text-align: center;
    padding: 50px 0;
    font-style: italic;
    color: ${theme.colors.text.secondary};
  `,
});

export const NoResults: FC = () => {
  const styles = useStyles2(getStyles);
  return <p className={styles.noResults}>No results matching your query were found.</p>;
};
