import React from 'react';

import { Alert } from '@grafana/ui';

import { isIntOrHeApiError } from 'models/api-models';
import { ErroneousQuery } from 'types';

export function AlertFromRtqError(props: ErroneousQuery) {
  const { error, refetch } = props;

  let message: string;

  if (typeof error === 'object' && 'data' in error) {
    if (isIntOrHeApiError(error.data)) {
      message = error.data.error.message;
    } else {
      message = JSON.stringify(error.data);
    }
  } else {
    message = JSON.stringify(error);
  }

  return <Alert severity="error" title={message} buttonContent={'Retry'} onRemove={() => refetch()} />;
}
