// based on TextLink implementation in Grafana, except omitted some rarely used props
import { css } from '@emotion/css';
import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { GrafanaTheme2, locationUtil, textUtil, ThemeTypographyVariantTypes } from '@grafana/data';
import { Icon, IconSize, useTheme2 } from '@grafana/ui';

interface TextLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'> {
  /** url to which redirect the user, external or internal */
  href: string;
  /** Specify if the link will redirect users to a page in or out Grafana */
  external?: boolean;
  /** True when the link will be displayed inline with surrounding text, false if it will be displayed as a block. Depending on this prop correspondent default styles will be applied */
  inline?: boolean;
  /** The default variant is 'body'. To fit another styles set the correspondent variant as it is necessary also to adjust the icon size */
  variant?: keyof ThemeTypographyVariantTypes;
  children: string;
}

const svgSizes: {
  [key in keyof ThemeTypographyVariantTypes]: IconSize;
} = {
  h1: 'xl',
  h2: 'xl',
  h3: 'lg',
  h4: 'lg',
  h5: 'md',
  h6: 'md',
  body: 'md',
  bodySmall: 'xs',
  code: 'md',
};

export const TextLinkFallback = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ href, external = false, inline = true, variant = 'body', children, ...rest }, ref) => {
    const validUrl = locationUtil.stripBaseFromUrl(textUtil.sanitizeUrl(href ?? ''));

    const theme = useTheme2();
    const styles = getLinkStyles(theme, inline, variant);
    const externalIcon = 'external-link-alt';

    return external ? (
      <a href={validUrl} ref={ref} target="_blank" rel="noreferrer" {...rest} className={styles}>
        {children}
        <Icon size={svgSizes[variant] || 'md'} name={externalIcon} />
      </a>
    ) : (
      <Link ref={ref} to={validUrl} {...rest} className={styles}>
        {children}
      </Link>
    );
  }
);

TextLinkFallback.displayName = 'TextLink';

const getLinkStyles = (theme: GrafanaTheme2, inline: boolean, variant?: keyof ThemeTypographyVariantTypes) => {
  return css([
    variant && {
      ...theme.typography[variant],
    },
    {
      alignItems: 'center',
      color: theme.colors.text.link,
      gap: '0.25em',
      display: 'inline-flex',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        color: theme.colors.text.link,
      },
    },
    inline && {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  ]);
};
