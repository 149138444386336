import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  const { colors, typography } = theme;

  return {
    container: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
    `,
    wrapper: css`
      display: flex;
      width: 100%;
      max-width: 1480px;
      margin: 0 auto;
      flex-direction: column;
      padding: 0 20px;
    `,
    fullWidthPage: css`
      margin-left: unset;
      margin-right: unset;
      max-width: unset;

      > div,
      ul {
        margin-left: unset;
        margin-right: unset;
      }
    `,

    tabsContent: css`
      color: ${colors.text.primary};

      h4 {
        font-size: ${typography.pxToRem(19)};
      }

      section:not(:last-of-type) {
        margin-bottom: 70px;
      }
    `,

    contentWrapper: css`
      margin-top: 40px;
    `,

    tabsBar: css`
      padding: 0 20px;

      > div,
      ul {
        max-width: 1440px;
        margin: 0 auto;
      }
    `,
    installedAlert: css`
      font-size: ${typography.pxToRem(14)};
    `,
    icon: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
    `,
    successColor: css`
      color: ${theme.colors.success.text};
    `,
    dangerColor: css`
      background-color: ${theme.colors.error.main};
      color: ${theme.colors.primary.contrastText};

      :focus,
      :hover {
        color: ${theme.colors.primary.contrastText};
        background-color: ${theme.colors.error.shade};
      }
    `,
  };
};
