import semver from 'semver';

import { config } from '@grafana/runtime';
/* eslint-disable-next-line no-restricted-imports */
import { TextLink as OriginalTextLink } from '@grafana/ui';

import { TextLinkFallback } from './TextLinkFallback';

function isTextLinkAvailable() {
  return semver.gte(config.buildInfo.version, '10.1.0');
}

export const TextLink = isTextLinkAvailable() ? OriginalTextLink : TextLinkFallback;
