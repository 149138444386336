import { css } from '@emotion/css';
import React from 'react';
import { useForm } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, useStyles2 } from '@grafana/ui';

import { AppO11yTracesTestButton } from './AppO11yTracesTestButton';

export const useServiceInfo = (
  { includeServiceInstanceId }: { includeServiceInstanceId: boolean } = { includeServiceInstanceId: false }
) => {
  const styles = useStyles2(getStyles);

  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      serviceName: undefined,
      serviceNamespace: undefined,
      serviceVersion: undefined,
      serviceInstanceId: undefined,
    },
  });

  const [serviceName, serviceNamespace, serviceVersion, serviceInstanceId] = watch([
    'serviceName',
    'serviceNamespace',
    'serviceVersion',
    'serviceInstanceId',
  ]);

  return {
    serviceName,
    serviceNamespace,
    serviceVersion,
    serviceInstanceId,

    form: (
      <div className={styles.serviceInfo}>
        <Field
          label="Service name"
          description="The name of your application"
          required
          invalid={!!errors.serviceName}
          error={errors.serviceName ? 'Enter a valid service name' : ''}
        >
          <Input id="serviceName" placeholder="Service name" {...register('serviceName', { required: true })} />
        </Field>

        <Field
          label="Service namespace"
          description="The namespace for your application, helps to distinguish a group of services"
        >
          <Input id="serviceNamespace" placeholder="Service namespace" {...register('serviceNamespace')} />
        </Field>

        <Field label="Service version" description="The version string of the service API or implementation">
          <Input id="serviceVersion" placeholder="Service version" {...register('serviceVersion')} />
        </Field>

        {includeServiceInstanceId && (
          <Field label="Service instance ID" description="Hostname or Kubernetes pod of your application">
            <Input id="serviceInstanceId" placeholder="Service instance ID" {...register('serviceInstanceId')} />
          </Field>
        )}
      </div>
    ),

    testButton: (
      <AppO11yTracesTestButton serviceName={serviceName} serviceNamespace={serviceNamespace} onClick={trigger} />
    ),
  };
};

function getStyles(theme: GrafanaTheme2) {
  return {
    serviceInfo: css`
      display: flex;
      flex-direction: column;
    `,
  };
}
