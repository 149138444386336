import { createAsyncThunk } from '@reduxjs/toolkit';

import { faro, isObject } from '@grafana/faro-web-sdk';

import { AppError } from 'utils/errors';

import { setGeneralError, setLocalError } from './slice';

// it's a factory for the action because if using action directly, redux spews warrnings
// if passed an Error object - it's unserializable
export const handleError = (appError: AppError, error: Error) => {
  console.error(appError.message, error);
  faro.api.pushError(isObject(error) ? new Error(JSON.stringify(error)) : error);
  return handleErrorAction(appError);
};
export const handleLocalError = (appError: AppError, error: Error) => {
  console.error(appError.message, error);
  faro.api.pushError(isObject(error) ? new Error(JSON.stringify(error)) : error);
  return handleErrorNonDestructiveAction(appError);
};

const handleErrorAction = createAsyncThunk('app/handleError', async (appError: AppError, thunkAPI) => {
  console.error(appError.message);
  thunkAPI.dispatch(setGeneralError(appError));
});

const handleErrorNonDestructiveAction = createAsyncThunk(
  'app/handleLocalError',
  async (appError: AppError, thunkAPI) => {
    thunkAPI.dispatch(setLocalError(appError));
  }
);
