// PYTHON EXAMPLE
export const pythonCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `import requests
import time

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

logs = {"streams": [{"stream": {"Language": "Python", "source": "Code"}, "values": [[str(int(time.time()) * 1000000000), "This is my log line", ]]}]}


requests.post(url = "${url}",
              auth=(USER_ID, API_KEY),
              json=logs,
              headers={"Content-Type": "application/json"},
)`;
};

// NODE JS EXAMPLE
export const nodejsCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `import fetch from 'node-fetch';
  
const logs = { streams: [{ stream: { Language: 'NodeJS', source: 'Code' }, values: [[(Math.floor(Date.now() / 1000) * 1000000000).toString(), "This is my log line"]]}] };

const response = fetch("${url}", {
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ${userId}:${!token ? 'your-api-key' : token}',
    },
    
    body: JSON.stringify(logs),
})`;
};

// CURL EXAMPLE
export const curlCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `curl -X POST \
-H "Content-Type: application/json" \
-H "Authorization: Bearer ${userId}:${!token ? 'your-api-key' : token}" \
-d '{"streams": [{"stream": {"Language": "Curl", "source": "Shell"},"values": [["'"$(($(date +%s)*1000000000))"'", "This is my log line"]]}]}' \
${url}`;
};

export const goCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `package main

import (
  "bytes"
  "net/http"
  "fmt"
  "os"
  "strconv"
  "time"
)

var (
  client = &http.Client{}
)

func main() {
  logs := []byte(fmt.Sprintf("{\"streams\": [{\"stream\": {\"Language\": \"Go\", \"source\": \"Code\" }, \"values\": [[\"%s\", \"This is my log line\"]]}]}", strconv.FormatInt(time.Now().UnixNano(), 10)))
  
  req, err := http.NewRequest("POST", "${url}", bytes.NewBuffer(logs))
  if err != nil {
    fmt.Println(err)
    os.Exit(1)
  }
  req.Header.Set("Content-Type", "application/json")
  req.SetBasicAuth("${userId}", "${token ? token : 'your-api-key'}")
  client.Do(req)  
}`;
};

// Java EXAMPLE
export const javaCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.nio.charset.StandardCharsets;
import java.time.Instant;

public class Main {
    public static void main(String[] args) throws Exception {
        String logs = "{\"streams\": [{\"stream\": {\"Language\": \"Java\", \"source\": \"Code\"}, \"values\": [[\"" + Instant.now().toEpochMilli() * 1000000 + "\", \"This is my log line\"]]}]}";

        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
                .uri(URI.create("${url}"))
                .header("Content-Type", "application/json")
                .header("Authorization", "Bearer ${userId}:${!token ? 'your-api-key' : token}")
                .POST(HttpRequest.BodyPublishers.ofString(logs, StandardCharsets.UTF_8))
                .build();

        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
    }
}`;
};

// Wget EXAMPLE
export const wgetCodeLoki = (userId: number, token: string | undefined, url: string) => {
  return `wget --method=POST \
--header="Content-Type: application/json" \
--header="Authorization: Bearer ${userId}:${!token ? 'your-api-key' : token}" \
--body-data='{"streams": [{"stream": {"Language": "Curl", "source": "Shell"},"values": [["'"$(($(date +%s)*1000000000))"'", "This is my log line"]]}]}' \
--output-document=- \
${url}`;
};

export const snippet = () => {
  return ` {
    "streams": [  # streams are used to organize and label queryable data
      {
        "stream": { # A stream is a sequence of log entries with shared characteristics or metadata
          "Language": "NodeJS", # First Log Label
          "source": "Code" # Second Log Label
        },
        "values": [ # values present your actual log information
          [
            "(Math.floor(Date.now() / 1000) * 1000000000).toString()", # timestamp (in nanoseconds)
            "This is my log line" # your log itself (string)
          ],
          [
            "(Math.floor(Date.now() / 1000) * 1000000000).toString()", # timestamp (in nanoseconds)
            "This is another log line" # Second log line (string)
          ],
        ]
      }
    ]
  }`;
};

export const cloudflareSnippet = (url: string, userId: number, token: string | undefined) => {
  return `import {Cloki} from '@miketako3/cloki'
  
  const logger = getLokiLogger({
    lokiHost: ${url},
    lokiUser: ${userId},
    lokiToken: "${!token ? 'your-api-key' : token}"
  });
  
  await logger.info({message: "Hello World!"});
  
  await logger.error({message: "Hello World!", error: error});
  
  // with additional labels
  await logger.info({message: "Hello World!"}, {foo: "bar"});`;
};
