export enum Scope {
  ACCESS_POLICIES = 'accesspolicies',
  ALERTS = 'alerts',
  LOGS = 'logs',
  METRICS = 'metrics',
  RULES = 'rules',
  TRACES = 'traces',
  PROFILES = 'profiles',
}

export type APIToken = {
  token: string | undefined;
  success: boolean;
  error: {
    data: {
      message: string;
    };
  } | null;
};
