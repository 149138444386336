import { PrometheusLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const HOSTED_PROMETHEUS_METRICS_ID = 'hmInstancePromId';

export const hostedPrometheusMetrics: Source = {
  id: HOSTED_PROMETHEUS_METRICS_ID,
  name: 'Hosted Prometheus metrics',
  logo_url: PrometheusLogo,
  overview: `Your Grafana Cloud stack includes a massively scalable, high-performance, and highly available Prometheus endpoint. Bring together the raw, unsampled metrics for all your applications and infrastructure, spread around the globe, in one place - with 13-months retention (Pro).`,
  type: SourceType.HostedData,
  search_keywords: ['prometheus'],
  categories: ['Time Series'],
};
