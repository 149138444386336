import { useMemo } from 'react';

import { config } from '@grafana/runtime';

import { useIntegrationsQuery, useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { ErroneousQuery } from 'types';
import { Source } from 'types/Source';
import { sourcesWithLocalInstructions } from 'utils/localSources';

export const useSelectedAgentOrSaasIntegration = () => {
  const integrations = useIntegrationsQuery({});
  const javaIntegrations = useJavaIntegrationsQuery({});
  const sourceId = useSelectedIntegrationId();
  const selectedSource = useMemo(
    () =>
      integrations.data?.filter((source) => source.id === sourceId)[0] ||
      javaIntegrations.data?.filter((source) => source.id === sourceId)[0] ||
      ({} as Source),
    [integrations.data, javaIntegrations.data, sourceId]
  );

  return selectedSource;
};

export const useInstalledIntegrations = (): {
  installedIntegrations: Source[];
  isLoading: boolean;
  erroneousQuery: ErroneousQuery | undefined;
} => {
  const integrations = useIntegrationsQuery({ installed: true });
  const javaIntegrations = useJavaIntegrationsQuery({ installed: true });

  const installedIntegrations = [...(integrations.data ?? []), ...(javaIntegrations.data ?? [])];

  const isLoading = integrations.isLoading || javaIntegrations.isLoading;

  let erroneousQuery: ErroneousQuery | undefined = undefined;

  [integrations, javaIntegrations].forEach((query) => {
    if (query.isError) {
      erroneousQuery = query;
    }
  });

  return { installedIntegrations, isLoading, erroneousQuery };
};

export const useSelectedSource = () => {
  const sourceId = useSelectedIntegrationId();

  const selectedLocalSource = sourcesWithLocalInstructions.find((source) => source.id === sourceId);
  const selectedAgentOrSaasIntegration = useSelectedAgentOrSaasIntegration();

  return selectedLocalSource ?? selectedAgentOrSaasIntegration;
};

export const useSourceLogoUrl = () => {
  const source = useSelectedSource();
  return config.theme2.isLight && source.light_logo_url ? source.light_logo_url : source.logo_url;
};
