import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

import { Category } from '../types/Category';

const getStyles = (theme: GrafanaTheme2) => ({
  row: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  noMargin: css`
    margin: 0px;
  `,
  topMargin: css`
    margin-top: 18px;
  `,
  leftMargin: css`
    margin-left: 8px;
  `,
  tooltipContainer: css`
    padding: 14px 10px;
  `,
  tooltipTitle: css`
    margin-bottom: 11px;
  `,
  tooltipText: css`
    color: ${theme.colors.text.secondary};
  `,
  tooltipLink: css`
    margin-top: 32px;
  `,
  anchorTag: css`
    color: ${theme.colors.text.link} !important;
  `,
  button: css`
    svg {
      height: 16px;
      width: 16px;
    }

    span {
      font-weight: ${theme.typography.fontWeightRegular};
      text-decoration-line: underline;
    }

    :hover {
      background-color: unset;
    }
  `,
});

const getHelperContent = {
  [Category.Integration]: {
    text: 'Use integrations to collect data in Grafana Cloud to monitor the health and performance of your infrastructure services.',
    tooltipTitle: 'Integrations',
    tooltipText:
      'Integrations bundle together instrumentation libraries and simple configurations, along with pre-built dashboards, alerts and recording rules. They enable you to quickly get your observability stack up and running!',
    linkTitle: 'Integrations documentation',
    linkUrl: 'https://grafana.com/docs/grafana-cloud/data-configuration/integrations/',
  },
  [Category.MostPopular]: null,
  [Category.HostedData]: {
    text: 'Forward your custom metrics, traces, logs and profiles to Grafana Cloud.',
    tooltipTitle: 'Custom data',
    tooltipText:
      "Ship your custom metrics, logs, traces and profiles to Grafana Cloud's scalable backend, and use Grafana for any custom use case, from querying to alerting. Unlock high-performant queries, centralized data management and long-term storage with no additional overhead.",
    linkTitle: 'Custom data documentation',
    linkUrl: 'https://grafana.com/docs/grafana-cloud/fundamentals/gs-metrics/',
  },
  [Category.DataSource]: {
    text: 'Use data sources to build visualizations and queries for your external service data without having to move the data around.',
    tooltipTitle: 'Data sources',
    tooltipText:
      'Data sources allow you to connect data from your external services and databases to Grafana, enabling you to explore it through queries, visualizations and alerting.',
    linkTitle: 'Data source documentation',
    linkUrl: 'https://grafana.com/docs/grafana/latest/datasources/',
  },
  [Category.CloudApp]: null,
};

type HelperTooltipProps = {
  tooltipTitle: string;
  tooltipText: string;
  linkTitle: string;
  linkUrl: string;
};

const AidTooltip: React.FC<HelperTooltipProps> = (props) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.tooltipContainer}>
      <div className={cx(styles.row, styles.tooltipTitle)}>
        <Icon name="info-circle" />
        <b className={styles.leftMargin}>{props.tooltipTitle}</b>
      </div>
      <small className={styles.tooltipText}>{props.tooltipText}</small>
      <div className={cx(styles.row, styles.tooltipLink)}>
        <Icon name="arrow-right" />
        <TextLink href={props.linkUrl} className={cx(styles.leftMargin, styles.anchorTag)} external>
          {props.linkTitle}
        </TextLink>
      </div>
    </div>
  );
};

export const CategoryAid: React.FC<{ category: Category }> = ({ category }) => {
  const styles = useStyles2(getStyles);
  const content = getHelperContent[category];

  if (content == null) {
    return <></>;
  } else {
    return (
      <div className={cx(styles.row, styles.topMargin)}>
        <p className={styles.noMargin}>{content.text}</p>
        <Tooltip content={<AidTooltip {...content} />} placement="right-start" interactive={true}>
          <Button icon="info-circle" variant="primary" fill="text" size="sm" className={styles.button}>
            {content.tooltipTitle}
          </Button>
        </Tooltip>
      </div>
    );
  }
};
