import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';

import { FormErrors } from 'utils/consts';
import { validateURL } from 'utils/misc';

export const MetricsURLField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Field label="Scrape Job URL" invalid={errors.url ? true : undefined} error={errors.url?.message as string}>
      <Input
        placeholder="https://my-example-url.com:9999/metrics"
        id="url"
        data-testid="url-input-field"
        {...register('url', {
          required: {
            value: true,
            message: FormErrors.REQUIRED_FIELD,
          },
          validate: {
            isHTTP: validateURL,
          },
        })}
      />
    </Field>
  );
};
