import { GcpIcon } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

import { CSP_APP_ID } from './cspApp';

const GCP_ID = 'gcp';

export const gcp: Source = {
  id: GCP_ID,
  name: 'GCP',
  logo_url: GcpIcon,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: `/a/${CSP_APP_ID}/${GCP_ID}`,
  search_keywords: ['google', 'cloud', 'observability', 'monitoring', 'stackdriver'],
  categories: ['Cloud Provider', 'Logs', 'Servers and VMs'],
};
