import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { handleKeyDown } from 'utils/aria';

import { OptionValue } from './SelectGroup';

export const getStyles = (theme: GrafanaTheme2) => ({
  selectElement: css({
    position: 'relative',
    width: '333px',
    minWidth: '230px',
    flexGrow: 1,
    padding: '24px 30px 24px 24px',
    background: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.medium}`,
    marginRight: '16px',
    cursor: 'pointer',
    transition: 'background 0.2s ease-in-out',

    '&:hover': {
      background: theme.colors.background.secondary,
    },

    '&:last-child': {
      marginRight: 0,
    },
  }),
  fixedWidth: css({
    flexGrow: 0,
  }),
  mediumSize: css({
    width: '230px',
    height: '104px',
  }),
  selected: css({
    padding: '23px 30px 23px 23px',
    border: `2px solid ${theme.colors.primary.main}`,
    background: theme.colors.background.secondary,
  }),
  icon: css({
    width: '24px',
    height: 'auto',
    marginRight: '12px',
  }),
  titleWrapper: css({
    display: 'flex',
    alignItems: 'start',
    marginBottom: '15px',
  }),
  title: css({
    fontSize: theme.typography.body.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.colors.text.maxContrast,
    marginBottom: 0,
    lineHeight: '20px',
  }),
  description: css({
    fontSize: theme.typography.bodySmall.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 0,
    lineHeight: '16px',
    color: theme.colors.text.secondary,
  }),
  checkbox: css({
    display: 'block',
    width: '24px',
    minWidth: '24px',
    height: '24px',
    marginLeft: '15px',
    position: 'absolute',
    top: '5px',
    right: '5px',
  }),
  checked: css({
    position: 'relative',
    color: theme.colors.primary.main,
  }),
});

interface CardElementProps<T = any> {
  option: OptionValue;

  isSelected?: boolean;
  onClick?: (value?: T) => void;
  size?: 'md' | 'base' | 'base-fixed';
}

export const CardElement = ({ option, isSelected, onClick, size }: CardElementProps) => {
  const styles = useStyles2(getStyles);

  const isMediumSize = size === 'md';
  const isFixedSize = size === 'base-fixed';

  return (
    <div
      onClick={onClick}
      onKeyDown={handleKeyDown(onClick)}
      tabIndex={0}
      role="button"
      className={cx(
        styles.selectElement,
        { [styles.selected]: isSelected },
        { [styles.mediumSize]: isMediumSize },
        { [styles.fixedWidth]: isFixedSize }
      )}
    >
      <div className={styles.titleWrapper}>
        {option.icon && <img src={option.icon} className={styles.icon} alt={`icon of ${option.value}`} />}
        <p className={styles.title}>{option.label}</p>
      </div>
      {option.description && <p className={styles.description}>{option.description}</p>}
      <span className={styles.checkbox}>
        {isSelected && <Icon className={styles.checked} name="check" size="xl" />}
      </span>
    </div>
  );
};
