import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { SelectGroup } from 'components/SelectGroup';
import { useApiToken } from 'hooks/useApiToken';
import { DEFAULT_LOKI_URL, GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  link: css`
    color: ${theme.colors.text.link};
    &:hover {
      cursor: pointer;
    }
  `,
});

enum LokiOptions {
  Standalone = 'standalone',
  Kubernetes = 'kubernetes',
}

const configScripts = (url: string) => {
  const stripUrl = url.replace(/(^\w+:|^)\/\//, '');
  const constructUrl = url ? `${stripUrl}/api/prom/push` : DEFAULT_LOKI_URL;

  return {
    [LokiOptions.Standalone]: `server:
  http_listen_port: 0
  grpc_listen_port: 0
        
positions:
  filename: /tmp/positions.yaml
        
client:
  url: https://${GRAFANA_EXAMPLE_USER}:${GRAFANA_EXAMPLE_API}@${constructUrl}
        
scrape_configs:
- job_name: system
  static_configs:
  - targets:
      - localhost
    labels:
      job: varlogs
      __path__: /var/log/*.log`,
    [LokiOptions.Kubernetes]:
      `curl -fsS https://raw.githubusercontent.com/grafana/loki/master/tools/promtail.sh | sh -s ` +
      `${GRAFANA_EXAMPLE_USER} ${GRAFANA_EXAMPLE_API} ${stripUrl} default | kubectl apply --namespace=default -f  -`,
  };
};

const useCaseOptions = [
  { label: 'Send logs from a standalone host', value: LokiOptions.Standalone, config: 'string' },
  { label: 'Send logs from a Kubernetes cluster', value: LokiOptions.Kubernetes, config: 'string' },
];

export const HostedLogs = () => {
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const apiToken = useApiToken();
  const [useCase, setUseCase] = useState<LokiOptions>(LokiOptions.Standalone);

  const isStandalone = LokiOptions.Standalone === useCase;
  const successMessage =
    'Your configuration, complete with your API key, has been generated below. Copy and paste this code to <code>promtail/config.yaml</code> to send data using promtail.';
  const styles = useStyles2(getStyles);

  return (
    <ol>
      <li>
        <h2>Choose your use case</h2>

        <SelectGroup options={useCaseOptions} value={useCase} onChange={(val) => setUseCase(val)} size="md" />
      </li>
      <li>
        <h2>Configure promtail to send logs to your Grafana Cloud</h2>
        <p>
          <a
            className={styles.link}
            href="https://grafana.com/docs/grafana-cloud/data-configuration/logs/collect-logs-with-promtail/"
            target="_blank"
            rel="noreferrer"
          >
            See documentation
          </a>{' '}
          for gathering logs from a Linux host using Promtail.
        </p>
        <GenerateAPI
          config={configScripts(hostedDataDetails.hlInstanceUrl)[useCase]}
          instanceId={hostedDataDetails.hlInstanceId}
          successMessage={successMessage}
        />
      </li>
      {isStandalone && apiToken.token && (
        <li>
          <h2>Run promtail via Docker</h2>
          <Clipboard
            title="Use the following code snippet to run promtail via Docker."
            code='docker run --name promtail --volume "$PWD/promtail:/etc/promtail" --volume "/var/log:/var/log" grafana/promtail:main -config.file=/etc/promtail/config.yaml'
            clipboardButtonType="below"
          />
        </li>
      )}
    </ol>
  );
};
