import React, { FC, useEffect } from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { useDispatch } from 'hooks/useDispatch';

import { getModalFooterStyles } from '../ModalFooterStyles';
import { openAgentModal } from '../state/slice';

import ConnectionTestButton from './ConnectionTestButton';
import TestStatus from './TestStatus';

export const AgentModalFooter: FC<{ isDisabled: boolean; onClose: () => void }> = ({ isDisabled, onClose }) => {
  const dispatch = useDispatch();
  const styles = useStyles2(getModalFooterStyles);

  useEffect(() => {
    dispatch(openAgentModal());
  }, [dispatch]);

  return (
    <div className={styles.footer}>
      <TestStatus />

      <div className={styles.buttonRow}>
        <ConnectionTestButton isDisabled={isDisabled} />
        <Button variant="secondary" onClick={onClose}>
          Proceed to install integration
        </Button>
      </div>
    </div>
  );
};
