export const STATIC_LINK = 'https://grafana.com/docs/agent/latest/static/';
export const ALLOY_LINK = 'https://grafana.com/docs/alloy/latest/';
export const ALLOY_INSTALL_LINK = 'https://grafana.com/docs/alloy/latest/get-started/install/';
export const CONVERT_LINK = 'https://grafana.com/docs/alloy/latest/tasks/migrate/from-static/';

export const ALLOY_SYNTAX_LINK = 'https://grafana.com/docs/alloy/latest/concepts/configuration-syntax/';
export const STATIC_CONFIG_LINK = 'https://grafana.com/docs/agent/latest/static/configuration/create-config-file/';
export const K8S_MANUAL_CONFIG_LINK =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/';

export const K8S_INTEGRATION_CONFIG_EXTENSION_ID = 'grafana/grafana-k8s-app/helm-chart-integration-snippets/v1';
