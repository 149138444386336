import React, { FC } from 'react';

import { SelectableValue } from '@grafana/data';
import { Icon, Select } from '@grafana/ui';

export const ASCENDING_SORT_VALUE = 'alpha-asc';
const DESCENDING_SORT_VALUE = 'alpha-desc';

const SORT_OPTIONS = [
  // We use this unicode 'en dash' character (U+2013), because it looks nicer
  // than simple dash in this context. This is also used in the response of
  // the `sorting` endpoint, which is used in the search dashboard page.
  { label: 'Sort by A\u2013Z', value: ASCENDING_SORT_VALUE },
  { label: 'Sort by Z\u2013A', value: DESCENDING_SORT_VALUE },
];

type SortPickerProps = {
  onChange: (sortValue: SelectableValue) => void;
  isAscending: boolean;
  className?: string;
};

export const SortPicker: FC<SortPickerProps> = ({ onChange, isAscending, className }) => (
  <Select
    width={28}
    onChange={onChange}
    value={
      SORT_OPTIONS.find((opt) =>
        isAscending ? opt.value === ASCENDING_SORT_VALUE : opt.value === DESCENDING_SORT_VALUE
      ) ?? null
    }
    options={SORT_OPTIONS}
    aria-label="Sort"
    prefix={<Icon name={isAscending ? 'sort-amount-up' : 'sort-amount-down'} />}
    className={className}
  />
);
