import { APIAlloyPlatforms, AlloyPlatforms } from './types';

export function transformAlloyPlatformWithBlocks<Blocks>(
  apiAlloyPlatforms: APIAlloyPlatforms<Blocks> | undefined
): AlloyPlatforms<Blocks> | undefined {
  if (apiAlloyPlatforms === undefined) {
    return undefined;
  } else if ('all' in apiAlloyPlatforms) {
    return { type: 'unified', ...apiAlloyPlatforms };
  } else {
    return { type: 'specific', ...apiAlloyPlatforms };
  }
}
