export const snippetTypeOptions = [
  { label: 'Curl', value: 'curl' },
  { label: 'Python', value: 'python' },
  { label: 'Node', value: 'node' },
  { label: 'Wget', value: 'wget' },
  { label: 'Go', value: 'go' },
  { label: 'Java', value: 'java' },
];

export const platformTypeOptions = [
  { label: 'via HTTP', value: 'http' },
  { label: 'via Logging Library (TypeScript)', value: 'logging library' },
];
