/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';

import { dashboardsApi } from 'api/dashboards/dashboardsApi';
import { grafanaComApi } from 'api/grafanaCom/grafanaComApi';
import { hostedExportersApi } from 'api/hostedExporters/hostedExportersApi';
import { hostedGrafanaApi } from 'api/hostedGrafana/hostedGrafanaApi';
import { integrationsApi } from 'api/integrations/integrationsRtqApi';
import { orgInfoApi } from 'api/orgInfo/orgInfoApi';
import agentSlice from 'features/agent-integrations/state/slice';
import appSlice from 'features/app/state/slice';
import catalogSlice from 'features/catalog/state/slice';
import integrationsSlice from 'features/integrations-page/state/slice';
import dashboardsSlice from 'state/dashboards/slice';
import saasIntegrationReducer from 'state/saasIntegration/slice';
import sourceSlice from 'state/source/slice';

import { rtqErrorReporter } from './rtqErrorReporterMiddleware';
import { userTracking } from './userTracking';

export const store = configureStore({
  reducer: {
    app: appSlice,
    catalog: catalogSlice,
    saasIntegrations: saasIntegrationReducer,
    dashboards: dashboardsSlice,
    agent: agentSlice,
    source: sourceSlice,
    [hostedExportersApi.reducerPath]: hostedExportersApi.reducer,
    integrations: integrationsSlice,
    [hostedGrafanaApi.reducerPath]: hostedGrafanaApi.reducer,
    [grafanaComApi.reducerPath]: grafanaComApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    [orgInfoApi.reducerPath]: orgInfoApi.reducer,
    [dashboardsApi.reducerPath]: dashboardsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      hostedExportersApi.middleware,
      hostedGrafanaApi.middleware,
      grafanaComApi.middleware,
      integrationsApi.middleware,
      orgInfoApi.middleware,
      dashboardsApi.middleware,
      rtqErrorReporter,
      userTracking,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
