import React, { useState, FC } from 'react';

import { SelectGroup } from 'components/SelectGroup';
import { useQueryParam } from 'hooks/useQueryParam';
import { PrometheusMethodForwarding, PrometheusOptions, PrometheusRemoteWrite } from 'utils/enums';

import { ConfigureComponent } from './ConfigureComponent';

export const UseCase: FC = () => {
  const [remoteWrite] = useQueryParam('remoteWrite');

  const useCaseOptions = [
    {
      label: 'Send metrics from a single Prometheus instance',
      value: PrometheusOptions.SingleInstance,
      component: ConfigureComponent,
      props: {
        value: PrometheusOptions.SingleInstance,
        remoteWrite: remoteWrite as PrometheusRemoteWrite,
        method: PrometheusMethodForwarding.RemoteWrite,
      },
    },
    {
      label: 'Send metrics from multiple Prometheus instances',
      value: PrometheusOptions.MultipleInstances,
      component: ConfigureComponent,
      props: {
        value: PrometheusOptions.MultipleInstances,
        remoteWrite: remoteWrite as PrometheusRemoteWrite,
        method: PrometheusMethodForwarding.RemoteWrite,
      },
    },
    {
      label: 'Send metrics from multiple HA (high-availability) Prometheus instances',
      value: PrometheusOptions.MultipleInstancesHa,
      component: ConfigureComponent,
      props: {
        value: PrometheusOptions.MultipleInstancesHa,
        remoteWrite: remoteWrite as PrometheusRemoteWrite,
        method: PrometheusMethodForwarding.RemoteWrite,
      },
    },
  ];

  const [useCase, setUseCase] = useState<string | undefined>(PrometheusOptions.SingleInstance);

  const selectedOption = useCaseOptions.find((option) => option.value === useCase);

  const Component = selectedOption?.component;
  return (
    <>
      <li>
        <h2>Choose your use case for forwarding metrics</h2>
        <SelectGroup options={useCaseOptions} value={useCase} onChange={(val) => setUseCase(val)} size="md" />
      </li>
      {selectedOption && Component && <Component {...selectedOption.props} />}
    </>
  );
};
