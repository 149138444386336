// The API in this file is created with RTQ

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { PLUGIN_PROXY_URL } from 'api/utils';
import { HostedDataDetails, InstanceConnectionsDetails } from 'models/api-models';
import { HOSTED_DATA_DETAILS_FALLBACK } from 'utils/consts';
import { PlanType } from 'utils/enums';

export const DEV_HOSTED_DATA_DETAILS: HostedDataDetails = {
  orgSlug: 'raintank',
  orgId: 101,
  slug: 'k3d',
  url: 'http://grafana.k3d.localhost:9999',
  id: 1,
  trial: 102,
  trialExpiresAt: 'tomorrow',
  hmInstancePromId: 103,
  hlInstanceId: 104,
  hmInstanceGraphiteId: 105,
  htInstanceId: 106,
  hpInstanceId: 107,
  hlInstanceUrl: 'hlInstanceUrl',
  hmInstanceGraphiteUrl: 'hmInstanceGraphiteUrl',
  hmInstancePromUrl: 'hmInstancePromUrl',
  htInstanceUrl: 'htInstanceUrl',
  hpInstanceUrl: 'hpInstanceUrl',
  hmInstanceGraphiteType: 'hmInstanceGraphiteType',
  plan: PlanType.Free,
  regionSlug: 'dev-us',
};

export const DEV_INSTANCE_CONNECTION_DETAILS: InstanceConnectionsDetails = {
  privateConnectivityInfo: {
    tenants: [
      {
        type: 'service',
        id: 1,
        info: {
          serviceName: 'service1',
          privateDNS: 'service1.private',
        },
      },
      {
        type: 'service',
        id: 2,
        info: {
          serviceName: 'service2',
          privateDNS: 'service2.private',
        },
      },
    ],
    otlp: {
      serviceName: 'otlp',
      privateDNS: 'otlp.private',
    },
  },
  appPlatform: {
    url: 'appPlatformUrl',
    caData: 'caData',
  },
  influxUrl: 'influxUrl1',
  otlpHttpUrl: 'otlpHttpUrl',
};

export const grafanaComApi = createApi({
  reducerPath: 'grafanaComApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${PLUGIN_PROXY_URL}/grafanacom-api/`,
  }),
  endpoints: (builder) => ({
    getHostedDataDetails: builder.query<HostedDataDetails, void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        if (process.env.NODE_ENV === 'development') {
          return { data: DEV_HOSTED_DATA_DETAILS };
        }

        const result = await baseQuery('instances');
        if (result.data) {
          return { data: result.data as HostedDataDetails };
        } else if (result.error) {
          return { error: result.error };
        } else {
          return { error: { error: `unknown error in getHostedDataDetails`, status: 'CUSTOM_ERROR' } };
        }
      },
    }),
    getInstanceConnections: builder.query<InstanceConnectionsDetails, void>({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        if (process.env.NODE_ENV === 'development') {
          return { data: DEV_INSTANCE_CONNECTION_DETAILS };
        }

        const result = await baseQuery(`instances/connections`);
        if (result.data) {
          return { data: result.data as InstanceConnectionsDetails };
        } else if (result.error) {
          return { error: result.error };
        } else {
          return { error: { error: `unknown error in getInstanceConnections`, status: 'CUSTOM_ERROR' } };
        }
      },
    }),
  }),
});

export const useHostedDataDetailsWithFallback = () => {
  /*
    Using `useQueryState()` instead of `useGetHostedDataDetailsQuery()` results in
    not subscribing and not trying to fetch the data from the API.
    Less subscribers means less performance overhead, see
    https://github.com/reduxjs/redux-toolkit/discussions/2357
    We can do this, because we're sure we actually fetch the data once during
    the App's lifetime, and this data doesn't change.
  */
  return grafanaComApi.endpoints.getHostedDataDetails.useQueryState().data ?? HOSTED_DATA_DETAILS_FALLBACK;
};

export const { useGetHostedDataDetailsQuery, useGetInstanceConnectionsQuery } = grafanaComApi;
