import { cx } from '@emotion/css';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PluginPage } from '@grafana/runtime';
import { Alert, TabContent, useStyles2 } from '@grafana/ui';

import { useSourceLogoUrl } from 'api/integrations/hooks';
import { useIntegrationsQuery, useJavaIntegrationsQuery } from 'api/integrations/integrationsRtqApi';
import { AlertFromRtqError } from 'components/AlertFromRtqError';
import { Update } from 'components/Source/Update';
import { Spinner } from 'components/Spinner';
import { getCurrentTabComponent } from 'pages/Source/Tabs';
import { RootState } from 'state';
import { isAdmin } from 'utils/user';

import { UninstallModal } from './Header/UninstallModal';
import { useModalHandling } from './Header/UninstallModal/modalUtils';
import { getStyles } from './Source.styles';
import { SourceActionButtons } from './SourceActionButtons';
import { SourceTabs } from './SourceTabs';
import { useIntegrationFetching } from './sourceAPI';
import { sourceLabels } from './sourceLabels';

export const Source = () => {
  const styles = useStyles2(getStyles);
  const { selectedSource, sourceDetails, isSourceDetailsFetchPending, isRtqFetchError, shouldMakeRequests } =
    useIntegrationFetching();
  const infoItems = sourceLabels({ selectedSource });

  const {
    showModal,
    setShowModal,
    isIntegrationDeletionSuccessful,
    handleIntegrationSuccess,
    isIntegrationDeletionFailed,
    handleIntegrationError,
  } = useModalHandling();

  const integrationsQuery = useIntegrationsQuery({});
  const javaIntegrationsQuery = useJavaIntegrationsQuery({});

  const currentTab = useSelector((state: RootState) => state.source.currentTab);
  const ActiveTab = getCurrentTabComponent(selectedSource, sourceDetails, currentTab);

  const logo_url = useSourceLogoUrl();
  const pageNav = useMemo(() => ({ text: selectedSource.name, img: logo_url }), [selectedSource, logo_url]);

  if (!isAdmin()) {
    return (
      <PluginPage pageNav={pageNav}>
        <Alert title="Permission denied">
          Only users with the role &quot;admin&quot; can add new connections. To add a new connection, contact your
          Grafana administrator.
        </Alert>
      </PluginPage>
    );
  }

  const shouldShowSpinner = isSourceDetailsFetchPending && !isRtqFetchError;

  return shouldShowSpinner ? (
    <PluginPage pageNav={pageNav}>
      <Spinner message="Please wait..." />
    </PluginPage>
  ) : (
    <PluginPage
      pageNav={pageNav}
      info={infoItems.map((item) => ({
        label: item.label,
        value: <div key={item.label}>{item.value}</div>,
      }))}
      actions={
        selectedSource.installation !== undefined ? (
          <SourceActionButtons
            sourceDetails={sourceDetails}
            shouldMakeRequests={shouldMakeRequests}
            showModal={() => setShowModal(true)}
          />
        ) : undefined
      }
    >
      <div className={styles.container}>
        {shouldMakeRequests && integrationsQuery.isError ? (
          <AlertFromRtqError {...integrationsQuery} />
        ) : shouldMakeRequests && javaIntegrationsQuery.isError ? (
          <AlertFromRtqError {...javaIntegrationsQuery} />
        ) : (
          <>
            <Update selectedIntegration={selectedSource} />

            {isIntegrationDeletionSuccessful && (
              <Alert severity="success" title="">
                Integration removed successfully!
              </Alert>
            )}
            {isIntegrationDeletionFailed && (
              <Alert severity="error" title="">
                Failed to remove integration.
              </Alert>
            )}

            <SourceTabs selectedSource={selectedSource} sourceDetails={sourceDetails} />

            {showModal && selectedSource.installation !== undefined && (
              <UninstallModal
                selectedIntegration={selectedSource}
                onClose={() => setShowModal(false)}
                onIntegrationSuccessfulDeletion={handleIntegrationSuccess}
                onIntegrationFailedDeletion={handleIntegrationError}
              />
            )}

            <div className={cx(styles.wrapper, styles.fullWidthPage)}>
              <div className={styles.contentWrapper}>
                <TabContent className={styles.tabsContent}>{ActiveTab && <ActiveTab />}</TabContent>
              </div>
            </div>
          </>
        )}
      </div>
    </PluginPage>
  );
};
