import { css } from '@emotion/css';
import React from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, useStyles2 } from '@grafana/ui';

import { useSelectedSource } from 'api/integrations/hooks';
import { Markdown } from 'components/Markdown';
import { TextLink } from 'components/TextLink';
import { Pages } from 'e2eSelectors/pages';
import { RootState } from 'state';
import { Status } from 'utils/enums';
import { isAgentOrSaasIntegration } from 'utils/misc';

import { DashboardScreenshots } from './Dashboards/DashboardScreenshots';
import { ExternalLinks } from './ExternalLinks';

const getStyles = (theme: GrafanaTheme2) => {
  const { colors, spacing } = theme;

  return {
    aboutInfo: css`
      a {
        color: ${colors.text.link};
      }

      li {
        margin-left: ${spacing(2)};
        p {
          margin-bottom: 0;
        }
      }
    `,

    content: css`
      display: flex;
      width: 30%;
      margin-left: ${spacing(3)};
      flex-direction: column;
      min-width: 300px;
    `,
    link: css`
      color: ${colors.text.link};
      margin-left: 5px;
    `,
  };
};

export const ConnectorOverview = () => {
  const styles = useStyles2(getStyles);
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const sourceScreenshots = sourceDetails.dashboards?.screenshots || [];
  const areScreenshotsAvailable = sourceScreenshots && sourceScreenshots.length > 0;
  const selectedSource = useSelectedSource();
  const areDashboardsAvailable = sourceDetails?.dashboards?.status === Status.Available;

  return (
    <>
      <section>
        <h3 data-testid={Pages.Source.Overview.title}>
          About this {isAgentOrSaasIntegration(selectedSource) ? 'integration' : 'service'}
        </h3>
        <hr />
        {selectedSource.overview && <Markdown className={styles.aboutInfo} markdownSnippet={selectedSource.overview} />}
      </section>
      {areDashboardsAvailable && (
        <section>
          <h3>Dashboards</h3>
          <hr />
          {areScreenshotsAvailable && (
            <p>
              Pre-built dashboards are included in this integration. Metrics included in this integration are also
              available for you to explore and build your own dashboards and alerts.
            </p>
          )}
          {sourceScreenshots?.length > 0 && <DashboardScreenshots screenshots={sourceScreenshots} />}

          {!areScreenshotsAvailable && (
            <Alert
              title="This integration bundles the Grafana Agent, tailored Grafana dashboards, and alerting defaults to get out of box observability of this system"
              severity="info"
            >
              Dashboard previews are coming soon. In the mean time, you can install the integration and see the
              dashboards for yourself, or
              <TextLink
                href="https://grafana.com/docs/grafana-cloud/integrations/"
                className={styles.link}
                external
                inline
              >
                learn more about integrations on Grafana.com
              </TextLink>
            </Alert>
          )}
        </section>
      )}
      <section>
        <h3>Help</h3>
        <hr />
        <ExternalLinks />
      </section>
    </>
  );
};
