import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, LinkButton, Text, useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';
import { testTracesConnection } from 'features/agent-integrations/state/actions';
import { IntegrationConnectionStatus } from 'features/agent-integrations/state/slice';
import { useDispatch } from 'hooks/useDispatch';
import { RootState } from 'state';
import { CHECK_INTERVAL_MS, CHECK_TIMEOUT_MS } from 'utils/consts';

interface ConnectionTestButtonProps {
  serviceName: string | undefined;
  serviceNamespace: string | undefined;
  onClick: () => void;
}

export const AppO11yTracesTestButton = ({ serviceName, serviceNamespace, onClick }: ConnectionTestButtonProps) => {
  const styles = useStyles2(getStyles);
  const { tracesConnection } = useSelector((state: RootState) => state.agent);
  const dispatch = useDispatch();

  const [testingValues, setTestingValues] = useState<{
    serviceName: string;
    serviceNamespace: string | undefined;
  } | null>(null);
  const [checkStartedAt, setCheckStarted] = useState(0);

  const testingTimeoutExpired = new Date().getTime() - checkStartedAt > CHECK_TIMEOUT_MS;

  const onTestConnection = async () => {
    onClick();

    if (!serviceName) {
      return;
    }

    setTestingValues({ serviceName, serviceNamespace });
    dispatch(testTracesConnection({ serviceName, serviceNamespace }));
    setCheckStarted(new Date().getTime());
  };

  useEffect(() => {
    if (tracesConnection.status === IntegrationConnectionStatus.NoData && checkStartedAt && !testingTimeoutExpired) {
      if (testingValues) {
        const timeout = setTimeout(() => dispatch(testTracesConnection(testingValues)), CHECK_INTERVAL_MS);
        return () => clearTimeout(timeout);
      }
    }

    return () => {};
  }, [tracesConnection, dispatch, testingValues, checkStartedAt, testingTimeoutExpired]);

  switch (tracesConnection.status) {
    case IntegrationConnectionStatus.None:
      return (
        <Button
          size="md"
          onClick={() => {
            onTestConnection();
          }}
        >
          Test connection
        </Button>
      );

    case IntegrationConnectionStatus.NoData:
      if (testingTimeoutExpired) {
        return (
          <>
            <Button
              size="md"
              onClick={() => {
                onTestConnection();
              }}
            >
              Test connection
            </Button>
            <StatusMessage className={styles.alert} status="warning">
              <Text variant="body">
                We could not find any traces yet. Please wait a few minutes or try a different service name.
              </Text>
            </StatusMessage>
          </>
        );
      }
    // eslint-disable-next-line no-fallthrough -- when testingTimeoutExpired is false, we continue making requests, so status is technically pending still
    case IntegrationConnectionStatus.Pending:
      return (
        <Button
          size="md"
          onClick={() => {
            onTestConnection();
          }}
          icon="fa fa-spinner"
          disabled={true}
        >
          Testing connection
        </Button>
      );
    case IntegrationConnectionStatus.Error:
      return (
        <>
          <Button
            size="md"
            onClick={() => {
              onTestConnection();
            }}
          >
            Test connection
          </Button>
          <StatusMessage className={styles.alert} status="error">
            <Text variant="body">An unexpected error occurred. Please try again.</Text>
          </StatusMessage>
        </>
      );

    case IntegrationConnectionStatus.Success:
      return (
        <div className={styles.successContainer}>
          <Button
            fullWidth={false}
            size="md"
            onClick={() => {
              onTestConnection();
            }}
          >
            Test connection
          </Button>

          <StatusMessage className={styles.alert} status="success">
            <Text variant="body">Traces are being ingested properly</Text>
          </StatusMessage>

          <LinkButton
            fill="solid"
            variant="secondary"
            type="button"
            icon="application-observability"
            href={tracesConnection.link}
          >
            Go to Application observability
          </LinkButton>
        </div>
      );
  }
};

function getStyles(theme: GrafanaTheme2) {
  return {
    successContainer: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};

      p {
        margin: 0;
      }

      button,
      a {
        width: fit-content;
      }
    `,
    alert: css`
      margin-top: ${theme.spacing(1)};
    `,
  };
}
