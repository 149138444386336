import React from 'react';

import { Button, LinkButton, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { clickOpenUninstallModal, clickViewDashboards, SourceDetails } from 'state/source/slice';
import { Status } from 'utils/enums';
import { constructDashboardUrl } from 'utils/misc';

import { getStyles } from './Source.styles';

interface SourceActionButtonsProps {
  sourceDetails: SourceDetails;
  shouldMakeRequests: boolean;
  showModal: () => void;
}

export const SourceActionButtons: React.FC<SourceActionButtonsProps> = ({
  sourceDetails,
  shouldMakeRequests,
  showModal,
}) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();

  const areDashboardsAvailable =
    shouldMakeRequests &&
    sourceDetails.dashboards !== undefined &&
    sourceDetails.dashboards.status === Status.Available;

  const integrationId = useSelectedIntegrationId();

  return (
    <>
      {areDashboardsAvailable && sourceDetails?.dashboard_folder && (
        <LinkButton
          variant="primary"
          href={constructDashboardUrl(sourceDetails.dashboard_folder)}
          onClick={() => dispatch(clickViewDashboards(integrationId))}
        >
          View Dashboards
        </LinkButton>
      )}
      <Button
        data-testid={Pages.Source.uninstallButton}
        variant="destructive"
        className={styles.dangerColor}
        onClick={(e) => {
          dispatch(clickOpenUninstallModal(integrationId));
          showModal();
        }}
      >
        Uninstall integration
      </Button>
    </>
  );
};
