import { css } from '@emotion/css';
import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

const getStyles = () => ({
  datasourceRoadmap: css`
    padding: 10px 0 50px 0;
  `,
});

export const DatasourceRoadmapLinks = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <div className={styles.datasourceRoadmap}>
      <TextLink
        href="https://github.com/grafana/grafana/issues/new?assignees=&labels=area%2Fdatasource%2Ctype%2Fnew-plugin-request&projects=&template=3-data_source_request.yaml&title=%5BNew+Data+Source%5D%3A+%3Cname-of-service%3E"
        onClick={() => trackRudderStackEvent(RudderStackEvents.DataSourceRequestClicked, {})}
        external
      >
        Request new data source
      </TextLink>{' '}
      <br />
      <TextLink
        data-test="datasource-roadmap-link"
        href="https://github.com/orgs/grafana/projects/619/views/1?pane=info"
        onClick={() => trackRudderStackEvent(RudderStackEvents.DataSourceRoadmapClicked, {})}
        external
      >
        View roadmap
      </TextLink>
    </div>
  );
};
