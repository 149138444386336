import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  p: css({
    marginTop: '10px',
  }),
  step: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '> button:first-child': {
      padding: 0,
      '> div:first-child': {
        paddingTop: theme.spacing(0.25),
        '> svg': {
          margin: theme.spacing(0.25, 1, 0, 0),
        },
      },
    },
    '> div': {
      padding: 0,
    },
  }),
  topMargin: css({
    marginTop: theme.spacing(3),
  }),
  noMargin: css({
    margin: 0,
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
});
