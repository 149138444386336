import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IntegrationsState = {
  searchTerm: string;
  isSortAscending: boolean;
};

const initialState: IntegrationsState = {
  searchTerm: '',
  isSortAscending: true,
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    initIntegrations: (state) => {
      state = initialState;
    },
    clickAddNewInfraIntegration: (state) => {
      // redirect handled by component
    },
    typeIntegrationSearch: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    selectSort: (state, action: PayloadAction<boolean>) => {
      state.isSortAscending = action.payload;
    },
    clickIntegrationCard: (state, action: PayloadAction<string>) => {
      // redirect handled by component
    },
    clickIntegrationCardDashboards: (state, action: PayloadAction<string>) => {
      // redirect handled by component
    },
    clickIntegrationCardAlerts: (state, action: PayloadAction<string>) => {
      // redirect handled by component
    },
  },
});

export const {
  initIntegrations,
  clickAddNewInfraIntegration,
  typeIntegrationSearch,
  selectSort,
  clickIntegrationCard,
  clickIntegrationCardDashboards,
  clickIntegrationCardAlerts,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
