import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { CHECK_INTERVAL_MS, CHECK_TIMEOUT_MS } from 'utils/consts';

import { testIntegrationConnectionRequest } from '../state/actions';
import {
  IntegrationConnectionStatus,
  setIntegrationConnectionError,
  setIntegrationConnectionStatus,
} from '../state/slice';

import { IntegrationButtons } from './Buttons/IntegrationButtons';
import { TestConnectionError } from './TestConnectionError';

export const TestConnection: FC = () => {
  const getStyles = (theme: GrafanaTheme2) => ({
    checkConnection: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      color: ${theme.colors.text.secondary};
      margin-right: ${theme.spacing(2)};
      width: 100%;
    `,
    alert: css`
      margin-top: 16px;
    `,
  });

  const { integrationConnectionResult } = useSelector((state: RootState) => state.agent);

  const [checkStartedAt, setCheckStarted] = useState(0);
  const dispatch = useDispatch();
  const styles = useStyles2(getStyles);
  const integrationId = useSelectedIntegrationId();

  const testIntegrationConnection = () => {
    dispatch(testIntegrationConnectionRequest(integrationId));
    setCheckStarted(new Date().getTime());
  };

  useEffect(() => {
    dispatch(setIntegrationConnectionStatus({ status: IntegrationConnectionStatus.None, integrationId }));
  }, [dispatch, integrationId]);

  useEffect(() => {
    if (integrationConnectionResult.status === IntegrationConnectionStatus.NoData && checkStartedAt) {
      if (new Date().getTime() - checkStartedAt > CHECK_TIMEOUT_MS) {
        dispatch(setIntegrationConnectionError(integrationConnectionResult.error));
      } else {
        const timeout = setTimeout(() => dispatch(testIntegrationConnectionRequest(integrationId)), CHECK_INTERVAL_MS);
        return () => clearTimeout(timeout);
      }
    }

    return () => {};
  }, [integrationConnectionResult, dispatch, checkStartedAt, integrationId]);

  switch (integrationConnectionResult.status) {
    case IntegrationConnectionStatus.None:
      return <IntegrationButtons testConnection={testIntegrationConnection} />;
    case IntegrationConnectionStatus.Pending:
    case IntegrationConnectionStatus.NoData:
      return (
        <>
          <IntegrationButtons testConnection={testIntegrationConnection} />
          <p className={styles.checkConnection}>Checking connection. It might take up to a minute...</p>
        </>
      );

    case IntegrationConnectionStatus.Success:
      return (
        <>
          <IntegrationButtons testConnection={testIntegrationConnection} />
          <StatusMessage status="success" className={styles.alert}>
            Integration is collecting data and sending it to Grafana Cloud.
          </StatusMessage>
        </>
      );

    case IntegrationConnectionStatus.Error:
      return (
        <>
          <IntegrationButtons testConnection={testIntegrationConnection} />
          <TestConnectionError error={integrationConnectionResult.error} />
        </>
      );
  }
};
