import orangeRocket from 'img/rocket.svg';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const SETUP_GUIDE_ID = 'setup-guide-exp';

export const setupGuide: Source = {
  id: SETUP_GUIDE_ID,
  name: 'Setup Guide',
  logo_url: orangeRocket,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: '/a/cloud-home-app/setup-guide',
  search_keywords: [
    'alloy',
    'azure',
    'cloudflare',
    'cloudwatch',
    'datadog',
    'docker',
    'elasticsearch',
    'firehose',
    'fluentbit',
    'fluentd',
    'gcp',
    'heroku',
    'http logs',
    'kafka',
    'kubernetes',
    'linux',
    'loki',
    'new relic',
    'opentelementry',
    'oracle',
    'prometheus',
    'splunk',
    'sumo logic',
    'windows',
  ],
};
