import { cx } from '@emotion/css';
import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { GrafanaAlloyMessage, useCollectorSelector } from '@grafana-cloud/collector';

import { AgentStatus } from '../state/slice';

import { AlloyConfigModal } from './AlloyConfigModal';
import { AlloyDescription } from './AlloyDescription';
import { getInstallSectionStyles } from './InstallSection.styles';

export const InstallAlloySection = ({ showOSSelection }: { showOSSelection: boolean }) => {
  const styles = useStyles2(getInstallSectionStyles);
  const alloyStatus = useCollectorSelector((state) => state.collector.agentStatus);

  return (
    <li className={styles.li}>
      <h2>Install Grafana Alloy</h2>
      <div className={cx(styles.box, styles.blueBackground, styles.marginTop)}>
        <h5>Before you configure this integration, you must set up Grafana Alloy.</h5>
        <AlloyDescription />
        <AlloyConfigModal showOSSelection={showOSSelection} />
      </div>
      {alloyStatus === AgentStatus.Success && <GrafanaAlloyMessage />}
    </li>
  );
};
