import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Tooltip, useStyles2 } from '@grafana/ui';

import { DisabledReasonType, DISABLED_REASONS } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    padding: 15px 20px;

    > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  `,
  title: css`
    margin-bottom: 0 0 20px;
  `,
  description: css`
    margin: 0;
  `,
  parentContent: css`
    cursor: pointer;
  `,
});

type DisabledReasonTooltipProps = {
  reason?: DisabledReasonType;
  children: React.ReactElement;
};

export const DisabledReasonTooltip = ({ reason, children }: DisabledReasonTooltipProps) => {
  const styles = useStyles2(getStyles);

  // Reason does not exist, so do not show tooltip
  if (!reason || typeof DISABLED_REASONS[reason] === 'undefined') {
    return <>{children}</>;
  }

  const tooltipContent = (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        <strong>{DISABLED_REASONS[reason].title}</strong>
      </p>
      <p className={styles.description}>{DISABLED_REASONS[reason].description}</p>
    </div>
  );

  return (
    <Tooltip placement="bottom" content={tooltipContent}>
      <div className={styles.parentContent}>{children}</div>
    </Tooltip>
  );
};
