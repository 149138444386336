import { createAsyncThunk } from '@reduxjs/toolkit';

import { checkConnection } from 'api/hostedExporters';
import { OnboardingPluginError } from 'types';
import { ConnectionStatus } from 'utils/enums';
import { text } from 'utils/errors';

import { handleError } from '../saasIntegration/actions';
import { setConnectionStatus } from '../saasIntegration/slice';

export const checkConfluentConnection = createAsyncThunk(
  'confluent/checkConfluentConnection',
  async ({ payload }: { payload: { api_key: string; api_secret: string } }, thunkAPI) => {
    const jobType = 'confluent-cloud';
    const { api_key, api_secret } = payload;

    try {
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Pending));
      await checkConnection({ api_key, api_secret }, jobType, api_key);
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Success));
    } catch (error) {
      thunkAPI.dispatch(setConnectionStatus(ConnectionStatus.Error));
      const confluentError = text.errorFunctions.checkConfluentConnection(api_key);
      const obError: OnboardingPluginError = {
        ...confluentError,
        code: '',
        errorObject: error,
      };

      thunkAPI.dispatch(handleError({ obError }));
    }
  }
);
