import React, { FC } from 'react';

import { LinkButton } from '@grafana/ui';

export const GiveUsFeedbackButton: FC = () => {
  return (
    <LinkButton
      variant="secondary"
      href="https://docs.google.com/forms/d/e/1FAIpQLScD0CkReyBWkr1byQnMWGk887S-mmvPc11AqvbYBiZcDqUQfA/viewform"
      target="_blank"
      rel="noreferrer"
    >
      Give us feedback
    </LinkButton>
  );
};
