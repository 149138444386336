import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getInstructionsStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '40px',
    marginLeft: theme.spacing(3),
  }),
  instructionItem: css({
    marginBottom: '40px',

    h2: {
      margin: `${theme.spacing(2)} 0`,
      lineHeight: '26px',
      fontSize: theme.typography.h4.fontSize,
      color: theme.colors.text.maxContrast,
    },

    h3: {
      lineHeight: '22px',
      fontSize: theme.typography.h4.fontSize,
    },

    p: {
      fontSize: theme.typography.body.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
});
