import React from 'react';
import { useSelector } from 'react-redux';

import { Install } from 'components/Install';
import { RootState } from 'state';
import { Status } from 'utils/enums';

type Props = {
  installCardStyles?: string;
};

export const InstallCard = ({ installCardStyles }: Props) => {
  const { alerts, dashboards } = useSelector((state: RootState) => state.source.sourceDetails);
  const {
    configuredParameters: { isAlertsEnabled },
  } = useSelector((state: RootState) => state.agent);

  const areAlertsAvailable = alerts?.status === Status.Available;
  const areDashboardsAvailable = dashboards?.status === Status.Available;

  const showAlertsText = isAlertsEnabled === undefined ? true : isAlertsEnabled;
  const displayAlertsInTheText = areAlertsAvailable && showAlertsText;

  const dashboardsAndAlertsText = areDashboardsAvailable
    ? displayAlertsInTheText
      ? ' dashboards and alerts'
      : ' dashboards'
    : displayAlertsInTheText
      ? ' alerts'
      : '';

  return (
    <div className={installCardStyles}>
      <h3>Install {dashboardsAndAlertsText}</h3>
      {(areDashboardsAvailable || areAlertsAvailable) && (
        <p>Get access to pre-configured{dashboardsAndAlertsText} that work right away</p>
      )}
      <Install />
    </div>
  );
};
