import React from 'react';

import { locationService } from '@grafana/runtime';
import { Button } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

import { MetricsEndpointErrorCodes } from './enums';

const GRAFANA_SUPPORT_URL = 'https://grafana.com/profile/org#support';
const GRAFANA_PRICING_URL = 'https://grafana.com/products/cloud/pricing/';
const METRICS_ENDPOINT_TROUBLESHOOTING_LINK =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-metrics-endpoint/#troubleshooting-errors';

function ContactUs() {
  return (
    <p>
      If the error persists, you can{' '}
      <TextLink href={GRAFANA_SUPPORT_URL} external inline>
        contact us
      </TextLink>
      .
    </p>
  );
}

function TryAgain() {
  return (
    <>
      <p>Please try again.</p>;
      <ContactUs />
    </>
  );
}

function IncreaseLimit() {
  return (
    <p>
      You must delete existing rule groups in order to continue, or please{' '}
      <TextLink href={GRAFANA_SUPPORT_URL} external inline>
        create a support ticket
      </TextLink>{' '}
      to increase your limit if you still need help.
    </p>
  );
}

export type AppError = {
  message: string;
  action?: React.JSX.Element;
};

type Text = {
  error: {
    [key: string]: AppError;
  };
  errorFunctions: {
    [key: string]: (...args: string[]) => AppError;
  };
};

export const text: Text = {
  error: {
    installIntegration: { message: 'Error while attempting to install integrations.', action: <TryAgain /> },
    uninstallIntegration: { message: 'Error while attempting to uninstall an integration.', action: <TryAgain /> },
    updateIntegration: { message: 'Error while attempting to update an integration.', action: <TryAgain /> },
    saveConfigurationSelection: {
      message: 'Error while attempting to save your updated selections. ',
      action: <TryAgain />,
    },
    queryDatasource: { message: 'Error querying datasource.', action: <TryAgain /> },
    generateAgentInstall: { message: 'Error while generating agent install instruction.', action: <TryAgain /> },
    generateAgentConfig: { message: 'Error while generating agent config.', action: <TryAgain /> },
    generateDatasource: { message: 'Error while attempting to generate a new datasource.', action: <TryAgain /> },
    getSourcesList: { message: 'Error while fetching integrations.', action: <TryAgain /> },
    genericError: { message: 'An unknown error occurred.', action: <TryAgain /> },
    unauthorizedError: { message: 'Unauthorized. An API key is probably missing.', action: <ContactUs /> },
    queryDetails: { message: 'Error querying integration details.', action: <TryAgain /> },
    integrationNotFound: {
      message: 'Integration not found.',
      action: (
        <div>
          <Button onClick={() => locationService.getHistory().goBack()}>Go back</Button>
        </div>
      ),
    },
    fetchFolderUIDs: { message: 'Error while fetching folder UIDs.', action: <TryAgain /> },
  },
  errorFunctions: {
    checkConfluentConnection: (api_key: string) => ({
      message: `Authentication error using API key ${api_key}. Please make sure you are using the correct API key and API secret values.`,
      action: <ContactUs />,
    }),
    checkMetricsEndpointConnection: (code: string, urlField = 'URL') => {
      let message = '';
      switch (code) {
        case MetricsEndpointErrorCodes.NotPrometheusCompatible:
          message = `The request to the provided endpoint did not return Prometheus-compatible metrics. Please ensure your ${urlField} is valid and returns Prometheus metrics.`;
          break;
        case MetricsEndpointErrorCodes.UnauthorizedAccess:
          message = `The request to the provided endpoint with the credentials provided did not succeed. Please ensure your ${urlField} and credentials are correct.`;
          break;
        case MetricsEndpointErrorCodes.InvalidStatusCode:
          message = `The request to the provided endpoint did not return a valid response. Please ensure your ${urlField} and credentials are correct.`;
          break;
        case MetricsEndpointErrorCodes.InvalidInput:
          message = `An unknown error occurred. Please ensure your ${urlField} and credentials are correct.`;
          break;
        case MetricsEndpointErrorCodes.InvalidCertificate:
          message = `The request to the provided endpoint did not return a valid response. Please ensure the https certificate is valid.`;
          break;
        case MetricsEndpointErrorCodes.DNSError:
          message = `The request to the provided endpoint did not return a valid response. Please ensure your ${urlField} is correct.`;
          break;
        case MetricsEndpointErrorCodes.URLError:
          message = `The request to the provided endpoint did not return a valid response. Please ensure your ${urlField} is correct.`;
          break;
        case MetricsEndpointErrorCodes.RedirectUnsupportedError:
          message = `The request to the provided endpoint did not return a valid response. Please ensure your ${urlField} is correct and does not redirect to a different location.`;
          break;
        case MetricsEndpointErrorCodes.InvalidAuthSetup:
          message = `The request to the provided endpoint did not return a valid response. Please ensure your ${urlField} is correctly setup to handle authentication credentials.`;
          break;
        case MetricsEndpointErrorCodes.NetworkTimeout:
          message = `The request to the provided endpoint did not return a valid response within the expected timeout. Please ensure your ${urlField} is correct.`;
          break;
        default:
          message = `An unknown error occurred. Please ensure your ${urlField} and credentials are correct and try again.`;
          break;
      }
      return {
        message,
        action: (
          <p>
            For help troubleshooting common errors see our{' '}
            <TextLink href={METRICS_ENDPOINT_TROUBLESHOOTING_LINK} external inline>
              documentation
            </TextLink>
            .
          </p>
        ),
      };
    },
    deleteJob: (jobName: string) => ({ message: `Error while deleting scrape job ${jobName}.`, action: <TryAgain /> }),
    noDataSourceFound: (message: string) => ({
      message: `Internal error while testing the connection. ${message}`,
      action: <ContactUs />,
    }),
    updateJobStatus: (jobName: string) => ({
      message: `Error while updating scrape job ${jobName}.`,
      action: <TryAgain />,
    }),
    installRollbackFailed: (id: string) => ({
      message:
        'Error while attempting to install the integration. There could still be some dashboards left installed.',
      action: (
        <p>
          If retrying to install the integration does not help, try removing the following dashboards:{' '}
          <TextLink href={`/dashboards/f/integration---${id}/integration-${id}`} inline>
            {id}
          </TextLink>
          .
        </p>
      ),
    }),
  },
};

export const constructErrorMessage = (orgSlug?: string): Text => {
  const subscriptionUrl = orgSlug ? `https://grafana.com/orgs/${orgSlug}/subscription` : GRAFANA_PRICING_URL;

  return {
    error: {
      dashboard: {
        message:
          "You've already reached your included dashboard limit of 10 on the free plan and cannot install this integration.",
        action: (
          <p>
            You must delete an existing dashboard or{' '}
            <TextLink href={subscriptionUrl} external inline>
              upgrade
            </TextLink>{' '}
            to Grafana Cloud Pro in order to continue.
          </p>
        ),
      },
      alert: {
        message: "You've already reached the alerting rule group limit and cannot install this integration.",
        action: <IncreaseLimit />,
      },
      record: {
        message: "You've already reached the recording rule group limit and cannot install this integration.",
        action: <IncreaseLimit />,
      },
    },
    errorFunctions: {},
  };
};
