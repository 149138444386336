import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { GiveUsFeedbackButton } from './GiveUsFeedbackButton';
import { TestConnectionButton } from './TestConnectionButton';
import { IntegrationButtonProps } from './props';

export const IntegrationButtons: FC<IntegrationButtonProps> = ({ testConnection }) => {
  const getStyles = (theme: GrafanaTheme2) => ({
    container: css({
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(1),
    }),
  });
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <TestConnectionButton testConnection={testConnection} />
      <GiveUsFeedbackButton />
    </div>
  );
};
