import React, { FC } from 'react';

import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { OtelFlavor } from 'utils/enums';

import { getStyles } from './styles';

export const SdkEnvironmentVariables: FC<{
  flavor: OtelFlavor;
  serviceName: string | undefined;
  serviceNamespace: string | undefined;
  serviceInstanceId: string | undefined;
  serviceVersion: string | undefined;
}> = ({ flavor, serviceName, serviceNamespace, serviceInstanceId, serviceVersion }) => {
  const styles = useStyles2(getStyles);

  const serviceNamespaceConfig = serviceNamespace ? `,service.namespace=${serviceNamespace}` : '';
  const serviceVersionConfig = serviceVersion ? `,service.version=${serviceVersion}` : '';
  const serviceInstanceIdConfig = serviceInstanceId ? `,service.instance.id=${serviceInstanceId}` : '';
  return (
    <table className={styles.table}>
      <tr>
        {flavor === OtelFlavor.Java ? <th>System Property</th> : ''}
        <th>Environment Variable Name</th>
        <th>Description</th>
        <th>Value</th>
      </tr>
      <tr>
        {flavor === OtelFlavor.Java ? <td>otel.service.name</td> : ''}
        <td>OTEL_SERVICE_NAME</td>
        <td>
          The name of your application. The service name will be available as attributes for traces, metrics, and logs.
        </td>
        <td>
          <Clipboard code={serviceName ?? ''} clipboardButtonType="below" />
        </td>
      </tr>
      <tr>
        {flavor === OtelFlavor.Java ? <td>otel.resource.attributes</td> : ''}
        <td>OTEL_RESOURCE_ATTRIBUTES</td>
        <td>
          deployment.environment=&lt;production or staging&gt;
          {serviceNamespaceConfig}
          {serviceVersionConfig}
          {serviceInstanceIdConfig}
        </td>
        <td>
          <Clipboard
            multipleLines
            code={`deployment.environment=production${serviceNamespaceConfig}${serviceVersionConfig}${serviceInstanceIdConfig}`}
            clipboardButtonType="below"
          />
        </td>
      </tr>
      <tr>
        {flavor === OtelFlavor.Java ? <td>otel.exporter.otlp.endpoint</td> : ''}
        <td>OTEL_EXPORTER_OTLP_ENDPOINT</td>
        <td>
          <code>http://&lt;localhost&gt;:4317</code> - target URL of Grafana Alloy.
          <br />
          <br />
          When Grafana Alloy is running locally, you can skip this environment variable.
        </td>
        <td>
          <Clipboard code="http://localhost:4317" clipboardButtonType="below" />
        </td>
      </tr>
      <tr>
        {flavor === OtelFlavor.Java ? <td>otel.exporter.otlp.protocol</td> : ''}
        <td>OTEL_EXPORTER_OTLP_PROTOCOL</td>
        <td>
          Protocol used to connect to Grafana Alloy - <code>grpc</code> (default) or <code>http/protobuf</code>.
          <br />
          <br />
          When Grafana Alloy is running locally, you can skip this environment variable.
        </td>
        <td>
          <Clipboard code="grpc" clipboardButtonType="below" />
        </td>
      </tr>
    </table>
  );
};
