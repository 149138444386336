import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Catalog } from 'features/catalog';
import { Integrations } from 'features/integrations-page';
import { Java } from 'features/java-page';
import { useDispatch } from 'hooks/useDispatch';
import { Source } from 'pages/Source';
import { RootState } from 'state';
import { CSP_APP_ID } from 'utils/localSources/cspApp';
import { CONNECT_DATA_URL, INTEGRATIONS_URL } from 'utils/misc';

import { GeneralError } from './GeneralError';
import { pathnameChanged } from './state/slice';

export const Routes = () => {
  const dispatch = useDispatch();

  const { generalError } = useSelector((state: RootState) => state.app);

  const { search, hash, pathname } = useLocation();

  useEffect(() => {
    dispatch(pathnameChanged());
  }, [pathname, dispatch]);

  if (generalError) {
    return <GeneralError appError={generalError} />;
  }

  return (
    <Switch>
      <Redirect
        from="/a/grafana-easystart-app/:id?"
        to={{
          pathname: `${CONNECT_DATA_URL}/:id?`,
          search,
          hash,
        }}
      />

      {/* AWS */}
      <Redirect from={`${CONNECT_DATA_URL}/aws`} to={`/a/${CSP_APP_ID}/aws/configuration`} />
      <Redirect from={`${CONNECT_DATA_URL}/cloudwatch`} to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch`} />
      <Redirect from={`${INTEGRATIONS_URL}/cloudwatch`} to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch`} />
      <Redirect
        from={`${CONNECT_DATA_URL}/cloudwatch-logs`}
        to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch-logs`}
      />
      <Redirect
        from={`${INTEGRATIONS_URL}/cloudwatch-logs`}
        to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch-logs`}
      />
      <Redirect
        from={`${CONNECT_DATA_URL}/cloudwatch-logs-firehose`}
        to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch-logs-firehose`}
      />
      <Redirect
        from={`${INTEGRATIONS_URL}/cloudwatch-logs-firehose`}
        to={`/a/${CSP_APP_ID}/aws/configuration/cloudwatch-logs-firehose`}
      />
      <Redirect from={`${CONNECT_DATA_URL}/aws-alb-logs`} to={`/a/${CSP_APP_ID}/aws/configuration/aws-alb-logs`} />
      <Redirect from={`${INTEGRATIONS_URL}/aws-alb-logs`} to={`/a/${CSP_APP_ID}/aws/configuration/aws-alb-logs`} />
      <Redirect from={`${INTEGRATIONS_URL}/aws/ec2`} to={`/a/${CSP_APP_ID}/aws/dashboard/ec2`} />

      {/* GCP */}
      <Redirect from={`${CONNECT_DATA_URL}/gcp-logs`} to={`/a/${CSP_APP_ID}/gcp/configuration/gcp-logs`} />
      <Redirect from={`${INTEGRATIONS_URL}/gcp-logs`} to={`/a/${CSP_APP_ID}/gcp/configuration/gcp-logs`} />
      <Redirect from={`${CONNECT_DATA_URL}/gcp-metrics`} to={`/a/${CSP_APP_ID}/gcp/configuration/gcp-metrics`} />
      <Redirect from={`${INTEGRATIONS_URL}/gcp-metrics`} to={`/a/${CSP_APP_ID}/gcp/configuration/gcp-metrics`} />

      {/* Azure */}
      <Redirect
        from={`${CONNECT_DATA_URL}/azure-event-hubs`}
        to={`/a/${CSP_APP_ID}/azure/configuration/azure-event-hubs`}
      />
      <Redirect
        from={`${INTEGRATIONS_URL}/azure-event-hubs`}
        to={`/a/${CSP_APP_ID}/azure/configuration/azure-event-hubs`}
      />
      <Redirect from={`${CONNECT_DATA_URL}/azure-metrics`} to={`/a/${CSP_APP_ID}/azure/configuration/azure-metrics`} />
      <Redirect from={`${INTEGRATIONS_URL}/azure-metrics`} to={`/a/${CSP_APP_ID}/azure/configuration/azure-metrics`} />

      <Route sensitive exact path={CONNECT_DATA_URL} component={Catalog} />

      <Route sensitive exact path={`${CONNECT_DATA_URL}/java`} component={Java} />
      <Route sensitive exact path={`${CONNECT_DATA_URL}/:id?`} component={Source} />
      <Route sensitive exact path={INTEGRATIONS_URL} component={Integrations} />
      <Route sensitive exact path={`${INTEGRATIONS_URL}/:id?`} component={Source} />
    </Switch>
  );
};
