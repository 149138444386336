import React from 'react';

import { Button, ButtonVariant, IconName } from '@grafana/ui';

import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { Pages } from './Pages';
import { useSaveOptionAndNavigate } from './useSaveOptionAndNavigate';

export function AgentModeViewAllButton({
  title,
  variant,
  icon,
}: {
  title: string;
  variant: ButtonVariant;
  icon?: IconName;
}): React.JSX.Element {
  const saveOptionAndNavigate = useSaveOptionAndNavigate();
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <Button
      variant={variant}
      fill="outline"
      icon={icon}
      onClick={() => {
        saveOptionAndNavigate(Pages.Selection);
        trackRudderStackEvent(RudderStackEvents.AgentModeViewAllOptions, {});
      }}
    >
      {title}
    </Button>
  );
}
