import { CICDObservabilityLogo } from 'img/catalog';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const CICD_OBSERVABILITY_CARD_ID = 'grafana-cicd-observability';

export const CICDObservability: Source = {
  id: CICD_OBSERVABILITY_CARD_ID,
  slug: 'grafana-cicd-observability',
  name: 'CI/CD Observability',
  logo_url: CICDObservabilityLogo,
  type: SourceType.Alpha,
  search_keywords: [
    'CI/CD',
    'observability',
    'CI',
    'CD',
    'CI/CD observability',
    'continuous integration',
    'continuous deployment',
    'continuous delivery',
    'pipeline',
    'pipelines',
    'pipeline monitoring',
    'pipeline observability',
    'DORA',
    'DORA metrics',
    'deployment frequency',
    'lead time for changes',
    'mean time to restore',
    'change failure rate',
    'opentelemetry',
    'GitHub Actions',
    'GitLab CI',
    'Jenkins',
    'Travis CI',
    'CircleCI',
    'Bitbucket Pipelines',
    'AWS CodePipeline',
    'Azure DevOps',
    'ArgoCD',
  ],
  categories: ['CI/CD', 'Logs', 'Traces', 'Developer Tools', 'Time Series'],
};
