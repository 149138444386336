import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2, chromeHeaderHeight?: number) => ({
  p: css({
    color: theme.colors.text.secondary,
  }),
  sourcesList: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '16px',
    listStyle: 'none',
    marginBottom: '48px',
  }),
  alertWrapper: css({
    maxHeight: theme.spacing(4),
  }),
  alert: css({
    '> div:first-child': {
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      '> div:first-child': {
        paddingTop: '0',
      },
    },
  }),
  sticky: css({
    position: 'sticky',
    top: `${chromeHeaderHeight ?? 0}px`,
    padding: `${theme.spacing(2)} 0`,
    backgroundColor: theme.colors.background.primary,
    zIndex: '1',
  }),
  versionInfo: css({
    fontSize: theme.typography.bodySmall.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  }),
});
