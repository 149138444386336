import { PyroscopeLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const pyroscope: Source = {
  id: 'pyroscope-app',
  name: 'Grafana Cloud Profiles',
  logo_url: PyroscopeLogo,
  overview: ``,
  type: SourceType.CloudApp,
  externalUrl: '/a/grafana-pyroscope-app',
  search_keywords: ['profiling, profile, pyroscope'],
  categories: ['Profiling'],
};

export const HOSTED_PROFILES_ID = 'hpInstanceId';

export const hostedProfiles: Source = {
  id: HOSTED_PROFILES_ID,
  name: 'Hosted profiles',
  logo_url: PyroscopeLogo,
  overview: `Powered by Grafana Pyroscope, Grafana Cloud provides an easy-to-use, highly scalable, and cost-effective distributed continuous profiling system.`,
  type: SourceType.HostedData,
  search_keywords: ['profiling, profile, pyroscope'],
  categories: ['Profiling'],
};
