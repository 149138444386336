import { cx } from '@emotion/css';
import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { GrafanaAlloyMessage, useCollectorSelector } from '@grafana-cloud/collector';

import { getInstallSectionStyles } from '../InstallCollector/InstallSection.styles';
import { AgentStatus } from '../state/slice';

import { K8sClusterSetupModal } from './K8sClusterSetupModal';
import { K8sDescription } from './K8sDescription';

export const SetupK8sCluster = () => {
  const styles = useStyles2(getInstallSectionStyles);
  const alloyStatus = useCollectorSelector((state) => state.collector.agentStatus);

  return (
    <li className={styles.li}>
      <h2>Setup Kubernetes cluster</h2>
      <div className={cx(styles.box, styles.blueBackground, styles.marginTop)}>
        <h5>Before you configure this integration, you must set up your Kubernetes cluster.</h5>
        <K8sDescription />
        <K8sClusterSetupModal />
      </div>
      {alloyStatus === AgentStatus.Success && <GrafanaAlloyMessage />}
    </li>
  );
};
