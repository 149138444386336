import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  linkBox: css({
    width: '230px',
    height: '100%',
    padding: '24px',
    border: `1px solid ${theme.colors.border.medium}`,
    background: theme.components.panel.background,
    boxSizing: 'border-box',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'start',

    '&:hover': {
      background: theme.colors.background.secondary,
    },

    '&:focus': {
      padding: '23px',
      border: `2px solid ${theme.colors.border.strong}`,
    },

    img: {
      width: '20px',
      marginRight: '14px',
    },

    p: {
      marginBottom: 0,
    },
  }),
  linkList: css({
    listStyle: 'none',
    display: 'flex',

    li: {
      marginRight: '16px',
    },
  }),
});

type Props = {
  links: Array<{
    url: string | undefined;
    label: string;
    icon: string;
  }>;
};

export function LinksList({ links }: Props) {
  const styles = useStyles2(getStyles);

  return (
    <ul className={styles.linkList}>
      {links.map((link, index) => (
        <li key={index}>
          <a href={link.url} className={styles.linkBox} target="_blank" rel="noreferrer">
            <img src={link.icon} alt="link icon" />
            <p>{link.label}</p>
          </a>
        </li>
      ))}
    </ul>
  );
}
