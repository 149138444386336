import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { CloudAppsLight } from 'img/catalog';
import { HostedDataIcon, DatasourcesIcon, OnboardingLogo } from 'img/label';

import { Category } from '../types/Category';
import { categoryToLabelMap } from '../utils/mappings';

import { CategoryAid } from './CategoryAid';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    margin-bottom: 24px;
  `,
  categoryHeader: css`
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  `,
  categoryLabel: css`
    color: ${theme.colors.text};
    font-weight: ${theme.typography.fontWeightMedium};
    line-height: 24px;
    font-size: 19px;
    :not(:first-child) {
      margin-left: 12px;
    }
  `,
  separator: css`
    border-top: 1px solid ${theme.colors.border.strong};
  `,
});

const getCategoryHeaderContent = {
  [Category.Integration]: {
    icon: <img src={OnboardingLogo} height={20} width={16} alt="Integration Icon" />,
    label: categoryToLabelMap.get(Category.Integration) ?? '',
  },
  [Category.MostPopular]: {
    icon: '',
    label: categoryToLabelMap.get(Category.MostPopular) ?? '',
  },
  [Category.HostedData]: {
    icon: <img src={HostedDataIcon} height={16} width={20} alt="Hosted data Icon" />,
    label: categoryToLabelMap.get(Category.HostedData) ?? '',
  },
  [Category.DataSource]: {
    icon: <img src={DatasourcesIcon} height={20} width={16} alt="Datasource Icon" />,
    label: categoryToLabelMap.get(Category.DataSource) ?? '',
  },
  [Category.CloudApp]: {
    icon: <img src={CloudAppsLight} height={20} alt="Cloud App Icon" />,
    label: categoryToLabelMap.get(Category.CloudApp) ?? '',
  },
};

export const CategoryHeader: React.FC<{ category: Category }> = ({ category }) => {
  const styles = useStyles2(getStyles);
  const { icon, label } = getCategoryHeaderContent[category];
  return (
    <div className={styles.wrapper}>
      <div className={styles.categoryHeader}>
        {icon}
        <label className={styles.categoryLabel} data-testid={Pages.Catalog.categoryHeader(label)}>
          {label}
        </label>
      </div>
      <hr className={styles.separator} />
      <CategoryAid category={category} />
    </div>
  );
};
