import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { TextLink } from 'components/TextLink';

const getStyles = (theme: GrafanaTheme2) => ({
  linksContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: 'fit-content',
  }),
  link: css({
    color: theme.colors.text.link,
    fontSize: theme.typography.body.fontSize,
  }),
});

export const LinkContent = ({ title, links }: { title?: string; links?: Array<{ url: string; title: string }> }) => {
  const styles = useStyles2(getStyles);

  return (
    <li>
      <h2>{title}</h2>
      {links && links.length > 0 && (
        <div className={styles.linksContent}>
          {links.map((link) => (
            <TextLink key={link.title} external href={link?.url} className={styles.link}>
              {link?.title}
            </TextLink>
          ))}
        </div>
      )}
    </li>
  );
};
