import React, { FC } from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { getModalFooterStyles } from '../ModalFooterStyles';

export const K8sModalFooter: FC<{ onClose: () => void }> = ({ onClose }) => {
  const styles = useStyles2(getModalFooterStyles);

  return (
    <div className={styles.footer}>
      <div className={styles.buttonRow}>
        <Button variant="secondary" onClick={onClose}>
          Proceed to install integration
        </Button>
      </div>
    </div>
  );
};
