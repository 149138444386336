import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Alerts, Installation, Metrics, Rules, Screenshot, VersionHistory } from 'models/api-models';
import { Status } from 'utils/enums';

import { Tabs } from '../../pages/Source/TabsEnum';

export type SourceDetails = {
  dashboard_folder?: string;
  installation?: Installation;
  dashboards?: {
    screenshots: Screenshot[];
    status: Status;
  };

  agent_configuration?: {
    supported_platforms?: string[];
  };

  metrics?: {
    status: Status;
    available_metrics?: Metrics[];
  };

  alerts?: {
    status: Status;
    available_alerts?: Alerts[];
  };
  rules?: {
    status: Status;
    available_rules?: Rules[];
  };
  logs?: {
    status: Status;
  };

  version_history?: VersionHistory[];

  metrics_check_query?: string;
  logs_check_query?: string;

  configuration_defaults?: {
    enable_logs_toggle: boolean;
    configurable_logs_defaults: {
      logs_disabled: boolean;
    };
    enable_alerts_toggle: boolean;
    configurable_alerts_defaults: {
      alerts_disabled: boolean;
    };
  };
};

export enum InstallationStatus {
  Idle,
  Pending,
  Success,
  Error,
}

type SourceState = {
  integrationInstallationStatus: InstallationStatus;
  integrationUpdateStatus: InstallationStatus;
  sourceDetails: SourceDetails;
  currentTab: Tabs;
  folderUIDs: string[];
};

const initialState: SourceState = {
  integrationInstallationStatus: InstallationStatus.Idle,
  integrationUpdateStatus: InstallationStatus.Idle,
  sourceDetails: {},
  currentTab: Tabs.ConfigurationDetails,
  folderUIDs: [],
};

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    setIntegrationInstallationStatus(
      state,
      action: PayloadAction<{ status: InstallationStatus; integrationId: string }>
    ) {
      state.integrationInstallationStatus = action.payload.status;
    },
    setIntegrationUpdateStatus(state, action: PayloadAction<{ status: InstallationStatus; integrationId: string }>) {
      state.integrationUpdateStatus = action.payload.status;
    },
    setSourceDetails(state, action: PayloadAction<SourceDetails>) {
      state.sourceDetails = { ...state.sourceDetails, ...action.payload };
    },
    unsetSourceDetails(state) {
      state.sourceDetails = initialState.sourceDetails;
    },
    setSourceVersionHistory(state, action: PayloadAction<VersionHistory[]>) {
      state.sourceDetails.version_history = action.payload;
    },
    openSourcePage(state, action: PayloadAction<string>) {
      state.integrationInstallationStatus = InstallationStatus.Idle;
      state.folderUIDs = initialState.folderUIDs;
    },
    clickOpenUninstallModal: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    clickViewDashboards: (state, action: PayloadAction<string>) => {
      // handled by component
    },
    changeTab: (state, action: PayloadAction<{ currentTab: Tabs; integrationId: string }>) => {
      state.currentTab = action.payload.currentTab;
    },
    setFolderUIDs(state, action: PayloadAction<string[]>) {
      state.folderUIDs = action.payload;
    },
  },
});

export const {
  setIntegrationInstallationStatus,
  setIntegrationUpdateStatus,
  setSourceDetails,
  unsetSourceDetails,
  setSourceVersionHistory,
  openSourcePage,
  clickOpenUninstallModal,
  clickViewDashboards,
  changeTab,
  setFolderUIDs,
} = sourceSlice.actions;

export default sourceSlice.reducer;
