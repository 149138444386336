import { DotNetLogo } from 'img/agent';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const DOTNET_ID = 'dotnet';

export const dotnet: Source = {
  id: DOTNET_ID,
  name: '.NET',
  logo_url: DotNetLogo,
  overview: `OpenTelemetry instrumentation is the recommended standard to observe applications with Grafana Cloud. This integration helps you set up the Grafana Agent and .NET auto-instrumentation to send telemetry to Grafana Cloud.`,
  type: SourceType.AgentIntegration,
  search_keywords: [
    'net',
    'dotnet',
    'microsoft',
    'sharp',
    'c#',
    'f#',
    'fsharp',
    'basic',
    'asp.net',
    'aspnet',
    'asp',
    'vb.net',
    'vbnet',
    'otel',
    'opentelemetry',
  ],
  categories: ['Languages and Frameworks', 'Developer Tools'],
};
