import { RelatedContent, hostedDataIntegrationIdsWithRelatedContentTab } from 'features/hosted-data-integrations';
import { ConfigurationDetails } from 'pages/Source/ConfigurationDetails/ConfigurationDetails';
import { SourceDetails } from 'state/source/slice';
import { Source } from 'types/Source';
import { SourceType, Status } from 'utils/enums';
import { localAgentIntegrations } from 'utils/localSources';

import { Alerts } from './Alerts';
import { ConnectorOverview } from './ConnectorOverview/ConnectorOverview';
import { Metrics } from './Metrics';
import { Tabs } from './TabsEnum';
import { VersionHistory } from './VersionHistory/VersionHistory';

type TabsType = {
  id: Tabs;
  label: string;
  component: React.FC;
  isVisible: boolean;
  isDefault?: boolean;
};

export const getSelectedSourceTabs = (
  sourceDetails: SourceDetails,
  sourceId: string,
  sourceType: SourceType
): TabsType[] | null => {
  const areMetricsAvailable = sourceDetails?.metrics?.status === Status.Available;
  const areAlertsAvailable = sourceDetails?.alerts?.status === Status.Available;
  const isVersionHistoryAvailable = !!sourceDetails.version_history;

  if (sourceType === SourceType.AgentIntegration) {
    if (!!localAgentIntegrations.find((source) => source.id === sourceId)) {
      return [
        {
          id: Tabs.Overview,
          label: 'Overview',
          component: ConnectorOverview,
          isVisible: true,
        },
        {
          id: Tabs.ConfigurationDetails,
          label: 'Configuration details',
          component: ConfigurationDetails,
          isVisible: true,
          isDefault: true,
        },
      ];
    }

    return [
      {
        id: Tabs.Overview,
        label: 'Overview',
        component: ConnectorOverview,
        isVisible: true,
      },
      {
        id: Tabs.ConfigurationDetails,
        label: 'Configuration details',
        component: ConfigurationDetails,
        isVisible: true,
        isDefault: true,
      },
      {
        id: Tabs.Metrics,
        label: 'Metrics',
        component: Metrics,
        isVisible: areMetricsAvailable,
      },
      {
        id: Tabs.Alerts,
        label: 'Alerts',
        component: Alerts,
        isVisible: areAlertsAvailable,
      },
      {
        id: Tabs.VersionHistory,
        label: 'Version history',
        component: VersionHistory,
        isVisible: isVersionHistoryAvailable,
      },
    ];
  } else if (sourceType === SourceType.SaasIntegration || sourceType === SourceType.MetricsEndpointIntegration) {
    return [
      {
        id: Tabs.Overview,
        label: 'Overview',
        component: ConnectorOverview,
        isVisible: true,
      },
      {
        id: Tabs.ConfigurationDetails,
        label: 'Configuration details',
        component: ConfigurationDetails,
        isVisible: true,
        isDefault: true,
      },
      {
        id: Tabs.Alerts,
        label: 'Alerts',
        component: Alerts,
        isVisible: areAlertsAvailable,
      },
      {
        id: Tabs.Metrics,
        label: 'Metrics',
        component: Metrics,
        isVisible: areMetricsAvailable,
      },
      {
        id: Tabs.VersionHistory,
        label: 'Version history',
        component: VersionHistory,
        isVisible: isVersionHistoryAvailable,
      },
    ];
  } else if (sourceType === SourceType.HostedData) {
    return [
      {
        id: Tabs.Overview,
        label: 'Overview',
        component: ConnectorOverview,
        isVisible: true,
      },
      {
        id: Tabs.ConfigurationDetails,
        label: 'Configuration details',
        component: ConfigurationDetails,
        isVisible: true,
        isDefault: true,
      },
      {
        id: Tabs.RelatedContent,
        label: 'Related content',
        component: RelatedContent,
        isVisible: hostedDataIntegrationIdsWithRelatedContentTab.includes(sourceId),
      },
    ];
  }
  return null;
};

export const getCurrentTabComponent = (source: Source, sourceDetails: SourceDetails, tabId: Tabs) => {
  const selectedSourceTabs = getSelectedSourceTabs(sourceDetails, source.id, source.type);

  return selectedSourceTabs?.find((tab) => tabId === tab.id)?.component;
};
