import React from 'react';

import { Alert } from '@grafana/ui';

import { AgentIntegrationInstructionsContent } from 'features/agent-integrations/AgentIntegrationInstructions';

export function SelfHostedMimir() {
  return (
    <>
      <Alert
        title="The Self-Hosted Grafana Mimir integration can only run on a K8s environment. Please follow the instructions below to set up a helm chart to install this integration."
        severity="info"
      />
      <AgentIntegrationInstructionsContent />
    </>
  );
}
