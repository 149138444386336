import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, Icon, useStyles2 } from '@grafana/ui';

import { JobStatus } from 'components/SaasIntegration/JobStatus';
import { useDispatch } from 'hooks/useDispatch';
import { SupabaseJobApiResponse } from 'models/api-models';
import { jobSelected } from 'state/saasIntegration/selectors';
import { toggleSelectedJobs } from 'state/saasIntegration/slice';

import { JobCardProps } from '../JobManager/JobList';
import { getJobCardStyles } from '../common/MetricsEndpointInstructions.styles';

const getColumnStyles = () => ({
  column: css({
    width: '50%',
  }),
});

export const JobCard = ({ job, handleJobDelete, handleJobEdit }: JobCardProps) => {
  const dispatch = useDispatch();
  const isJobSelected = useSelector(jobSelected(job.name));
  const styles = useStyles2((theme: GrafanaTheme2) => getJobCardStyles(theme, { isJobSelected }));
  const columnStyles = useStyles2(getColumnStyles);

  const onChange = useCallback(
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(toggleSelectedJobs(name));
    },
    [dispatch]
  );

  let scrapeIntervalUnit = 'seconds';
  let scrapeIntervalSeconds = (job as SupabaseJobApiResponse).scrape_interval_seconds;
  if (scrapeIntervalSeconds && scrapeIntervalSeconds >= 60) {
    scrapeIntervalSeconds = scrapeIntervalSeconds / 60;
    scrapeIntervalUnit = scrapeIntervalSeconds === 1 ? 'minute' : 'minutes';
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.jobSelector}>
        <div className={styles.jobName}>
          <Checkbox className={styles.checkbox} label={job.name} value={isJobSelected} onChange={onChange(job.name)} />
        </div>
        <JobStatus enabled={job.enabled} disabledReason={job.disabled_reason} />
      </div>
      <table className={styles.table}>
        <colgroup>
          <col className={columnStyles.column} />
          <col className={columnStyles.column} />
        </colgroup>
        <thead>
          <tr>
            <th>Project Reference ID</th>
            <th>Scrape Interval</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(job as SupabaseJobApiResponse).supabase.project_id}</td>
            <td>
              {scrapeIntervalSeconds} {scrapeIntervalUnit}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>
              <div className={styles.icons}>
                <Icon name="pen" onClick={() => handleJobEdit(job)} />
                <Icon name="trash-alt" onClick={() => handleJobDelete(job)} />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
