import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { TextLink } from 'components/TextLink';
import { useApiToken } from 'hooks/useApiToken';
import { GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER, DEFAULT_GRAPHITE_URL } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  link: css`
    color: ${theme.colors.text.link};
    display: inline-block;
    font-size: ${theme.typography.fontSize}px;
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  linkWrapper: css`
    display: flex;
    justify-content: space-between;
  `,
  linkColumn: css`
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 8px;
    }
  `,
});

const generateConfig = (url: string, graphiteType: string) => {
  let constructUrl = DEFAULT_GRAPHITE_URL;
  if (url) {
    constructUrl = graphiteType === 'graphite-v5' ? `${url}/graphite/metrics` : '${url}/metrics';
  }

  return `## Global settings ##
# instance id's distinguish stats of multiple relays.
# do not run multiple relays with the same instance id.
# supported variables:
#  \${HOST} : hostname
instance = "\${HOST}"

## System ##
# this setting can be used to override the default GOMAXPROCS logic
# it is ignored if the GOMAXPROCS environment variable is set
max_procs = 2
pid_file = "carbon-relay-ng.pid"
# directory for spool files
spool_dir = "spool"

## Logging ##
# one of trace debug info warn error fatal panic
# see docs/logging.md for level descriptions
# note: if you used to use "notice", you should now use "info".
log_level = "info"

## Inputs ##
### plaintext Carbon ###
listen_addr = "0.0.0.0:2003"
# close inbound plaintext connections if they've been idle for this long ("0s" to disable)
plain_read_timeout = "2m"
### Pickle Carbon ###
pickle_addr = "0.0.0.0:2013"
# close inbound pickle connections if they've been idle for this long ("0s" to disable)
pickle_read_timeout = "2m"

## Validation of inputs ##
# you can also validate that each series has increasing timestamps
validate_order = false

# How long to keep track of invalid metrics seen
# Useful time units are "s", "m", "h"
bad_metrics_max_age = "24h"

[[route]]
key = 'grafanaNet'
type = 'grafanaNet'
addr = '${constructUrl}'
apikey = '${GRAFANA_EXAMPLE_USER}:${GRAFANA_EXAMPLE_API}'
schemasFile = '/etc/carbon-relay-ng/storage-schemas.conf'
aggregationFile = '/etc/carbon-relay-ng/storage-aggregation.conf' # optional

## Instrumentation ##
[instrumentation]
# in addition to serving internal metrics via expvar, you can send them to graphite/carbon
# IMPORTANT: setting this to "" will disable flushing, and metrics will pile up and lead to OOM
# see https://github.com/graphite-ng/carbon-relay-ng/issues/50
# so for now you MUST send them somewhere. sorry.
# (Also, the interval here must correspond to your setting in storage-schemas.conf if you use Grafana Cloud)
graphite_addr = "localhost:2003"
graphite_interval = 10000  # in ms`;
};

const baseUrl = 'https://packagecloud.io/raintank/raintank/packages';

const carbonRelayPkgName = 'carbon-relay-ng';
const carbonRelayNgPkgVer = '1.2-1';
const carbonRelayRpmFileName = `${carbonRelayPkgName}-${carbonRelayNgPkgVer}.x86_64.rpm`;
const carbonRelayDebFileName = `${carbonRelayPkgName}_${carbonRelayNgPkgVer}_amd64.deb`;

const links = [
  [
    {
      label: 'EL6 (Red Hat 6.x, CentOS 6.x, and CloudLinux 6.x)',
      url: `${baseUrl}/el/6/${carbonRelayRpmFileName}`,
    },
    {
      label: 'EL7 (Red Hat 7.x, CentOS 7.x, and CloudLinux 7.x.)',
      url: `${baseUrl}/el/7/${carbonRelayRpmFileName}`,
    },
  ],
  [
    {
      label: 'Debian Jessie',
      url: `${baseUrl}/debian/jessie/${carbonRelayDebFileName}`,
    },
    {
      label: 'Debian Stretch',
      url: `${baseUrl}/debian/stretch/${carbonRelayDebFileName}`,
    },
    {
      label: 'Debian Buster',
      url: `${baseUrl}/debian/buster/${carbonRelayDebFileName}`,
    },
  ],
  [
    {
      label: 'Ubuntu Xenial',
      url: `${baseUrl}/ubuntu/xenial/${carbonRelayDebFileName}`,
    },
    {
      label: 'Ubuntu Trusty',
      url: `${baseUrl}/ubuntu/trusty/${carbonRelayDebFileName}`,
    },
  ],
];

export const HostedGraphiteMetrics = () => {
  const styles = useStyles2(getStyles);
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const apiToken = useApiToken();
  const successMessage = `Your configuration, complete with your API key, has been generated below. \n
  Make sure the <code>schemasFile</code> field is set to the path to your storage-schemas.conf file, and, optionally, set the <code>aggregationFile</code> field to the path to your storage-aggregation.conf file. Then, copy, paste, and run this command to configure carbon-relay-ng to send metrics to Grafana Cloud. `;

  return (
    <ol>
      <li>
        <h2>Install carbon-relay-ng</h2>
        <p>
          To send Graphite metrics to Grafana Cloud, you will need a running Graphite instance in your environment.{' '}
          <TextLink href="https://grafana.com/docs/" className={styles.link} external inline>
            Learn more about configuring Graphite instance
          </TextLink>{' '}
          .
        </p>
        <p>
          Install a carbon-relay-ng service in each of the datacenter or regions that you will be sending metrics from.
          Choose your platform and follow the link to install the latest version.
        </p>

        <div className={styles.linkWrapper}>
          {links.map((category, index) => (
            <div className={styles.linkColumn} key={index}>
              {category.map((link) => (
                <TextLink key={link.url} href={link.url} className={styles.link} external>
                  {link.label}
                </TextLink>
              ))}
            </div>
          ))}
        </div>
      </li>
      <li>
        <h2>Configure carbon-relay-ng to send metrics to your Grafana Cloud</h2>
        <GenerateAPI
          config={generateConfig(hostedDataDetails.hmInstanceGraphiteUrl, hostedDataDetails.hmInstanceGraphiteType)}
          instanceId={hostedDataDetails.hmInstanceGraphiteId}
          successMessage={successMessage}
        />
      </li>
      {apiToken.token && (
        <li>
          <h2>Create a storage schemas file</h2>
          <p>
            Create a new /etc/carbon-relay-ng/storage-schemas.conf file. This file has the same format as the Graphite
            storage-schemas.conf file.
          </p>
          <p>
            This file assigns resolutions to metrics by pattern matching. This config assumes you are sending all your
            metrics at a 10s resolution, ie. 6 times per minute. Change the 10s to your actual resolution if it is
            different, or create multiple rules as needed. It is important that this file reflects your actual data
            being sent. If you have a pre-existing Graphite installation, you can copy the file from there. (
            <a
              href="https://graphite.readthedocs.io/en/latest/config-carbon.html#storage-schemas-conf"
              target="_blank"
              rel="noreferrer"
            >
              config file details
            </a>
            )
          </p>
          <Clipboard
            code={`[default]
  pattern = .*
  retentions = 10s:1d`}
            multipleLines={true}
            clipboardButtonType="below"
          />
        </li>
      )}
      {apiToken.token && (
        <li>
          <h2>Create a storage aggregation file</h2>
          <p>
            Optionally, create a new /etc/carbon-relay-ng/storage-aggregation.conf file. This file has the same format
            as the Graphite storage-aggregation.conf file. (If not set, the default Grafana Cloud aggregations will be
            applied instead.)
          </p>
          <p>
            This file defines rollup methods to use for your metrics, by pattern matching. This config assumes you want
            all your metrics to be rolled up using averaging. Adjust and create new rules as needed to match the desired
            behavior. (
            <a
              href="https://graphite.readthedocs.io/en/latest/config-carbon.html#storage-aggregation-conf"
              target="_blank"
              rel="noreferrer"
            >
              config file details
            </a>
            ) It is important that this file reflects your actual data being sent. If you have a pre-existing Graphite
            installation, you can copy the file from there.
          </p>
          <Clipboard
            code={`[default]
    pattern = .*
    xFilesFactor = 0.5
    aggregationMethod = average`}
            multipleLines={true}
            clipboardButtonType="below"
          />
        </li>
      )}
    </ol>
  );
};
