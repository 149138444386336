import React, { FC } from 'react';

import { useSelectedSource } from 'api/integrations/hooks';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { MetricsEndpointFlavor } from 'models/api-models';
import { SaasIntegrationWithJobType } from 'state/saasIntegration/slice';

import { JobManager } from '../../SaasIntegrationWithScrapeJobs/JobManager/JobManager';
import {
  CreateJob as SupabaseCreateJob,
  EditJob as SupabaseEditJob,
  JobCard as SupabaseJobCard,
} from '../SupabaseInstructions';

import { CreateJob } from './CreateJob';
import { EditJob } from './EditJob';
import { JobCard } from './JobCard';

export const MetricsEndpointInstructions: FC = () => {
  const selectedSource = useSelectedSource();
  const integrationId = useSelectedIntegrationId();

  if (selectedSource.flavor === MetricsEndpointFlavor.Default) {
    return (
      <JobManager
        saasIntegrationId={integrationId as SaasIntegrationWithJobType}
        EditJobForm={EditJob}
        CreateJobForm={CreateJob}
        JobCard={JobCard}
      />
    );
  } else if (selectedSource.flavor === MetricsEndpointFlavor.Supabase) {
    return (
      <JobManager
        saasIntegrationId={integrationId as SaasIntegrationWithJobType}
        EditJobForm={SupabaseEditJob}
        CreateJobForm={SupabaseCreateJob}
        JobCard={SupabaseJobCard}
      />
    );
  } else {
    return (
      <JobManager
        saasIntegrationId={integrationId as SaasIntegrationWithJobType}
        EditJobForm={EditJob}
        CreateJobForm={CreateJob}
        JobCard={JobCard}
      />
    );
  }
};
