import React, { useState, useMemo } from 'react';

import { RadioButtonGroup, useStyles2, Tooltip, Icon } from '@grafana/ui';

import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { useApiToken } from 'hooks/useApiToken';
import { GRAFANA_EXAMPLE_API, DEFAULT_LOKI_URL } from 'utils/consts';
import { getInfluxUrl } from 'utils/misc';

import { getStyles } from './styles';
import { platformTypeOptions, snippetTypeOptions } from './utilities/dataTypes';
import {
  curlCodeLoki,
  goCodeLoki,
  javaCodeLoki,
  nodejsCodeLoki,
  pythonCodeLoki,
  snippet,
  wgetCodeLoki,
  cloudflareSnippet,
} from './utilities/snippets/codeSnippets';

export const LogsHTTPInstructions = () => {
  const styles = useStyles2(getStyles);
  const [codeSnippet, setCodeSnippet] = useState<string>('curl');
  const [platform, setPlatform] = useState<string>('http');

  const { hlInstanceId, hlInstanceUrl } = useHostedDataDetailsWithFallback();
  const { token } = useApiToken();

  const influxUrl = useMemo(
    () => getInfluxUrl(hlInstanceUrl ? `${hlInstanceUrl}/loki/api/v1/push` : DEFAULT_LOKI_URL),
    [hlInstanceUrl]
  );

  const displaySnippet = () => {
    const snippet = getSnippetTypeLoki(codeSnippet);
    return snippet !== undefined ? snippet : ''; // Provide an empty string as the default value
  };

  const getSnippetTypeLoki = (snippet: string) => {
    if (snippet === 'curl') {
      return curlCodeLoki(hlInstanceId, token, influxUrl);
    } else if (snippet === 'python') {
      return pythonCodeLoki(hlInstanceId, token, influxUrl);
    } else if (snippet === 'node') {
      return nodejsCodeLoki(hlInstanceId, token, influxUrl);
    } else if (snippet === 'wget') {
      return wgetCodeLoki(hlInstanceId, token, influxUrl);
    } else if (snippet === 'go') {
      return goCodeLoki(hlInstanceId, token, influxUrl);
    } else if (snippet === 'java') {
      return javaCodeLoki(hlInstanceId, token, influxUrl);
    } else {
      return;
    }
  };

  const successMessage = 'Your Access Policy Token has been generated below!';
  const isLoggingSelected = platform === 'logging library';

  return (
    <>
      <h1>Configure sending Logs via HTTP</h1>
      <ol className={styles.instructions}>
        <li>
          <h2>
            Select Format
            <Tooltip
              placement="top"
              content="via HTTP : Send logs directly using an HTTP request using the provided code samples. Configured Logs will not display in your terminal. via Logging Library: Use a special library for easy logging. Configured Logs will display in your terminal and Grafana Cloud's Loki for monitoring."
            >
              <Icon name="info-circle" className={styles.infoIcon} />
            </Tooltip>
          </h2>
          <RadioButtonGroup
            options={platformTypeOptions}
            value={platform}
            onChange={(v) => setPlatform(v)}
            className={styles.radioButtonGroup}
          />
        </li>

        <li>
          <h2>Generate an Access Policy Token</h2>
          <p>
            <b>
              Generate an Access Policy Token to be used to authenticate your logs (token will be added to below code
              snippets once generated.)
            </b>
          </p>
          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hlInstanceId}
            successMessage={successMessage}
          />
        </li>
        <li>
          <h2>Anatomy of your Loki log</h2>
          <p>The request body to send to a Loki ingest URL</p>
          <pre>{snippet()}</pre>
        </li>
        {isLoggingSelected ? (
          <li>
            <h2>Install the library</h2>
            <Clipboard code={'npm i @miketako3/cloki'} clipboardButtonType="below" />
            <p>Add the logger to your application and start pushing logs to Grafana Cloud!</p>

            <Clipboard
              code={cloudflareSnippet(hlInstanceUrl, hlInstanceId, token)}
              multipleLines={true}
              expandHeight={true}
              clipboardButtonType="below"
            />
          </li>
        ) : (
          <li>
            <h2>Send logs from your application code.</h2>
            <p>
              <b>Add the code snippet of your choice to your application and start pushing logs to Grafana Cloud!</b>
            </p>
            <RadioButtonGroup
              options={snippetTypeOptions}
              value={codeSnippet}
              onChange={(v) => setCodeSnippet(v)}
              className={styles.radioButtonGroup}
            />
            {displaySnippet() !== '' ? (
              <Clipboard code={displaySnippet()} multipleLines={true} expandHeight={true} clipboardButtonType="below" />
            ) : (
              'Select a snippet type'
            )}
          </li>
        )}
      </ol>
    </>
  );
};
