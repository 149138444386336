import { css } from '@emotion/css';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { InstallCard } from 'components/InstallCard';
import { Pages } from 'e2eSelectors/pages';
import { ScrapeJob } from 'models/api-models';
import { RootState } from 'state';
import { SaasIntegrationWithJobType } from 'state/saasIntegration/slice';
import { InstallationStatus } from 'state/source/slice';

import { ScrapeJobActions } from './ScrapeJobActions';

type JobListCallbacks = {
  handleJobDelete: (job: ScrapeJob) => void;
  handleJobEdit: (job: ScrapeJob) => void;
};

export type JobCardProps = JobListCallbacks & { job: ScrapeJob };

const getStyles = (theme: GrafanaTheme2) => ({
  scrapeJobsTop: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
    margin-top: 0;
  `,
  step: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
  marginRight: css`
    margin-right: ${theme.spacing(1)};
  `,
});

type JobListProps = JobListCallbacks & {
  jobs: ScrapeJob[];
  handleAddScrapeJob: () => void;
  JobCard: FC<JobCardProps>;
  saasIntegrationId: SaasIntegrationWithJobType;
};

export const JobList = ({
  handleJobDelete,
  handleJobEdit,
  handleAddScrapeJob,
  jobs,
  JobCard,
  saasIntegrationId,
}: JobListProps) => {
  const styles = useStyles2(getStyles);

  const { integrationInstallationStatus } = useSelector((state: RootState) => state.source);

  const source = useSelectedAgentOrSaasIntegration();

  return (
    <>
      <div className={styles.scrapeJobsTop}>
        <h2>Your scrape jobs</h2>
        <div>
          <Button variant="primary" onClick={handleAddScrapeJob} data-testid={Pages.SaaSIntegration.addScrapeJobButton}>
            Add new scrape job
          </Button>
        </div>
      </div>

      <ScrapeJobActions saasIntegrationId={saasIntegrationId} />
      {jobs.map((job) => (
        <JobCard key={job.name} job={job} handleJobDelete={handleJobDelete} handleJobEdit={handleJobEdit} />
      ))}

      {saasIntegrationId === 'confluent-cloud' &&
        integrationInstallationStatus !== InstallationStatus.Pending &&
        source?.installation === undefined && <InstallCard installCardStyles={styles.step} />}
    </>
  );
};
