import * as api from 'api/baseApi';
import {
  CheckConnectionError,
  HostedExportersApiErrorResult,
  isErrorResponse,
  SaasIntegrationConnection,
} from 'models/api-models';

export async function checkConnection(
  connectionData: SaasIntegrationConnection,
  jobType: string,
  mainErrorField: string
): Promise<void> {
  try {
    await api.requestIntegrations(`/he-api/${jobType}/verify-auth`, {
      method: 'POST',
      showErrorAlert: false,
      data: JSON.stringify(connectionData),
    });
  } catch (error) {
    if (mainErrorField && isErrorResponse<HostedExportersApiErrorResult>(error)) {
      const checkConnectionError: CheckConnectionError = { ...error, context: { mainErrorField } };
      throw checkConnectionError;
    }
    throw error;
  }
}
