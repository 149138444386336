import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Modal, useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { InstallationStatus } from 'state/source/slice';
import { RudderStackEvents } from 'utils/enums';

import { updateConfiguration } from '../state/actions';
import { isAlertsToggleDirty } from '../state/isAlertsToggleDirty';
import { isLogsToggleDirty } from '../state/isLogsToggleDirty';

import { getStyles } from './SaveConfigurationSelections.styles';

export const SaveConfigurationSelections = () => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();

  const { trackRudderStackEvent } = useRudderStack();

  const source = useSelectedAgentOrSaasIntegration();
  const integrationId = useSelectedIntegrationId();
  const { orgSlug } = useHostedDataDetailsWithFallback();
  const { isLogsEnabled, isAlertsEnabled } = useSelector((state: RootState) => state.agent.configuredParameters);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const saveSelectionsStatus = useSelector((state: RootState) => state.agent.saveSelectionsStatus);
  const isSavingSelections = saveSelectionsStatus === InstallationStatus.Pending;

  const isLogsDirty = useSelector((state: RootState) => isLogsToggleDirty(state));
  const isAlertsDirty = useSelector((state: RootState) => isAlertsToggleDirty(state));
  const isThereAnythingToSave = isLogsDirty || isAlertsDirty;

  useEffect(() => {
    if (!isSavingSelections) {
      setIsModalOpen(false);
    }
  }, [isSavingSelections]);

  return (
    <>
      <Modal
        className={styles.overrideModal}
        title="Save configuration selections"
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
      >
        <div className={styles.modalContent}>
          <div className={styles.alertText}>
            <Icon className={styles.icon} name="info-circle" size="lg" />
            <span>There may be downstream effects of saving your selections:</span>
          </div>
          {isLogsDirty && (
            <p className={styles.text}>
              Saving your new selections will overwrite any custom changes that have been made directly to the default
              dashboards. If you wish to keep any custom changes made to the included dashboards, make a copy of these
              before saving these selections. Otherwise, if you used the data from this integration to create your own,
              separate custom dashboards and alerts, there will be no effect.
            </p>
          )}
          {isAlertsDirty && (
            <p className={styles.text}>
              Saving your new selections will install or remove alerts based on your configuration. Make sure to review
              your alert settings before proceeding.
            </p>
          )}
          <div className={styles.buttons}>
            <Button
              variant="primary"
              disabled={isSavingSelections}
              onClick={() => {
                dispatch(updateConfiguration({ integrationId, orgSlug }));
                trackRudderStackEvent(RudderStackEvents.SaveConfigSelections, {
                  integration_slug: integrationId,
                  logs_enabled: isLogsEnabled,
                  alerts_enabled: isAlertsEnabled,
                });
              }}
            >
              {isSavingSelections && <Icon className={styles.icon} name="fa fa-spinner" />}
              Save configuration selections
            </Button>
            <Button
              variant="secondary"
              disabled={isSavingSelections}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        disabled={Boolean(source.has_update) || !isThereAnythingToSave}
        variant="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
        className={styles.saveButton}
      >
        Save configuration selections
      </Button>
    </>
  );
};
