import { debounce } from 'lodash';
import React from 'react';

import { Field, Input, Label, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';

import { removeLoadingBar, setHostName } from '../state/slice';

import { getStyles } from './HostnameFiled.styles';

export const HostnameField = ({ onFocus, onBlur }: { onFocus: () => void; onBlur: () => void }) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();

  const debouncedRemoveLoadingBar = debounce(() => dispatch(removeLoadingBar()), 1500);

  const HostnameLabel = (
    <Label
      className={styles.label}
      description="Add the hostname value you want to set for your integration. This will uniquely identify this instance on your dashboards and alerts."
    >
      Value for Hostname
    </Label>
  );
  return (
    <Field label={HostnameLabel}>
      <Input
        type="text"
        aria-label="Hostname"
        id="hostname"
        name="value"
        placeholder="Hostname"
        onChange={(e) => {
          dispatch(setHostName(e.currentTarget.value));
          debouncedRemoveLoadingBar();
        }}
        className={styles.hostnameInput}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid={Pages.Source.AgentConfig.hostnameRelabelInput}
      />
    </Field>
  );
};
