import * as api from 'api/baseApi';

import {
  ApiIntegrationDetails,
  DashboardInstallInfo,
  SuccessfulApiResult,
  VersionHistory,
} from '../../models/api-models';
import { PLUGIN_PROXY_URL } from '../utils';

export async function installIntegration(
  integrationId: string,
  isLogsEnabled?: boolean,
  isAlertsEnabled?: boolean
): Promise<void> {
  const body = {
    configuration: {
      configurable_logs: {
        logs_disabled: false,
      },
      configurable_alerts: {
        alerts_disabled: false,
      },
    },
  };

  if (isLogsEnabled !== undefined) {
    body.configuration.configurable_logs.logs_disabled = !isLogsEnabled;
  }

  if (isAlertsEnabled !== undefined) {
    body.configuration.configurable_alerts.alerts_disabled = !isAlertsEnabled;
  }

  await api.requestIntegrations(`/integrations-api-admin/integrations/${integrationId}/install`, {
    method: 'POST',
    showErrorAlert: false,
    data: body,
  });
}

export async function uninstallIntegration(integrationId: string): Promise<void> {
  await api.requestIntegrations(`/integrations-api-admin/integrations/${integrationId}/uninstall`, {
    method: 'POST',
    showErrorAlert: false,
  });
}

export async function upgradeIntegration(integrationId: string): Promise<void> {
  await api.requestIntegrations(`/integrations-api-admin/integrations/${integrationId}/upgrade`, {
    method: 'POST',
    showErrorAlert: false,
  });
}

export async function getIntegrationDetails(integrationId: string): Promise<ApiIntegrationDetails> {
  const response = await api.requestIntegrations<SuccessfulApiResult<ApiIntegrationDetails>>(
    `/integrations-api-admin/integrations/${integrationId}`
  );

  return response.data.data;
}

export async function getIntegrationChangelog(integrationId: string): Promise<VersionHistory[]> {
  const response = await api.requestIntegrations(`/integrations-api-admin/integrations/${integrationId}/changelog`);

  return response.data.data;
}

export async function queryDatasource<R = unknown>(datasourceName: string, query: string): Promise<R[]> {
  const queryStart = datasourceName === 'grafanacloud-logs' ? '/loki' : '';

  const response = await api.requestDatasource<{ data: { result: R[] } }>(
    datasourceName,
    `${queryStart}/api/v1/query?query=${encodeURIComponent(query)}`
  );

  if (response.status !== 200) {
    throw response;
  }
  return response.data.data.result;
}

export async function getDashboardInstallInfos(
  integrationId: string,
  isLogsEnabled?: boolean
): Promise<DashboardInstallInfo[]> {
  const body = {
    configuration: {
      configurable_logs: {
        logs_disabled: false,
      },
    },
  };

  if (isLogsEnabled !== undefined) {
    body.configuration.configurable_logs.logs_disabled = !isLogsEnabled;
  }

  const response = await api.post<SuccessfulApiResult<DashboardInstallInfo[]>>(
    `${PLUGIN_PROXY_URL}/integrations-api-admin/integrations/${integrationId}/dashboards`,
    body
  );
  return response.data;
}
