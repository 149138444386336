import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getMetricsStyles = (theme: GrafanaTheme2) => ({
  wrapper: css``,
  table: css`
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
    margin-top: 12px;
    tbody tr:nth-child(even) {
      background: transparent;
    }
    tbody tr:nth-child(odd) {
      background: ${theme.colors.background.secondary};
    }
    td {
      font-weight: 400;
      font-size: 12px;
    }
    td,
    th {
      &:not(:last-child) {
        width: 200px;
      }
      padding: 10px 8px;
    }
  `,
  buttons: css`
    a:not(:last-child) {
      margin-right: 10px;
    }
  `,
});
