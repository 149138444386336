import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { createDashboard, createFolder } from 'api/hostedGrafana/hostedGrafana';
import { QueryError } from 'types';
import { Dashboard, InstallDashboardRequest } from 'types/dashboard';

export const dashboardsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'api/' }),
  reducerPath: 'dashboardsApi',
  endpoints: (build) => ({
    installDashboard: build.mutation<Dashboard, InstallDashboardRequest>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const { folderTitle, dashboard, slugUrl, overwrite } = args;
        try {
          await createFolder(folderTitle);
          const dashboardUpdated = JSON.parse(JSON.stringify(dashboard).replaceAll('$slugUrl', slugUrl));
          const result: Dashboard = await createDashboard(dashboardUpdated, folderTitle, overwrite);
          return { data: result };
        } catch (e) {
          const err = e as any;
          return { error: { data: { message: err?.data?.error || err?.message } } as QueryError };
        }
      },
    }),
  }),
});

export const { useInstallDashboardMutation } = dashboardsApi;
