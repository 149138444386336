import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

export function useJobTypeWithFlavor(): { jobType: string; flavor?: string } {
  const saasIntegrationId = useSelectedIntegrationId();
  const selectedSource = useSelectedAgentOrSaasIntegration();
  if (selectedSource.type === 'metrics-endpoint') {
    return { jobType: 'metrics-endpoint', flavor: selectedSource.flavor };
  } else {
    return { jobType: saasIntegrationId };
  }
}
