import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { Resource } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';
import { ActionType, transformResources } from 'utils/saasIntegrations';

import { CreateJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';
import { ScrapeJobNameField } from '../common/ScrapeJobNameField';

import AddConfluentAPIKey from './AddConfluentAPIKey';
import { AddResources } from './AddResources';
import { getJobFormStyles } from './ConfluentInstructionsStyles';
import { InstructionsComponent } from './InstructionsComponent';

type ReactHookFormProps = {
  resources: Resource[];
  apiKey: string;
  apiSecret: string;
  name: string;
};

const defaultValues: ReactHookFormProps = {
  apiKey: '',
  apiSecret: '',
  name: '',
  resources: [{ resourceType: '', resourceId: '' }],
};

export const CreateJob: FC<CreateJobFormProps> = ({ onSaveJob }) => {
  const styles = useStyles2(getJobFormStyles);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });

  const methods = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleCreate: SubmitHandler<ReactHookFormProps> = (currentJob) => {
    const confluentApiJob = currentJob.resources.reduce(transformResources, {} as { [key: string]: string[] });

    onSaveJob({
      name: currentJob.name,
      api_key: {
        name: currentJob.apiKey,
        secret: currentJob.apiSecret,
      },
      enabled: true,
      ...confluentApiJob,
    });
  };

  const isButtonDisabled =
    !connectionSuccess || createJobStatus === QueryStatus.pending || createJobStatus === QueryStatus.fulfilled;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCreate)}>
        <InstructionsComponent />
        <div className={styles.job}>
          <ScrapeJobNameField />
          <AddConfluentAPIKey actionType={ActionType.Add} onSuccess={() => setConnectionSuccess(true)} />
          <AddResources isAppendButtonEnabled={connectionSuccess} />
          <JobStatusWidget />
          <Button variant="primary" type="submit" aria-label="Save Scrape Job" disabled={isButtonDisabled}>
            {createJobStatus === QueryStatus.pending && <Spinner className={styles.spinner} />}
            Save Scrape Job
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
