import React from 'react';

import { TextLink } from 'components/TextLink';

import { STATIC_CONFIG_LINK, STATIC_LINK } from '../consts';

export const AgentStaticDescription = () => {
  return (
    <>
      <p>
        Grafana Agent Static is the original mode of the Grafana agent, and is configured using a{' '}
        <TextLink href={STATIC_CONFIG_LINK} external inline>
          YAML file
        </TextLink>
        , which is organized into sections representing metrics, logs, and traces scrapes.
      </p>
      <p>
        Read more about{' '}
        <TextLink href={STATIC_LINK} external inline>
          Grafana Agent Static
        </TextLink>
        .
      </p>
    </>
  );
};
