import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getModalFooterStyles = (theme: GrafanaTheme2) => ({
  footer: css`
    display: flex;
    flex-direction: column;

    flex-shrink: 0;
    margin-top: ${theme.spacing(2)};
    border-top: 1px solid ${theme.colors.border.medium};
    padding-top: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};
  `,
  buttonRow: css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(2)};
    align-items: flex-end;
  `,
});
