import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';
import { isEditor } from 'utils/user';

// Editors have access to cloud apps, therefore we show them the cards with proper links
export function isPermissionMissing(item: Source) {
  return isEditor() && item.type !== SourceType.CloudApp;
}

export function isCardWithModal(item: Source) {
  return item.type === SourceType.Alpha;
}
