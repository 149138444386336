export enum SourceType {
  SaasIntegration = 'saas',
  MetricsEndpointIntegration = 'metrics-endpoint',
  HostedData = 'hosted-data',
  AgentIntegration = 'agent',
  CloudApp = 'cloud-app',
  DataSource = 'data-source',
  Alpha = 'alpha',
}

export enum SourcePlan {
  Enterprise = 'enterprise',
  Cloud = 'cloud',
  OSS = 'oss',
  Active = 'active',
}

export enum Status {
  Available = 'available',
  NotSupported = 'not_supported',
}

export enum PrometheusOptions {
  SingleInstance = 'single_instance',
  MultipleInstances = 'multiple_instances',
  MultipleInstancesHa = 'multiple_instances_ha',
}

export enum ConnectionStatus {
  None,
  Pending,
  Success,
  Error,
}

export enum PlanType {
  Gcloud = 'gcloud',
  Free = 'free',
}

export enum LanguageOptions {
  GrafanaAlloy = 'Grafana Alloy',
  GrafanaAgent = 'Grafana Agent',
  Golang = 'Golang',
  Java = 'Java',
  Dotnet = 'Dotnet',
  EBPF = 'eBPF',
  Python = 'Python',
  Ruby = 'Ruby',
  NodeJs = 'Node.js',
  Rust = 'Rust',
}

export enum RudderStackEvents {
  PageView = 'Page View',
  ViewSource = 'connections_source_page_opened',
  ViewDashboards = 'connections_view_dashboards_button_clicked',
  AbandonUninstall = 'connections_abandon_uninstall_integration_clicked',
  InstallButton = 'connections_install_integration_button_clicked',
  UninstallButton = 'connections_uninstall_integration_button_clicked',
  UpdateButton = 'connections_update_integration_button_clicked',
  UpdateFailed = 'connections_update_integration_failed',
  UpdateSucceeded = 'connections_update_integration_succeeded',
  RemoveIntegration = 'connections_remove_integration_button_clicked',
  InstallSuccess = 'connections_install_integration_succeeded',
  InstallFailed = 'connections_install_integration_failed',
  CardClick = 'connections_integration_card_clicked',
  SearchInput = 'connections_catalog_input_search_typed',
  SearchSetFromUrl = 'connections_catalog_input_search_set_from_url',
  SearchCategorySetFromUrl = 'connections_catalog_input_search_category_set_from_url',
  SearchCategorySelected = 'connections_catalog_search_category_selected',
  SearchExactMatchSelected = 'connections_catalog_search_exact_match_selected',
  FilterClick = 'connections_catalog_category_filter_pill_clicked',
  FilterSetFromUrl = 'connections_catalog_category_filter_set_from_url',
  TestConnectionClick = 'connections_test_agent_connection_clicked',
  TestConnectionSucceeded = 'connections_test_agent_connection_succeeded',
  TestConnectionFailed = 'connections_test_agent_connection_failed',
  StateFilterClicked = 'connections_catalog_state_filter_clicked',
  StateFilterSetFromUrl = 'connections_catalog_state_filter_set_from_url',
  SortClicked = 'connections_catalog_sort_filter_clicked',
  SortSetFromUrl = 'connections_catalog_sort_set_from_url',
  DashboardsButtonClick = 'connections_dashboards_button_clicked',
  AlertsAndRecordingButtonClick = 'connections_alerts_and_recording_button_clicked',
  TestIntegrationConnectionClick = 'connections_test_integration_connection_clicked',
  TestIntegrationConnectionSucceeded = 'connections_test_integration_connection_succeeded',
  TestIntegrationConnectionFailed = 'connections_test_integration_connection_failed',
  TestIntegrationConnectionTroubleshootingDocsLinkClick = 'connections_test_integration_connection_troubleshooting_docs_link_clicked',
  OpenAgentConfigModalButton = 'connections_agent_config_modal_opened',
  CreateApiKeySucceeded = 'connections_create_api_key_succeeded',
  TabChanged = 'connections_integration_tab_changed',
  MetricsSetToggled = 'connections_metrics_set_toggled',
  FilterReset = 'connections_catalog_filter_reset_clicked',
  SeeUpdates = `connections_catalog_see_updates_clicked`,
  OpenExtendedMetricsModalButton = 'extended_metrics_modal_opened',
  CopiedSnippetToClipboard = 'connections_code_snippet_copied',
  SaveConfigSelections = 'connections_save_config_selections_clicked',
  LogsToggled = 'connections_logs_toggled',
  AlertsToggled = 'connections_alerts_toggled',
  IntermediateInstructionClick = `connections_intermediate_instructions_step_clicked`,
  AgentModeSelectionProceed = 'connections_agent_mode_selection_proceed',
  AgentModeSwitchToStatic = 'connections_agent_mode_switch_to_static',
  AgentModeSwitchToFlow = 'connections_agent_mode_switch_to_flow',
  AgentModeViewAllOptions = 'connections_agent_mode_view_all_options',
  AgentModeProceedFromConvertPage = 'connections_agent_mode_proceed_from_convert_page',
  AgentModePageSetFromUrl = 'connections_agent_mode_set_from_url',
  AgentModePageUpdated = 'connections_agent_mode_page_updated',
  TestTracesConnectionClick = 'connections_test_traces_connection_clicked',
  TestTracesConnectionSucceeded = 'connections_test_traces_connection_succeeded',
  TestTracesConnectionNoData = 'connections_test_traces_connection_nodata',
  TestTracesConnectionFailed = 'connections_test_traces_connection_failed',
  DataSourceRequestClicked = 'connections_data_source_request_clicked',
  DataSourceRoadmapClicked = 'connections_data_source_roadmap_clicked',

  // SaaS integrations
  CreateScrapeJobInitiated = `connections_scrape_job_creation_initiated`,
  UpdateScrapeJobInitiated = `connections_scrape_job_update_initiated`,
  CreateOrUpdateScrapeJobSuccessfully = `connections_scrape_job_create_or_update_success`,
  CreateOrUpdateScrapeJobFailed = `connections_scrape_job_create_or_update_failed`,
  CreateOrUpdateScrapeJobPending = `connections_scrape_job_create_or_update_pending`,

  /* Integrations page (formerly called Infrastructure) */
  InfraPageView = 'Your Infrastructure Page View',
  AddNewInfraIntegrationClick = 'connections_add_new_infra_integration_clicked',
  InfraSearchInput = 'connections_input_in_infra_search_typed',
  InfraSortSelected = 'connections_infra_sort_option_selected',
  InfraCardClick = 'connections_infra_integration_card_clicked',
  InfraCardDashboardsClick = 'connections_dashboards_button_on_infra_integration_card_clicked',
  InfraCardAlertsClick = 'connections_alerts_button_on_infra_integration_card_clicked',
}

export enum FilterJobStatus {
  All,
  Enabled,
  Disabled,
}

export enum CollectorMode {
  Alloy = 'alloy',
  AgentStatic = 'static',
}

export enum ApiKeyType {
  ExistingKey = 'existing_key',
  NewKey = 'new_key',
}

export enum EventSource {
  URL = 'url',
  Click = 'click',
  Type = 'type',
}

// source: https://github.com/grafana/grafana-com/blob/main/packages/grafana-com-base/lib/types/org-grafana-cloud.ts
export enum Tiers {
  Free = 'free',
  Trial = 'trial',
  Pro = 'subscribed',
  GCPPro = 'gcp-subscribed',
  GCPFlatFeePro = 'gcp-flat-fee-subscribed',
  LegacyPro = 'legacy-subscribed',
  AzurePro = 'azure-subscribed',
}

export enum OtelFlavor {
  Generic = 1,
  Java,
  Collector,
}

export enum MetricsEndpointErrorCodes {
  InvalidInput = 'invalid_input',
  InvalidStatusCode = 'invalid_status_code',
  NotPrometheusCompatible = 'not_prometheus_compatible',
  UnauthorizedAccess = 'unauthorized_access',
  InvalidCertificate = 'invalid_certificate',
  DNSError = 'dns_error',
  URLError = 'url_error',
  RedirectUnsupportedError = 'redirect_unsupported_error',
  InvalidAuthSetup = 'invalid_auth_setup',
  NetworkTimeout = 'network_timeout',
}

export enum PrometheusMethodForwarding {
  Alloy = 'alloy',
  RemoteWrite = 'remote',
}

export enum PrometheusRemoteWrite {
  Direct = 'direct',
  ViaAlloy = 'via-alloy',
}
