import { Route } from 'react-router-dom';

import {
  getWebInstrumentations,
  initializeFaro as initFaro,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { config } from '@grafana/runtime';

import { PLUGIN_URLS } from 'utils/misc';

import { getFaroConfig } from './faroConfig';

const { environment, url, name } = getFaroConfig();
const FARO_GLOBAL_OBJECT_KEY = 'easystart-app-faro';

export function initializeFaro() {
  if (Object.prototype.hasOwnProperty.call(window, FARO_GLOBAL_OBJECT_KEY)) {
    return (window as any)[FARO_GLOBAL_OBJECT_KEY];
  } else {
    return initFaro({
      url,
      app: {
        name,
        version: PACKAGE_VERSION,
        environment,
      },
      instrumentations: [
        ...getWebInstrumentations(),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              history,
              Route,
            },
          },
        }),
      ],
      isolate: true,
      globalObjectKey: FARO_GLOBAL_OBJECT_KEY,
      beforeSend: (event) => {
        const isOnPluginPage = PLUGIN_URLS.some((pluginUrl) => (event.meta.page?.url ?? '').includes(pluginUrl));
        if (!isOnPluginPage) {
          return null;
        }

        return event;
      },
      user: {
        id: String(config.bootData.user.id),
        username: config.bootData.user.name,
        attributes: {
          orgId: String(config.bootData.user.orgId),
        },
      },
    });
  }
}
