import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { GenerateToken, RealmTypes, ScopePermissions } from '@grafana-cloud/access-policies';
import { Clipboard } from '@grafana-cloud/ui';
import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { apiTokenGenerateSuccess } from 'features/app/state/slice';
import { useApiToken } from 'hooks/useApiToken';
import { useApiTokenCacheKey } from 'hooks/useApiTokenCacheKey';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { Scope } from 'models/access-policies';
import { getAccessPolicyName } from 'utils/AccessPolicyUtils';
import { GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';
import { jsonStringRepl } from 'utils/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  subtitle: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    margin-bottom: 0;
  `,
  generateToken: css`
    font-size: ${theme.typography.body.fontSize};
  `,
});

type Props = {
  config: string;
  instanceId: number;
  successMessage: string;
  shouldShowClipboard?: boolean;
  shouldShowInstruction?: boolean;
};

export const GenerateAPI: FC<Props> = ({
  config,
  instanceId,
  successMessage,
  shouldShowClipboard = true,
  shouldShowInstruction = true,
}) => {
  const styles = useStyles2(getStyles);
  const { id: stackId } = useHostedDataDetailsWithFallback();
  const dispatch = useDispatch();
  const fixedCacheKey = useApiTokenCacheKey();

  const { token } = useApiToken();
  const integrationId = useSelectedIntegrationId();

  let updatedConfig = jsonStringRepl(config, new RegExp(GRAFANA_EXAMPLE_USER, 'g'), instanceId.toString());

  if (token) {
    updatedConfig = jsonStringRepl(updatedConfig, new RegExp(GRAFANA_EXAMPLE_API, 'g'), token);
  }

  function onTokenGenerated() {
    dispatch(apiTokenGenerateSuccess(integrationId));
  }

  return (
    <>
      {shouldShowInstruction && (
        <p className={styles.title}>
          Click below to generate a Grafana.com Access Policy token. This Access Policy token will appear as the
          password in the configuration below.
        </p>
      )}

      <div className={styles.generateToken}>
        <GenerateToken
          onTokenGenerated={onTokenGenerated}
          fixedCacheKey={fixedCacheKey}
          successMessage={successMessage}
          showExpiration={false}
          showScopes={true}
          showToken={false}
          tokenNameLabel="Access Policy token name"
          tokenNamePlaceholder="Access Policy token name"
          tokenNameDescription="The name will help you view and revoke this token in grafana.com later."
          accessPolicyName={getAccessPolicyName(stackId)}
          scopes={[Scope.METRICS, Scope.LOGS, Scope.TRACES, Scope.PROFILES].map(
            (scope) => scope + ':' + ScopePermissions.WRITE
          )}
          realms={[
            {
              type: RealmTypes.STACK,
              identifier: String(stackId),
              labelPolicies: [],
            },
          ]}
        />
        {token && shouldShowClipboard && (
          <Clipboard code={updatedConfig} multipleLines={true} expandHeight={true} clipboardButtonType="below" />
        )}
      </div>
    </>
  );
};
