export enum MetricsSetType {
  Filtered = 'filtered',
  Unfiltered = 'unfiltered',
}

interface APIAgentDetailsStatic {
  default_type: MetricsSetType;
  enable_hostname_relabel_and_filter: boolean;
  pre_instructions: string;
  post_instructions: string;
  integrations_snippets: string;
  integrations_snippets_filtered: string;
  metrics_snippets: string;
  metrics_snippets_filtered: string;
  logs_snippets: string;
  agent_example_configuration: string;
  agent_example_configuration_filtered: string;
  agent_example_configuration_filtered_logs_disabled: string;
  agent_example_configuration_logs_disabled: string;
}

type SimpleBlocks = {
  blocks: string;
  blocks_filtered?: string;
};

type SimpleLogBlocks = {
  blocks: string;
};

type AdvancedBlocks = {
  blocks: string;
  blocks_filtered?: string;
  instructions: string;
};

type AdvancedLogBlocks = {
  blocks: string;
  instructions: string;
};

export type APIAlloyPlatforms<Blocks> =
  | {
      all: Blocks;
    }
  | {
      darwin: Blocks;
      linux: Blocks;
      windows: Blocks;
    };

export interface APIAgentDetailsAlloySimple {
  general_guidance: string;

  metrics?: APIAlloyPlatforms<SimpleBlocks>;
  integrations?: APIAlloyPlatforms<SimpleBlocks>;
  logs?: APIAlloyPlatforms<SimpleLogBlocks>;
}

export interface APIAgentDetailsAlloyAdvanced {
  general_guidance: string;

  metrics?: APIAlloyPlatforms<AdvancedBlocks>;
  integrations?: APIAlloyPlatforms<AdvancedBlocks>;
  logs?: APIAlloyPlatforms<AdvancedLogBlocks>;
}

export type APIAgentDetailsAlloy = {
  pre_instructions: string;
} & (
  | { simple: APIAgentDetailsAlloySimple }
  | { advanced: APIAgentDetailsAlloyAdvanced }
  | {
      simple: APIAgentDetailsAlloySimple;
      advanced: APIAgentDetailsAlloyAdvanced;
    }
);

export type APIAgentDetails =
  | {
      static: APIAgentDetailsStatic;
    }
  | {
      alloy: APIAgentDetailsAlloy;
    };

export interface StaticContent {
  defaultType: MetricsSetType;
  enableHostnameRelabelAndFilter: boolean;
  preInstructions: string;
  postInstructions: string;
  integrationsSnippets: string;
  integrationsSnippetsFiltered: string;
  metricsSnippets: string;
  metricsSnippetsFiltered: string;
  logsSnippets: string;
  agentExampleConfiguration: string;
  agentExampleConfigurationFiltered: string;
  agentExampleConfigurationFilteredLogsDisabled: string;
  agentExampleConfigurationLogsDisabled: string;
}

export type AlloyPlatforms<Blocks> =
  | {
      type: 'unified';
      all: Blocks;
    }
  | {
      type: 'specific';
      darwin: Blocks;
      linux: Blocks;
      windows: Blocks;
    };

export interface AlloySimpleContent {
  preInstructions: string;
  generalGuidance: string;

  metrics?: AlloyPlatforms<SimpleBlocks>;
  integrations?: AlloyPlatforms<SimpleBlocks>;
  logs?: AlloyPlatforms<SimpleLogBlocks>;
}

export interface AlloyAdvancedContent {
  preInstructions: string;
  generalGuidance: string;

  metrics?: AlloyPlatforms<AdvancedBlocks>;
  integrations?: AlloyPlatforms<AdvancedBlocks>;
  logs?: AlloyPlatforms<AdvancedLogBlocks>;
}

export type AgentDetails =
  | {
      contentType: 'static';
      static: StaticContent;
    }
  | {
      contentType: 'alloy-advanced';
      advanced: AlloyAdvancedContent;
    }
  | {
      contentType: 'alloy-simple';
      simple: AlloySimpleContent;
    }
  | {
      contentType: 'alloy-both-modes';
      simple: AlloySimpleContent;
      advanced: AlloyAdvancedContent;
    };

export type AgentDetailsStatic = AgentDetails & { contentType: 'static' };
export type AgentDetailsAlloyAdvanced = AgentDetails & { contentType: 'alloy-advanced' };
export type AgentDetailsAlloySimple = AgentDetails & { contentType: 'alloy-simple' };
export type AgentDetailsAlloyBothModes = AgentDetails & { contentType: 'alloy-both-modes' };
