import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';

import { GrafanaAgentMessage } from './GrafanaAgentMessage';

export const TestStatusSuccess = () => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <StatusMessage status="success" className={styles.alert}>
        Awesome! The agent is good to go.
      </StatusMessage>
      <GrafanaAgentMessage />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  alert: css({
    marginTop: theme.spacing(2),
    marginBottom: 0,
  }),
});
