import { OrgInfoResult } from 'models/api-models';
import { planNames } from 'utils/consts';
import { Tiers } from 'utils/enums';

export const getOrgPlan = (result: OrgInfoResult[] | undefined): Tiers => {
  if (!result?.length) {
    return Tiers.Free;
  }

  return result[0]?.metric.org_grafana_cloud as Tiers;
};

export const getContract = (result: OrgInfoResult[] | undefined) => {
  return result?.[0]?.metric.org_contract_type;
};

const isAdvanced = (contract: string | undefined) => {
  return contract === 'contracted';
};

export const convertPlan = (plan: Tiers, contract: string | undefined): string => {
  return isAdvanced(contract) ? 'Advanced' : planNames[plan];
};
