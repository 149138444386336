const seconds = Math.floor(Date.now() / 1000);

export const graphiteBody = `
[
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]`;

export const promBody = 'test,bar_label=abc,source=grafana_cloud_docs metric=35.2';

// PYTHON EXAMPLES
export const pythonCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import requests
import json

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

response = requests.post('${url}', 
                         headers = {
                           'Content-Type': 'application/json',
                           'Authorization': f'Bearer {USER_ID}:{API_KEY}'
                         },
                         data = str(json.dumps(body))
)

data = response.json()

print(data)
`;
};

export const pythonCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `
import requests
import base64

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

body = 'test,bar_label=abc,source=grafana_cloud_docs metric=35.2'

response = requests.post('${url}', 
                         headers = {
                           'Content-Type': 'text/plain',
                         },
                         data = str(body),
                         auth = (USER_ID, API_KEY)
)

status_code = response.status_code

print(status_code)
`;
};

// RUBY EXAMPLES
export const rubyCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
require 'faraday'

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

response = Faraday.post('${url}',
    body.to_json, 
    content_type: 'application/json',
    authorization: "Bearer #{USER_ID}:#{API_KEY}"
)

data = JSON.parse(response.body)

puts data
`;
};

export const rubyCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `
require 'net/http'
require 'uri'
require 'base64'

USER_ID = "${userId}"
API_KEY = "${!token ? 'your-api-key' : token}"
BASE64_ENCODED_AUTH = Base64.strict_encode64(USER_ID + ':' + API_KEY)

uri = "${url}"
uri = URI.parse(uri)

body = 'test,bar_label=abc,source=grafana_cloud_docs metric=35.2'

response = Net::HTTP.start(uri.host, uri.port, use_ssl: true) do |client|
  request                  = Net::HTTP::Post.new(uri.path)
  request.body             = body
  request["Authorization"] = "Basic #{BASE64_ENCODED_AUTH}"
  request["Content-Type"]  = "text/plain"
  client.request(request)
end

data = response.code

puts data
`;
};
// NODE JS EXAMPLES

export const nodejsCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `
import fetch from 'node-fetch';

const USER_ID = ${userId}
const API_KEY = "${!token ? 'your-api-key' : token}"

const body = 'test,bar_label=abc,source=grafana_cloud_docs metric=35.2';

const response = await fetch('${url}', {
    method: 'post',
    body,
    headers: {
        'Authorization': \`Bearer $\{USER_ID}\:$\{API_KEY}\`, 
        'Content-Type': 'text/plain',
    },
});

const data = await response.status;

console.log(data);
`;
};

export const nodejsCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import fetch from 'node-fetch';

const USER_ID = ${userId}
const API_KEY = "${!token ? 'your-api-key' : token}"

const body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

const response = await fetch('${url}', {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
        'Authorization': \`Bearer $\{USER_ID}\:$\{API_KEY}\`, 
        'Content-Type': 'application/json',
    },
});


const data = await response.json()

console.log(data);
`;
};
// CURL EXAMPLES

export const curlCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `
API_KEY="${userId}:${!token ? 'your-api-key' : token}"
URL="${url}"

curl -X \
 POST -H \
 "Authorization: Bearer $API_KEY" -H \
 "Content-Type: text/plain" "$URL" -d "test,bar_label=abc,source=grafana_cloud_docs metric=35.2"
`;
};

export const curlCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
  API_KEY="${userId}:${!token ? 'your-api-key' : token}"
  URL="${url}"

curl -X  POST -H  "Authorization: Bearer $API_KEY" -H  "Content-Type: application/json" "$URL" -d '[{
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }]'
`;
};

// GO EXAMPLES
export const goCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
package main
import (
	"fmt"
	"bytes"
	"io/ioutil"
	"net/http"
 )
 

 func main() {
	byteValue := \`
 [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]
 \`

   API_KEY := "${userId}:${!token ? 'your-api-key' : token}"
   URL :="${url}"
	 var bearer = "Bearer " + API_KEY
	 var jsonStr = []byte(byteValue)
	 req, err := http.NewRequest("POST", URL, bytes.NewBuffer(jsonStr))
	 req.Header.Set("Content-Type", "application/json")
	 req.Header.Set("Authorization", bearer)
	 client := &http.Client{}
	 resp, err := client.Do(req)

	 if err != nil {
		 panic(err)
	 }

	 defer resp.Body.Close()
 
	 body, _ := ioutil.ReadAll(resp.Body)
	 fmt.Println("response Body:", string(body))

 }
`;
};

export const goCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `

package main
import (
	"fmt"
	"bytes"
	"net/http"
 )
 

 func main() {
   API_KEY := "${userId}:${!token ? 'your-api-key' : token}"     
   URL := "${url}"
	 BODY := "test,bar_label=abc,source=grafana_cloud_docs metric=35.2"
	 var bearer = "Bearer " + API_KEY
   var byteStr = []byte(BODY)

	 req, err := http.NewRequest("POST", URL, bytes.NewBuffer(byteStr))
	 req.Header.Set("Content-Type", "text/plain")
	 req.Header.Set("Authorization", bearer)
	 client := &http.Client{}
	 resp, err := client.Do(req)

	 if err != nil {
		 panic(err)
	 }
	 defer resp.Body.Close()

	 fmt.Println("response Status:", resp.Status)
	 fmt.Println("body", resp.Body)
 }
`;
};

export const javaCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;
import java.io.BufferedReader;
import java.io.InputStreamReader;

public class Main {
  public static void main(String[] args) {
     try {
         int userId = ${userId}; // your userId
         String apiKey = "${!token ? 'your-api-key' : token}";
         URL url = new URL("${url}");
         HttpURLConnection con = (HttpURLConnection) url.openConnection();
         con.setRequestMethod("POST");
         con.setRequestProperty("Content-Type", "application/json");
         con.setRequestProperty("Authorization", "Bearer " + userId + ":" + apiKey);

         String jsonInputString =
                 "[" +
                 "    {" +
                 "        \"name\": \"test.metric\"," +
                 "        \"interval\": 10," +
                 "        \"value\": 12.345," +
                 "        \"tags\": [\"foo=baz\", \"source=grafana_cloud_docs\"]," +
                 "        \"time\": ${seconds}" +
                 "    }" +
                 "]";

         con.setDoOutput(true);
         OutputStream os = con.getOutputStream();
         os.write(jsonInputString.getBytes());
         os.flush();
         os.close();

         BufferedReader in = new BufferedReader(
                 new InputStreamReader(con.getInputStream()));
         String inputLine;
         StringBuffer response = new StringBuffer();
         while ((inputLine = in.readLine()) != null) {
             response.append(inputLine);
         }
         in.close();

         System.out.println(response);
     } catch (Exception e) {
         System.out.println("Exception: " + e);
     }
     }
  
`;
};

export const javaCodePrometheus = (userId: number, token: string | undefined, url: string) => {
  return `
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;
import java.io.BufferedReader;
import java.io.InputStreamReader;

public class Main {
  public static void main(String[] args) {
      try {
          int userId = ${userId}; // your userId
          String apiKey = "${!token ? 'your-api-key' : token};"
          URL url = new URL("${url}");
          HttpURLConnection con = (HttpURLConnection) url.openConnection();
          con.setRequestMethod("POST");
          con.setRequestProperty("Content-Type", "text/plain");
          con.setRequestProperty("Authorization", "Bearer " + userId + ":" + apiKey);
          String plainText = "test,bar_label=abc,source=grafana_cloud_docs metric=35.2";
          con.setDoOutput(true);
          OutputStream os = con.getOutputStream();
          os.write(plainText.getBytes());
          os.flush();
          os.close();

          int responseCode = con.getResponseCode();
          BufferedReader in = new BufferedReader(
                  new InputStreamReader(con.getInputStream()));
          in.close();
          System.out.println(responseCode);
      } catch (Exception e) {
          System.out.println("Exception: " + e);
      }
  }
}`;
};
