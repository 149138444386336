import React, { FC, useEffect } from 'react';

import { useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { getJobStatusWidgetStyles } from './JobStatusWidget.styles';

export const JobPendingWidget: FC<{ sourceId: string | null }> = ({ sourceId }) => {
  const styles = useStyles2(getJobStatusWidgetStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const message = 'Creating scrape job. It might take up to a minute...';

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.CreateOrUpdateScrapeJobPending, {
      integration_slug: sourceId,
      message,
    });
  }, [sourceId, message, trackRudderStackEvent]);

  return (
    <p
      className={styles.createScrapeJobStatus}
      role="alert"
      aria-label="Checking connection"
      data-testid={Pages.SaaSIntegration.alertParagraph('creation-pending')}
    >
      {message}
    </p>
  );
};
