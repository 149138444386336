import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC } from 'react';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { JobFailedWidget } from './JobFailedWidget';
import { JobPendingWidget } from './JobPendingWidget';
import { JobSuccessWidget } from './JobSuccessWidget';

export const JobStatusWidget: FC = () => {
  const [_, { status, error, data }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });
  const sourceId = useSelectedIntegrationId();

  switch (status) {
    case QueryStatus.rejected:
      return <JobFailedWidget sourceId={sourceId} error={error} />;

    case QueryStatus.fulfilled:
      return <JobSuccessWidget sourceId={sourceId} data={data} />;

    case QueryStatus.pending:
      return <JobPendingWidget sourceId={sourceId} />;

    default:
      return null;
  }
};
