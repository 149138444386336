import React, { FC, useState } from 'react';

import { InlineField, InlineSwitch, useStyles2 } from '@grafana/ui';

import { useGetInstanceConnectionsQuery, useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { TextLink } from 'components/TextLink';
import { useApiToken } from 'hooks/useApiToken';
import { useServiceInfo } from 'pages/Source/ConfigurationDetails/Local/common/useServiceInfo';
import { OtelFlavor } from 'utils/enums';

import { SdkEnvironmentVariables } from './SdkEnvironmentVariables';
import { getCollectorConfig, type OTELCollectorFeatures } from './collector';
import { getStyles } from './styles';

interface CollectorConfigProps {
  features: OTELCollectorFeatures;
}

const CollectorConfig: FC<CollectorConfigProps> = ({ features }) => {
  const apiToken = useApiToken();
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { data: instanceConnectionsDetails } = useGetInstanceConnectionsQuery();

  const config: { [key in string]: number | string | undefined } = {
    GRAFANA_CLOUD_INSTANCE_ID: hostedDataDetails.id,
    GRAFANA_CLOUD_API_KEY: apiToken.token,
    GRAFANA_CLOUD_OTLP_ENDPOINT: `${instanceConnectionsDetails?.otlpHttpUrl}/otlp`,
  };

  const successMessage = 'Copy the generated configuration file as described below!';

  return (
    <GenerateAPI
      config={getCollectorConfig(config, features)}
      instanceId={0}
      shouldShowInstruction={false}
      successMessage={successMessage}
    />
  );
};

const Instructions = () => {
  const styles = useStyles2(getStyles);
  const {
    serviceName,
    serviceNamespace,
    serviceVersion,
    serviceInstanceId,
    form: serviceInfo,
    testButton,
  } = useServiceInfo({ includeServiceInstanceId: true });

  const [features, setFeatures] = useState<OTELCollectorFeatures>({});

  const onFeatureChange = (feature: keyof OTELCollectorFeatures, value: boolean) => {
    setFeatures({ ...features, [feature]: value });
  };

  return (
    <div className={styles.instructions}>
      <ol>
        <li className={styles.tabsWrapper}>
          <h2>Install the contrib distribution</h2>
          <p>
            There are two distributions of the &nbsp;
            <TextLink href="https://grafana.com/docs/opentelemetry/collector/" external inline>
              OpenTelemetry Collector
            </TextLink>
            : core and contrib. We recommend installing the &nbsp;
            <TextLink
              href="https://github.com/open-telemetry/opentelemetry-collector-releases/releases"
              external
              inline
            >
              contrib distribution
            </TextLink>
            &nbsp; as it has the required components we&apos;re going to use.
          </p>
        </li>
        <li>
          <h2>Select optional features</h2>
          <div>
            <InlineField
              tooltip={
                'If enabled, generates host metrics based on telemetry data. Required by Application Observability.'
              }
              label={'Enable Application Observability'}
            >
              <InlineSwitch
                onChange={(e) => onFeatureChange('enableAppO11yHostInfoConnector', e.currentTarget.checked)}
                value={features.enableAppO11yHostInfoConnector ?? false}
              />
            </InlineField>
          </div>
        </li>
        <li>
          <h2>Generate OpenTelemetry Collector Configuration</h2>
          <CollectorConfig features={features} />
        </li>
        <li key="app-env-var-sdk">
          <h2>Sending data from applications</h2>
          <p>
            Configure the OpenTelemetry Auto Instrumentation and SDKs via environment variables to send OTLP data to
            Grafana Alloy. Support for environment variables varies across SDKs - check this{' '}
            <a
              href="https://github.com/open-telemetry/opentelemetry-specification/blob/main/spec-compliance-matrix.md#environment-variables"
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>{' '}
            for details.
          </p>

          {serviceInfo}

          <SdkEnvironmentVariables
            serviceName={serviceName}
            serviceNamespace={serviceNamespace}
            serviceVersion={serviceVersion}
            serviceInstanceId={serviceInstanceId}
            flavor={OtelFlavor.Generic}
          />
        </li>
        <li>
          <h2>Test your connection</h2>
          <p>
            In order to test that your connection is working, you need to first add a <code>service.name</code>.
          </p>

          {testButton}
        </li>
      </ol>
    </div>
  );
};

export const CollectorOpenTelemetryInstructions = () => Instructions();
