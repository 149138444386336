import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

const getJobFormStyles = (theme: GrafanaTheme2) => ({
  job: css({
    position: 'relative',
    backgroundColor: theme.colors.background.primary,
    border: `1px solid ${theme.components.input.borderColor}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '780px',

    label: {
      fontSize: theme.typography.body.fontSize,
    },
  }),
  spacer: css({
    padding: '20px 0',
    borderTop: `1px solid ${theme.components.input.borderColor}`,
  }),
  link: css({
    marginLeft: '0.2rem',
    color: theme.colors.text.link,
    fontSize: theme.typography.body.fontSize,
  }),
  spinner: css({
    marginRight: theme.spacing(1),
  }),
});

const getJobCardStyles = (theme: GrafanaTheme2, { isJobSelected }: { isJobSelected: boolean }) => ({
  wrapper: css({
    backgroundColor: isJobSelected ? theme.colors.action.hover : theme.colors.background.secondary,
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)} 0`,
    width: '100%',
  }),
  table: css({
    fontSize: theme.typography.body.fontSize,
    borderCollapse: 'collapse',
    width: '100%',
    marginTop: theme.spacing(1),

    'thead tr, tfoot tr': {
      backgroundColor: theme.colors.background.primary,
    },

    'tfoot tr': {
      borderTop: `1px solid ${theme.components.input.borderColor}`,

      td: {
        padding: '12px 8px',
      },
    },

    'tbody tr:nth-child(even)': {
      background: theme.colors.background.primary,
    },

    'tbody tr:nth-child(odd)': {
      background: 'transparent',
    },

    td: {
      fontWeight: 400,
      fontSize: theme.typography.bodySmall.fontSize,
    },

    'td, th': {
      width: '140px',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    },
  }),
  icons: css({
    float: 'right',
    cursor: 'pointer',

    '> div': {
      marginLeft: theme.spacing(1),
    },
  }),
  jobSelector: css({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  }),
  jobName: css({
    display: 'flex',
    alignItems: 'baseline',
  }),
  checkbox: css({
    marginRight: '10px',
    position: 'relative',
    top: '2px',

    '> span': {
      fontSize: '19px',
      fontWeight: 400,
    },
  }),
});

const getInstructionsStyles = (theme: GrafanaTheme2) => ({
  instructions: css({
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '40px',
    marginLeft: theme.spacing(3),
  }),
  link: css({
    marginLeft: '0.5rem',
    color: theme.colors.text.link,
    fontSize: theme.typography.body.fontSize,
  }),
  instructionItem: css({
    marginBottom: '40px',
    h2: {
      margin: `${theme.spacing(2)} 0`,
      lineHeight: '26px',
      fontSize: theme.typography.h4.fontSize,
      color: theme.colors.text.maxContrast,
    },
    h3: {
      lineHeight: '22px',
      fontSize: theme.typography.h4.fontSize,
    },
    p: {
      fontSize: theme.typography.body.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
});

export { getJobFormStyles, getJobCardStyles, getInstructionsStyles };
