import { GrafanaDatasource } from 'types/sources';
import { DisabledReasonType } from 'utils/consts';
import { Status, PlanType, SourceType } from 'utils/enums';

/*
 * PROTOCOL MODELS
 */
export interface SuccessfulFetchResponse<T> {
  status: number;
  data: T;
}

export interface ErrorFetchResponse<E> {
  status: number;
  data: E;
}

export function isErrorResponse<D = unknown>(thing: any): thing is ErrorFetchResponse<D> {
  return (
    typeof thing === 'object' &&
    'status' in thing &&
    !isNaN(thing.status) &&
    (thing.status < 200 || thing.status >= 300) &&
    'data' in thing
  );
}

export function isHostedExportersApiErrorResult(thing: any): thing is HostedExportersApiErrorResult {
  if (!thing) {
    return false;
  }
  return (
    typeof thing === 'object' &&
    'error' in thing &&
    typeof thing.error === 'object' &&
    'code' in thing.error &&
    'message' in thing.error
  );
}

// what the integrations api returns in body in case of success
export interface SuccessfulApiResult<D> {
  status: 'success';
  data: D;
}

// what the integrations api resturns in body in case of error
export interface ErrorApiResult {
  status: 'error';
  error: string;
}

export interface HostedExportersApiErrorResult {
  status?: 'error'; // deprecated
  message?: string; // deprecated
  error: {
    message: string;
    code: string;
  };
}

interface ApiErrorResult {
  error: {
    message: string;
    code: string;
  };
}

// integrations-API and Hosted-Exporters-API returns this type of error
export function isIntOrHeApiError(thing: any): thing is ApiErrorResult {
  return (
    typeof thing === 'object' &&
    'error' in thing &&
    typeof thing.error === 'object' &&
    'message' in thing.error &&
    'code' in thing.error
  );
}

export interface CheckConnectionError extends ErrorFetchResponse<HostedExportersApiErrorResult> {
  context: { mainErrorField: string };
}

export function isCheckConnectionError(thing: any): thing is CheckConnectionError {
  return isErrorResponse(thing) && 'context' in thing;
}

/*
 * DATA MODELS
 */

export interface Installation {
  stack_id?: string;
  slug?: string;
  version: string;
  installed_on: string;
  configuration?: {
    configurable_logs?: {
      logs_disabled: boolean;
    };
    configurable_alerts?: {
      alerts_disabled: boolean;
    };
  };
}

interface ApiIntegration {
  name: string;
  installation?: Installation;
  overview?: string;
  logo: {
    dark_theme_url: string;
    light_theme_url: string;
  };
  slug: string;
  version: string;
  type: SourceType.AgentIntegration | SourceType.SaasIntegration | SourceType.MetricsEndpointIntegration;
  dashboard_folder?: string;
  search_keywords: string[];
  categories: SemanticSearchCategoryType[];
  has_update: boolean;
  rule_namespace?: string;
  flavor?: string;
}

export type Screenshot = {
  description: string;
  sort_order: number;
  url: string;
};

export type Metrics = {
  name: string;
  description?: string;
  type?: string;
};

export type Alerts = {
  name: string;
  summary?: string;
  namespace?: string;
  group?: string;
};

export type Rules = {
  name?: string;
  namespace?: string;
  group?: string;
};

export type VersionHistory = {
  Date: string;
  SortOrder: number;
  Text: string;
  Version: string;
};

export interface AlertDetails {
  status: Status;
  available_alerts?: Alerts[];
}

export interface RuleDetails {
  status: Status;
  available_rules?: Rules[];
}

export interface ApiIntegrationDetails extends ApiIntegration {
  agent_configuration?: {
    supported_platforms?: string[];
  };
  dashboards: {
    status: Status;
    screenshots: Screenshot[];
  };
  metrics?: {
    status: Status;
    available_metrics?: Metrics[];
  };
  alerts?: {
    status: Status;
    available_alerts?: Alerts[];
  };
  rules?: {
    status: Status;
    available_rules?: Rules[];
  };
  logs?: {
    status: Status;
  };
  version_history?: VersionHistory[];
  metrics_check_query?: string;
  logs_check_query?: string;
  configuration_defaults: {
    enable_logs_toggle: boolean;
    configurable_logs_defaults: {
      logs_disabled: boolean;
    };
    enable_alerts_toggle: boolean;
    configurable_alerts_defaults: {
      alerts_disabled: boolean;
    };
  };
}

export interface HostedDataDetails {
  orgSlug: string;
  orgId: number;
  slug: string;
  id: number;
  trial: number;
  trialExpiresAt: string;
  plan: PlanType;
  hmInstancePromId: number;
  hlInstanceId: number;
  hmInstanceGraphiteId: number;
  htInstanceId: number;
  hpInstanceId: number;
  hlInstanceUrl: string;
  hmInstanceGraphiteUrl: string;
  hmInstancePromUrl: string;
  hmInstanceGraphiteType: string;
  htInstanceUrl: string;
  hpInstanceUrl: string;
  [val: string]: string | number;
  regionSlug: string;
  url: string;
}

export interface InstanceConnectionsDetails {
  privateConnectivityInfo: {
    tenants: Array<{
      type: string;
      id: number;
      info: {
        serviceName: string;
        privateDNS: string;
      };
    }>;
    otlp: {
      serviceName: string;
      privateDNS: string;
    };
  };
  appPlatform: {
    url: string;
    caData: string;
  };
  influxUrl: string;
  otlpHttpUrl: string;
}

export type ApiIntegrationResult = Record<string, ApiIntegration>;

export type Resource = {
  resourceId: string;
  resourceType: string;
};

export enum MetricsEndpointFlavor {
  Default = 'default',
  Supabase = 'supabase',
}

export interface MetricsEndpointJob {
  bearerToken?: string;
  basicPassword?: string;
  basicUsername?: string;
  url: string;
  scrapeIntervalSeconds: number;
  name: string;
  authenticationMethod: string;
  flavor: MetricsEndpointFlavor;
  enabled: boolean;
  disabledReason?: DisabledReasonType;
}

export interface MetricsEndpointJobApiResponse {
  bearer_token?: string;
  basic_password?: string;
  basic_username?: string;
  url?: string;
  scrape_interval_seconds: number;
  authentication_method?: string;
  name: string;
  flavor: MetricsEndpointFlavor;
  enabled: boolean;
  disabled_reason?: DisabledReasonType;
}
export interface ConfluentJob {
  apiKey?: string;
  apiSecret?: string;
  name: string;
  resources: Resource[];
  enabled: boolean;
  disabled_reason?: DisabledReasonType;
}

export interface ConfluentJobApiResponse {
  api_key: {
    name?: string;
    secret?: string;
  };
  kafka_ids?: string[];
  connector_ids?: string[];
  ksql_ids?: string[];
  schema_registry_ids?: string[];
  name: string;
  enabled: boolean;
  disabled_reason?: DisabledReasonType;
}

export interface SupabaseJob {
  scrapeIntervalSeconds: number;
  name: string;
  flavor: MetricsEndpointFlavor.Supabase;
  enabled: boolean;
  disabledReason?: DisabledReasonType;
  apiKey: string;
  projectId: string;
}

export interface SupabaseJobApiResponse {
  scrape_interval_seconds: number;
  name: string;
  flavor: MetricsEndpointFlavor.Supabase;
  enabled: boolean;
  disabled_reason?: DisabledReasonType;
  supabase: {
    api_key?: string;
    project_id?: string;
  };
}

export type ConfluentAccount = {
  api_key: string;
  api_secret: string;
};

export type MetricsEndpointCredentials = {
  flavor: string;
  bearer_token: string;
  basic_password: string;
  basic_username: string;
  authentication_method: string;
  url: string;
};

export type SupabaseCredentials = {
  flavor: MetricsEndpointFlavor.Supabase;
  supabase: { api_key: string; project_id: string };
};

// Extend these for other SaaS integrations
export type ScrapeJob = ConfluentJobApiResponse | MetricsEndpointJobApiResponse | SupabaseJobApiResponse;
export type SaasIntegrationConnection = ConfluentAccount | MetricsEndpointCredentials | SupabaseCredentials;
export type ScrapeJobApiResponse = MetricsEndpointJobApiResponse | SupabaseJobApiResponse;

export interface DashboardInstallInfo {
  dashboard: { uid: string };
  folder_name: string;
  overwrite: boolean;
}

export interface GrafanaDatasourceResponse {
  items: GrafanaDatasource[];
  direction: string;
  orderBy: string;
}

export type OrgInfoResult = {
  value: Array<number | string>;
  metric: {
    cluster: string;
    hg_included_users: string;
    hl_included_usage: string;
    hl_retention_included_usage: string;
    hm_included_series: string;
    ht_included_usage: string;
    org_contract_type: string;
    org_grafana_cloud: string;
    org_id: string;
    trial_end_date?: string;
    org_name: string;
    org_slug: string;
  };
};

export interface QueryDatasourceResult {
  // its properties can vary depending on the query
  metric: object;
  // value looks like: [timestamp: number; value: string], e.g. [1688025044,"0"]
  value: Array<number | string>;
}

export const allSemanticSearchCategories = [
  'APM',
  'Business Intelligence',
  'CI/CD',
  'Cloud Provider',
  'Database',
  'Data Format',
  'Data Transformation',
  'Data Warehouse',
  'Developer Tools',
  'IoT',
  'Languages and Frameworks',
  'Logs',
  'Machine Learning',
  'Message Queue',
  'Networking',
  'NoSQL',
  'Operating System',
  'Profiling',
  'Security',
  'Servers and VMs',
  'SQL',
  'Time Series',
  'Traces',
  'Web Servers and Proxies',
] as const;

export type SemanticSearchCategoryType = (typeof allSemanticSearchCategories)[number];
