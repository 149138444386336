import React from 'react';
import { useSelector } from 'react-redux';

import { Button, useStyles2, Spinner } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { StatusMessage } from 'components/StatusMessage';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { installSingleIntegration } from 'state/source/actions';
import { InstallationStatus } from 'state/source/slice';
import { RudderStackEvents, Status } from 'utils/enums';

import { getInstallStyles } from './Install.styles';

const InstallButton = ({
  disabled,
  showSpinner,
  buttonText,
}: {
  disabled: boolean;
  showSpinner: boolean;
  buttonText?: string;
}) => {
  const styles = useStyles2(getInstallStyles);
  const dispatch = useDispatch();
  const { trackRudderStackEvent } = useRudderStack();
  const { orgSlug } = useHostedDataDetailsWithFallback();
  const { isLogsEnabled } = useSelector((state: RootState) => state.agent.configuredParameters);
  const integrationId = useSelectedIntegrationId();

  return (
    <Button
      disabled={disabled}
      onClick={() => {
        dispatch(installSingleIntegration({ integrationId, orgSlug }));
        trackRudderStackEvent(RudderStackEvents.InstallButton, {
          integration_slug: integrationId,
          logs_enabled: isLogsEnabled,
        });
      }}
      data-testid={Pages.Source.Config.install}
    >
      {showSpinner && <Spinner className={styles.spinner} />}
      {buttonText ?? 'Install'}
    </Button>
  );
};

export const Install = ({ buttonText }: { buttonText?: string }) => {
  const styles = useStyles2(getInstallStyles);
  const { integrationInstallationStatus } = useSelector((state: RootState) => state.source);

  const selectedIntegration = useSelectedAgentOrSaasIntegration();

  const { alerts, dashboards } = useSelector((state: RootState) => state.source.sourceDetails);
  const {
    configuredParameters: { isAlertsEnabled },
  } = useSelector((state: RootState) => state.agent);
  const areAlertsAvailable = alerts?.status === Status.Available;
  const showAlertsText = isAlertsEnabled === undefined ? true : isAlertsEnabled;
  const displayAlertsInTheText = areAlertsAvailable && showAlertsText;

  const areDashboardsAvailable = dashboards?.status === Status.Available;
  const dashboardsAndAlertsText = areDashboardsAvailable
    ? displayAlertsInTheText
      ? 'Dashboards and alerts'
      : 'Dashboards'
    : displayAlertsInTheText
      ? 'Alerts'
      : '';

  switch (integrationInstallationStatus) {
    case InstallationStatus.Idle:
      return (
        <div className={styles.marginBottom}>
          <InstallButton
            disabled={selectedIntegration?.installation !== undefined}
            showSpinner={false}
            buttonText={buttonText}
          />
        </div>
      );

    case InstallationStatus.Pending:
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={true} showSpinner={true} buttonText={buttonText} />
        </div>
      );

    case InstallationStatus.Success:
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={true} showSpinner={false} buttonText={buttonText} />
          <StatusMessage status="success" className={styles.alert} data-testid="install-success-message">
            {dashboardsAndAlertsText} have been installed.
          </StatusMessage>
        </div>
      );

    case InstallationStatus.Error:
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={false} showSpinner={false} buttonText={buttonText} />
          <StatusMessage status="error" className={styles.alert} data-testid="install-error-message">
            Something went wrong. Please try again.
          </StatusMessage>
        </div>
      );
  }
};
