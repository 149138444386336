import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getDashboardScreenshotsStyles = (theme: GrafanaTheme2) => ({
  screenshotsWrapper: css`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: ${theme.spacing(3)};
    margin-right: ${theme.spacing(3)};
  `,
  modal: css`
    width: 80vw;
  `,
  img: css`
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    border: 1px solid ${theme.colors.border.medium};
  `,
  overlayText: css`
    position: absolute;
    top: -5%;
    left: 0;
  `,
  arrowLeft: css`
    position: absolute;
    top: 50%;
    left: -3vw;
    transform: translateY(-50%);
    cursor: pointer;
  `,
  arrowRight: css`
    position: absolute;
    top: 50%;
    right: -3vw;
    transform: translateY(-50%);
    cursor: pointer;
  `,
  arrowIcon: css`
    background: ${theme.colors.background.primary};
  `,
  disabled: css`
    cursor: not-allowed;
  `,
});
