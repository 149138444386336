import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Modal, Button, useStyles2, ButtonVariant, Alert, AlertVariant } from '@grafana/ui';

import {
  useDeleteScrapeJobsMutation,
  useDisableScrapeJobsMutation,
  useEnableScrapeJobsMutation,
} from 'api/hostedExporters/hostedExportersApi';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { RootState } from 'state';
import { deselectJob, selectJob, resetSelectedJobs, SaasIntegrationWithJobType } from 'state/saasIntegration/slice';
import { useFilteredJobs } from 'state/saasIntegration/useFilteredJobs';

import { JobStatusFilter } from './JobStatusFilter';
import { getStyles } from './ScrapeJobActions.styles';

enum ScrapeJobActionType {
  Disable,
  Enable,
  Delete,
}

type ActionButtonProps = {
  open: boolean;
  onConfirm?: () => void;
  closeModal?: () => void;
  onClick?: () => void;
  mainButtonText: string;
  confirmText: string;
  description: string;
  variant: ButtonVariant;
  confirmButtonVariant: ButtonVariant;
  confirmButtonDisabled: boolean;
};

export const ActionButton = ({
  open,
  onClick,
  onConfirm,
  closeModal,
  mainButtonText,
  confirmText,
  confirmButtonVariant,
  description,
  variant,
  confirmButtonDisabled = false,
}: ActionButtonProps) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <Button aria-label="button" variant={variant} onClick={onClick}>
        {mainButtonText}
      </Button>
      <Modal className={styles.modalWrapper} isOpen={open} title={confirmText} onDismiss={closeModal}>
        <div>
          {description}
          <div className={styles.buttonWrapper}>
            <Button aria-label="button" className={styles.cancelButton} onClick={closeModal} variant="secondary">
              Cancel
            </Button>
            <Button
              aria-label="button"
              variant={confirmButtonVariant}
              onClick={onConfirm}
              disabled={confirmButtonDisabled}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const getJobUpdateMessage = (action: ScrapeJobActionType, selectedJobs: number) => {
  const updateMessage = {
    [ScrapeJobActionType.Enable]: `${selectedJobs} jobs successfully enabled`,
    [ScrapeJobActionType.Disable]: `${selectedJobs} jobs successfully disabled`,
    [ScrapeJobActionType.Delete]: `${selectedJobs} jobs successfully deleted`,
  }[action];

  return updateMessage;
};

export const ScrapeJobActions = ({ saasIntegrationId }: { saasIntegrationId: SaasIntegrationWithJobType }) => {
  const styles = useStyles2(getStyles);
  const { selectedJobs } = useSelector((state: RootState) => state.saasIntegrations);
  const [activeButtonAction, setActiveButtonAction] = useState<ScrapeJobActionType | null>(null);
  const [jobUpdateMessage, setJobUpdateMessage] = useState<{ message: string; severity: AlertVariant } | null>(null);
  const filteredJobs = useFilteredJobs();
  const dispatch = useDispatch();

  const [enableScrapeJobs, { status: enableStatus }] = useEnableScrapeJobsMutation();
  const [disableScrapeJobs, { status: disableStatus }] = useDisableScrapeJobsMutation();
  const [deleteScrapeJobs, { status: deleteStatus }] = useDeleteScrapeJobsMutation();

  const [jobQueryState, setJobQueryState] = useState(QueryStatus.uninitialized);

  useEffect(() => {
    switch (activeButtonAction) {
      case ScrapeJobActionType.Enable:
        setJobQueryState(enableStatus);
        break;
      case ScrapeJobActionType.Disable:
        setJobQueryState(disableStatus);
        break;
      case ScrapeJobActionType.Delete:
        setJobQueryState(deleteStatus);
        break;
      default:
        break;
    }
  }, [activeButtonAction, enableStatus, disableStatus, deleteStatus]);

  useEffect(() => {
    if (jobQueryState === QueryStatus.fulfilled) {
      setJobUpdateMessage({
        message: getJobUpdateMessage(activeButtonAction as ScrapeJobActionType, selectedJobs.length),
        severity: 'success',
      });
    } else {
      setJobUpdateMessage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobQueryState]);

  // Clear the message from the screen
  useEffect(() => {
    if (jobUpdateMessage) {
      setActiveButtonAction(null);
      dispatch(resetSelectedJobs());

      // Remove message
      const timer = setTimeout(() => {
        setJobUpdateMessage(null);
        clearTimeout(timer);
      }, 3000);
    }
  }, [jobUpdateMessage, dispatch, saasIntegrationId]);

  const onSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const toggleJobs = e.target.checked ? selectJob : deselectJob;
      filteredJobs.forEach((job) => dispatch(toggleJobs(job.name)));
    },
    [dispatch, filteredJobs]
  );

  const getCommonProps = (actionType: ScrapeJobActionType) => ({
    onClick: () => setActiveButtonAction(actionType),
    closeModal: () => setActiveButtonAction(null),
    open: activeButtonAction === actionType,
    key: actionType,
  });

  const actions = [
    {
      ...getCommonProps(ScrapeJobActionType.Enable),
      mainButtonText: 'Enable',
      description: `You are about to enable ${selectedJobs.length} scrape job(s). This means that metrics will start to be scraped from these services, which will result in additional active series in Grafana Cloud.`,
      confirmText: jobQueryState === QueryStatus.pending ? 'Enabling...' : 'Enable scrape jobs',
      variant: 'primary' as ButtonVariant,
      confirmButtonVariant: 'primary' as ButtonVariant,
      onConfirm: () => {
        enableScrapeJobs(selectedJobs);
      },
    },
    {
      ...getCommonProps(ScrapeJobActionType.Disable),
      mainButtonText: 'Disable',
      description: `You are about to disable ${selectedJobs.length} scrape job(s). This means that metrics will no longer be scraped from these services until you enable the scrape job again.`,
      confirmText: jobQueryState === QueryStatus.pending ? 'Disabling...' : 'Disable scrape jobs',
      variant: 'secondary' as ButtonVariant,
      confirmButtonVariant: 'primary' as ButtonVariant,
      onConfirm: () => {
        disableScrapeJobs(selectedJobs);
      },
    },
    {
      ...getCommonProps(ScrapeJobActionType.Delete),
      mainButtonText: 'Delete',
      description: `You are about to delete ${selectedJobs.length} scrape job(s). This is a permanent action, and it means that metrics will no longer be scraped from these services.`,
      confirmText: jobQueryState === QueryStatus.pending ? 'Deleting...' : 'Delete scrape jobs',
      variant: 'destructive' as ButtonVariant,
      confirmButtonVariant: 'destructive' as ButtonVariant,
      onConfirm: () => {
        deleteScrapeJobs(selectedJobs);
      },
    },
  ];

  let allScrapeJobsSelected = filteredJobs.length > 0 && selectedJobs.length === filteredJobs.length;
  let checkboxLabel = allScrapeJobsSelected ? 'Deselect all scrape jobs' : 'Select all scrape jobs';

  return (
    <>
      {jobUpdateMessage && (
        <Alert className={styles.alert} title={jobUpdateMessage.message} severity={jobUpdateMessage.severity} />
      )}
      <div className={styles.wrapper}>
        {filteredJobs.length > 0 && (
          <div className={styles.selectedJobs}>
            <Checkbox
              data-testid={Pages.SaaSIntegration.selectAllScrapeJobs}
              value={allScrapeJobsSelected}
              className={styles.selectAllCheckbox}
              onChange={onSelectAll}
              label={checkboxLabel}
            />
          </div>
        )}
        <div className={styles.selectedJobs}>
          {selectedJobs.length > 0 && (
            <>
              <span className={styles.selectedJobsMessage}>Amount of selected scrape jobs: {selectedJobs.length}</span>
              <div>
                {actions.map((action) => (
                  <ActionButton
                    {...action}
                    key={action.key}
                    confirmButtonDisabled={jobQueryState === QueryStatus.pending}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <JobStatusFilter />
      </div>
    </>
  );
};
