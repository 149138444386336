import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { TextLink } from 'components/TextLink';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import alertIcon from 'img/alert-icon.svg';
import compassIcon from 'img/compass-icon.svg';
import { CloudIcon, DashboardIcon, PaperIcon, RightCircleIcon } from 'img/hostedData';
import { RootState } from 'state';
import { fetchBillingUrl } from 'state/dashboards/actions';
import { HOSTED_GRAPHITE_METRICS_ID } from 'utils/localSources/hostedGraphiteMetrics';
import { HOSTED_LOGS_ID } from 'utils/localSources/hostedLogs';
import { HOSTED_PROMETHEUS_METRICS_ID } from 'utils/localSources/hostedPrometheusMetrics';
import { HOSTED_TRACES_ID } from 'utils/localSources/hostedTraces';
import { HOSTED_PROFILES_ID } from 'utils/localSources/pyroscope';

import { LinksList } from './LinksList';

const getStyles = (theme: GrafanaTheme2) => ({
  titleWrapper: css({
    marginBottom: '60px',
  }),

  title: css({
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  }),
  subTitle: css({
    fontSize: '19px',
    color: theme.colors.text.primary,
  }),
  item: css({
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '50px',
    '&:last-child': {
      marginBottom: 0,
    },

    h2: {
      fontSize: theme.typography.h4.fontSize,
    },
  }),
  itemContent: css({
    marginLeft: '20px',
    top: '3px',
    position: 'relative',

    h2: {
      marginBottom: '16px',
    },

    '> p': {
      color: theme.colors.text.secondary,
    },
  }),
});

const getExploringLink = (slug: string) => {
  return [
    {
      label: 'Search for recent traces',
      url: `/explore?left=grafanacloud-${slug}-traces`,
      icon: RightCircleIcon,
    },
  ];
};

const getExploringProfilesLink = (slug: string) => {
  return [
    {
      label: 'Search for recent profiles',
      url: `/explore?left=grafanacloud-${slug}-profiles`,
      icon: RightCircleIcon,
    },
  ];
};

const commonAlertLinks = [
  {
    label: 'Create alert rules',
    url: '/alerting/new',
    icon: CloudIcon,
  },
  {
    label: 'Define routing and notification channels',
    url: '/alerting/routes',
    icon: PaperIcon,
  },
];

const relatedContentDetails: {
  [key: string]: {
    title: string;
    alertTitle: string;
    icon: string;
    alt: string;
    links: (slug: string) => Array<{ label: string; url: string; icon: string }>;
  };
} = {
  [HOSTED_PROMETHEUS_METRICS_ID]: {
    title: 'Access your Prometheus metrics in Grafana Cloud through pre-built or custom dashboards and alerts.',
    alertTitle: 'Configure Grafana Cloud alerts for your Prometheus data',
    icon: alertIcon,
    alt: 'Alerting Icon',
    links: () => commonAlertLinks,
  },
  [HOSTED_LOGS_ID]: {
    title: 'Access your logs in Grafana Cloud through pre-built or custom dashboards and alerts.',
    alertTitle: 'Configure Grafana Cloud alerts for your Loki data',
    icon: alertIcon,
    alt: 'Alerting Icon',
    links: () => commonAlertLinks,
  },
  [HOSTED_GRAPHITE_METRICS_ID]: {
    title: 'Access your Graphite metrics in Grafana Cloud through pre-built or custom dashboards and alerts.',
    alertTitle: 'Configure Grafana Cloud alerts for your Graphite data',
    icon: alertIcon,
    alt: 'Alerting Icon',
    links: () => commonAlertLinks,
  },
  [HOSTED_TRACES_ID]: {
    title: 'Access your Tempo traces in Grafana Cloud through pre-built or custom dashboards and alerts.',
    alertTitle: 'Explore interactions with your Tempo data',
    icon: compassIcon,
    alt: 'Exploring Icon',
    links: (slug: string) => getExploringLink(slug),
  },
  [HOSTED_PROFILES_ID]: {
    title: 'Access your Pyroscope profiles in Grafana Cloud through pre-built or custom dashboards and alerts.',
    alertTitle: 'Explore interactions with your Pyroscope data',
    icon: compassIcon,
    alt: 'Exploring Icon',
    links: (slug: string) => getExploringProfilesLink(slug),
  },
};

export const hostedDataIntegrationIdsWithRelatedContentTab = Object.keys(relatedContentDetails);

export function RelatedContent() {
  const styles = useStyles2(getStyles);

  const integrationId = useSelectedIntegrationId();

  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { billingUrl } = useSelector((state: RootState) => state.dashboards);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!billingUrl) {
      dispatch(fetchBillingUrl());
    }
  }, [billingUrl, dispatch]);

  const commonVisualLinks = [
    {
      label: 'Check out these popular dashboard templates',
      url: 'https://grafana.com/grafana/dashboards',
      icon: RightCircleIcon,
    },
    {
      label: 'Keep tabs on your usage with this Usage dashboard',
      url: billingUrl,
      icon: RightCircleIcon,
    },
    {
      label: 'Or create a new dashboard',
      url: '/dashboard/new',
      icon: RightCircleIcon,
    },
  ];

  const { title, alertTitle, icon, alt, links } = relatedContentDetails[integrationId];

  return (
    <>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{title}</p>
      </div>

      <div className={styles.item}>
        <img src={DashboardIcon} alt="Dashboard Icon" />
        <div className={styles.itemContent}>
          <h2>Visualize your data</h2>
          <LinksList links={commonVisualLinks} />
          <TextLink href="https://grafana.com/docs/" external inline>
            Check out our docs for best practices
          </TextLink>
        </div>
      </div>

      <div className={styles.item}>
        <img src={icon} alt={alt} />
        <div className={styles.itemContent}>
          <h2>{alertTitle}</h2>
          <LinksList links={links(hostedDataDetails.slug)} />
        </div>
      </div>
    </>
  );
}
