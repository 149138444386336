import { SelectableValue } from '@grafana/data';

// Based on types of react-select, but without readonly.
export interface GroupBase<Option> {
  readonly options: Option[];
  readonly label?: string;
}

// Based on types of react-select, but without readonly.
export type OptionsOrGroups<Option> = Array<Option | GroupBase<Option>>;

export enum SemanticSearchOptionType {
  Category = 'category',
  SourceWithLink = 'sourceWithLink',
  SourceWithModal = 'sourceWithModal',
}

export type SemanticSearchOption =
  | (SelectableValue<string> & { type: SemanticSearchOptionType.Category })
  | (SelectableValue<string> & { type: SemanticSearchOptionType.SourceWithLink; url: string })
  | (SelectableValue<string> & { type: SemanticSearchOptionType.SourceWithModal });
